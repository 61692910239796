// @flow
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { getShippingApi, performShippingApi } from './api';

import type { RootState } from '../index';
import type { HTTPError } from '../../common/error';
import type { PerformShippingRequest } from './api';

// Actions
const GET_SHIPPING_REQUEST = 'GET_SHIPPING_REQUEST';
const GET_SHIPPING_SUCCESS = 'GET_SHIPPING_SUCCESS';
const GET_SHIPPING_ERROR = 'GET_SHIPPING_ERROR';
const PERFORM_SHIPPING_SHIPPING_REQUEST = 'PERFORM_SHIPPING_SHIPPING_REQUEST';
const PERFORM_SHIPPING_SHIPPING_SUCCESS = 'PERFORM_SHIPPING_SHIPPING_SUCCESS';
const PERFORM_SHIPPING_SHIPPING_ERROR = 'PERFORM_SHIPPING_SHIPPING_ERROR';
const CHANGE_SHIPPING_PAGINATION = 'CHANGE_SHIPPING_PAGINATION';
const RESET_SHIPPING_STATE = 'RESET_SHIPPING_STATE';

type ShippingAction = {
  +type: string;
  +shippingOrders?: { [key: string]: any }[];
  +totalShippingOrders?: number;
  +shippingRequest?: { [key: string]: any };
  +changes?: { [key: string]: any };
  +error?: HTTPError;
};

export type ShippingState = {
  page: number;
  limit: number;
  isLoadingGetShipping?: boolean;
  getShippingSuccess?: boolean;
  getShippingError?: HTTPError;
  isLoadingPerformShipping?: boolean;
  performShippingSuccess?: boolean;
  performShippingError?: HTTPError;
  shippingOrders?: { [key: string]: any }[];
  totalShippingOrders?: number;
};

// Action Creators
export function executeGetShipping(filter?: { [filterKey: string]: string, page: 0 }): ShippingAction {
  return {
    type: GET_SHIPPING_REQUEST,
    filter,
  };
}

export function executeGetShippingSuccess(shippingOrders: { [key: string]: any }[]/*, totalShippingOrders: number*/): ShippingAction {
  return {
    type: GET_SHIPPING_SUCCESS,
    shippingOrders,
    // totalShippingOrders,
  };
}

export function executeGetShippingError(error: HTTPError): ShippingAction {
  return {
    type: GET_SHIPPING_ERROR,
    error,
  };
}

export function executePerformShipping(request: PerformShippingRequest): ShippingAction {
  return {
    type: PERFORM_SHIPPING_SHIPPING_REQUEST,
    request,
  };
}

export function executePerformShippingSuccess(): ShippingAction {
  return {
    type: PERFORM_SHIPPING_SHIPPING_SUCCESS,
  };
}

export function executePerformShippingError(error: HTTPError): ShippingAction {
  return {
    type: PERFORM_SHIPPING_SHIPPING_ERROR,
    error,
  };
}

export function changeShippingPagination(changes: { [key: string]: any }) {
  return {
    type: CHANGE_SHIPPING_PAGINATION,
    changes,
  };
}

export function executeResetShippingState(): ShippingAction {
  return {
    type: RESET_SHIPPING_STATE,
  };
}

export const actions = {
  GET_SHIPPING_REQUEST,
  GET_SHIPPING_SUCCESS,
  GET_SHIPPING_ERROR,
  PERFORM_SHIPPING_SHIPPING_REQUEST,
  PERFORM_SHIPPING_SHIPPING_SUCCESS,
  PERFORM_SHIPPING_SHIPPING_ERROR,
  CHANGE_SHIPPING_PAGINATION,
  RESET_SHIPPING_STATE,
  executeGetShipping,
  executeGetShippingSuccess,
  executeGetShippingError,
  executePerformShipping,
  executePerformShippingSuccess,
  executePerformShippingError,
  changeShippingPagination,
  executeResetShippingState,
};

export const initialState: ShippingState = {
  page: 0,
  limit: 10,
};

// Reducer
export default function reducer(state: ShippingState = initialState,
                                action: ShippingAction): ShippingState {
  switch (action.type) {
    case GET_SHIPPING_REQUEST:
      return {
        ...state,
        isLoadingGetShipping: true,
        getShippingSuccess: false,
      };
    case GET_SHIPPING_SUCCESS:
      return {
        ...state,
        isLoadingGetShipping: false,
        getShippingSuccess: true,
        shippingOrders: action.shippingOrders,
        totalShippingOrders: action.totalShippingOrders,
      };
    case GET_SHIPPING_ERROR:
      return {
        ...state,
        getShippingSuccess: false,
        getShipOrderError: action.error,
      };
    case PERFORM_SHIPPING_SHIPPING_REQUEST:
      return {
        ...state,
        isLoadingPerformShipping: true,
        performShippingSuccess: false,
      };
    case PERFORM_SHIPPING_SHIPPING_SUCCESS:
      return {
        ...state,
        isLoadingPerformShipping: false,
        performShippingSuccess: true,
      };
    case PERFORM_SHIPPING_SHIPPING_ERROR:
      return {
        ...state,
        performShippingSuccess: false,
        performShippingError: action.error,
      };
    case CHANGE_SHIPPING_PAGINATION:
      const data = { ...action.changes };
      return { ...state, ...data };
    case RESET_SHIPPING_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetShippingEpic(action$: ActionsObservable<ShippingAction>, state$: StateObservable<RootState>) {
  return action$.ofType(GET_SHIPPING_REQUEST).pipe(
    mergeMap(
      ({ filter }) => getShippingApi({ ...filter }).pipe(
        map((shippingOrders) => executeGetShippingSuccess(shippingOrders)),
        catchError((e: HTTPError) => of(executeGetShippingError(e)))),
    )
  );
}

export function executePerformShippingEpic(action$: ActionsObservable<ShippingAction>) {
  return action$.ofType(PERFORM_SHIPPING_SHIPPING_REQUEST).pipe(
    mergeMap(
      ({ request }) => performShippingApi(request).pipe(
        map(() => executePerformShippingSuccess()),
        catchError((e: HTTPError) => of(executePerformShippingError(e))))
    )
  );
}

export function executeGetShippingAfterSuccessEpic(action$: ActionsObservable<ShippingAction>) {
  return action$.ofType(PERFORM_SHIPPING_SHIPPING_SUCCESS).pipe(
    map(() => executeGetShipping()),
  );
}

export function changeShippingPaginationEpic(action$: ActionsObservable<ShippingAction>) {
  return action$.ofType(CHANGE_SHIPPING_PAGINATION).pipe(
    map(() => executeGetShipping(),
    )
  );
}
