// @flow
// import { Observable } from 'rxjs';
import { getCreator, putCreator, /*postCreator, putCreator, deleteCreator*/ } from '../../common/http'
import environment from '../../environment';

export const endpoints = Object.freeze({
  GET_POPULAR_PRODUCTS: `${environment.apiUrl}/administrative/product/popular/list`,
  UPDATE_POPULAR_PRODUCTS: `${environment.apiUrl}/administrative/product/popular`,
});

// export const getPopularProductsApi = (queryParams: any) => Observable.create(
//   (observer) => {
//     let popularProducts;
//     const { page, limit, ord, sort } = queryParams;
//
//     popularProducts = stableSort(apiPopularProducts, getSorting(ord, sort))
//       .slice(page * limit, page * limit + limit);
//     const totalPopularProducts = apiPopularProducts.length;
//
//     observer.next({ popularProducts, totalPopularProducts });
//   });

export const getPopularProductsApi = getCreator(fetch, endpoints.GET_POPULAR_PRODUCTS);

// export const changeVisibilityPopularProductsApi = (data: any, o: any) => Observable.create(
//   (observer) => {
//     const { id, value } = data;
//     console.log('data', data);
//     const productIndex = apiPopularProducts.findIndex(e => e.id === id);
//     apiPopularProducts[productIndex].visibility = value;
//     observer.next(apiPopularProducts);
//   });

export const changeVisibilityPopularProductsApi = putCreator(fetch, endpoints.UPDATE_POPULAR_PRODUCTS);
