// @flow
export default function styles(theme: { [key: string]: any }) {
    return ({
        container: {
            padding: theme.spacing.unit * 3,
        },
        imagePreview: {
            padding: theme.spacing.unit * 2,
        },
        imageElement: {
            width: 213,
            height: 213,
            display: 'block',
            "object-fit": "contain"
        },
        formControlMarginNormal: {
            marginTop: 16,
            marginBottom: 8,
        },
    });
}
