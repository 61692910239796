// @flow
import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditDeliveryConfirmCell from './EditDeliveryIdCell';
import red from '@material-ui/core/colors/red';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';

import styles from '../style';

type Props = {
  +classes: { [key: string]: any };
  +handleSelect: (e: Event, orderId: string) => void;
  +handleEditing: (e: Event, orderId: string) => void;
  +handleModal: (lastMileShippingId: number) => void;
  +handleUpdateDeliveryConfirm: (id: string, deliveryConfirmId: string) => void;
  +handleConfirmDeliveryConfirm: (lastMileShippingId: number) => void;
  +handleDeliveryNumberChange: (e: Event, orderId: string) => void;
  +isLoading?: boolean;
  +deliveryConfirmOrders?: { [key: string]: any }[];
  +stateOrders: { [key: string]: any };
  +page: number;
  +rowsPerPage: number;
};

const innerTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    secondary: {
      main: red[500],
    },
  },
});

class DeliveryConfirmTableBody extends Component<Props> {
  getStateValues = (orderId: string, trackingNumber: string): { [key: string]: any } => {
    const { stateOrders } = this.props;
    const stateValues = {
      selected: false,
      editing: false,
      trackingNumber,
    };
    if (stateOrders[orderId]) {
      if (stateOrders[orderId].selected) {
        stateValues.selected = stateOrders[orderId].selected;
      }
      if (typeof stateOrders[orderId].trackingNumber === 'string') {
        stateValues.trackingNumber = stateOrders[orderId].trackingNumber;
      }
      if (stateOrders[orderId].editing) {
        stateValues.editing = stateOrders[orderId].editing
      }
    }
    return stateValues;
  };

  buildOrders = (deliveryConfirmOrders: { [key: string]: any }[]) => {
    const {
      classes, handleSelect, handleUpdateDeliveryConfirm, handleModal,
      handleEditing, handleConfirmDeliveryConfirm, handleDeliveryNumberChange,
    } = this.props;
    return deliveryConfirmOrders.map((order, index) => {
      const items = this.buildItems(order.items);
      const stateValues = this.getStateValues(order.order.id, order.trackingNumber);
      const title = (
        <Grid container>
          <Grid item container xs={4}>
            <Grid item container xs={2}>
              <Checkbox checked={stateValues.selected}
                        onChange={(e) => handleSelect(e, order.order.id)}/>
            </Grid>
            <EditDeliveryConfirmCell trackingNumber={stateValues.trackingNumber}
                                     handleDeliveryNumberChange={handleDeliveryNumberChange}
                                     editing={stateValues.editing}
                                     orderId={order.order.id}
                                     handleEditing={handleEditing}
                                     lastMileShippingId={order.lastMileShippingId}
                                     handleUpdateDeliveryConfirm={handleUpdateDeliveryConfirm}/>
          </Grid>
          <Grid item container xs={4}>
            <Grid item container xs={6}>
              <div className={classes.textContainer}>
                <h6 className={classes.title}>{order.order.id}</h6>
              </div>
            </Grid>
            <Grid item container xs={6}>
              <div className={classes.textContainer}>
                {order.order.beforeDate &&
                <h6 className={classes.title}>{moment(order.order.beforeDate).format('DD/MM/YYYY')}</h6>}
              </div>
            </Grid>
          </Grid>
          <Grid item container xs={4}>
            <Grid item container xs={4}>
              <MuiThemeProvider theme={innerTheme}>
                <div className={classes.textContainer}>
                  <Button color="secondary"
                          onClick={() => handleModal(order.lastMileShippingId)}
                          variant="outlined">
                    CANCEL
                  </Button>
                </div>
              </MuiThemeProvider>
            </Grid>
            <Grid item container xs={8}>
              <div className={classes.textContainer}>
                <Button color="primary"
                        onClick={() => handleConfirmDeliveryConfirm(order.lastMileShippingId)}
                        variant="outlined">
                  CONFIRM RECEPTION
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      );
      const marginBottom = (index === (deliveryConfirmOrders.length - 1)) ? 0 : 36;
      return (
        <Grid item xs={12} key={index} style={{ marginBottom }}>
          <Card>
            <CardHeader className={classes.orderHeader}
                        title={title}/>
            {items}
          </Card>
        </Grid>
      );
    });
  };

  buildItems = (items: { [key: string]: any }[]) => {
    const { classes } = this.props;
    return items.map((item, itemIndex) => {
      return (
        <CardContent key={itemIndex}>
          <Grid container spacing={16} justify="space-evenly">
            <Grid item spacing={16} container xs={6}>
              <Grid item xs={4}/>
              <Grid item xs={3}>
                <Card className={classes.imageCard}>
                  <CardMedia component="img"
                             image={item.orderItem.image}/>
                </Card>
              </Grid>
              <Grid item container xs={5}>
                <div className={classes.textContainer}>
                  {item.orderItem.name}
                </div>
              </Grid>
            </Grid>
            <Grid item xs={6}/>
          </Grid>
        </CardContent>
      );
    });
  };

  buildBody = () => {
    const {
      isLoading,
      classes,
      deliveryConfirmOrders,
      page,
      rowsPerPage
    } = this.props;
    if (isLoading) {
      return (
        <Paper style={{ width: '100%' }}>
          <div className={classes.progressContainer}>
            <CircularProgress className={classes.tableProgress} size={70}/>
          </div>
        </Paper>
      );
    } else {
      if (deliveryConfirmOrders && deliveryConfirmOrders.length) {
        return this.buildOrders(
          deliveryConfirmOrders.slice(
            page * rowsPerPage, page * rowsPerPage + rowsPerPage,
          ));
      } else {
        return (
          <Paper style={{ width: '100%' }}>
            <div style={{ height: '58vh', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
              <h5>No matches found</h5>
            </div>
          </Paper>
        );
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.rootContainer}>
        {this.buildBody()}
      </Grid>);
  }
}

DeliveryConfirmTableBody.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleModal: PropTypes.func.isRequired,
  handleEditing: PropTypes.func.isRequired,
  handleUpdateDeliveryConfirm: PropTypes.func.isRequired,
  handleConfirmDeliveryConfirm: PropTypes.func.isRequired,
  handleDeliveryNumberChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  deliveryConfirmOrders: PropTypes.array,
  stateOrders: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeliveryConfirmTableBody);
