// @flow
export default function styles(there: { [key: string]: any }) {
  return ({
    loading: {
      opacity: 0.35,
      pointerEvents: 'none',
      userSelect: 'none',
    },
    orderItemWrap: {
      padding: 16,
    },
    itemImage: {
      width: 250,
      height: 250,
    },
    property: {
      fontSize: 13,
      lineHeight: '16px',
      color: '#9E9E9E',
      margin: 0,
    },
    span: {
      fontSize: 16,
      lineHeight: '24px',
      color: '#212121',
    },
    itemVolumetricImage: {
      display: 'block',
      width: '100px',
      margin: '8px auto 0',
    },
    itemName: {
      marginTop: 8,
      color: '#2196F3',
      textDecoration: 'none',
    },
    uploadProperty: {
      fontSize: 13,
      lineHeight: '16px',
      color: '#9E9E9E',
    },
    invoiceExists: { color: '#2196F3' },
    invoiceMissing: { color: '#BDBDBD' },
    invoiceReady: { color: '#388E3C' },
    invoiceError: { color: '#F44336' },
    textError: {
      margin: 0,
      color: '#F44336',
      fontSize: 13,
      lineHeight: '16px',
    },
    buttonReceiveOrderItem: {
      marginTop: '16px',
      color: 'white',
    },
    iconSuccess: { color: '#00C853' },
    iconWarning: { color: '#FFD600' },
    cancelled: {
      fontSize: 16,
      lineHeight: '24px',
      color: '#F44336',
    },
  });
}
