// @flow
// import { Observable } from 'rxjs';
import environment from '../../environment';
// import shippingOrders from './orders';
import { postCreator, getCreator } from '../../common/http';

export const endpoints = Object.freeze({
  GET_SHIPPING: `${environment.apiUrl}/operations/progress-transactions/to-ship/list`,
  PERFORM_SHIPPING: `${environment.apiUrl}/operations/progress-transactions/to-ship/ship`,
  DOWNLOAD_XLSX: `${environment.apiUrl}/operations/progress-transactions/shipped/xlsx`,
});

export const getShippingApi = getCreator(fetch, endpoints.GET_SHIPPING);

/*export const getShippingApi = (queryParams: any, o: any) => Observable.create(
  (observer) => {
    // let progressOrders;
    // const { filterId } = queryParams;
    const totalShippingOrders = shippingOrders.length;
    /!*if (filterId !== '') {
      progressOrders = [apiOrders.find(e => e.id === filterId)];
      if (progressOrders[0] === undefined) {
        progressOrders = [];
      }
    }*!/

    observer.next({ shippingOrders, totalShippingOrders });
  });*/

export const performShippingApi = postCreator(fetch, endpoints.PERFORM_SHIPPING);

/*export const sendShippingApi = (data: any, o: any) => Observable.create(
  (observer) => {
    data.forEach(order => {
      const { id } = order;
      const orderIndex = shippingOrders.findIndex(e => e.id === id);
      shippingOrders.splice(orderIndex, 1);
    });
    observer.next(shippingOrders);
  });*/

export type PerformShippingRequest = {
  orders: { orderId: string, size?: "SMALL" | "MEDIUM" | "LARGE" | "" }[];
  deliveryCourierId: number;
};
