const apiFeaturedProducts = [
  {
    id: 6,
    name: 'Airtex Boxing Gloves',
    url: 'https://www.google.com/',
    image: 'https://images-na.ssl-images-amazon.com/images/I/51OOgCU6ZbL.jpg',
    description: 'whatever',
    price: '68',
    category: '9',
    quantity: 1,
    weight: '860',
  },
  {
    id: 7,
    name: 'Fairtex Boxing Gloves',
    url: 'https://www.google.com/',
    image: 'https://images-na.ssl-images-amazon.com/images/I/51OOgCU6ZbL.jpg',
    description: 'whatever',
    price: '69',
    category: '9',
    quantity: 1,
    weight: '860',
  },
  {
    id: 8,
    name: 'Fairtex Boxing Gloves',
    url: 'https://www.google.com/',
    image: 'https://images-na.ssl-images-amazon.com/images/I/51OOgCU6ZbL.jpg',
    description: 'whatever',
    price: '120',
    category: '9',
    quantity: 1,
    weight: '860',
  },
  {
    id: 9,
    name: 'Fairtex Boxing Gloves',
    url: 'https://www.google.com/',
    image: 'https://images-na.ssl-images-amazon.com/images/I/51OOgCU6ZbL.jpg',
    description: 'whatever',
    price: '108',
    category: '5',
    quantity: 1,
    weight: '860',
  },
];

export default apiFeaturedProducts;
