// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import LogIn from '../components/LogIn';
import { actions as AuthActions } from '../duck';

import type { RootState } from '../../index';

const mapStateToProps = (state: RootState) => {
  return {
    loginFirstStepSuccess: state.auth.loginFirstStepSuccess,
    loginFirstStepError: state.auth.loginFirstStepError,
    isLoadingLoginFirstStep: state.auth.isLoadingLoginFirstStep,
    isLoadingGetSession: state.auth.isLoadingGetSession,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return bindActionCreators(AuthActions, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogIn);

