// @flow
import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import { Link } from 'react-router-dom';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { InlineDatePicker } from 'material-ui-pickers';
import PrintIcon from '@material-ui/icons/Print';
import update from 'immutability-helper';
import { withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { FormValidator } from '../../../common/form';
import moment from 'moment';
import {endpoints} from '../api';

import styles from './style';

type Props = {
  +classes: { [key: string]: any };
  +deliveryCouriers?: { [key: string]: any }[];
  +deliveryCourierIndex: number;
  +executeGetShipping: (filter?: { [key: string]: string }) => void;
  +atLeastOneSelected: () => void;
  +handleDeliveryCourier: (e: Event) => void;
  +handleModal: () => void;
  +selectedOrdersIds?: string[];
};

type State = {
  search: {
    value: string,
    errors?: string[];
  };
  date: {
    value: moment,
    errors?: string[],
  };
  filterKey: string;
  ordersFilter: {
    value: string;
    errors?: string[];
  }
};

const filterOptions = ['orderId', 'beforeDate', 'buyerName', 'buyerEmail', 'travelerName', 'travelerEmail'];

const ordersFilterOptions = [{
  id: 'incomplete',
  label: 'Incomplete',
}, {
  id: 'local-delivery',
  label: 'Lima province',
}, {
  id: 'non-local-delivery',
  label: 'Other provinces',
}];

class ShippingTableToolbar extends Component<Props, State> {
  searchFormValidator: FormValidator;
  state = {
    search: {
      value: '',
    },
    date: {
      value: moment(),
    },
    filterKey: 'orderId',
    ordersFilter: {
      value: '',
    },
  };

  constructor(props: Props) {
    super(props);
    this.searchFormValidator = new FormValidator({
      orderId: {
        orderIdFormat: 'orderIdFormat',
      },
      travelerEmail: {
        emailFormat: 'emailFormat',
      },
      buyerEmail: {
        emailFormat: 'emailFormat',
      },
      beforeDate: {
        dateFormat: 'dateFormat',
      },
      travelerName: {
        none: () => false,
      },
      buyerName: {
        none: () => false,
      },
    });
  }

  handleChange = (e: Event) => {
    const { name, value } = (e.target: { [key: string]: any });
    if (name === 'filterKey') {
      this.setState(prevState => update(prevState, {
        [name]: { $set: value },
        search: {
          $unset: ['errors'],
        },
      }));
    } else {
      this.setState(prevState => update(prevState, {
        [name]: {
          value: { $set: value },
          $unset: ['errors'],
        },
      }));
    }
  };

  handleDateInput = (newDate: ?moment) => {
    const { executeGetShipping } = this.props;
    const { date } = this.state;
    if (!newDate || !date.value || newDate.format('YYYY-MM-DD') !== date.value.format('YYYY-MM-DD')) {
      this.setState(prevState => update(prevState, {
        date: {
          value: { $set: newDate },
        },
        search: {
          $unset: ['errors'],
        },
      }));
      if (newDate) {
        executeGetShipping({ beforeDate: newDate.format('YYYY-MM-DD') });
      } else executeGetShipping();
    }
  };

  getInputBox = () => {
    const { classes } = this.props;
    const { filterKey, search, date } = this.state;
    if (filterKey === 'beforeDate') {
      return (
        <InlineDatePicker format="DD-MM-YYYY"
                          className={classes.dateSelect}
                          keyboard
                          clearable
                          name="date"
                          placeholder={moment().format('DD-MM-YYYY')}
                          margin="none"
                          mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                          value={date.value}
                          onChange={this.handleDateInput}/>
      );
    } else {
      return (
        <div className={classes.tableSearch}>
          <div className={classes.tableSearchIcon}>
            <SearchIcon/>
          </div>
          <InputBase placeholder="Search…"
                     value={search.value}
                     name="search"
                     onChange={this.handleChange}
                     onKeyDown={(e) => e.keyCode === 13 ? this.search(e) : undefined}
                     classes={{
                       root: classes.tableInputRoot,
                       input: classes.tableSearchInput,
                     }}/>
        </div>);
    }
  };

  search = (e: Event) => {
    const { executeGetShipping } = this.props;
    const { filterKey } = this.state;
    if (e.target instanceof HTMLInputElement) {
      const { value } = e.target;
      if (value && value.length) {
        const errors = this.searchFormValidator.validateField(filterKey, value);
        if (errors.length) {
          this.setState(prevState => update(prevState, {
            search: {
              errors: { $set: errors },
            },
          }));
        } else {
          const filter = { [filterKey]: value };
          executeGetShipping(filter);
        }
      } else {
        executeGetShipping();
      }
    }
  };

  handleChangeOrdersFilter = (e: Event) => {
    if (e.target instanceof HTMLSelectElement) {
      const { name, value } = e.target;
      const { executeGetShipping } = this.props;
      this.setState({ [name]: value });
      const params = {};
      if (value) params.ordersFilter = value;
      executeGetShipping(params);
    }
  }

  createDownloadCsvLink = () => {
    const { selectedOrdersIds } = this.props;
    if (selectedOrdersIds && selectedOrdersIds.length) {
      return `${endpoints.DOWNLOAD_XLSX}?order_ids=${selectedOrdersIds.join(',')}`;
    }
  };

  render() {
    const { classes, handleModal, handleDeliveryCourier, atLeastOneSelected, deliveryCouriers, deliveryCourierIndex, selectedOrdersIds } = this.props;
    const { search, filterKey, ordersFilter } = this.state;
    return (
      <Toolbar style={{ display: 'block' }}>
        <div className={classes.toolbar}
             style={{ paddingBottom: 14, paddingTop: 14, alignItems: 'center' }}>
          <TextField value={filterKey}
                     className={classes.filterSelect}
                     error={!!search.errors && !!search.errors}
                     name="filterKey"
                     variant="outlined"
                     SelectProps={{
                       SelectDisplayProps: {
                         style: {
                           paddingTop: 9,
                           paddingBottom: 8,
                         },
                       },
                     }}
                     select
                     onChange={this.handleChange}>
            {filterOptions.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </TextField>

          {this.getInputBox()}

          <div className={classes.tableSpacer}/>

          {/*{couriers && <TextField value={couriers[deliveryCourierIndex].id}
                                  className={classes.filterSelect}
                                  name="courier"
                                  variant="outlined"
                                  SelectProps={{
                                    // native: true,
                                    SelectDisplayProps: {
                                      style: {
                                        paddingTop: 9,
                                        paddingBottom: 8,
                                      },
                                    },
                                  }}
                                  native="true"
                                  select
                                  onChange={handleDeliveryCourier}>
            {couriers.map(({ id, name }) => (
              // <MenuItem key={id} value={id}>{name}</MenuItem>
              <option key={id} value={id}>{name}</option>
            ))}
          </TextField>}*/}

          {deliveryCouriers && <Select native
                                       className={classes.filterSelect}
                                       value={deliveryCouriers[deliveryCourierIndex].id}
                                       onChange={handleDeliveryCourier}
                                       inputProps={{
                                         style: {
                                           paddingTop: 9,
                                           paddingBottom: 8,
                                         },
                                       }}
                                       input={<OutlinedInput labelWidth={0}/>}>
            {deliveryCouriers.map(({ id, name }) => (
              <option key={id} value={id}>{name}</option>
            ))}
          </Select>}
          <Select native
                  name="ordersFilter"
                  className={classes.filterSelect}
                  value={ordersFilter.value}
                  onChange={this.handleChangeOrdersFilter}
                  inputProps={{
                    style: {
                      paddingTop: 9,
                      paddingBottom: 8,
                    },
                  }}
                  input={<OutlinedInput labelWidth={0}/>}>
            <option value="">None</option>
            {ordersFilterOptions.map(({ id, label }) => (
              <option key={id} value={id}>{label}</option>
            ))}
          </Select>

          <Button color="primary"
                  onClick={window.print}
                  disabled={!atLeastOneSelected()}
                  style={{ height: 38 }}
                  className={classes.toolbarButton}>
            <PrintIcon/>
          </Button>
          <Button className={classes.toolbarButton}
                  disabled={!atLeastOneSelected()}
                  color="primary"
                  variant="contained"
                  href={this.createDownloadCsvLink()}
                  target="_blank" >
            DOWNLOAD CSV
          </Button>
          <Button className={classes.toolbarButton}
                  disabled={!atLeastOneSelected()}
                  onClick={handleModal}
                  color="primary"
                  variant="contained">
            SHIP ORDERS
          </Button>
        </div>

        <div style={{ paddingTop: 10, paddingBottom: 14 }}>
          <Tabs style={{ minHeight: 35, marginLeft: 10 }}
                value={0}>
            <Tab style={{ minHeight: 35 }}
                 label="SHIPPING"/>
            <Tab style={{ minHeight: 35 }}
                 component={Link}
                 to={`/operations/delivery-confirm`}
                 label="DELIVERY CONFIRM"/>
          </Tabs>
        </div>
      </Toolbar>);
  }
}

ShippingTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  deliveryCouriers: PropTypes.array,
  deliveryCourierIndex: PropTypes.number.isRequired,
  executeGetShipping: PropTypes.func.isRequired,
  atLeastOneSelected: PropTypes.func.isRequired,
  handleDeliveryCourier: PropTypes.func.isRequired,
  handleModal: PropTypes.func.isRequired,
  selectedOrdersIds: PropTypes.array,
};

export default withStyles(styles)(ShippingTableToolbar);
