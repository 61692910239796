// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  FormControl,
  Input,
  InputLabel,
  Typography,
  withStyles,
} from '@material-ui/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {endpoints, updateOrderItemStoreOrderIdApi} from '../../api';

type Props = {
  +classes: { [key: string]: any },
  +orderItemId: string,
  +storeOrderId: string,
  +notes: string,
};

type State = {
  notes: string,
  isLoading: boolean,
};

const styles = (theme: { [key: string]: any }) => {
  return {
    formControl: {
      width: '100%',
      marginTop: 8,
    },
  };
};

class CourierReceptionOrderItemNotes extends Component<Props, State> {
  notesSubject$: Subject<string>;

  constructor(props: Props) {
    super(props);
    this.state = {
      notes: props.notes,
      isLoading: false,
    };

    this.notesSubject$ = new Subject();
  }

  componentDidMount() {
    const {orderItemId} = this.props;
    this.notesSubject$.pipe(debounceTime(750)).subscribe((notes) => {
      updateOrderItemStoreOrderIdApi({
        storeOrderId: this.props.storeOrderId || '',
        notes,
      }, {
        route: endpoints.UPDATE_ORDER_ITEM_TRACKING_ORDER_ID.replace(':orderItemId', orderItemId),
      }).toPromise()
        .then(() => console.log('updated'))
        .catch((e) => console.log(e))
      console.log('notes', notes);
    });
  }

  componentWillUnmount() {
    if (this.notesSubject$) {
      this.notesSubject$.unsubscribe();
    }
  }

  handleChangeNotes = (e: Event) => {
    if (e.target instanceof HTMLInputElement) {
      const newValue = e.target.value;
      this.setState({
        notes: newValue,
      }, () => this.notesSubject$.next(newValue));
    }
  };

  render() {
    const { classes } = this.props;
    const { notes, isLoading } = this.state;
    return (
      <Card>
        <CardContent>
          <Typography component="h5" variant="h5">
            Notes
          </Typography>
          <FormControl className={classes.formControl}>
            <InputLabel>Notes</InputLabel>
            <Input
              type="text"
              value={notes}
              disabled={isLoading}
              onChange={this.handleChangeNotes}
            />
          </FormControl>
        </CardContent>
      </Card>
    );
  }
}

CourierReceptionOrderItemNotes.propTypes = {
  classes: PropTypes.object.isRequired,
  orderItemId: PropTypes.string,
  storeOrderId: PropTypes.string,
  notes: PropTypes.string,
};

export default withStyles(styles)(CourierReceptionOrderItemNotes);
