// @flow
import { getCreator, postCreator } from '../../common/http';
import environment from '../../environment';

export const endpoints = Object.freeze({
  GET_SHIPMENTS_PENDING_RECEPTION: `${environment.apiUrl}/operations/progress-transactions/reception/courier`,
  RECEIVE_PRODUCTS_AT_QEMPO: `${environment.apiUrl}/operations/progress-transactions/reception/receive-products`,
  CANCEL_PRODUCTS: `${environment.apiUrl}/operations/progress-transactions/reception/cancel-products`,
  UPLOAD_INVOICE_FILE: `${environment.apiUrl}/stored-file/upload/file`,
  UPDATE_ORDER_ITEM_INVOICE: `${environment.apiUrl}/operations/courier-tracking/:orderItemId/invoice`,
  UPDATE_ORDER_ITEM_TRACKING_NUMBER: `${environment.apiUrl}/operations/courier-tracking/:orderItemId/tracking`,
  UPDATE_ORDER_ITEM_TRACKING_ORDER_ID: `${environment.apiUrl}/operations/courier-tracking/:orderItemId/tracking_order_id`,
  MISSING_TRACKING: `${environment.apiUrl}/operations/available-orders/:orderId/missing-tracking`,
});

export const getPendingShipmentsApi = getCreator(fetch, endpoints.GET_SHIPMENTS_PENDING_RECEPTION);
export const receiveProductsAtQempoApi = postCreator(fetch, endpoints.RECEIVE_PRODUCTS_AT_QEMPO);
export const cancelProductsApi = postCreator(fetch, endpoints.CANCEL_PRODUCTS);
export const uploadInvoiceFileApi = postCreator(fetch, endpoints.UPLOAD_INVOICE_FILE);
export const updateOrderItemInvoiceApi = postCreator(fetch, endpoints.UPDATE_ORDER_ITEM_INVOICE);
export const updateOrderItemTrackingNumberApi = postCreator(fetch, endpoints.UPDATE_ORDER_ITEM_TRACKING_NUMBER);
export const updateOrderItemStoreOrderIdApi = postCreator(fetch, endpoints.UPDATE_ORDER_ITEM_TRACKING_ORDER_ID);
export const missingTrackingApi = postCreator(fetch, endpoints.MISSING_TRACKING);

export type GetPendingShipmentsRequest = {
  orderId?: string,
  dateUntil?: string,
  orderType?: string,
  courierId?: string,
};

export type ReceiveProductsAtQempoRequest = {
  items: {
    orderItemId: number,
    amount: number,
  }[],
};

export type CancelProductsRequest = {
  items: {
    orderItemId: number,
    amount: number,
    motive: string,
    message: string,
  }[],
};

export type UploadInvoiceFileRequest = {
  file: FormData,
  resourceGroup: 'EXTERNAL_INVOICE',
};

export type UpdateOrderItemInvoiceRequest = {
  storedFileId: number,
};

export type UpdateOrderItemTrackingRequest = {
  trackingCourier: string,
  trackingNumber: string,
  trackingUrl?: string,
};

export type UpdateOrderItemStoreOrderIdRequest = {
  storeOrderId: string,
};

export type CourierReceptionRequest =
  | GetPendingShipmentsRequest
  | ReceiveProductsAtQempoRequest
  | CancelProductsRequest
  | UploadInvoiceFileRequest
  | UpdateOrderItemInvoiceRequest
  | UpdateOrderItemTrackingRequest
  | UpdateOrderItemStoreOrderIdRequest;
