// @flow
/* eslint-disable */
export const emailRegex = /[a-zA-Z0-9_\.\-]+\@[a-zA-Z0-9\-]+\.+[a-zA-Z0-9]{2,4}/;
export const decimalRegex = /[0-9]{0,}(\.([0-9][0-9])?)/;
export const transactionRegex = /^TR[A-Z]{2}\d{8,}$/;
export const orderRegex = /^TR[A-Z]{2}\d{8,}$/;
export const flightRegex = /^FL[A-Z]{2}\d{8,}$/;
export const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
export const urlRegex = /^(https?:\/\/|ftps?:\/\/)?([a-z0-9%\-]+\.){1,}([a-z0-9\-]+)?(:(\d{1,5}))?(\/([a-z0-9\|\-._~:\/\?#\[\]@!$&'\(\)\*\+,;=%]+)?)?$/i;

export function limitStringEllipsis(text: string = '', limit: number): string {
    return text.length > limit ? `${text.substr(0, limit)}...` : text;
}

export function transactionVerifier(text: string = ''): boolean {
    return transactionRegex.test(text);
}

export function orderVerifier(text: string = ''): boolean {
    return orderRegex.test(text);
}

export function flightVerifier(text: string = ''): boolean {
    return flightRegex.test(text);
}

export function urlVerifier(text: string = ''): boolean {
    // general pattern recognition https://regex101.com/r/RgKTA4/2
    const match = text.match(urlRegex);
    if (!match) {
        return false;
    }
    // validate TLD
    if (typeof match[3] !== 'string') {
        return false;
    }
    // we are going to leave this for now, we don't want to add extra size to our js bundle
    // if (tlds.indexOf(match[3].toLowerCase()) === -1) return false;

    // validate port
    // if (match[5] && (!isPort(match[5]))) return false;
    return true;
}
