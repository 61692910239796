// @flow
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { endpoints, getRefundsApi, uploadRefundsApi, uploadImageRefundsApi } from './api';

import type { RootState } from '../index';
import type { HTTPError } from '../../common/error';
import type { Refund, RefundsListResponse, ImageFileResponse } from '../../entities/webapp';

// Actions
const GET_REFUNDS_REQUEST = 'GET_REFUNDS_REQUEST';
const GET_REFUNDS_SUCCESS = 'GET_REFUNDS_SUCCESS';
const GET_REFUNDS_ERROR = 'GET_REFUNDS_ERROR';
const CHANGE_REFUNDS_PAGINATION_REQUEST = 'CHANGE_REFUNDS_PAGINATION_REQUEST';
const UPLOAD_REFUNDS_REQUEST = 'UPLOAD_REFUNDS_REQUEST';
const UPLOAD_REFUNDS_SUCCESS = 'UPLOAD_REFUNDS_SUCCESS';
const UPLOAD_REFUNDS_ERROR = 'UPLOAD_REFUNDS_ERROR';
const UPLOAD_IMAGE_REFUNDS_REQUEST = 'UPLOAD_IMAGE_REFUNDS_REQUEST';
const UPLOAD_IMAGE_REFUNDS_SUCCESS = 'UPLOAD_IMAGE_REFUNDS_SUCCESS';
const UPLOAD_IMAGE_REFUNDS_ERROR = 'UPLOAD_IMAGE_REFUNDS_ERROR';
const RESET_REFUNDS_STATE = 'RESET_REFUNDS_STATE';

type RefundsAction = {
  +type: string;
  +refunds?: Refund[];
  +totalRefunds?: number,
  +id?: string,
  +changes?: { [key: string]: any };
  +error?: HTTPError;
};

export type RefundsState = {
  isLoadingGetRefunds?: boolean;
  getRefundsSuccess?: boolean;
  getRefundsError?: HTTPError;
  isLoadingUploadImageRefunds?: boolean;
  uploadImageRefundsSuccess?: boolean;
  uploadImageRefundsError?: HTTPError;
  isLoadingUploadRefunds?: boolean;
  uploadRefundsSuccess?: boolean;
  uploadRefundsError?: HTTPError;
  page: number;
  limit: number;
  sort: string;
  ord: string;
  filterId: string;
  imageId: string;
  refundId: string;
  refunds?: { [key: string]: any }[];
  totalRefunds?: number,
};

// Action Creators
export function executeGetRefunds(): RefundsAction {
  return {
    type: GET_REFUNDS_REQUEST,
  };
}

export function executeGetRefundsSuccess(data: RefundsListResponse): RefundsAction {
  return {
    type: GET_REFUNDS_SUCCESS,
    refunds: data.items,
    totalRefunds: data.count,
  };
}

export function executeGetRefundsError(error: HTTPError): RefundsAction {
  return {
    type: GET_REFUNDS_ERROR,
    error,
  };
}

export function changeRefundsPagination(changes: { [key: string]: any }): RefundsAction {
  return {
    type: CHANGE_REFUNDS_PAGINATION_REQUEST,
    changes,
  };
}

export function executeUploadRefunds(): RefundsAction {
  return {
    type: UPLOAD_REFUNDS_REQUEST,
  };
}

export function executeUploadRefundsSuccess(): RefundsAction {
  return {
    type: UPLOAD_REFUNDS_SUCCESS,
  };
}

export function executeUploadRefundsError(error: HTTPError): RefundsAction {
  return {
    type: UPLOAD_REFUNDS_ERROR,
    error,
  };
}

export function executeUploadImageRefunds(file: { [key: string]: any }, id: string): RefundsAction {
  return {
    type: UPLOAD_IMAGE_REFUNDS_REQUEST,
    file,
    id,
  };
}

export function executeUploadImageRefundsSuccess(data: ImageFileResponse): RefundsAction {
  return {
    type: UPLOAD_IMAGE_REFUNDS_SUCCESS,
    id: data.storedFileId,
  };
}

export function executeUploadImageRefundsError(error: HTTPError): RefundsAction {
  return {
    type: UPLOAD_IMAGE_REFUNDS_ERROR,
    error,
  };
}

export function executeResetRefundsState(): RefundsAction {
  return {
    type: RESET_REFUNDS_STATE,
  };
}

export const actions = {
  GET_REFUNDS_REQUEST,
  GET_REFUNDS_SUCCESS,
  GET_REFUNDS_ERROR,
  CHANGE_REFUNDS_PAGINATION_REQUEST,
  UPLOAD_REFUNDS_REQUEST,
  UPLOAD_REFUNDS_SUCCESS,
  UPLOAD_REFUNDS_ERROR,
  UPLOAD_IMAGE_REFUNDS_REQUEST,
  UPLOAD_IMAGE_REFUNDS_SUCCESS,
  UPLOAD_IMAGE_REFUNDS_ERROR,
  RESET_REFUNDS_STATE,
  executeGetRefunds,
  executeGetRefundsSuccess,
  executeGetRefundsError,
  changeRefundsPagination,
  executeResetRefundsState,
  executeUploadRefunds,
  executeUploadRefundsSuccess,
  executeUploadImageRefunds,
  executeUploadImageRefundsSuccess,
  executeUploadImageRefundsError,
  executeUploadRefundsError,
};

export const initialState: RefundsState = {
  page: 0,
  limit: 20,
  ord: 'asc',
  sort: 'id',
  filterId: '',
  imageId: '',
  refundId: '',
};

// Reducer
export default function reducer(state: RefundsState = initialState,
                                action: RefundsAction): RefundsState {
  switch (action.type) {
    case GET_REFUNDS_REQUEST:
      return {
        ...state,
        isLoadingGetRefunds: true,
        getRefundsSuccess: false,
      };
    case GET_REFUNDS_SUCCESS:
      return {
        ...state,
        isLoadingGetRefunds: false,
        getRefundsSuccess: true,
        refunds: action.refunds,
        totalRefunds: action.totalRefunds,
      };
    case GET_REFUNDS_ERROR:
      return {
        ...state,
        getRefundsSuccess: false,
        getRefundsError: action.error,
      };
    case CHANGE_REFUNDS_PAGINATION_REQUEST:
      const data = { ...action.changes };
      return { ...state, ...data };
    case UPLOAD_REFUNDS_REQUEST:
      return {
        ...state,
        isLoadingUploadRefunds: true,
        uploadRefundsSuccess: false,
      };
    case UPLOAD_REFUNDS_SUCCESS:
      return {
        ...state,
        isLoadingUploadRefunds: false,
        uploadRefundsSuccess: true,
      };
    case UPLOAD_REFUNDS_ERROR:
      return {
        ...state,
        uploadImageRefundsSuccess: false,
        uploadImageRefundsError: action.error,
      };
    case UPLOAD_IMAGE_REFUNDS_REQUEST:
      return {
        ...state,
        isLoadingUploadImageRefunds: true,
        uploadImageRefundsSuccess: false,
        refundId: action.id,
      };
    case UPLOAD_IMAGE_REFUNDS_SUCCESS:
      return {
        ...state,
        isLoadingUploadImageRefunds: false,
        uploadImageRefundsSuccess: true,
        imageId: action.id,
      };
    case UPLOAD_IMAGE_REFUNDS_ERROR:
      return {
        ...state,
        uploadImageRefundsSuccess: false,
        uploadImageRefundsError: action.error,
      };
    case RESET_REFUNDS_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetRefundsEpic(action$: ActionsObservable<RefundsAction>, state$: StateObservable<RootState>) {
  return action$.ofType(GET_REFUNDS_REQUEST).pipe(
    mergeMap(
      () => getRefundsApi({
        page: state$.value.refunds.page,
        limit: state$.value.refunds.limit,
        ord: state$.value.refunds.ord,
        sort: state$.value.refunds.sort,
        filterId: state$.value.refunds.filterId,
      }).pipe(
        map((data: { [key: string]: any }) => executeGetRefundsSuccess(data)),
        catchError((e: HTTPError) => of(executeGetRefundsError(e))))
    ),
  );
}

export function changeRefundsPaginationEpic(action$: ActionsObservable<RefundsAction>) {
  return action$.ofType(CHANGE_REFUNDS_PAGINATION_REQUEST).pipe(
    map(() => executeGetRefunds()
    )
  );
}

export function executeUploadImageRefundsEpic(action$: ActionsObservable<RefundsAction>) {
  return action$.ofType(UPLOAD_IMAGE_REFUNDS_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ file }) => uploadImageRefundsApi(file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).pipe(
        map((data) => executeUploadImageRefundsSuccess(data)),
        catchError((e: HTTPError) => of(executeUploadImageRefundsError(e))))
    )
  );
}

export function executeUploadRefundsActionEpic(action$: ActionsObservable<RefundsAction>, state$: StateObservable<RootState>) {
  return action$.ofType(UPLOAD_IMAGE_REFUNDS_SUCCESS).pipe(
    map(() => executeUploadRefunds()
    )
  );
}

export function executeUploadRefundsEpic(action$: ActionsObservable<RefundsAction>, state$: StateObservable<RootState>) {
  return action$.ofType(UPLOAD_REFUNDS_REQUEST).pipe(
    delay(1000),
    mergeMap(
      () => uploadRefundsApi({
          imageId: state$.value.refunds.imageId,
        },
        {
          route: endpoints.UPLOAD_REFUNDS.replace(':id', state$.value.refunds.refundId),
        }).pipe(
        map(() => executeUploadRefundsSuccess()),
        catchError((e: HTTPError) => of(executeUploadRefundsError(e))))
    )
  );
}

export function executeGetRefundsAgainEpic(action$: ActionsObservable<RefundsAction>, state$: StateObservable<RootState>) {
  return action$.ofType(UPLOAD_REFUNDS_SUCCESS).pipe(
    map(() => executeGetRefunds()
    )
  );
}
