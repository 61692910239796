// @flow
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

type Props = {
  +open: boolean;
  +handleModal: (modalName: 'openCancelModal' | 'openConfirmModal') => void;
  +handleConfirmReception: () => void;
  +getSelectedOrders: () => string[];
};

export default class ReceptionTableConfirmDialog extends PureComponent<Props> {
  render() {
    const { open, handleModal, handleConfirmReception, getSelectedOrders } = this.props;
    return (
      <Dialog open={open}
              onClose={() => handleModal('openConfirmModal')}>
        <DialogTitle style={{ textAlign: 'center' }}>
          Are you sure you want to receive this orders?
        </DialogTitle>
        <DialogContent>
          {open && getSelectedOrders().map((key, i) =>
            <Typography key={key} variant="body1">{i + 1}. {key}</Typography>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleModal('openConfirmModal')} style={{ margin: 'auto' }}>
            NO
          </Button>
          <Button color="primary" onClick={handleConfirmReception} style={{ margin: 'auto' }}>
            YES
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ReceptionTableConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  handleConfirmReception: PropTypes.func.isRequired,
  getSelectedOrders: PropTypes.func.isRequired,
};


