// @flow
import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
// import CouponIcon from '@material-ui/icons/LocalOffer';
// import FeaturedProductIcon from '@material-ui/icons/Stars';
// import PopularProductIcon from '@material-ui/icons/SupervisorAccount';
// import MailingListIcon from '@material-ui/icons/Email';
// import OrderIcon from '@material-ui/icons/ShoppingCart';
// import ProgressOrdersIcon from '@material-ui/icons/Forward';
import ReceptionIcon from '@material-ui/icons/FlightLand';
import ShippingIcon from '@material-ui/icons/LocalShipping';
import AvailableOrdersIcon from '@material-ui/icons/AllInbox';
import CourierTrackingIcon from '@material-ui/icons/TrackChanges';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import RefundIcon from '@material-ui/icons/MonetizationOn';
// import PendingIcon from '@material-ui/icons/Assignment';
// import ReviewIcon from '@material-ui/icons/Report';
//import LocalMallIcon from '@material-ui/icons/LocalMall';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core";

type Props = {
  +id: string;
  +logout: () => void;
  +classes: { [key: string]: any };
  +open: boolean;
  +onClick: () => void;
};

const styles = theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    [theme.breakpoints.up('xs')]: {
      paddingRight: 0,
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  button: {
    color: '#656565',
    textDecoration: 'none',
  },
  drawerPaper: {
    height: '100%',
    minHeight: '100vh',
    position: 'relative',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      width: '100vw',
    },
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
});

class DrawerMenu extends Component<Props> {
  handleLogOut = (e: Event) => {
    e.preventDefault();
    const { logout } = this.props;
    logout();
  };

  render() {
    const { classes, open, onClick, id } = this.props;
    return (
      <Drawer variant="permanent"
              id={id}
              classes={
                {
                  paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
                }
              }
              open={open}>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={onClick}>
            <ChevronLeftIcon/>
          </IconButton>
        </div>
        <Divider/>
        <List>
          <div>
            <ListSubheader inset>
              Operations
            </ListSubheader>
            {/*<Link to={`/operations/refunds/list`}
                  className={classes.button}>
              <ListItem button
                        className={classes.button}>
                <ListItemIcon>
                  <RefundIcon/>
                </ListItemIcon>
                <ListItemText primary="Refunds"/>
              </ListItem>
            </Link>*/}
            {/*<Link to={`/operations/pending/list`}
                  className={classes.button}>
              <ListItem button
                        className={classes.button}>
                <ListItemIcon>
                  <PendingIcon/>
                </ListItemIcon>
                <ListItemText primary="Pending"/>
              </ListItem>
            </Link>*/}
            {/*<Link to={`/operations/review/list`}
                  className={classes.button}>
              <ListItem button
                        className={classes.button}>
                <ListItemIcon>
                  <ReviewIcon/>
                </ListItemIcon>
                <ListItemText primary="Under Review"/>
              </ListItem>
            </Link>*/}
            <Link to={`/operations/reception`}
                  className={classes.button}>
              <ListItem button
                        className={classes.button}>
                <ListItemIcon>
                  <ReceptionIcon/>
                </ListItemIcon>
                <ListItemText primary="Reception"/>
              </ListItem>
            </Link>
            <Link to={`/operations/shipping`}
                  className={classes.button}>
              <ListItem button
                        className={classes.button}>
                <ListItemIcon>
                  <ShippingIcon/>
                </ListItemIcon>
                <ListItemText primary="Shipping"/>
              </ListItem>
            </Link>
            <Link to={`/operations/available-orders/list`}
                  className={classes.button}>
              <ListItem button
                        className={classes.button}>
                <ListItemIcon>
                  <AvailableOrdersIcon/>
                </ListItemIcon>
                <ListItemText primary="Available orders"/>
              </ListItem>
            </Link>
            <Link to={`/operations/courier-tracking`}
                  className={classes.button}>
              <ListItem button
                        className={classes.button}>
                <ListItemIcon>
                  <CourierTrackingIcon/>
                </ListItemIcon>
                <ListItemText primary="Courier Tracking"/>
              </ListItem>
            </Link>
          </div>
        </List>
        {/* <List>
          <div>
            <ListSubheader inset>
              Administrative
            </ListSubheader>
            <Link to={`/administrative/order/list`}
                  className={classes.button}>
              <ListItem button
                        className={classes.button}>
                <ListItemIcon>
                  <OrderIcon/>
                </ListItemIcon>
                <ListItemText primary="Orders"/>
              </ListItem>
            </Link>
            <Link to={`/administrative/featured-product/list`}
                  className={classes.button}>
              <ListItem button
                        className={classes.button}>
                <ListItemIcon>
                  <FeaturedProductIcon/>
                </ListItemIcon>
                <ListItemText primary="Featured Products"/>
              </ListItem>
            </Link>
            <Link to={`/administrative/popular-product/list`}
                  className={classes.button}>
              <ListItem button
                        className={classes.button}>
                <ListItemIcon>
                  <PopularProductIcon/>
                </ListItemIcon>
                <ListItemText primary="Popular Products"/>
              </ListItem>
            </Link>
            <Link to={`/administrative/coupon/list`}
                  className={classes.button}>
              <ListItem button
                        className={classes.button}>
                <ListItemIcon>
                  <CouponIcon/>
                </ListItemIcon>
                <ListItemText primary="Coupons"/>
              </ListItem>
            </Link>
          </div>
        </List>*/}
        {/*<List>
          <div>
            <ListSubheader inset>
              Mailing
            </ListSubheader>
            <Link to={`/mailing/list/list`}
                  className={classes.button}>
              <ListItem button
                        className={classes.button}>
                <ListItemIcon>
                  <MailingListIcon/>
                </ListItemIcon>
                <ListItemText primary="Lists"/>
              </ListItem>
            </Link>
          </div>
        </List>*/}
        <Divider/>
        <ListSubheader inset>
          Web Tools
        </ListSubheader>
        <Link to={`/webtools/product-crafter`}
              className={classes.button}>
          <ListItem button
                    className={classes.button}>
            <ListItemIcon>
              <AddShoppingCartIcon/>
            </ListItemIcon>
            <ListItemText primary="Product Crafter"/>
          </ListItem>
        </Link>
        {/* <Link to={`/webtools/bag-crafter`}
              className={classes.button}>
          <ListItem button
                    className={classes.button}>
            <ListItemIcon>
              <LocalMallIcon/>
            </ListItemIcon>
            <ListItemText primary="Bag Crafter"/>
          </ListItem>
        </Link> */}
        <Divider/>
        <div>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <a href="/"
             onClick={this.handleLogOut}
             className={classes.button}>
            <ListItem button
                      className={classes.button}>
              <ListItemIcon>
                <LogoutIcon/>
              </ListItemIcon>
              <ListItemText primary="Logout"/>
            </ListItem>
          </a>
          <br/>
          <br/>
        </div>
      </Drawer>
    );
  }
}

DrawerMenu.propTypes = {
  id: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(DrawerMenu);
