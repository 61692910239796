// @flow
import { Observable } from 'rxjs';
// import { getCreator, postCreator, putCreator, deleteCreator } from '@qempo/qempo-web-common/http';
import environment from '../../environment';
import apiMailingLists from './mailingLists';

export const endpoints = Object.freeze({
  GET_LISTS: `${environment.apiUrl}/mailing/list/list`
});

function desc(a, b, sort) {
  if (b[sort] < a[sort]) {
    return -1;
  }
  if (b[sort] > a[sort]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order: string, sort: string) {
  return order === 'desc' ? (a, b) => desc(a, b, sort) : (a, b) => -desc(a, b, sort);
}

export const getListsApi = (queryParams: any) => Observable.create(
  (observer) => {
    let lists;
    const { page, limit, ord, sort, filterName } = queryParams;

    lists = stableSort(apiMailingLists, getSorting(ord, sort))
      .slice(page * limit, page * limit + limit);
    const totalLists = apiMailingLists.length;

    if (filterName !== '') {
      lists = [apiMailingLists.find(e => e.name === filterName)];
      if (lists[0] === undefined) {
        lists = [];
      }
    }

    observer.next({ lists, totalLists });
  });

export const deleteListsApi = (data: any, o: any) => Observable.create(
  (observer) => {
    const { id } = data;
    const productIndex = apiMailingLists.findIndex(e => e.id === id);
    apiMailingLists.splice(productIndex, 1);
    observer.next(apiMailingLists);
  });
