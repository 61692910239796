// @flow
import { Observable, Observer } from 'rxjs';
// import { getCreator, postCreator, putCreator, deleteCreator } from '@qempo/qempo-web-common/http';
import environment from '../../environment';
import { searchCouponById } from '../coupons/api';
import type { Rule } from '../../entities/webapp';

export const endpoints = Object.freeze({
  CREATE_COUPON: `${environment.apiUrl}/administrative/coupon/`,
  GET_COUPON: `${environment.apiUrl}/administrative/coupon/:id`,
  UPDATE_COUPON: `${environment.apiUrl}/administrative/coupon/:id`,
  UPDATE_RULES: `${environment.apiUrl}/administrative/coupon/:id`,
  GET_COUPON_TYPES: `${environment.apiUrl}/meta/coupon/types`,
  GET_COUPON_DISCOUNT_TYPES: `${environment.apiUrl}/meta/coupon/discount-types`,
  GET_COUPON_RULES: `${environment.apiUrl}/meta/coupon/rules`,
});

export const getOneCouponApi = (queryParams: any, o: any) => Observable.create(
  (observer: Observer) => {
    const coupon = searchCouponById(parseInt(o.route.substr(54), 10));
    observer.next(coupon);
  }
);

export const updateCouponApi = (data: any, o: any) => Observable.create(
  (observer: Observer) => {
    const coupon = searchCouponById(parseInt(o.route.substr(54), 10));
    coupon.couponCode = data.couponCode !== undefined ? data.couponCode : coupon.couponCode;
    coupon.couponType = data.couponType !== undefined ? data.couponType : coupon.couponType;
    coupon.couponDiscountType = data.couponDiscountType !== undefined ? data.couponDiscountType : coupon.couponDiscountType;
    coupon.startDate = data.startDate !== undefined ? data.startDate : coupon.startDate;
    coupon.endDate = data.endDate !== undefined ? data.endDate : coupon.endDate;
    coupon.owner = data.owner !== undefined ? data.owner : coupon.owner;
    coupon.discountAmount = data.discountAmount !== undefined ? data.discountAmount : coupon.discountAmount;
    coupon.discountPercentage = data.discountPercentage !== undefined ? data.discountPercentage : coupon.discountPercentage;
    coupon.discountLimit = data.discountLimit !== undefined ? data.discountLimit : coupon.discountLimit;
    coupon.couponTypeId = data.couponTypeId !== undefined ? data.couponTypeId : coupon.couponTypeId;
    coupon.couponDiscountTypeId = data.couponDiscountTypeId !== undefined ? data.couponDiscountTypeId  : coupon.couponDiscountTypeId ;
    if (data.discountAmount === null) {
      delete coupon.discountAmount;
    }
    if (data.discountLimit === null) {
      delete coupon.discountLimit;
    }
    if (data.discountPercentage === null) {
      delete coupon.discountPercentage;
    }
    observer.next(coupon);
  }
);

export const createCouponApi = (data: any, o: any) => Observable.create(
  (observer: Observer) => {
    observer.next(data);
  }
);

export type UpdateCouponRequest = {
  id?: number;
  couponCode?: string;
  couponType?: string;
  couponDiscountType?: string;
  startDate?: string;
  endDate?: string;
  owner?: string;
  discountAmount?: ?string;
  discountPercentage?: ?string;
  discountLimit?: ?string;
  active?: boolean;
  creditConversionAllowed?: boolean;
  createdAt?: string;
  updatedAt?: string;
  rules?: Rule[];
};

export type UpdateRulesRequest = {
  rules: Rule[];
};

