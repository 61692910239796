// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormValidator } from '../../../common/form';
import FirstStepAuth from './FirstStepAuth';
// import SecondStepAuth from './SecondStepAuth';

import type { FormValidationResult } from '../../../common/form';
import type { HTTPError } from '../../../common/error';

type Props = {
  +classes: { [key: string]: string };
  +loginFirstStep: (email: string, password: string) => void;
  +loginSecondStep: (token: string) => void;
  +loginFirstStepSuccess: boolean;
  +loginFirstStepError?: HTTPError;
  +isLoadingLoginFirstStep?: boolean;
  +isLoadingGetSession?: boolean;
};

type State = {
  email: {
    value: string,
    errors?: string[],
  };
  password: {
    value: string,
    errors?: string[],
  };
  token: {
    value: string,
    errors?: string[],
  };
};

export function commonStyle(theme: { [key: string]: any }) {
  return ({
    layout: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
      margin: theme.spacing.unit,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing.unit,
    },
    submit: {
      marginTop: theme.spacing.unit * 3,
    },
    progress: {
      position: 'absolute',
      top: '50%',
      [theme.breakpoints.up('sm')]: {
        left: '50%',
      },
      left: '40%',
    },
  });
}

class LogIn extends Component<Props, State> {
  firstStepFormValidator: FormValidator;
  secondStepFormValidator: FormValidator;

  state = {
    email: {
      value: '',
    },
    password: {
      value: '',
    },
    token: {
      value: '',
    },
  };

  constructor(props: Props) {
    super(props);
    this.firstStepFormValidator = new FormValidator({
      email: {
        required: 'required',
        emailFormat: 'emailFormat',
      },
      password: {
        required: 'required',
      },
    });
    this.secondStepFormValidator = new FormValidator({
      token: {
        required: 'required'
      },
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.loginFirstStepError && (JSON.stringify(this.props.loginFirstStepError) !== JSON.stringify(prevProps.loginFirstStepError))) {
      const errors = this.props.loginFirstStepError && [`${this.props.loginFirstStepError.code}`];
      this.setState(prevState => ({
        email: {
          value: prevState.email.value,
          errors,
        },
        password: {
          value: prevState.password.value,
          errors,
        }
      }));
    }
  }

  handleChange = (e: Event) => {
    if (e.target instanceof HTMLInputElement) {
      const { name, value } = e.target;
      this.setState({ [name]: { value } });
    }
  };

  handleFocus = (e: Event) => {
    if (e.target instanceof HTMLInputElement) {
      const { name, value } = e.target;
      this.setState({ [name]: { value } });
    }
  };

  handleBlur = (e: Event) => {
    if (e.target instanceof HTMLInputElement) {
      const { name, value } = e.target;
      const errors = this.firstStepFormValidator.validateField(name, value);
      if (errors.length && (errors[0] === 'emailFormat')) {
        this.setState({
          [name]: { value, errors }
        });
      } else {
        this.setState({
          [name]: { value }
        });
      }
    }
  };

  handleSubmitFirstStep = (e: Event) => {
    e.preventDefault();
    const { loginFirstStep } = this.props;
    const { email, password } = this.state;
    const emailValue = email.value;
    const passwordValue = password.value;
    const errors = this.firstStepFormValidator.validateForm({
      email: emailValue,
      password: passwordValue,
    });
    if (errors.length) {
      errors.forEach((error: FormValidationResult) => {
        this.setState({
            [error.field]: {
              ...this.state[error.field],
              errors: error.errors,
            },
          }
        );
      });
    } else {
      loginFirstStep(emailValue, passwordValue);
    }
  };

  handleSubmitSecondStep = (e: Event) => {
    e.preventDefault();
    const { loginSecondStep } = this.props;
    const { token } = this.state;
    const errors = this.secondStepFormValidator.validateForm({
      token: token.value,
    });
    if (errors.length) {
      errors.forEach((error: FormValidationResult) => {
        this.setState({
            [error.field]: {
              ...this.state[error.field],
              errors: error.errors,
            },
          }
        );
      });
    } else {
      loginSecondStep(token.value);
    }
  };

  render() {
    const { email, password } = this.state;
    const { classes, loginFirstStepError, isLoadingLoginFirstStep, isLoadingGetSession } = this.props;

    return (
      <div>
        {isLoadingGetSession ? <CircularProgress className={classes.progress} size={80}/>
          : <FirstStepAuth email={email}
                           password={password}
                           loginFirstStepError={loginFirstStepError}
                           isLoadingLoginFirstStep={isLoadingLoginFirstStep}
                           handleChange={this.handleChange}
                           handleBlur={this.handleBlur}
                           handleFocus={this.handleFocus}
                           handleSubmit={this.handleSubmitFirstStep}/>}
        {/* <SecondStepAuth handleSubmit={this.handleSubmitSecondStep}
                          handleFocus={this.handleFocus}
                          handleChange={this.handleChange}
                          token={token}/>}*/}
      </div>
    );
  }
}

LogIn.propTypes = {
  classes: PropTypes.object.isRequired,
  loginFirstStep: PropTypes.func.isRequired,
  loginSecondStep: PropTypes.func.isRequired,
  loginFirstStepSuccess: PropTypes.bool,
  loginFirstStepError: PropTypes.object,
  isLoadingLoginFirstStep: PropTypes.bool,
  isLoadingGetSession: PropTypes.bool,
};

export default withStyles(commonStyle)(LogIn);
