// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles.js';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MultiImage from "../../../../elements/MultiImage/MultiImage";
import { Link } from 'react-router-dom';

import type { AvailableOrderType } from "../../duck";

type Props = {
  +classes: { [key: string]: mixed };
  +availableOrder: AvailableOrderType;
  +handleClick: (orderId: string) => void;
};

class AvailableOrderItem extends Component<Props> {
  render() {
    const { classes, availableOrder, handleClick } = this.props;
    return (
      <Grid item xs={12}
            onClick={() => handleClick(availableOrder.id)}>
        <Link to={`/operations/available-orders/${availableOrder.id}`}
        className={classes.link}>
          <Paper className={classes.itemWrap}>
            <Grid container spacing={24}>
              <Grid item className={classes.imagesWrap}>
                <MultiImage items={availableOrder.items}/>
              </Grid>
              <Grid item xs={4} sm container direction="column" spacing={16}
                    justify="center">
                <Grid item container alignItems="flex-start" direction="column">
                  <div className={classes.id}>
                    {availableOrder.id}
                  </div>
                  <p style={{margin: 0}}>{availableOrder.serviceTier} ({availableOrder.serviceTier === 'FLEXIBLE' ? 'Viajeros' : 'Carga'})</p>
                </Grid>
                <Grid item>
                  Order value:
                  <div>${availableOrder.invoice.subtotal}</div>
                </Grid>
              </Grid>
              <Grid item>
                <div className={classes.separator}/>
              </Grid>
              <Grid item xs={4} sm container
                    direction="column" justify="space-around" alignItems="flex-start">
                <Grid item>
                  Traveler/Courier commission
                  <div className={classes.commission}>
                    ${availableOrder.invoice.commission}
                  </div>
                </Grid>
                <Grid item>
                  Qempo comission
                  <div className={classes.commission}>
                    ${availableOrder.invoice.qempo}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Link>
      </Grid>
    );
  }
}

AvailableOrderItem.propTypes = {
  classes: PropTypes.object.isRequired,
  availableOrder: PropTypes.object,
  handleClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(AvailableOrderItem);
