// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import ShippingTableMain from '../components/ShippingTableMain';
import { actions as ShippingActions } from '../duck';
import { actions as LocalValuesActions } from '../../local-values/duck';
import { withRouter } from 'react-router-dom';

import type { RootState } from '../../index';

const mapStateToProps = (state: RootState) => {
  return {
    limit: state.shipping.limit,
    page: state.shipping.page,
    deliveryCouriers: state.localValues.deliveryCouriers,
    shippingOrders: state.shipping.shippingOrders,
    totalShippingOrders: state.shipping.totalShippingOrders,
    isLoadingGetShipping: state.shipping.isLoadingGetShipping,
    getShippingSuccess: state.shipping.getShippingSuccess,
    getShippingError: state.shipping.getShippingError,
    isLoadingPerformShipping: state.shipping.isLoadingPerformShipping,
    performShippingSuccess: state.shipping.performShippingSuccess,
    performShippingError: state.shipping.performShippingError,
    isLoadingGetDeliveryCouriers: state.localValues.isLoadingGetDeliveryCouriers,
    getDeliveryCouriersSuccess: state.localValues.getDeliveryCouriersSuccess,
    getDeliveryCouriersError: state.localValues.getDeliveryCouriersError,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return bindActionCreators({ ...ShippingActions, ...LocalValuesActions }, dispatch);
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShippingTableMain));
