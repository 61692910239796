// @flow
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { getPendingApi, sendPendingApi, reportPendingApi } from './api';

import type { RootState } from '../index';
import type { HTTPError } from '../../common/error';

// Actions
const GET_PENDING_REQUEST = 'GET_PENDING_REQUEST';
const GET_PENDING_SUCCESS = 'GET_PENDING_SUCCESS';
const GET_PENDING_ERROR = 'GET_PENDING_ERROR';
const SEND_PENDING_REQUEST = 'SEND_PENDING_REQUEST';
const SEND_PENDING_SUCCESS = 'SEND_PENDING_SUCCESS';
const SEND_PENDING_ERROR = 'SEND_PENDING_ERROR';
const REPORT_PENDING_REQUEST = 'REPORT_PENDING_REQUEST';
const REPORT_PENDING_SUCCESS = 'REPORT_PENDING_SUCCESS';
const REPORT_PENDING_ERROR = 'REPORT_PENDING_ERROR';
const CHANGE_PENDING_PAGINATION = 'CHANGE_PENDING_PAGINATION';
const RESET_PENDING_STATE = 'RESET_PENDING_STATE';

type PendingAction = {
  +type: string;
  +pendingOrders?: { [key: string]: any }[];
  +travelers?: { [key: string]: any }[];
  +totalPendingOrders?: number;
  +pendingRequest?: { [key: string]: any };
  +changes?: { [key: string]: any };
  +error?: HTTPError;
};

export type PendingState = {
  page: number;
  limit: number;
  search: string;
  filterKey: string;
  isLoadingGetPending?: boolean;
  getPendingSuccess?: boolean;
  getPendingError?: HTTPError;
  isLoadingSendPending?: boolean;
  sendPendingSuccess?: boolean;
  sendPendingError?: HTTPError;
  isLoadingReportPending?: boolean;
  reportPendingSuccess?: boolean;
  reportPendingError?: HTTPError;
  pendingOrders?: { [key: string]: any }[];
  travelers?: { [key: string]: any }[];
  totalPendingOrders?: number;
};

// Action Creators
export function executeGetPending(): PendingAction {
  return {
    type: GET_PENDING_REQUEST,
  };
}

export function executeGetPendingSuccess(pendingOrders: { [key: string]: any }[], travelers: { [key: string]: any }[], totalPendingOrders: number): PendingAction {
  return {
    type: GET_PENDING_SUCCESS,
    pendingOrders,
    travelers,
    totalPendingOrders,
  };
}

export function executeGetPendingError(error: HTTPError): PendingAction {
  return {
    type: GET_PENDING_ERROR,
    error,
  };
}

export function executeSendPending(data: { [key: string]: any }): PendingAction {
  return {
    type: SEND_PENDING_REQUEST,
    data,
  };
}

export function executeSendPendingSuccess(): PendingAction {
  return {
    type: SEND_PENDING_SUCCESS,
  };
}

export function executeSendPendingError(error: HTTPError): PendingAction {
  return {
    type: SEND_PENDING_ERROR,
    error,
  };
}

  export function executeReportPending(data: { [key: string]: any }): PendingAction {
  return {
    type: REPORT_PENDING_REQUEST,
    data,
  };
}

export function executeReportPendingSuccess(): PendingAction {
  return {
    type: REPORT_PENDING_SUCCESS,
  };
}

export function executeReportPendingError(error: HTTPError): PendingAction {
  return {
    type: REPORT_PENDING_ERROR,
    error,
  };
}

export function changePendingPagination(changes: { [key: string]: any }) {
  return {
    type: CHANGE_PENDING_PAGINATION,
    changes,
  };
}

export function executeResetPendingState(): PendingAction {
  return {
    type: RESET_PENDING_STATE,
  };
}

export const actions = {
  GET_PENDING_REQUEST,
  GET_PENDING_SUCCESS,
  GET_PENDING_ERROR,
  SEND_PENDING_REQUEST,
  SEND_PENDING_SUCCESS,
  SEND_PENDING_ERROR,
  CHANGE_PENDING_PAGINATION,
  RESET_PENDING_STATE,
  executeGetPending,
  executeGetPendingSuccess,
  executeGetPendingError,
  executeSendPending,
  executeSendPendingSuccess,
  executeSendPendingError,
  executeReportPending,
  executeReportPendingSuccess,
  executeReportPendingError,
  changePendingPagination,
  executeResetPendingState,
};

export const initialState: PendingState = {
  page: 0,
  limit: 10,
  search: '',
  filterKey: 'id',
};

// Reducer
export default function reducer(state: PendingState = initialState,
                                action: PendingAction): PendingState {
  switch (action.type) {
    case GET_PENDING_REQUEST:
      return {
        ...state,
        isLoadingGetPending: true,
        getPendingSuccess: false,
      };
    case GET_PENDING_SUCCESS:
      return {
        ...state,
        isLoadingGetPending: false,
        getPendingSuccess: true,
        pendingOrders: action.pendingOrders,
        travelers: action.travelers,
        totalPendingOrders: action.totalPendingOrders,
      };
    case GET_PENDING_ERROR:
      return {
        ...state,
        getPendingSuccess: false,
        getShipOrderError: action.error,
      };
    case SEND_PENDING_REQUEST:
      return {
        ...state,
        isLoadingSendPending: true,
        sendPendingSuccess: false,
      };
    case SEND_PENDING_SUCCESS:
      return {
        ...state,
        isLoadingSendPending: false,
        sendPendingSuccess: true,
      };
    case SEND_PENDING_ERROR:
      return {
        ...state,
        sendPendingSuccess: false,
        sendPendingError: action.error,
      };
    case REPORT_PENDING_REQUEST:
      return {
        ...state,
        isLoadingReportPending: true,
        reportPendingSuccess: false,
      };
    case REPORT_PENDING_SUCCESS:
      return {
        ...state,
        isLoadingReportPending: false,
        reportPendingSuccess: true,
      };
    case REPORT_PENDING_ERROR:
      return {
        ...state,
        reportPendingSuccess: false,
        reportPendingError: action.error,
      };
    case CHANGE_PENDING_PAGINATION:
      const data = { ...action.changes };
      return { ...state, ...data };
    case RESET_PENDING_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetPendingEpic(action$: ActionsObservable<PendingAction>, state$: StateObservable<RootState>) {
  return action$.ofType(GET_PENDING_REQUEST).pipe(
    delay(1000),
    mergeMap(
      () => getPendingApi({
        page: state$.value.pending.page,
        limit: state$.value.pending.limit,
        [state$.value.pending.filterKey]: state$.value.pending.search,
      }).pipe(
        map(({ pendingOrders, travelers, totalPendingOrders }) => executeGetPendingSuccess(pendingOrders, travelers, totalPendingOrders)),
        catchError((e: HTTPError) => of(executeGetPendingError(e))))
    )
  );
}

export function executeSendPendingEpic(action$: ActionsObservable<PendingAction>) {
  return action$.ofType(SEND_PENDING_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ data }) => sendPendingApi(data).pipe(
        map(() => executeSendPendingSuccess()),
        catchError((e: HTTPError) => of(executeSendPendingError(e))))
    )
  );
}

export function executeReportPendingEpic(action$: ActionsObservable<PendingAction>) {
  return action$.ofType(REPORT_PENDING_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ data }) => reportPendingApi(data).pipe(
        map(() => executeReportPendingSuccess()),
        catchError((e: HTTPError) => of(executeReportPendingError(e))))
    )
  );
}

export function changePendingPaginationEpic(action$: ActionsObservable<PendingAction>) {
  return action$.ofType(CHANGE_PENDING_PAGINATION).pipe(
    map(() => executeGetPending()
    )
  );
}
