// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import styles from './style';

type Props = {
  +classes: { [key: string]: any };
}

class ShippingTableHead extends Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.headContainer}>
        <Grid item container xs={6}>
          <Grid item xs={2}/>
          <Grid item xs={6} className={classes.headCell}>
            orderId
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.headCell}>
          dueDate
        </Grid>
        <Grid item xs={2} className={classes.headCell}>
          {/*size*/}
        </Grid>
      </Grid>);
  }
}

ShippingTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShippingTableHead);
