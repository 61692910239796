// @flow
// import { Observable } from 'rxjs';
import { getCreator, postCreator } from '../../common/http'
import environment from '../../environment';

export const endpoints = Object.freeze({
  GET_SESSION: `${environment.apiUrl}/auth/session`,
  LOGIN_FIRST_STEP: `${environment.apiUrl}/auth/login`,
  LOGIN_SECOND_STEP: `${environment.apiUrl}/auth/loginsecondstep`,
  LOGOUT: `${environment.apiUrl}/auth/logout`,
});

export const getSessionApi = getCreator(fetch, endpoints.GET_SESSION);
/*export const getSessionApi = (queryParams: any, o:any) => Observable.create((observer) => {
    observer.next({
        type: 'user',
        id: 'QUS462845',
        email: 'giwah@qempo.com',
        firstName: 'Gi Wah',
        lastName: 'Dávalos',
    });
});*/
export const loginFirstStepApi = postCreator(fetch, endpoints.LOGIN_FIRST_STEP);
// export const loginFirstStepApi = (data: any, o: any) => Observable.create((observer) => {
//     observer.next({});
// });
export const loginSecondStepApi = postCreator(fetch, endpoints.LOGIN_SECOND_STEP);
// export const loginSecondStepApi = (data: any, o:any) => Observable.create((observer) => {
//     observer.next({
//         type: 'user',
//         id: 'QUS462845',
//         email: 'giwah@qempo.com',
//         firstName: 'Gi Wah',
//         lastName: 'Dávalos',
//     });
// });
export const logoutApi = postCreator(fetch, endpoints.LOGOUT);
