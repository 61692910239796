//@flow
import {withStyles} from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes, {instanceOf} from 'prop-types';
import {FormControlLabel} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import {setOrderItemArrived, endpoints} from './api';
import styles from './styles';

import type {OrderItem} from '../../entities';

type Props = {|
  +classes: { [key: string]: string };
  +orderItem: OrderItem;
|};

type State = {|
  checked: boolean;
  isLoading: boolean;
|};

class ArrivedOrderItem extends Component<Props, State> {
  constructor(props: Props) {
    super();
    this.state = {
      checked: props.orderItem.hasArrived,
      isLoading: false,
    };
  }

  handleChange = (event: Event) => {
    if (event.target instanceof HTMLInputElement) {
      const targetChecked = event.target.checked;
      const {orderItem} = this.props;
      this.setState({
        isLoading: true,
      }, () => {
        setOrderItemArrived({enabled: targetChecked}, {
          route: endpoints.ORDER_ITEM_HAS_ARRIVED.replace(':orderItemId', orderItem.orderItemId)
        })
          .toPromise()
          .then(() => this.setState({checked: targetChecked, isLoading: false}))
          .catch(() => this.setState({isLoading: false}));
      });
    }
  };

  render() {
    const {checked, isLoading} = this.state;
    const {classes} = this.props;
    return (
      <FormControlLabel
        className={isLoading ? classes.isLoading : undefined}
        control={
          <Checkbox
            checked={checked}
            onChange={this.handleChange}
            name="checkedB"
            color="primary"
          />
        }
        label="Arrived to Country"
      />
    );
  }
}

ArrivedOrderItem.propTypes = {
  classes: PropTypes.object,
  orderItem: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArrivedOrderItem);
