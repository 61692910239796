// @flow
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from '../styles';
import { Grid, Card, CardHeader, Paper, Checkbox, Button } from '@material-ui/core';
import moment from 'moment';

import CourierReceptionOrderItem from './CourierReceptionOrderItem';

import type { Shipment, ShipmentItem } from '../../../../entities';
import type { ShipmentSelection, OrderItemInputs } from '../CourierReception';
import type { ConfirmReceptionRequest } from '../../../reception/api';
import type { OrderItemOperationState } from '../../duck';

type CheckboxState = {
  +checked: boolean,
  +indeterminate: boolean,
};
export type OrderItemCheckboxState = CheckboxState & {
  +orderItemId: number,
};
type ShipmentCheckboxesState = CheckboxState & {
  +shipmentId: number,
  +items: OrderItemCheckboxState[],
};

type Props = {
  +classes: { [key: string]: any },
  +shipment: Shipment,
  +selection: ?ShipmentSelection,
  +handleSelectionUpdate: (shipmentId: number, orderItemId: ?number, checked: ?boolean, amount: ?number) => void,
  +executeReceiveProductsAtQempo: (request: ConfirmReceptionRequest) => void,
  +handleOrderItemOnFileChange: (shipmentId: number, orderItemId: number, ev: SyntheticInputEvent<HTMLInputElement>) => void,
  +updateOrderItemInvoice: (shipmentId: number, orderItemId: number) => void,
  +handleChangeItemTrackingNumber: (shipmentId: number, orderItemId: number, trackingNumber: string, trackingUrl: ? string) => void,
  +handleChangeItemStoreOrderId: (shipmentId: number, orderItemId: number, storeOrderId: string) => void,
  +userInputsMap: {
    [shipmentId: number]: {
      [orderItemId: number]: OrderItemInputs,
    },
  },
  +updateOrderItemTrackingNumber: (shipmentId: number, orderItemId: number) => void,
  +updateOrderItemStoreOrderId: (shipmentId: number, orderItemId: number) => void,
  +orderItemOperations: ?{
    [orderItemId: number]: OrderItemOperationState,
  },
  +resetOrderItemInvoice: (shipmentId: number, orderItemId: number) => void,
  +sendMissingTrackingMail: () => void;
  +isPostingMissingTrackingMail?: boolean;
};

class CourierReceptionShipment extends Component<Props> {
  buildShipmentCheckboxesState = (shipment: Shipment, selection: ?ShipmentSelection): ShipmentCheckboxesState => {
    // This funtion parses the selection state and produces an easy accesible state for the checkboxes in the list
    // The indeterminate state of the checkbox is used when the amount of subelements selected is not the total of sub elements
    const items = shipment.items.map((shipmentItem) => {
      const selectedOrderItemAmount = selection && selection.items[shipmentItem.orderItem.orderItemId];
      if (!selectedOrderItemAmount) {
        return {
          orderItemId: shipmentItem.orderItem.orderItemId,
          checked: false,
          indeterminate: false,
        };
      }
      const calcAmount = shipmentItem.orderItem.amount - shipmentItem.orderItem.receivedCount - shipmentItem.orderItem.cancelledCount;
      return {
        orderItemId: shipmentItem.orderItem.orderItemId,
        checked: selectedOrderItemAmount ? selectedOrderItemAmount === calcAmount : false,
        indeterminate: selectedOrderItemAmount ? selectedOrderItemAmount !== calcAmount : false,
      };
    });
    return {
      shipmentId: shipment.shipmentId,
      checked: !!selection,
      indeterminate: !!selection ? Object.keys(selection.items).length !== shipment.items.length : false,
      items,
    };
  };

  handleReceiveShipment = (shipment: Shipment, selection: ?ShipmentSelection): void => {
    const { executeReceiveProductsAtQempo } = this.props;
    if (!selection) {
      throw Error('Trying to receive an unselected shipment');
    }
    const request: ConfirmReceptionRequest = { items: [] };
    for (const orderItemSelectionId in selection.items) {
      request.items.push({
        orderItemId: parseInt(orderItemSelectionId),
        amount: selection.items[parseInt(orderItemSelectionId)],
      });
    }
    if (request.items.length) {
      executeReceiveProductsAtQempo(request);
    }
  };

  handleCheckboxChange = (e: Event, shipmentId: number, orderItemId: ?number) => {
    // This function handles the Checkbox Changes for both the Shipment and the OrderItem
    const { handleSelectionUpdate } = this.props;
    if (e.target instanceof HTMLInputElement) {
      const { checked } = e.target;
      handleSelectionUpdate(shipmentId, orderItemId, checked, null);
    }
  };

  buildItems = (shipment: Shipment, selection: ?ShipmentSelection, checkboxesState: any): React$Node[] => {
    const {
      handleSelectionUpdate,
      userInputsMap,
      updateOrderItemInvoice,
      handleOrderItemOnFileChange,
      handleChangeItemTrackingNumber,
      handleChangeItemStoreOrderId,
      updateOrderItemTrackingNumber,
      updateOrderItemStoreOrderId,
      orderItemOperations,
      resetOrderItemInvoice,
      classes,
    } = this.props;
    return shipment.items.map((item: ShipmentItem, itemIndex: number) => {
      const { orderItem } = item;
      const itemSelectionAmount: number = (selection && selection.items[item.orderItem.orderItemId]) || 0;
      const itemCheckboxState = checkboxesState.find((itemCheckboxState) => itemCheckboxState.orderItemId === orderItem.orderItemId);
      return (
        <Fragment key={orderItem.orderItemId}>
          <CourierReceptionOrderItem
            orderItem={orderItem}
            orderItemInputs={userInputsMap[shipment.shipmentId] && userInputsMap[shipment.shipmentId][orderItem.orderItemId]}
            itemSelectionAmount={itemSelectionAmount}
            itemCheckboxState={itemCheckboxState}
            updateOrderItemInvoice={() => updateOrderItemInvoice(shipment.shipmentId, orderItem.orderItemId)}
            handleChangeItemStoreOrderId={(newValue: string) =>
              handleChangeItemStoreOrderId(shipment.shipmentId, orderItem.orderItemId, newValue)
            }
            handleChangeItemTrackingNumber={(trackingNumber: string, trackingUrl?: string) =>
              handleChangeItemTrackingNumber(shipment.shipmentId, orderItem.orderItemId, trackingNumber, trackingUrl)
            }
            handleSelectionUpdate={(checked: boolean, newValue: ?number) =>
              handleSelectionUpdate(shipment.shipmentId, orderItem.orderItemId, checked, newValue)
            }
            handleOrderItemOnFileChange={(ev: SyntheticInputEvent<HTMLInputElement>) =>
              handleOrderItemOnFileChange(shipment.shipmentId, orderItem.orderItemId, ev)
            }
            updateTrackingNumber={() => updateOrderItemTrackingNumber(shipment.shipmentId, orderItem.orderItemId)}
            updateStoreOrderId={() => updateOrderItemStoreOrderId(shipment.shipmentId, orderItem.orderItemId)}
            resetInvoice={() => resetOrderItemInvoice(shipment.shipmentId, orderItem.orderItemId)}
            operations={orderItemOperations ? orderItemOperations[orderItem.orderItemId] : null}
          />
          {itemIndex !== shipment.items.length - 1 ? <hr className={classes.lowProfileHr}/> : null}
        </Fragment>
      );
    });
  };

  verifyHasTracking = (): boolean =>
    this.props.shipment.items.reduce(
      (acc: boolean, shipmentItem: ShipmentItem) =>
        acc && !!shipmentItem.orderItem.tracking && !!shipmentItem.orderItem.tracking.trackingNumber,
      true,
    )

  render() {
    const {
      classes,
      shipment,
      selection,
      handleSelectionUpdate,
      sendMissingTrackingMail,
      isPostingMissingTrackingMail,
    } = this.props;
    const checkboxesState = this.buildShipmentCheckboxesState(shipment, selection);
    return (
      <Grid item xs={12} key={shipment.order.id} className={classes.regularPadding}>
        <Card elevation={3}>
          <CardHeader
            className={classes.orderHeader}
            key={shipment.order.id + '-Order'}
            title={
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <div className={classes.textContainer}>
                    <h6 className={classes.title}>{shipment.order.id}</h6>
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className={classes.textContainer}>
                    <Paper className={classes.paper} elevation={2}>
                      <h6 className={classes.title}>
                        Buyer: {shipment.order.purchaser.firstName} {shipment.order.purchaser.lastName}
                      </h6>
                      <h6 className={classes.title}>Phone: {shipment.order.shippingAddress.phone}</h6>
                      <h6 className={classes.title}>Email: {shipment.order.purchaser.email}</h6>
                      <h6 className={classes.title}>Departament: {shipment.order.shippingAddress.admin1.name}</h6>
                      <h6 className={classes.title}>City: {shipment.order.shippingAddress.admin2.name}</h6>
                      {shipment.order.shippingAddress.admin3 &&
                      <h6 className={classes.title}>District: {shipment.order.shippingAddress.admin3.name}</h6>}
                    </Paper>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  {!this.verifyHasTracking() &&
                  <Button
                    className={classes.buttonMissingTracking}
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={isPostingMissingTrackingMail}
                    onClick={sendMissingTrackingMail}
                  >
                    Send missing tracking mail
                  </Button>}
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.textContainer}>
                    <h6 className={[classes.title, classes.centerText].join(' ')}>
                      {shipment.order.beforeDate ? 'Full' : 'Lite'}
                    </h6>
                  </div>
                </Grid>
              </Grid>
            }
          />
          <CardHeader
            className={classes.shipmentHeader}
            key={shipment.order.id + '-Shipment'}
            title={
              <Grid container justify="space-between" alignItems="center">
                <Grid item container alignItems="center" xs={3}>
                  <Grid item xs={3}>
                    <Checkbox
                      checked={checkboxesState.checked}
                      indeterminate={checkboxesState.indeterminate}
                      onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                        const { checked } = e.target;
                        handleSelectionUpdate(shipment.shipmentId, null, checked, null);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.textContainer}>
                      <h6 className={classes.title}>Shipment: #{shipment.shipmentId}</h6>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <div className={classes.textContainer}>
                    <h6 className={classes.title}>Delivery
                      date: {moment(shipment.deliveryDate).format('DD/MM/YYYY')}</h6>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className={classes.textContainer}>
                    <h6 className={classes.title}>Courier: {shipment.courier.name}</h6>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className={classes.ctaRightContainer}>
                    <Button
                      onClick={() => this.handleReceiveShipment(shipment, selection)}
                      disabled={!(checkboxesState.checked || checkboxesState.indeterminate)}
                      color="primary"
                      variant="contained"
                    >
                      RECEIVE
                    </Button>
                  </div>
                </Grid>
              </Grid>
            }
          />
          {this.buildItems(shipment, selection, checkboxesState.items)}
        </Card>
      </Grid>
    );
  }
}

CourierReceptionShipment.propTypes = {
  classes: PropTypes.object.isRequired,
  shipment: PropTypes.object.isRequired,
  selection: PropTypes.object,
  userInputsMap: PropTypes.object,
  handleSelectionUpdate: PropTypes.func.isRequired,
  executeReceiveProductsAtQempo: PropTypes.func.isRequired,
  handleOrderItemOnFileChange: PropTypes.func.isRequired,
  updateOrderItemInvoice: PropTypes.func.isRequired,
  handleChangeItemTrackingNumber: PropTypes.func.isRequired,
  handleChangeItemStoreOrderId: PropTypes.func.isRequired,
  updateOrderItemTrackingNumber: PropTypes.func.isRequired,
  updateOrderItemStoreOrderId: PropTypes.func.isRequired,
  orderItemOperations: PropTypes.object,
  resetOrderItemInvoice: PropTypes.func.isRequired,
  sendMissingTrackingMail: PropTypes.func.isRequired,
  isPostingMissingTrackingMail: PropTypes.bool,
};

export default withStyles(styles)(CourierReceptionShipment);
