// @flow
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { getReviewApi, approveReviewApi, cancelReviewApi, fixReviewApi } from './api';

import type { RootState } from '../index';
import type { HTTPError } from '../../common/error';

// Actions
const GET_REVIEW_REQUEST = 'GET_REVIEW_REQUEST';
const GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS';
const GET_REVIEW_ERROR = 'GET_REVIEW_ERROR';
const APPROVE_REVIEW_REQUEST = 'APPROVE_REVIEW_REQUEST';
const APPROVE_REVIEW_SUCCESS = 'APPROVE_REVIEW_SUCCESS';
const APPROVE_REVIEW_ERROR = 'APPROVE_REVIEW_ERROR';
const CANCEL_REVIEW_REQUEST = 'CANCEL_REVIEW_REQUEST';
const CANCEL_REVIEW_SUCCESS = 'CANCEL_REVIEW_SUCCESS';
const CANCEL_REVIEW_ERROR = 'CANCEL_REVIEW_ERROR';
const FIX_REVIEW_REQUEST = 'FIX_REVIEW_REQUEST';
const FIX_REVIEW_SUCCESS = 'FIX_REVIEW_SUCCESS';
const FIX_REVIEW_ERROR = 'FIX_REVIEW_ERROR';
const CHANGE_REVIEW_PAGINATION = 'CHANGE_REVIEW_PAGINATION';
const RESET_REVIEW_STATE = 'RESET_REVIEW_STATE';

type ReviewAction = {
  +type: string;
  +reviewOrders?: { [key: string]: any }[];
  +totalReviewOrders?: number;
  +reviewRequest?: { [key: string]: any };
  +changes?: { [key: string]: any };
  +error?: HTTPError;
};

export type ReviewState = {
  page: number;
  limit: number;
  search: string;
  filterKey: string;
  isLoadingGetReview?: boolean;
  getReviewSuccess?: boolean;
  getReviewError?: HTTPError;
  isLoadingApproveReview?: boolean;
  approveReviewSuccess?: boolean;
  approveReviewError?: HTTPError;
  isLoadingCancelReview?: boolean;
  cancelReviewSuccess?: boolean;
  cancelReviewError?: HTTPError;
  isLoadingFixReview?: boolean;
  fixReviewSuccess?: boolean;
  fixReviewError?: HTTPError;
  reviewOrders?: { [key: string]: any }[];
  totalReviewOrders?: number;
};

// Action Creators
export function executeGetReview(): ReviewAction {
  return {
    type: GET_REVIEW_REQUEST,
  };
}

export function executeGetReviewSuccess(reviewOrders: { [key: string]: any }[], totalReviewOrders: number): ReviewAction {
  return {
    type: GET_REVIEW_SUCCESS,
    reviewOrders,
    totalReviewOrders,
  };
}

export function executeGetReviewError(error: HTTPError): ReviewAction {
  return {
    type: GET_REVIEW_ERROR,
    error,
  };
}

export function executeApproveReview(data: { [key: string]: any }): ReviewAction {
  return {
    type: APPROVE_REVIEW_REQUEST,
    data,
  };
}

export function executeApproveReviewSuccess(): ReviewAction {
  return {
    type: APPROVE_REVIEW_SUCCESS,
  };
}

export function executeApproveReviewError(error: HTTPError): ReviewAction {
  return {
    type: APPROVE_REVIEW_ERROR,
    error,
  };
}

export function executeCancelReview(data: { [key: string]: any }): ReviewAction {
  return {
    type: CANCEL_REVIEW_REQUEST,
    data,
  };
}

export function executeCancelReviewSuccess(): ReviewAction {
  return {
    type: CANCEL_REVIEW_SUCCESS,
  };
}

export function executeCancelReviewError(error: HTTPError): ReviewAction {
  return {
    type: CANCEL_REVIEW_ERROR,
    error,
  };
}

export function executeFixReview(data: { [key: string]: any }): ReviewAction {
  return {
    type: FIX_REVIEW_REQUEST,
    data,
  };
}

export function executeFixReviewSuccess(): ReviewAction {
  return {
    type: FIX_REVIEW_SUCCESS,
  };
}

export function executeFixReviewError(error: HTTPError): ReviewAction {
  return {
    type: FIX_REVIEW_ERROR,
    error,
  };
}

export function changeReviewPagination(changes: { [key: string]: any }) {
  return {
    type: CHANGE_REVIEW_PAGINATION,
    changes,
  };
}

export function executeResetReviewState(): ReviewAction {
  return {
    type: RESET_REVIEW_STATE,
  };
}

export const actions = {
  GET_REVIEW_REQUEST,
  GET_REVIEW_SUCCESS,
  GET_REVIEW_ERROR,
  APPROVE_REVIEW_REQUEST,
  APPROVE_REVIEW_SUCCESS,
  APPROVE_REVIEW_ERROR,
  CHANGE_REVIEW_PAGINATION,
  RESET_REVIEW_STATE,
  executeGetReview,
  executeGetReviewSuccess,
  executeGetReviewError,
  executeApproveReview,
  executeApproveReviewSuccess,
  executeApproveReviewError,
  executeCancelReview,
  executeCancelReviewSuccess,
  executeCancelReviewError,
  executeFixReview,
  executeFixReviewSuccess,
  executeFixReviewError,
  changeReviewPagination,
  executeResetReviewState,
};

export const initialState: ReviewState = {
  page: 0,
  limit: 10,
  search: '',
  filterKey: 'id',
};

// Reducer
export default function reducer(state: ReviewState = initialState,
                                action: ReviewAction): ReviewState {
  switch (action.type) {
    case GET_REVIEW_REQUEST:
      return {
        ...state,
        isLoadingGetReview: true,
        getReviewSuccess: false,
      };
    case GET_REVIEW_SUCCESS:
      return {
        ...state,
        isLoadingGetReview: false,
        getReviewSuccess: true,
        reviewOrders: action.reviewOrders,
        totalReviewOrders: action.totalReviewOrders,
      };
    case GET_REVIEW_ERROR:
      return {
        ...state,
        getReviewSuccess: false,
        getShipOrderError: action.error,
      };
    case APPROVE_REVIEW_REQUEST:
      return {
        ...state,
        isLoadingApproveReview: true,
        approveReviewSuccess: false,
      };
    case APPROVE_REVIEW_SUCCESS:
      return {
        ...state,
        isLoadingApproveReview: false,
        approveReviewSuccess: true,
      };
    case APPROVE_REVIEW_ERROR:
      return {
        ...state,
        approveReviewSuccess: false,
        approveReviewError: action.error,
      };
    case CANCEL_REVIEW_REQUEST:
      return {
        ...state,
        isLoadingCancelReview: true,
        cancelReviewSuccess: false,
      };
    case CANCEL_REVIEW_SUCCESS:
      return {
        ...state,
        isLoadingCancelReview: false,
        cancelReviewSuccess: true,
      };
    case CANCEL_REVIEW_ERROR:
      return {
        ...state,
        cancelReviewSuccess: false,
        cancelReviewError: action.error,
      };
    case FIX_REVIEW_REQUEST:
      return {
        ...state,
        isLoadingFixReview: true,
        fixReviewSuccess: false,
      };
    case FIX_REVIEW_SUCCESS:
      return {
        ...state,
        isLoadingFixReview: false,
        fixReviewSuccess: true,
      };
    case FIX_REVIEW_ERROR:
      return {
        ...state,
        fixReviewSuccess: false,
        fixReviewError: action.error,
      };
    case CHANGE_REVIEW_PAGINATION:
      const data = { ...action.changes };
      return { ...state, ...data };
    case RESET_REVIEW_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetReviewEpic(action$: ActionsObservable<ReviewAction>, state$: StateObservable<RootState>) {
  return action$.ofType(GET_REVIEW_REQUEST).pipe(
    delay(1000),
    mergeMap(
      () => getReviewApi({
        page: state$.value.review.page,
        limit: state$.value.review.limit,
        [state$.value.review.filterKey]: state$.value.review.search,
      }).pipe(
        map(({ reviewOrders, totalReviewOrders }) => executeGetReviewSuccess(reviewOrders, totalReviewOrders)),
        catchError((e: HTTPError) => of(executeGetReviewError(e))))
    )
  );
}

export function executeApproveReviewEpic(action$: ActionsObservable<ReviewAction>) {
  return action$.ofType(APPROVE_REVIEW_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ data }) => approveReviewApi(data).pipe(
        map(() => executeApproveReviewSuccess()),
        catchError((e: HTTPError) => of(executeApproveReviewError(e))))
    )
  );
}

export function executeCancelReviewEpic(action$: ActionsObservable<ReviewAction>) {
  return action$.ofType(CANCEL_REVIEW_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ data }) => cancelReviewApi(data).pipe(
        map(() => executeCancelReviewSuccess()),
        catchError((e: HTTPError) => of(executeCancelReviewError(e))))
    )
  );
}

export function executeFixReviewEpic(action$: ActionsObservable<ReviewAction>) {
  return action$.ofType(FIX_REVIEW_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ data }) => fixReviewApi(data).pipe(
        map(() => executeFixReviewSuccess()),
        catchError((e: HTTPError) => of(executeFixReviewError(e))))
    )
  );
}

export function changeReviewPaginationEpic(action$: ActionsObservable<ReviewAction>) {
  return action$.ofType(CHANGE_REVIEW_PAGINATION).pipe(
    map(() => executeGetReview()
    )
  );
}
