// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from '../styles';
import { Card, Link, CardMedia, CardContent, Grid, Typography, Button, CircularProgress, Snackbar } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';

import type { OrderItem } from '../../../../entities';
import type { OrderItemInputs } from '../CourierReception';
import type { InvoiceOperation } from '../../duck';

type Props = {
  +classes: { [key: string]: any },
  +orderItem: OrderItem,
  +orderItemInputs?: OrderItemInputs,
  handleOrderItemOnFileChange: (ev: SyntheticInputEvent<HTMLInputElement>) => void,
  updateOrderItemInvoice: () => void,
  +operation: ?InvoiceOperation,
  resetInvoice: () => void,
};

class CourierReceptionOrderItemInvoice extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { operation, resetInvoice } = this.props;
    // Reset after success
    if (prevProps.operation && operation) {
      if (prevProps.operation.updateOrderItemInvoiceSuccess && !operation.updateOrderItemInvoiceSuccess) {
        resetInvoice();
      }
    }
  }
  renderCta() {
    const { operation, updateOrderItemInvoice, orderItemInputs, classes } = this.props;
    if (operation && operation.isLoading) {
      return <CircularProgress className={classes.circularProgressAdornment} size={24} thickness={2} />;
    } else {
      if (operation && operation.updateOrderItemInvoiceSuccess) {
        return <DoneIcon className={classes.doneIcon} />;
      } else if (operation && operation.error) {
        return <ErrorIcon className={classes.errorIcon} />;
      } else {
        return (
          <Button
            onClick={() => updateOrderItemInvoice()}
            disabled={!(orderItemInputs && orderItemInputs.invoiceImagePreview)}
            color="primary"
            variant="contained"
          >
            Update
          </Button>
        );
      }
    }
  }

  render() {
    const { classes, orderItemInputs, orderItem, handleOrderItemOnFileChange, operation } = this.props;
    return (
      <Card className={classes.invoice}>
        <Link
          className={classes.invoice}
          href={
            orderItemInputs &&
            orderItemInputs.invoiceImagePreview ? null :
              orderItem.externalInvoice ? orderItem.externalInvoice.image : ''
          }
          target="_blank"
          rel="noreferrer"
        >
          <CardMedia
            className={classes.invoiceImage}
            image={
              orderItemInputs &&
              orderItemInputs.invoiceImagePreview ? orderItemInputs.invoiceImagePreview :
                orderItem.externalInvoice ? orderItem.externalInvoice.image : ''
            }
            title={'Invoice: ' + orderItem.name}
          />
        </Link>
        <CardContent>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={12}>
              <Typography component="h5" variant="h5">
                Invoice
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <input
                accept="image/*"
                className={[classes.input, classes.maxFullWidth].join(' ')}
                disabled={operation && operation.isLoading}
                type="file"
                key={orderItemInputs && orderItemInputs.invoiceImageFileInputKey ? orderItemInputs.invoiceImageFileInputKey : 'generic'}
                onChange={(ev: SyntheticInputEvent<HTMLInputElement>) => {
                  handleOrderItemOnFileChange(ev);
                }}
              />
            </Grid>
            <Grid item xs={4} className={classes.invoiceCtaGrid}>
              {this.renderCta()}
            </Grid>
          </Grid>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={operation ? !!operation.error : false}
            autoHideDuration={6000}
            message={
              <span id="message-id">
                {operation && operation.error && operation.error.data && operation.error.data.Error
                  ? operation.error.data.Error.message
                  : 'Generic Error'}
              </span>
            }
          />
        </CardContent>
      </Card>
    );
  }
}

CourierReceptionOrderItemInvoice.propTypes = {
  classes: PropTypes.object.isRequired,
  orderItem: PropTypes.object.isRequired,
  orderItemInputs: PropTypes.object,
  handleOrderItemOnFileChange: PropTypes.func.isRequired,
  updateOrderItemInvoice: PropTypes.func.isRequired,
  resetInvoice: PropTypes.func.isRequired,
};

export default withStyles(styles)(CourierReceptionOrderItemInvoice);
