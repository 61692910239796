// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';

type Props = {
  classes: { [key: string]: mixed };
  getTrackingNotificationStatus: (orderId: string) => void;
  isLoadingTrackingNotificationStatus?: boolean;
  postTrackingNotificationStatus: (orderId: string, status: boolean) => void;
  isPostingTrackingNotificationStatus?: boolean;
  trackingNotificationStatusMap: { [key: string]: boolean };
  orderId: string;
};

type State = {
  trackingStatus: boolean;
}

class TrackingNotificationStatus extends Component<Props, State> {
  state = {
    trackingStatus: true,
  };

  componentDidUpdate(prevProps: Props) {
    const { trackingNotificationStatusMap, orderId } = this.props;
    if (typeof prevProps.trackingNotificationStatusMap[orderId] === 'undefined' &&
      typeof trackingNotificationStatusMap[orderId] !== 'undefined') {
      this.setState({ trackingStatus: trackingNotificationStatusMap[orderId] });
    }
  }

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { postTrackingNotificationStatus, orderId } = this.props;
    if (e.target instanceof HTMLInputElement) {
      this.setState({ trackingStatus: e.target.checked });
      postTrackingNotificationStatus(orderId, e.target.checked);
    }
  }

  render() {
    const {
      classes,
      getTrackingNotificationStatus,
      isLoadingTrackingNotificationStatus,
      isPostingTrackingNotificationStatus,
      trackingNotificationStatusMap,
      orderId,
    } = this.props;
    const { trackingStatus } = this.state;
    const trackingNotificationStatus = trackingNotificationStatusMap[orderId];
    return (
      <div className={classes.trackingWrapper}>
        {(isLoadingTrackingNotificationStatus || isPostingTrackingNotificationStatus) &&
        <CircularProgress className={classes.progress} size={20}/>}
        {typeof trackingNotificationStatus === 'undefined' &&
        <a
          className={classes.btnNotificationStatus}
          onClick={() => getTrackingNotificationStatus(orderId)}
        >
          Get notification status
        </a>}
        {typeof trackingNotificationStatus !== 'undefined' &&
        <div>
          <Switch
            checked={trackingStatus}
            onChange={this.handleChange}
            color="primary"
          />
          {trackingNotificationStatus &&
          <p className={classes.statusText}>Notifications are ON</p>}
          {!trackingNotificationStatus &&
          <p className={classes.statusText}>Notifications are OFF</p>}
        </div>}
      </div>
    )
  }
}

TrackingNotificationStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  getTrackingNotificationStatus: PropTypes.func.isRequired,
  isLoadingTrackingNotificationStatus: PropTypes.bool,
  postTrackingNotificationStatus: PropTypes.func.isRequired,
  isPostingTrackingNotificationStatus: PropTypes.bool,
  trackingNotificationStatusMap: PropTypes.object.isRequired,
  orderId: PropTypes.string.isRequired,
};


export default withStyles(styles)(TrackingNotificationStatus);
