// @flow
import React, { Component } from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { createMuiTheme, withStyles } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { InlineDatePicker } from 'material-ui-pickers';
import update from 'immutability-helper';
import red from '@material-ui/core/colors/red';
import moment from 'moment';
import { FormValidator } from '../../../common/form';
import { Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames/bind';

import styles from './styles';

import type { Courier } from '../../../entities';
import type { HTTPError } from '../../../common/error';

type Props = {
  +classes: { [key: string]: any },
  +executeGetPendingShipments: (queryFilter?: {
    [key: string]: string,
  }) => void,
  +executeGetCouriers: () => void,
  +isLoadingGetCouriers?: boolean,
  +couriers?: Courier[],
  +getCouriersError?: HTTPError,
  +isLoadingGetOrdersPendingReception?: boolean,
  +atLeastOneSelected: boolean,
  +handleModal: (modalName: string) => void,
  +selectedShipmentsCount: number,
};

type State = {
  search: {
    value: string,
    errors?: string[],
  },
  date: {
    value: moment,
    errors?: string[],
  },
  orderType: {
    value: string,
    errors?: string[],
  },
  courierId: {
    value: string,
    errors?: string[],
  },
  filterKey: string,
};

// const innerTheme = createMuiTheme({
//   typography: {
//     useNextVariants: true,
//   },
//   palette: {
//     secondary: {
//       main: red[500],
//     },
//   },
// });

const filterOptions = ['orderId', 'trackingId', 'dateUntil'];
const orderTypes = ['FULL', 'LITE'];
const initialState: State = {
  search: {
    value: '',
  },
  date: {
    value: moment(),
  },
  orderType: {
    value: '',
  },
  courierId: {
    value: '',
  },
  filterKey: 'orderId',
};

class CourierReceptionToolbar extends Component<Props, State> {
  searchFormValidator: FormValidator;
  changesSubject$: Subject;
  state = initialState;

  constructor(props: Props) {
    super(props);
    this.searchFormValidator = new FormValidator({
      orderId: {
        orderIdFormat: 'orderIdFormat',
      },
      dateUntil: {
        dateFormat: 'dateFormat',
      },
    });
    this.changesSubject$ = new Subject();
  }

  componentDidMount(): * {
    const {
      executeGetCouriers,
      couriers,
      executeGetPendingShipments,
    } = this.props;
    if (!couriers) executeGetCouriers();
    this.changesSubject$.pipe(debounceTime(1000)).subscribe(() => {
      executeGetPendingShipments(this.buildQueryParams());
    });
  }

  componentWillUnmount(): * {
    if (this.changesSubject$) {
      this.changesSubject$.unsubscribe();
    }
  }

  handleChange = (e: Event) => {
    const { name, value } = (e.target: { [filterKey: string]: any });
    if (name === 'filterKey') {
      this.setState(prevState =>
        update(prevState, {
          [name]: { $set: value },
          search: {
            $unset: ['errors'],
          },
        }),
      );
    } else if (name === 'search') {
      const { filterKey } = this.state;
      /*const errors = this.searchFormValidator.validateField(filterKey, value);
      if (errors.length) {
        this.setState(prevState =>
          update(prevState, {
            search: {
              value: { $set: value },
              errors: { $set: errors },
            },
          })
        );
      } else {*/
      this.setState(prevState =>
        update(prevState, {
          search: {
            value: { $set: value },
            $unset: ['errors'],
          },
        }),
      );
      // }
    } else {
      this.setState(
        prevState =>
          update(prevState, {
            [name]: {
              value: { $set: value },
              $unset: ['errors'],
            },
          }),
        this.changesSubject$.next(),
      );
    }
  };

  buildQueryParams = (): { [key: string]: string } => {
    const { filterKey, search, date, orderType, courierId } = this.state;
    const params = {};
    if (filterKey !== 'dateUntil') {
      if (!!search.value && !(!!search.errors && !!search.errors.length))
        params[filterKey] = search.value;
    } else {
      if (!!date.value) params.dateUntil = date.value.format('YYYY-MM-DD');
    }
    if (!!orderType.value) params.orderType = orderType.value;
    if (!!courierId.value) params.courierId = courierId.value;
    return params;
  };

  handleDateInput = (newDate: moment) => {
    const { date } = this.state;
    if (
      !newDate ||
      !date.value ||
      newDate.format('YYYY-MM-DD') !== date.value.format('YYYY-MM-DD')
    ) {
      this.setState(
        prevState =>
          update(prevState, {
            date: {
              value: { $set: newDate },
            },
            search: {
              $unset: ['errors'],
            },
          }),
        this.changesSubject$.next(),
      );
    }
  };

  search = (e: Event) => {
    const { filterKey } = this.state;
    if (e.target instanceof HTMLInputElement) {
      /*const { value } = e.target;
      if (value && value.length) {
        const errors = this.searchFormValidator.validateField(filterKey, value);
        if (errors.length) {
          this.setState(prevState =>
            update(prevState, {
              search: {
                errors: { $set: errors },
              },
            })
          );
        } else {
          this.changesSubject$.next();
        }
      } else {*/
      this.changesSubject$.next();
      // }
    }
  };

  getInputBox = () => {
    const { classes } = this.props;
    const { filterKey, search, date } = this.state;
    if (filterKey === 'dateUntil') {
      return (
        <InlineDatePicker
          format="DD-MM-YYYY"
          className={classes.dateSelect}
          keyboard
          clearable
          name="date"
          placeholder={moment().format('DD-MM-YYYY')}
          margin="none"
          mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
          value={date.value}
          onChange={this.handleDateInput}
        />
      );
    } else {
      return (
        <div className={classes.tableSearch}>
          <div className={classes.tableSearchIcon}>
            <SearchIcon/>
          </div>
          <InputBase
            placeholder="Search…"
            value={search.value}
            name="search"
            onChange={this.handleChange}
            onKeyDown={e => (e.keyCode === 13 ? this.search(e) : undefined)}
            classes={{
              root: classes.tableInputRoot,
              input: classes.tableSearchInput,
            }}
          />
        </div>
      );
    }
  };

  handleReset = () => {
    this.setState(initialState, this.changesSubject$.next());
  };

  render() {
    const {
      classes,
      handleModal,
      atLeastOneSelected,
      selectedShipmentsCount,
      isLoadingGetCouriers,
      couriers,
      isLoadingGetOrdersPendingReception,
    } = this.props;
    const { search, filterKey, courierId, orderType } = this.state;
    const couriersOptions: { [key: string]: string }[] = (couriers || []).map(
      (courier: Courier) => {
        return {
          value: courier.id,
          label: courier.name,
        };
      },
    );
    return (
      <Toolbar className={classes.toolbar}>
        <Grid container direction="column">
          <Grid item container justify="space-between" spacing={16}>
            <Grid item>
              {/*TODO: InputLabelProps not working. Label needs new css*/}
              <TextField
                value={filterKey}
                name="filterKey"
                variant="outlined"
                error={!!search.errors && !!search.errors}
                className={classes.filterSelect}
                SelectProps={{
                  SelectDisplayProps: {
                    style: {
                      paddingTop: 9,
                      paddingBottom: 8,
                    },
                  },
                }}
                select
                onChange={this.handleChange}
              >
                {filterOptions.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              {this.getInputBox()}

              <TextField
                className={classNames(classes.select, classes.filterSelect)}
                value={courierId.value}
                label="Courier"
                name="courierId"
                variant="outlined"
                disabled={isLoadingGetCouriers}
                onChange={this.handleChange}
                select
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                SelectProps={{
                  SelectDisplayProps: {
                    style: {
                      paddingTop: 9,
                      paddingBottom: 8,
                    },
                  },
                }}
              >
                <MenuItem value="">None</MenuItem>
                {couriersOptions.map(
                  (option: { [key: string]: string }, i: number) => (
                    <MenuItem key={i} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ),
                )}
              </TextField>
              <TextField
                className={classNames(classes.select, classes.filterSelect)}
                value={orderType.value}
                label="Order type"
                name="orderType"
                variant="outlined"
                onChange={this.handleChange}
                select
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                SelectProps={{
                  SelectDisplayProps: {
                    style: {
                      paddingTop: 9,
                      paddingBottom: 8,
                    },
                  },
                }}
              >
                <MenuItem value="">None</MenuItem>
                {orderTypes.map((option: string, i: number) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <Button
                className={classes.buttonReset}
                color="primary"
                disabled={isLoadingGetOrdersPendingReception}
                variant="contained"
                onClick={this.handleReset}
              >
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                onClick={window.print}
                disabled={!atLeastOneSelected}
                style={{ height: 38 }}
                className={classes.toolbarButton}
              >
                <PrintIcon/>
              </Button>

              {/* <MuiThemeProvider theme={innerTheme}>
                <Button disabled={!atLeastOneSelected}
                        className={classes.toolbarButton}
                        onClick={() => handleModal('openCancelModal')}
                        color="secondary"
                        variant="contained">
                  CANCEL
                </Button>
              </MuiThemeProvider> */}
              <Button
                className={classes.toolbarButton}
                onClick={() => handleModal('openConfirmModal')}
                disabled={!atLeastOneSelected}
                color="primary"
                variant="contained"
              >
                RECEIVE
                {selectedShipmentsCount > 0 && ` (${selectedShipmentsCount})`}
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Tabs value={1}>
              <Tab
                component={Link}
                to={`/operations/reception`}
                label="Travellers"
              />
              <Tab label="Couriers"/>
            </Tabs>
          </Grid>
        </Grid>
      </Toolbar>
    );
  }
}

CourierReceptionToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  executeGetPendingShipments: PropTypes.func.isRequired,
  executeGetCouriers: PropTypes.func.isRequired,
  isLoadingGetCouriers: PropTypes.bool,
  couriers: PropTypes.array,
  getCouriersError: PropTypes.object,
  isLoadingGetOrdersPendingReception: PropTypes.bool,
  atLeastOneSelected: PropTypes.bool,
  handleModal: PropTypes.func.isRequired,
  selectedShipmentsCount: PropTypes.number.isRequired,
};

export default withStyles(styles)(CourierReceptionToolbar);
