// @flow
import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { InlineDatePicker } from 'material-ui-pickers';
import update from 'immutability-helper';
import red from '@material-ui/core/colors/red';
import moment from 'moment';
import { FormValidator } from '../../../common/form';
import { Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

import styles from './style';

type Props = {
  +classes: { [key: string]: any };
  +executeGetReception: (filter?: { [key: string]: string }) => void;
  +atLeastOneSelected: () => void;
  +handleModal: (modalName: string) => void;
  +selectedOrdersCount: number;
};

type State = {
  search: {
    value: string,
    errors?: string[],
  };
  date: {
    value: moment,
    errors?: string[],
  };
  filterKey: string;
};

const innerTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    secondary: {
      main: red[500],
    },
  },
});

const filterOptions = ['flightId', 'orderId', 'flightDate', 'travelerName'];

class ReceptionTableToolbar extends Component<Props, State> {
  searchFormValidator: FormValidator;
  state = {
    search: {
      value: '',
    },
    date: {
      value: moment(),
    },
    filterKey: 'flightId',
  };

  constructor(props: Props) {
    super(props);
    this.searchFormValidator = new FormValidator({
      flightId: {
        flightIdFormat: 'flightIdFormat',
      },
      orderId: {
        orderIdFormat: 'orderIdFormat',
      },
      flightDate: {
        dateFormat: 'dateFormat',
      },
      travelerName: {
        none: () => false,
      },
    });
  }

  handleChange = (e: Event) => {
    const { name, value } = (e.target: { [filterKey: string]: any });
    if (name === 'filterKey') {
      this.setState(prevState => update(prevState, {
        [name]: { $set: value },
        search: {
          $unset: ['errors'],
        },
      }));
    } else {
      this.setState(prevState => update(prevState, {
        [name]: {
          value: { $set: value },
          $unset: ['errors'],
        },
      }));
    }
  };

  handleDateInput = (newDate: ?moment) => {
    const { executeGetReception } = this.props;
    const { date } = this.state;
    if (!newDate || !date.value || newDate.format('YYYY-MM-DD') !== date.value.format('YYYY-MM-DD')) {
      this.setState(prevState => update(prevState, {
        date: {
          value: { $set: newDate },
        },
        search: {
          $unset: ['errors'],
        },
      }));
      if (newDate) {
        executeGetReception({ paidAt: newDate.format('YYYY-MM-DD') });
      } else executeGetReception();
    }
  };

  search = (e: Event) => {
    const { executeGetReception } = this.props;
    const { filterKey } = this.state;
    if (e.target instanceof HTMLInputElement) {
      const { value } = e.target;
      if (value && value.length) {
        const errors = this.searchFormValidator.validateField(filterKey, value);
        if (errors.length) {
          this.setState(prevState => update(prevState, {
            search: {
              errors: { $set: errors },
            },
          }));
        } else {
          const filter = { [filterKey]: value };
          executeGetReception(filter);
        }
      } else {
        executeGetReception();
      }
    }
  };

  getInputBox = () => {
    const { classes } = this.props;
    const { filterKey, search, date } = this.state;
    if (filterKey === 'flightDate') {
      return (
        <InlineDatePicker format="DD-MM-YYYY"
                          className={classes.dateSelect}
                          keyboard
                          clearable
                          name="date"
                          placeholder={moment().format('DD-MM-YYYY')}
                          margin="none"
                          mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                          value={date.value}
                          onChange={this.handleDateInput}/>
      );
    } else {
      return (
        <div className={classes.tableSearch}>
          <div className={classes.tableSearchIcon}>
            <SearchIcon/>
          </div>
          <InputBase placeholder="Search…"
                     value={search.value}
                     name="search"
                     onChange={this.handleChange}
                     onKeyDown={(e) => e.keyCode === 13 ? this.search(e) : undefined}
                     classes={{
                       root: classes.tableInputRoot,
                       input: classes.tableSearchInput,
                     }}/>
        </div>);
    }
  };

  render() {
    const { classes, handleModal, atLeastOneSelected, selectedOrdersCount } = this.props;
    const { search, filterKey } = this.state;
    return (
      <Toolbar className={classes.toolbar}>
        <Grid container direction="column">
          <Grid item container justify="space-between">
            <Grid item>
              <TextField value={filterKey}
                         name="filterKey"
                         variant="outlined"
                         error={!!search.errors && !!search.errors}
                         className={classes.filterSelect}
                         SelectProps={{
                           SelectDisplayProps: {
                             style: {
                               paddingTop: 9,
                               paddingBottom: 8,
                             },
                           },
                         }}
                         select
                         onChange={this.handleChange}>
                {filterOptions.map(option => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </TextField>

              {this.getInputBox()}
            </Grid>
            <Grid item>
              <Button color="primary"
                      onClick={window.print}
                      disabled={!atLeastOneSelected()}
                      style={{ height: 38 }}
                      className={classes.toolbarButton}>
                <PrintIcon/>
              </Button>

              <MuiThemeProvider theme={innerTheme}>
                <Button disabled={!atLeastOneSelected()}
                        className={classes.toolbarButton}
                        onClick={() => handleModal('openCancelModal')}
                        color="secondary"
                        variant="contained">
                  CANCEL
                </Button>
              </MuiThemeProvider>
              <Button className={classes.toolbarButton}
                      onClick={() => handleModal('openConfirmModal')}
                      disabled={!atLeastOneSelected()}
                      color="primary"
                      variant="contained">
                RECEIVE{selectedOrdersCount > 0 && ` (${selectedOrdersCount})`}
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Tabs value={0}>
              <Tab label="Travellers"/>
              <Tab component={Link}
                   to={`/operations/courier-reception`}
                   label="Couriers"/>
            </Tabs>
          </Grid>
        </Grid>
      </Toolbar>);
  }
}

ReceptionTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  executeGetReception: PropTypes.func.isRequired,
  atLeastOneSelected: PropTypes.func.isRequired,
  handleModal: PropTypes.func.isRequired,
  selectedOrdersCount: PropTypes.number.isRequired,
};

export default withStyles(styles)(ReceptionTableToolbar);
