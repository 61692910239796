// @flow
import environment from '../../environment';
import { getCreator, postCreator } from '../../common/http';

export const endpoints = Object.freeze({
    SCRAP_PRODUCT: `${environment.apiUrl}/scrapping/scrap`,
    CREATE_LINK: `${environment.apiUrl}/tools/bitlify`,
});

export const scrapProductApi = getCreator(fetch, endpoints.SCRAP_PRODUCT);
export const createLinkApi = postCreator(fetch, endpoints.CREATE_LINK);

export type ScrapProductRequest = {
    url: String;
};

export type CreateLinkRequest = {
    long_url: String;
};