// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Checkbox from '@material-ui/core/Checkbox';

import styles from './style';

type Props = {
  +classes: { [key: string]: any };
}

class DeliveryConfirmTableHead extends Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.headContainer}>
        <Grid item container xs={4}>
          <Grid item xs={2}/>
          <Grid item xs={6} className={classes.headCell}>
            trackingId
          </Grid>
        </Grid>
        <Grid item container xs={4} className={classes.headCell}>
          <Grid item xs={6} className={classes.headCell}>
            orderId
          </Grid>
          <Grid item xs={6} className={classes.headCell}>
            beforeDate
          </Grid>
        </Grid>
        <Grid item xs={4}/>
      </Grid>);
  }
}

DeliveryConfirmTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeliveryConfirmTableHead);
