// @flow
// import { Observable } from 'rxjs';
import { getCreator, postCreator/*, putCreator, deleteCreator*/ } from '../../common/http'
import environment from '../../environment';
// import apiRefunds from './refunds';

export const endpoints = Object.freeze({
  GET_REFUNDS: `${environment.apiUrl}/operations/refunds/list`,
  UPLOAD_IMAGE: `${environment.apiUrl}/administrative/stored-file/upload/file`,
  UPLOAD_REFUNDS: `${environment.apiUrl}/operations/refunds/:id`,
});

export const getRefundsApi = getCreator(fetch, endpoints.GET_REFUNDS);

// export const getRefundsApi = (data: any, o: any) => Observable.create(
//   (observer) => {
//     observer.next({ refunds: apiRefunds, totalRefunds: apiRefunds.length });
//   });

export const uploadRefundsApi = postCreator(fetch, endpoints.UPLOAD_REFUNDS);

// export const uploadRefundsApi = (data: any, o: any) => Observable.create(
//   (observer) => {
//     const totalRefunds = apiRefunds.length;
//     console.log('data', data);
//     const refundIndex = apiRefunds.findIndex(e => e.id === data.refundId);
//     apiRefunds.splice(refundIndex, 1);
//     observer.next({ refunds: apiRefunds, totalRefunds });
//   });

export const uploadImageRefundsApi = postCreator(fetch, endpoints.UPLOAD_IMAGE);

// export const uploadImageRefundsApi = (data: any, o: any) => Observable.create(
//   (observer) => {
//     console.log('image', data);
//     observer.next({ id: 'IMG042' });
//   });
