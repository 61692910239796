// @flow
import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { FormValidator } from '../../../common/form';
import { renderError } from '../../../common/form/render-error';
import PropTypes from 'prop-types';

import { commonStyle } from './LogIn';
import logo from '../../../assets/img/qempo_logo_primary.png';

type Props = {
  +classes: { [key: string]: string };
  +handleSubmit: () => void;
  +handleFocus: () => void;
  +handleBlur: () => void;
  +handleChange: () => void;
  +isLoadingLoginFirstStep?: boolean;
  +email: {
    value: string,
    errors?: string[],
  };
  +password: {
    value: string,
    errors?: string[],
  };
};

class FirstStepAuth extends Component<Props> {
  render() {
    const { classes, handleSubmit, handleBlur, handleChange, handleFocus, isLoadingLoginFirstStep, password, email } = this.props;

    const passwordError = password.errors && FormValidator.buildErrorLabel(password.errors, {
      required: 'Password is required',
      '401': 'Wrong user or password',
      '500': 'Internal Server Error',
    }, renderError);

    const emailError = email.errors && FormValidator.buildErrorLabel(email.errors, {
      emailFormat: 'Email format error',
      required: 'Email is required',
    }, renderError);

    return (
      <main className={classes.layout}>
        {isLoadingLoginFirstStep ? <CircularProgress className={classes.progress} size={80}/> :
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar} src={logo}>
            </Avatar>
            <Typography component="h1"
                        variant="h5">
              Sign in
            </Typography>
            <form className={classes.form}
                  onSubmit={handleSubmit}>
              <FormControl margin="normal"
                           required
                           fullWidth
                           error={!!emailError}>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input id="email"
                       type="email"
                       name="email"
                       autoComplete="email"
                       value={email.value}
                       onChange={handleChange}
                       onFocus={handleFocus}
                       onBlur={handleBlur}
                       autoFocus/>
                {emailError}
              </FormControl>
              <FormControl margin="normal"
                           required
                           fullWidth
                           error={!!passwordError}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input id="password"
                       type="password"
                       name="password"
                       autoComplete="current-password"
                       value={password.value}
                       onChange={handleChange}
                       onFocus={handleFocus}/>
                {passwordError}
              </FormControl>
              <Button type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleSubmit}>
                Sign in
              </Button>
            </form>
          </Paper>}
      </main>
    );
  }
}

FirstStepAuth.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isLoadingLoginFirstStep: PropTypes.bool,
  email: PropTypes.object.isRequired,
  password: PropTypes.object.isRequired,
};

export default withStyles(commonStyle)(FirstStepAuth);
