// @flow
export default function styles(theme: { [key: string]: any }) {
  return ({
    trackingWrapper: {
      marginTop: '8px',
      position: 'relative',
    },
    btnNotificationStatus: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      cursor: 'pointer',
    },
    statusText: {
      display: 'inline-block',
    },
    progress: {
      position: 'absolute',
      top: '50%',
      [theme.breakpoints.up('sm')]: {
        left: '50%',
      },
      left: '40%',
    },
  });
};
