export const apiCoupons = [
  {
    id: 6,
    couponCode: "COUPON_CODE1",
    couponTypeId: 1,
    couponDiscountTypeId: 1,
    startDate: "2018/01/15",
    endDate: "2018/01/16",
    owner: "U12345678",
    discountAmount: "10.23",
    active: true,
    creditConversionAllowed: false,
    createdAt: 1547055342637,
    updatedAt: 1547055342637,
    rules: [
      {
        className: "MaxValidUsesValidator",
        params: {
          max_uses: 1,
        },
      },
      {
        className: "MaxValidUsesPerUserValidator",
        params: {
          max_uses: 1
        },
      },
      {
        className: "UrlMatchesValidator",
        params: {
          regex: false,
          pattern: [
            "https://www.apple.com/shop/buy-iphone/iphone-xs/5.8-inch-display-512gb-silver-unlocked",
            "https://www.apple.com/shop/buy-iphone/iphone-xs/5.8-inch-display-512gb-space-gray-unlocked",
            "https://www.apple.com/shop/buy-iphone/iphone-xs/5.8-inch-display-512gb-gold-unlocked",
            "https://www.apple.com/shop/buy-iphone/iphone-xs/6.5-inch-display-512gb-silver-unlocked",
            "https://www.apple.com/shop/buy-iphone/iphone-xs/6.5-inch-display-512gb-space-gray-unlocked",
            "https://www.apple.com/shop/buy-iphone/iphone-xs/6.5-inch-display-512gb-gold-unlocked"
          ],
          check_all: false
        }
      },
      {
        className: "DaysNoOrderValidator",
        params: { days: 10, valid_days: 3 }
      },
      {
        className: "DaysNoPurchaseValidator",
        params: { days: 40, valid_days: 3 }
      },
    ]
  },
  {
    id: 7,
    couponCode: "COUPON_CODE2",
    couponTypeId: 1,
    couponDiscountTypeId: 2,
    startDate: "2018/01/15",
    endDate: "2018/01/16",
    owner: "U12345678",
    discountPercentage: "0.1",
    discountLimit: "50.00",
    active: true,
    creditConversionAllowed: false,
    createdAt: 1547055342637,
    updatedAt: 1547055342637,
    rules: [
      {
        className: "MaxValidUsesValidator",
        params: {
          max_uses: 1
        },
      },
      {
        className: "MaxValidUsesPerUserValidator",
        params: {
          max_uses: 1
        },
      },
      {
        className: "UrlMatchesValidator",
        params: {
          regex: false,
          pattern: [
            "https://www.apple.com/shop/buy-iphone/iphone-xs/5.8-inch-display-512gb-silver-unlocked",
            "https://www.apple.com/shop/buy-iphone/iphone-xs/5.8-inch-display-512gb-space-gray-unlocked",
            "https://www.apple.com/shop/buy-iphone/iphone-xs/5.8-inch-display-512gb-gold-unlocked",
            "https://www.apple.com/shop/buy-iphone/iphone-xs/6.5-inch-display-512gb-silver-unlocked",
            "https://www.apple.com/shop/buy-iphone/iphone-xs/6.5-inch-display-512gb-space-gray-unlocked",
            "https://www.apple.com/shop/buy-iphone/iphone-xs/6.5-inch-display-512gb-gold-unlocked"
          ],
          check_all: false
        }
      },
      {
        className: "DaysNoOrderValidator",
        params: { days: 10, valid_days: 3 }
      },
      {
        className: "DaysNoPurchaseValidator",
        params: { days: 40, valid_days: 3 }
      },
    ]
  },
];

export default apiCoupons;
