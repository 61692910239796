// @flow
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import type { ShipmentSelection } from '../components/CourierReception';

type Props = {
  +open: boolean,
  +handleModal: (modalName: 'openCancelModal' | 'openConfirmModal') => void,
  +handleConfirmReception: () => void,
  +selectedShipments: {
    [shipmentId: number]: ShipmentSelection,
  },
};

export default class CourierReceptionConfirmDialog extends PureComponent<Props> {
  render() {
    const {
      open,
      handleModal,
      handleConfirmReception,
      selectedShipments,
    } = this.props;
    return (
      <Dialog open={open} onClose={() => handleModal('openConfirmModal')}>
        <DialogTitle style={{ textAlign: 'center' }}>
          Are you sure you want to receive this shipments?
        </DialogTitle>
        <DialogContent>
          {open &&
            Object.keys(selectedShipments).map((shipmentKey, i) => {
              return (
                <Typography key={shipmentKey} variant='body1'>
                  {i + 1}. #{shipmentKey}
                </Typography>
              );
            })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleModal('openConfirmModal')}
            style={{ margin: 'auto' }}
          >
            NO
          </Button>
          <Button
            color='primary'
            onClick={handleConfirmReception}
            style={{ margin: 'auto' }}
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CourierReceptionConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  handleConfirmReception: PropTypes.func.isRequired,
  selectedShipments: PropTypes.object,
};
