//@flow
import environment from '../../environment';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ActionsObservable } from 'redux-observable';
import { of, forkJoin } from 'rxjs';
import {
    scrapProductApi,
    createBagApi,
    uploadImageUrlApi,
    createLinkApi
} from './api';
import update from 'immutability-helper';

import type { HTTPError } from '../../common/error';

// Actions
const BAG_CRAFTER_SCRAP_PRODUCT_REQUEST = 'BAG_CRAFTER_SCRAP_PRODUCT_REQUEST';
const BAG_CRAFTER_SCRAP_PRODUCT_SUCCESS = 'BAG_CRAFTER_SCRAP_PRODUCT_SUCCESS';
const BAG_CRAFTER_SCRAP_PRODUCT_ERROR = 'BAG_CRAFTER_SCRAP_PRODUCT_ERROR';
const BAG_CRAFTER_CREATE_BAG_REQUEST = 'BAG_CRAFTER_CREATE_BAG_REQUEST';
const BAG_CRAFTER_CREATE_BAG_SUCCESS = 'BAG_CRAFTER_CREATE_BAG_SUCCESS';
const BAG_CRAFTER_CREATE_BAG_ERROR = 'BAG_CRAFTER_CREATE_BAG_ERROR';
const BAG_CRAFTER_ADD_BAG_ITEM = 'BAG_CRAFTER_ADD_BAG_ITEM';
const BAG_CRAFTER_REMOVE_BAG_ITEM = 'BAG_CRAFTER_REMOVE_BAG_ITEM';
const BAG_CRAFTER_CLEAR_BAG_ITEMS = 'BAG_CRAFTER_CLEAR_BAG_ITEMS';

export const actions = {
    BAG_CRAFTER_SCRAP_PRODUCT_REQUEST,
    BAG_CRAFTER_SCRAP_PRODUCT_SUCCESS,
    BAG_CRAFTER_SCRAP_PRODUCT_ERROR,
    BAG_CRAFTER_CREATE_BAG_REQUEST,
    BAG_CRAFTER_CREATE_BAG_SUCCESS,
    BAG_CRAFTER_CREATE_BAG_ERROR,
    BAG_CRAFTER_ADD_BAG_ITEM,
    BAG_CRAFTER_REMOVE_BAG_ITEM,
    BAG_CRAFTER_CLEAR_BAG_ITEMS,
    executeScrapProductRequest,
    executeCreateBagRequest,
    executeAddBagItem,
    executeRemoveBagItem,
    executeClearBagItems
};

export type ScrappedProduct = {
    +linkUrl ?: string;
    +name ?: string;
    +imageUrl ?: string;
    +price ?: string;
    +categoryId ?: number;
    +price ?: string;
    +shipping ?: string;
};

export type BagItem = {
    name: string;
    url: string;
    detail: string;
    amount: number;
    price: string;
    shipping: string;
    itemCategoryId: number;
    imageUrl: string;
    originalPackaging: boolean;
    volumeTier: string;
};

export type Bag = {
    items: BagItem[];
    serviceTier?: string;
    travelerCommission?: string;
    qempoCommission?: string;
    lastMile?: string;
    taxReserve?: string;
    utmCampaignSource: string;
    utmCampaignMedium: string;
    utmCampaignName: string;
};

export type BagCrafterAction = {
    +type: string;
    +shortUrl ?: string;
    +product ?: ScrappedProduct;
    +bagRequest ?: Bag;
    +bagItem?: BagItem;
    +bagItemIndex?: number;
    +error ?: HTTPError;
};

export function executeScrapProductRequest(productUrl: string): BagCrafterAction {
    return {
        type: BAG_CRAFTER_SCRAP_PRODUCT_REQUEST,
        productUrl,
    }
};

export function executeScrapProductSuccess(product: ScrappedProduct): BagCrafterAction {
    return {
        type: BAG_CRAFTER_SCRAP_PRODUCT_SUCCESS,
        product
    }
};

export function executeScrapProductError(error: HTTPError): BagCrafterAction {
    return {
        type: BAG_CRAFTER_SCRAP_PRODUCT_ERROR,
        error
    }
};

export function executeCreateBagRequest(bagRequest: Bag): BagCrafterAction {
    return {
        type: BAG_CRAFTER_CREATE_BAG_REQUEST,
        bagRequest,
    }
};

export function executeCreateBagSuccess(shortUrl: string): BagCrafterAction {
    return {
        type: BAG_CRAFTER_CREATE_BAG_SUCCESS,
        shortUrl
    }
};

export function executeCreateBagError(error: HTTPError): BagCrafterAction {
    return {
        type: BAG_CRAFTER_CREATE_BAG_ERROR,
        error
    }
};

export function executeAddBagItem(bagItem: BagItem): BagCrafterAction {
    return {
        type: BAG_CRAFTER_ADD_BAG_ITEM,
        bagItem
    }
};

export function executeRemoveBagItem(bagItemIndex: number): BagCrafterAction {
    return {
        type: BAG_CRAFTER_REMOVE_BAG_ITEM,
        bagItemIndex
    }
};

export function executeClearBagItems(): BagCrafterAction {
    return {
        type: BAG_CRAFTER_CLEAR_BAG_ITEMS,
    }
};

// State
export type BagCrafterState = {
    loading: boolean;
    error?: HTTPError;
    product?: ScrappedProduct;
    shortUrl?: string;
    bagItems: BagItem[];
};

export const initialState: BagCrafterState = {
    loading: false,
    bagItems: []
};

// Reducer
export default function reducer(state: BagCrafterState = initialState, action: BagCrafterAction): BagCrafterState {
    switch (action.type) {
        case BAG_CRAFTER_SCRAP_PRODUCT_REQUEST:
            return update(state, {
                loading: { $set: true },
                $unset: ['error'],
            });
        case BAG_CRAFTER_SCRAP_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                product: action.product
            };
        case BAG_CRAFTER_SCRAP_PRODUCT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case BAG_CRAFTER_CREATE_BAG_REQUEST:
            return update(state, {
                loading: { $set: true },
                $unset: ['error'],
            });
        case BAG_CRAFTER_CREATE_BAG_SUCCESS:
            return {
                ...state,
                loading: false,
                shortUrl: action.shortUrl
            };
        case BAG_CRAFTER_CREATE_BAG_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case BAG_CRAFTER_ADD_BAG_ITEM:
            return {
                ...state,
                bagItems: !!action.bagItem ? [...state.bagItems].concat({ ...action.bagItem }) : state.bagItems
            };
        case BAG_CRAFTER_REMOVE_BAG_ITEM:
            return {
                ...state,
                bagItems: typeof action.bagItemIndex === 'number' ? state.bagItems.filter((item, index) => index !== action.bagItemIndex) : state.bagItems
            };
        case BAG_CRAFTER_CLEAR_BAG_ITEMS:
            return {
                ...state,
                bagItems: []
            };
        default:
            return {
                ...state
            };
    }
};

// Epics
export function executeScrapProductEpic(action$: ActionsObservable<BagCrafterAction>) {
    return action$.ofType(BAG_CRAFTER_SCRAP_PRODUCT_REQUEST).pipe(
        mergeMap(
            ({ productUrl }) => scrapProductApi({
                url: productUrl,
            }).pipe(
                map(({ url, categoryId, image, name, price, shipping }) => executeScrapProductSuccess({
                    linkUrl: url,
                    name: name,
                    imageUrl: image,
                    categoryId: categoryId,
                    price: price,
                    shipping: shipping
                })),
                catchError((e: HTTPError) => of(executeScrapProductError(e))))
        )
    );
};

export function executeCreateBagEpic(action$: ActionsObservable<BagCrafterAction>) {
    return action$.ofType(BAG_CRAFTER_CREATE_BAG_REQUEST).pipe(
        mergeMap(
            ({ bagRequest }) => forkJoin(bagRequest.items.map(bagItem => uploadImageUrlApi(
                {
                    url: bagItem.imageUrl,
                    resourceGroup: 'PRODUCT'
                }
            ))).pipe(
                mergeMap(
                    (UploadImageUrlApiRepsonses) =>
                        createBagApi(
                            {
                                items: bagRequest.items.map((item, index) => ({
                                    ...item,
                                    imageId: UploadImageUrlApiRepsonses[index].storedFileId
                                })),
                                serviceTier: bagRequest.serviceTier,
                                taxReserve: bagRequest.taxReserve,
                                commission: bagRequest.travelerCommission,
                                qempo: bagRequest.qempoCommission,
                                delivery: bagRequest.lastMile,
                            }
                        ).pipe(
                            mergeMap(
                                ({ bagId }) => createLinkApi({
                                    longUrl: `${environment.webUrl}/new-order/quote/bag/${
                                        bagId}?utm_source=${bagRequest.utmCampaignSource}&utm_medium=${
                                            bagRequest.utmCampaignMedium}&utm_campaign=${bagRequest.utmCampaignName}`,
                                    title: `NewLink:${Date.now()}`
                                }, {
                                    headers: {
                                        "Authorization": `Bearer ${environment.bitlyToken}`,
                                        "Content-Type": "application/json"
                                    }
                                }).pipe(
                                    map(({ link }) => executeCreateBagSuccess(link)),
                                    catchError((e: HTTPError) => of(executeCreateBagError(e))))
                            ),
                            catchError((e: HTTPError) => of(executeCreateBagError(e)))
                        )
                ),
                catchError((e: HTTPError) => of(executeCreateBagError(e)))
            )
        )
    );
};

