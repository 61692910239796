// @flow
import { Observable } from 'rxjs';
// import { getCreator, postCreator, putCreator, deleteCreator } from '@qempo/qempo-web-common/http';
import environment from '../../environment';
import apiFeaturedProducts from './featuredProducts';

export const endpoints = Object.freeze({
  GET_FEATURED_PRODUCTS: `${environment.apiUrl}/administrative/product/featured/list`,
  DELETE_FEATURED_PRODUCTS: `${environment.apiUrl}/administrative/product/featured/:id`,
});

function desc(a, b, sort) {
  if (b[sort] < a[sort]) {
    return -1;
  }
  if (b[sort] > a[sort]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order: string, sort: string) {
  return order === 'desc' ? (a, b) => desc(a, b, sort) : (a, b) => -desc(a, b, sort);
}

export const getFeaturedProductsApi = (queryParams: any) => Observable.create(
  (observer) => {
    let featuredProducts;
    const { page, limit, ord, sort } = queryParams;

    featuredProducts = stableSort(apiFeaturedProducts, getSorting(ord, sort))
      .slice(page * limit, page * limit + limit);
    const totalFeaturedProducts = apiFeaturedProducts.length;

    observer.next({ featuredProducts, totalFeaturedProducts });
  });

export const deleteFeaturedProductsApi = (data: any, o: any) => Observable.create(
  (observer) => {
    const { id } = data;
    console.log('id', id);
    const productIndex = apiFeaturedProducts.findIndex(e => e.id === id);
    apiFeaturedProducts.splice(productIndex, 1);
    observer.next(apiFeaturedProducts);
  });
