const apiProgressOrders = [
  {
    id: 'TR001',
    state: 'IN_QEMPO',
    traveler: 'Qempo Traveler',
    flightDate: '2018/05/26',
    tracking: '1245',
    subTotal: '150',
    shippingAddress: 'Calle Tomas Ramsey',
    progress: 'Hello',
  },
  {
    id: 'TR002',
    state: 'IN_QEMPO',
    traveler: 'Qempo Traveler',
    flightDate: '2018/05/26',
    tracking: '1245',
    subTotal: '150',
    shippingAddress: 'Calle Tomas Ramsey',
    progress: 'Hello',
  },
  {
    id: 'TR003',
    state: 'SHIPPING',
    traveler: 'Qempo Traveler',
    flightDate: '2018/05/26',
    tracking: '1245',
    subTotal: '150',
    shippingAddress: 'Calle Tomas Ramsey',
    progress: 'Hello',
  },
  {
    id: 'TR004',
    state: 'SHIPPING',
    traveler: 'Qempo Traveler',
    flightDate: '2018/05/26',
    tracking: '1245',
    subTotal: '150',
    shippingAddress: 'Calle Tomas Ramsey',
    progress: 'Hello',
  },
  {
    id: 'TR005',
    state: 'SHIPPING',
    traveler: 'Qempo Traveler',
    flightDate: '2018/05/26',
    tracking: '1245',
    subTotal: '150',
    shippingAddress: 'Calle Tomas Ramsey',
    progress: 'Hello',
  },
];

export default apiProgressOrders;
