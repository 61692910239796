// @flow
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import ReceptionTableHead from './ReceptionTableHead';
import ReceptionTableToolbar from './ReceptionTableToolbar';
import ReceptionTableBody from './ReceptionTableBody';
import ReceptionTableCancelDialog from './ReceptionTableCancelDialog';
import ReceptionTableConfirmDialog from './ReceptionTableConfirmDialog';
import update from 'immutability-helper';
import { FormValidator } from '../../../common/form';
import './Reception.css';
import moment from 'moment';
import TablePaginationActions from '../../../elements/TablePaginationActions/TablePaginationActions';

import styles from './style';

import type { HTTPError } from '../../../common/error';
import type { ConfirmReceptionRequest, CancelReceptionRequest } from '../api';

type Props = {
  +classes: { [key: string]: any };
  +receptionFlights?: { [key: string]: any }[];
  +totalReceptionFlights: number;
  +executeGetCancellationMotives: () => void;
  +executeGetReception: (filter?: { [key: string]: any }) => void;
  +executeConfirmReception: (request: ConfirmReceptionRequest) => void;
  +executeCancelReception: (request: CancelReceptionRequest) => void;
  +changeReceptionPagination: (changes: { [key: string]: any }) => void;
  +executeResetReceptionState: () => void;
  +isLoadingGetReception: boolean;
  +getReceptionSuccess: boolean;
  +getReceptionError: HTTPError;
  +isLoadingConfirmReception: boolean;
  +confirmReceptionSuccess: boolean;
  +confirmReceptionError: HTTPError;
  +isLoadingCancelReception: boolean;
  +cancelReceptionSuccess: boolean;
  +cancelReceptionError: HTTPError;
  +search: string;
  +page: number;
  +limit: number;
  +filterKey: string;
  +cancellationMotives: { [key: string]: any }[];
};

type State = {
  flights: {
    [flightId: string]: {
      orders: {
        [orderId: string]: {
          selected: boolean,
          indeterminate: boolean,
          items: {
            [orderItemId: string]: {
              selected: boolean,
              received: number,
            },
          },
        },
      },
    },
  };
  openCancelModal: boolean;
  openConfirmModal: boolean;
  page: number,
  rowsPerPage: number,
};

class ReceptionTableMain extends Component<Props, State> {
  trackingFormValidator: FormValidator;
  state = {
    flights: {},
    openCancelModal: false,
    openConfirmModal: false,
    page: 0,
    rowsPerPage: 100,
  };

  constructor(props: Props) {
    super(props);
    this.trackingFormValidator = new FormValidator({
      tracking: {
        required: 'required',
      },
    });
  }

  componentDidMount() {
    const { executeGetReception, executeGetCancellationMotives } = this.props;
    executeGetReception();
    executeGetCancellationMotives();
  }

  componentWillUnmount() {
    const { executeResetReceptionState } = this.props;
    executeResetReceptionState();
  }

  resetState = () => {
    this.setState({ flights: {}, openCancelModal: false, openConfirmModal: false });
  };

  handleConfirmReception = () => {
    const { executeConfirmReception } = this.props;
    const request: any = {};
    const itemsState = this.getSelectedItemsState();
    const receptionItems = this.getSelectedItems();
    request.items = Object.keys(itemsState).map(key => {
      const keyValue = parseInt(key, 10);
      if (typeof itemsState[key].amount === 'number') {
        return {
          orderItemId: keyValue,
          amount: itemsState[key].amount,
        };
      } else {
        const apiItem: any = receptionItems.find(({ orderItemId }) => orderItemId + '' === key);
        return {
          orderItemId: keyValue,
          amount: apiItem.amount - apiItem.receivedCount - apiItem.cancelledCount,
        };
      }
    });
    this.resetState();
    executeConfirmReception(request);
  };

  handleModal = (modalName: 'openCancelModal' | 'openConfirmModal') => {
    this.setState(prevState => ({ [modalName]: !prevState[modalName] }));
  };

  checkObjectKey = (flightId: string, orderId?: string, orderItemId?: string): boolean => {
    const { flights } = this.state;
    let response = !!flights[flightId];
    if (orderId && orderItemId) {
      response = !!(flights[flightId] && flights[flightId].orders[orderId] && flights[flightId].orders[orderId].items[orderItemId]);
    } else if (orderId) {
      response = !!(flights[flightId] && flights[flightId].orders[orderId]);
    }
    return response;
  };

  setStateHelper = (flightId: string, orderId: string, orderItemId: string | 'none', key: string | 'none', newState: any) => {
    if (orderItemId !== 'none' && this.checkObjectKey(flightId, orderId, orderItemId)) {
      this.setState(prevState => update(prevState, {
        flights: {
          [flightId]: {
            orders: {
              [orderId]: {
                items: {
                  [orderItemId]: {
                    [key]: { $set: newState },
                  },
                },
              },
            },
          },
        },
      }));
    } else if (this.checkObjectKey(flightId, orderId)) {
      if (orderItemId === 'none') {
        if (key === 'none') {
          this.setState(prevState => update(prevState, {
            flights: {
              [flightId]: {
                orders: {
                  [orderId]: { $set: newState },
                },
              },
            },
          }));
        } else {
          this.setState(prevState => update(prevState, {
            flights: {
              [flightId]: {
                orders: {
                  [orderId]: {
                    [key]: { $set: newState },
                  },
                },
              },
            },
          }));
        }
      } else {
        this.setState(prevState => update(prevState, {
          flights: {
            [flightId]: {
              orders: {
                [orderId]: {
                  items: {
                    [orderItemId]: {
                      $set: {
                        [key]: newState,
                      },
                    },
                  },
                },
              },
            },
          },
        }));
      }
    } else if (this.checkObjectKey(flightId)) {
      if (key === 'none') {
        this.setState(prevState => update(prevState, {
          flights: {
            [flightId]: {
              orders: {
                [orderId]: { $set: newState },
              },
            },
          },
        }));
      } else {
        this.setState(prevState => update(prevState, {
          flights: {
            [flightId]: {
              orders: {
                [orderId]: {
                  $set: {
                    [key]: newState,
                  },
                },
              },
            },
          },
        }));
      }
    } else {
      if (key === 'none') {
        this.setState(prevState => update(prevState, {
          flights: {
            [flightId]: {
              $set: {
                orders: {
                  [orderId]: newState,
                },
              },
            },
          },
        }));
      } else {
        this.setState(prevState => update(prevState, {
          flights: {
            [flightId]: {
              $set: {
                orders: {
                  [orderId]: {
                    [key]: newState,
                  },
                },
              },
            },
          },
        }));
      }
    }
  };

  handleSelectOrder = (e: Event, flightId: string, orderId: string, items: { [key: string]: any }[]) => {
    const { flights } = this.state;
    if (e.target instanceof HTMLInputElement) {
      const { checked } = e.target;
      const itemsState = {};
      items.forEach(({ orderItemId }) => {
        itemsState[orderItemId] = {
          selected: checked,
        };
      });
      if (this.checkObjectKey(flightId, orderId)) {
        Object.keys(flights[flightId].orders[orderId].items).forEach((key) => itemsState[key] = {
          ...flights[flightId].orders[orderId].items[key],
          selected: checked,
        });
      }
      this.setStateHelper(flightId, orderId, 'none', 'none', { selected: checked, items: itemsState });
    }
  };

  handleSelectItem = (e: Event, flightId: string, orderId: string, orderItemId: *) => {
    if (e.target instanceof HTMLInputElement) {
      const { checked } = e.target;
      const orderValues = this.getOrderValues(flightId, orderId, orderItemId, checked);
      if (this.checkObjectKey(flightId, orderId)) {
        this.setStateHelper(flightId, orderId, orderItemId, 'selected', checked);
        this.setStateHelper(flightId, orderId, 'none', 'indeterminate', orderValues.indeterminate);
        this.setStateHelper(flightId, orderId, 'none', 'selected', orderValues.selected);
      } else {
        this.setStateHelper(flightId, orderId, orderItemId, 'none', {
          selected: orderValues.selected,
          indeterminate: orderValues.indeterminate,
          items: {
            [orderItemId]: {
              selected: checked,
            },
          },
        });
      }
    }
  };

  handleProductAmount = (e: SyntheticKeyboardEvent<HTMLInputElement>, flightId: string, orderId: string, orderItemId: *, itemAmount: number) => {
    const { key } = e;
    const regex = new RegExp('^[0-' + itemAmount + ']$');
    const test: boolean = regex.test(key);
    const numberValue = parseInt(key, 10);
    if (test) {
      if (this.checkObjectKey(flightId, orderId)) {
        this.setStateHelper(flightId, orderId, orderItemId, 'received', numberValue);
      } else {
        this.setStateHelper(flightId, orderId, orderItemId, 'none', {
          selected: false,
          items: {
            [orderItemId]: {
              received: numberValue,
            },
          },
        });
      }
    }
  };

  handleAdd = (flightId: string, orderId: string, orderItemId: *, itemAmount: number) => {
    const { flights } = this.state;
    if (this.checkObjectKey(flightId, orderId, orderItemId)) {
      const received = flights[flightId].orders[orderId].items[orderItemId].received;
      if (typeof received === 'number' && received < itemAmount) {
        this.setStateHelper(flightId, orderId, orderItemId, 'received', received + 1);
      }
    }
  };

  handleSubtract = (flightId: string, orderId: string, orderItemId: *, itemAmount: number) => {
    const { flights } = this.state;
    let value;
    if (itemAmount > 1) value = itemAmount - 1; else value = itemAmount;
    if (this.checkObjectKey(flightId, orderId)) {
      if (this.checkObjectKey(flightId, orderId, orderItemId)) {
        const received = flights[flightId].orders[orderId].items[orderItemId].received;
        if (typeof received === 'number' && received > 1) {
          value = received - 1;
        }
      }
      this.setStateHelper(flightId, orderId, orderItemId, 'received', value);
    } else {
      this.setStateHelper(flightId, orderId, orderItemId, 'none', {
        items: {
          [orderItemId]: { received: value },
        },
      });
    }
  };

  handleChangePage = (e: Event, page: number) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({ page: 0, rowsPerPage: parseInt(value) });
  };

  atLeastOneSelected = () => {
    const { flights } = this.state;
    return Object.keys(flights).some((key) => {
      return Object.keys(flights[key].orders).some(orderKey => {
        const order = flights[key].orders[orderKey];
        return order.selected || Object.keys(order.items).some(itemKey => {
          const item = order.items[itemKey];
          return item.selected;
        });
      });
    });
  };

  getSelectedItemsState = () => {
    const { flights } = this.state;
    const selectedItems = {};
    Object.keys(flights).forEach((key) => {
      Object.keys(flights[key].orders).forEach(orderKey => {
        const order = flights[key].orders[orderKey];
        Object.keys(order.items).forEach(itemKey => {
          const item = order.items[itemKey];
          if (item.selected) {
            if (typeof item.received === 'number') {
              selectedItems[itemKey] = {
                amount: item.received,
              };
            } else {
              selectedItems[itemKey] = {};
            }
          }
        });
      });
    });
    return selectedItems;
  };

  getOrderValues = (flightId: string, orderId: string, orderItemId: string, value: boolean) => {
    const { flights } = this.state;
    const { receptionFlights } = this.props;
    const itemsLength = ((receptionFlights ? receptionFlights : []).find(
      flight => flight.id === flightId): any)
      .orders.find(
        order => order.id === orderId,
      ).items.length;
    let response = {};
    if (this.checkObjectKey(flightId, orderId)) {
      const orderState = flights[flightId].orders[orderId];
      orderState.items[orderItemId] = {
        ...orderState.items[orderItemId],
        selected: value,
      };
      const itemsKeys = Object.keys(orderState.items);
      if (itemsKeys.length === itemsLength) {
        // if all items exist in state then indeterminate=true unless all of them have the same value
        response.indeterminate = !(itemsKeys.every(key => orderState.items[key].selected) || itemsKeys.every(key => !orderState.items[key].selected));
        response.selected = itemsKeys.every(key => orderState.items[key].selected);
      } else {
        // if there are missing items in state then indeterminate=false unless one of them is selected
        response.indeterminate = itemsKeys.some(key => orderState.items[key].selected);
        response.selected = false;
      }
    } else {
      response.indeterminate = itemsLength !== 1;
      response.selected = itemsLength === 1;
    }
    return response;
  };

  getSelectedItems = (): { [key: string]: any }[] => {
    const { receptionFlights } = this.props;
    const selectedItemsState: { [key: string]: * } = this.getSelectedItemsState();
    const selectedItems = [];
    receptionFlights && receptionFlights.forEach(({ orders }) => {
      orders.forEach(({ items }) => {
        items.filter(({ orderItemId }) => !!selectedItemsState[orderItemId]).forEach(item => selectedItems.push(item));
      });
    });
    return selectedItems;
  };

  getSelectedOrders = (): string[] => {
    const { flights } = this.state;
    const selectedOrders = [];
    Object.keys(flights).forEach((key) => {
      Object.keys(flights[key].orders).forEach(orderKey => {
        const order = flights[key].orders[orderKey];
        if (order.selected || order.indeterminate) selectedOrders.push(orderKey);
      });
    });
    return selectedOrders;
  };

  render() {
    const {
      classes,
      isLoadingGetReception,
      isLoadingConfirmReception,
      cancellationMotives,
      receptionFlights,
      executeConfirmReception,
      isLoadingCancelReception,
      executeCancelReception,
      executeGetReception,
    } = this.props;
    const {
      flights,
      openCancelModal,
      openConfirmModal,
      rowsPerPage,
      page,
    } = this.state;
    return (
      <Fragment>
        <div id="reception-main-wrap">
          <Paper>
            <ReceptionTableToolbar executeGetReception={executeGetReception}
                                   selectedOrdersCount={this.getSelectedOrders().length}
                                   atLeastOneSelected={this.atLeastOneSelected}
                                   handleModal={this.handleModal}/>
          </Paper>
          <div className={classes.tableWrapper}>
            <ReceptionTableHead/>
            <ReceptionTableBody receptionFlights={receptionFlights}
                                getSelectedItems={this.getSelectedItems}
                                executeConfirmReception={executeConfirmReception}
                                resetState={this.resetState}
                                stateFlights={flights}
                                checkObjectKey={this.checkObjectKey}
                                handleAdd={this.handleAdd}
                                handleSubtract={this.handleSubtract}
                                handleProductAmount={this.handleProductAmount}
                                handleSelectOrder={this.handleSelectOrder}
                                handleSelectItem={this.handleSelectItem}
                                isLoading={isLoadingGetReception || isLoadingConfirmReception || isLoadingCancelReception}
                                rowsPerPage={rowsPerPage}
                                page={page}
            />
          </div>
          <ReceptionTableCancelDialog open={openCancelModal}
                                      resetState={this.resetState}
                                      handleModal={this.handleModal}
                                      getSelectedItems={this.getSelectedItems}
                                      getSelectedItemsState={this.getSelectedItemsState}
                                      executeCancelReception={executeCancelReception}
                                      cancellationMotives={cancellationMotives}/>
          <ReceptionTableConfirmDialog open={openConfirmModal}
                                       getSelectedOrders={this.getSelectedOrders}
                                       handleConfirmReception={this.handleConfirmReception}
                                       handleModal={this.handleModal}/>
          <Paper>
            <TablePagination
              component="div"
              rowsPerPageOptions={[100, 200, 300, 500]}
              count={receptionFlights ? receptionFlights.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </div>
        <div id="reception-print-wrap">
          {this.getSelectedOrders() && this.getSelectedOrders().map((orderId, index: number) => {
            const flightId = Object.keys(flights).find(flightId => orderId in flights[flightId].orders);
            const flight = (receptionFlights ? receptionFlights : []).find(
              flight => flight.id === flightId,
            );
            const order = flight && flight.orders.find(order => order.id === orderId);
            return order && <Fragment key={index}>
              <section className="reception-label-wrap">
                <div>
                  <b>
                    QEMPO - {orderId}
                  </b>
                </div>
                <div>
                  <b>
                    BeforeDate: {moment(order.beforeDate).format('DD/MM/YYYY')}
                  </b>
                </div>
                <div>
                  <b>
                    {order.shippingAddress.firstName} {order.shippingAddress.lastName}
                  </b>
                </div>
                <div>
                  <b>
                    {order.shippingAddress.idNumber}
                  </b>
                </div>
                <div>
                  <b>
                    {order.shippingAddress.phone}
                  </b>
                </div>
                {!!order.shippingAddress.address1 && <div>
                  {order.shippingAddress.address1.length > 30 ?
                    <b style={{ fontSize: 10 }}>
                      {order.shippingAddress.address1}
                    </b> :
                    <b>
                      {order.shippingAddress.address1}
                    </b>}
                </div>}
                {!!order.shippingAddress.address2 && <div>
                  {order.shippingAddress.address2.length > 30 ?
                    <b style={{ fontSize: 10 }}>
                      {order.shippingAddress.address2}
                    </b> :
                    <b>
                      {order.shippingAddress.address2}
                    </b>}
                </div>}
                <div>
                  <b>
                    {order.shippingAddress.admin3 && `${order.shippingAddress.admin3.name}, `}{order.shippingAddress.admin2.name}, {order.shippingAddress.admin1.name}
                  </b>
                </div>
              </section>
              <section className="reception-label-wrap">
                {order.items.map((item, i: number) =>
                  <div key={i}>
                    <b>
                      {(item.amount - item.cancelledCount - item.receivedCount)} x {item.name.substring(0, 30)}
                    </b>
                  </div>)}
              </section>
            </Fragment>

          })}
        </div>
      </Fragment>);
  }
}

ReceptionTableMain.propTypes = {
  classes: PropTypes.object.isRequired,
  executeGetCancellationMotives: PropTypes.func.isRequired,
  executeGetReception: PropTypes.func.isRequired,
  executeConfirmReception: PropTypes.func.isRequired,
  executeCancelReception: PropTypes.func.isRequired,
  changeReceptionPagination: PropTypes.func.isRequired,
  executeResetReceptionState: PropTypes.func.isRequired,
  receptionFlights: PropTypes.array,
  totalReceptionFlights: PropTypes.number,
  isLoadingGetReception: PropTypes.bool,
  getReceptionSuccess: PropTypes.bool,
  getReceptionError: PropTypes.object,
  isLoadingConfirmReception: PropTypes.bool,
  confirmReceptionSuccess: PropTypes.bool,
  confirmReceptionError: PropTypes.object,
  isLoadingCancelReception: PropTypes.bool,
  cancelReceptionSuccess: PropTypes.bool,
  cancelReceptionError: PropTypes.object,
  search: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  cancellationMotives: PropTypes.array,
};

export default withStyles(styles)(ReceptionTableMain);
