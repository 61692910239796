// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ArrivedOrderItem from '../../../../elements/ArrivedOrderItem/ArrivedOrderItem';
import CourierReceptionOrderItemTracking from './CourierReceptionOrderItemTracking';
import CourierReceptionOrderItemInvoice from './CourierReceptionOrderItemInvoice';

import styles from '../styles';
import {
  CardContent,
  Grid,
  Checkbox,
  Card,
  CardMedia,
  IconButton,
  Typography,
  TextField,
  Paper,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import type { OrderItem } from '../../../../entities';
import type { OrderItemCheckboxState } from './CourierReceptionShipment';
import type { OrderItemInputs } from '../CourierReception';
import type { OrderItemOperationState } from '../../duck';

type Props = {
  +classes: { [key: string]: any },
  +orderItem: OrderItem,
  +orderItemInputs: ?OrderItemInputs,
  +itemSelectionAmount: number,
  +itemCheckboxState: OrderItemCheckboxState,
  updateOrderItemInvoice: () => void,
  resetInvoice: () => void,
  handleChangeItemStoreOrderId: (newValue: string) => void,
  handleChangeItemTrackingNumber: (trackingNumber: string, trackingUrl?: string) => void,
  handleSelectionUpdate: (checked: boolean, newValue: ?number) => void,
  handleOrderItemOnFileChange: (ev: SyntheticInputEvent<HTMLInputElement>) => void,
  updateTrackingNumber: () => void,
  updateStoreOrderId: () => void,
  +operations: ?OrderItemOperationState,
};

class CourierReceptionOrderItem extends Component<Props> {
  buildItemDetail = (orderItem: OrderItem) => {
    const { classes } = this.props;
    if (!!orderItem.detail && orderItem.detail.length > 0) {
      return (
        <Paper className={classes.paper} elevation={2}>
          <Typography component="p" className={classes.multilineText}>
            {orderItem.detail.trim()}
          </Typography>
          <hr/>
          <Typography
            component="strong">Packaging: {orderItem.originalPackaging ? 'With Box' : 'Without Box'}</Typography>
        </Paper>
      );
    } else {
      return (
        <Paper className={classes.paper} elevation={2}>
          <Typography
            component="strong">Packaging: {orderItem.originalPackaging ? 'With Box' : 'Without Box'}</Typography>
        </Paper>
      );
    }
  };

  getCompletenessCell = (amount: number, received: number): React$Node => {
    const { classes } = this.props;
    if (amount === received) {
      return (
        <Typography variant="subtitle1" component="h6"
                    className={[classes.completenessCell, classes.completenessCellGreen].join(' ')}>
          Complete
        </Typography>
      );
    } else {
      return (
        <Typography variant="subtitle1" component="h6"
                    className={[classes.completenessCell, classes.completenessCellRed].join(' ')}>
          Incomplete
        </Typography>
      );
    }
  };

  render() {
    const {
      classes,
      orderItem,
      orderItemInputs,
      itemSelectionAmount,
      itemCheckboxState,
      updateOrderItemInvoice,
      handleChangeItemStoreOrderId,
      handleChangeItemTrackingNumber,
      handleSelectionUpdate,
      handleOrderItemOnFileChange,
      updateTrackingNumber,
      updateStoreOrderId,
      operations,
      resetInvoice,
    } = this.props;
    const calculatedAmount = orderItem.amount - orderItem.receivedCount - orderItem.cancelledCount;
    return (
      <CardContent style={{background: orderItem.hasArrived ? '#9aea9c' : 'white'}}>
        <Grid container spacing={16} justify="space-evenly">
          <Grid item spacing={16} container xs={4}>
            <Grid item xs={2}>
              <Checkbox
                checked={itemCheckboxState.checked}
                indeterminate={itemCheckboxState.indeterminate}
                className={classes.checkboxMarginBottom}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>) => handleSelectionUpdate(e.target.checked, null)}
              />
            </Grid>
            <Grid item container xs={10}>
              <Card className={classes.imageCard}>
                <CardContent>
                  <CardMedia component="img" image={orderItem.image}/>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item container spacing={8} direction="column" justify="flex-start" alignItems="stretch" xs={4}>
            <Grid item>
              Product:{' '}
              <a href={orderItem.url} target="_blank" rel="noopener noreferrer">
                {orderItem.name}
              </a>
            </Grid>
            <Grid item container justify="space-between" alignItems="center">
              <Grid item container xs={3}>
                <span className={classes.containerDiv}>Amount:</span>
              </Grid>
              <Grid item xs={6}>
                <IconButton
                  className={classes.leftIconButton}
                  disabled={!itemCheckboxState.indeterminate && !itemCheckboxState.checked}
                  onClick={() => {
                    if (!itemSelectionAmount) return;
                    const newValue = itemSelectionAmount - 1;
                    handleSelectionUpdate(true, newValue >= 0 ? newValue : 0);
                  }}
                >
                  <RemoveIcon/>
                </IconButton>
                <TextField
                  value={itemSelectionAmount}
                  name="received"
                  type="number"
                  InputProps={{
                    endAdornment: `/ ${calculatedAmount}`,
                  }}
                  // eslint-disable-next-line
                  inputProps={{
                    style: {
                      paddingTop: 9,
                      paddingBottom: 8,
                      paddingLeft: 7,
                    },
                  }}
                  disabled={!itemCheckboxState.indeterminate && !itemCheckboxState.checked}
                  variant="outlined"
                  className={classes.amountInput}
                />
                <IconButton
                  className={classes.rightIconButton}
                  disabled={!itemCheckboxState.indeterminate && !itemCheckboxState.checked}
                  onClick={() => {
                    if (!itemSelectionAmount) return;
                    const newValue = itemSelectionAmount + 1;
                    handleSelectionUpdate(true, newValue <= calculatedAmount ? newValue : calculatedAmount);
                  }}
                >
                  <AddIcon/>
                </IconButton>
              </Grid>
              <Grid item xs={3}>
                {this.getCompletenessCell(calculatedAmount, itemSelectionAmount)}
              </Grid>
            </Grid>
            <Grid item>{this.buildItemDetail(orderItem)}</Grid>
            <Grid item><ArrivedOrderItem orderItem={orderItem} /></Grid>
          </Grid>
          <Grid item xs={4}>
            <CourierReceptionOrderItemInvoice
              orderItem={orderItem}
              orderItemInputs={orderItemInputs}
              handleOrderItemOnFileChange={handleOrderItemOnFileChange}
              updateOrderItemInvoice={updateOrderItemInvoice}
              resetInvoice={resetInvoice}
              operation={operations ? operations.invoice : null}
            />
            <CourierReceptionOrderItemTracking
              orderItem={orderItem}
              orderItemInputs={orderItemInputs}
              handleChangeItemTrackingNumber={handleChangeItemTrackingNumber}
              handleChangeItemStoreOrderId={handleChangeItemStoreOrderId}
              updateTrackingNumber={updateTrackingNumber}
              updateStoreOrderId={updateStoreOrderId}
              operations={{
                tracking: operations ? operations.tracking : null,
                storeOrderId: operations ? operations.storeOrderId : null,
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    );
  }
}

CourierReceptionOrderItem.propTypes = {
  classes: PropTypes.object.isRequired,
  orderItem: PropTypes.object.isRequired,
  orderItemInputs: PropTypes.object,
  itemSelectionAmount: PropTypes.number.isRequired,
  itemCheckboxState: PropTypes.object.isRequired,
  updateOrderItemInvoice: PropTypes.func.isRequired,
  handleChangeItemStoreOrderId: PropTypes.func.isRequired,
  handleChangeItemTrackingNumber: PropTypes.func.isRequired,
  handleSelectionUpdate: PropTypes.func.isRequired,
  handleOrderItemOnFileChange: PropTypes.func.isRequired,
  updateTrackingNumber: PropTypes.func.isRequired,
  updateStoreOrderId: PropTypes.func.isRequired,
  operations: PropTypes.object,
  resetInvoice: PropTypes.func.isRequired,
};

export default withStyles(styles)(CourierReceptionOrderItem);
