// @flow
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import {
  endpoints,
  searchCountryApi,
  getCouponTypesApi,
  getCouponDiscountTypesApi,
  getItemCategoriesApi,
  getCouponRulesApi,
  getListRulesApi,
  getOrderStatesApi,
  getCancellationMotivesApi,
  getDeliveryCouriersApi,
  getServiceTiersApi, getCouriersApi,
} from './api';

import type { HTTPError } from '../../common/error';
import type { CountryFull } from '../../entities/webapp/country';
import type { MetaObject } from './utils';
import type { Courier } from '../../entities';

// Actions
const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR';
const GET_PRODUCT_CATEGORIES_REQUEST = 'GET_PRODUCT_CATEGORIES_REQUEST';
const GET_PRODUCT_CATEGORIES_SUCCESS = 'GET_PRODUCT_CATEGORIES_SUCCESS';
const GET_PRODUCT_CATEGORIES_ERROR = 'GET_PRODUCT_CATEGORIES_ERROR';
const GET_COUPON_TYPES_REQUEST = 'GET_COUPON_TYPES_REQUEST';
const GET_COUPON_TYPES_SUCCESS = 'GET_COUPON_TYPES_SUCCESS';
const GET_COUPON_TYPES_ERROR = 'GET_COUPON_TYPES_ERROR';
const GET_COUPON_DISCOUNT_TYPES_REQUEST = 'GET_COUPON_DISCOUNT_TYPES_REQUEST';
const GET_COUPON_DISCOUNT_TYPES_SUCCESS = 'GET_COUPON_DISCOUNT_TYPES_SUCCESS';
const GET_COUPON_DISCOUNT_TYPES_ERROR = 'GET_COUPON_DISCOUNT_TYPES_ERROR';
const GET_COUPON_RULES_REQUEST = 'GET_COUPON_RULES_REQUEST';
const GET_COUPON_RULES_SUCCESS = 'GET_COUPON_RULES_SUCCESS';
const GET_COUPON_RULES_ERROR = 'GET_COUPON_RULES_REQUEST';
const GET_LIST_RULES_REQUEST = 'GET_LIST_RULES_REQUEST';
const GET_LIST_RULES_SUCCESS = 'GET_LIST_RULES_SUCCESS';
const GET_LIST_RULES_ERROR = 'GET_LIST_RULES_ERROR';
const GET_ORDER_STATES_REQUEST = 'GET_ORDER_STATES_REQUEST';
const GET_ORDER_STATES_SUCCESS = 'GET_ORDER_STATES_SUCCESS';
const GET_ORDER_STATES_ERROR = 'GET_ORDER_STATES_ERROR';
const GET_CANCELLATION_MOTIVES_REQUEST = 'GET_CANCELLATION_MOTIVES_REQUEST';
const GET_CANCELLATION_MOTIVES_SUCCESS = 'GET_CANCELLATION_MOTIVES_SUCCESS';
const GET_CANCELLATION_MOTIVES_ERROR = 'GET_CANCELLATION_MOTIVES_ERROR';
const GET_DELIVERY_COURIERS_REQUEST = 'GET_DELIVERY_COURIERS_REQUEST';
const GET_DELIVERY_COURIERS_SUCCESS = 'GET_DELIVERY_COURIERS_SUCCESS';
const GET_DELIVERY_COURIERS_ERROR = 'GET_DELIVERY_COURIERS_ERROR';
const GET_COURIERS_REQUEST = 'GET_COURIERS_REQUEST';
const GET_COURIERS_SUCCESS = 'GET_COURIERS_SUCCESS';
const GET_COURIERS_ERROR = 'GET_COURIERS_ERROR';
const GET_SERVICE_TIERS_REQUEST = 'GET_SERVICE_TIERS_REQUEST';
const GET_SERVICE_TIERS_SUCCESS = 'GET_SERVICE_TIERS_SUCCESS';
const GET_SERVICE_TIERS_ERROR = 'GET_SERVICE_TIERS_ERROR';
const RESET_LOCAL_VALUES_STATE = 'RESET_LOCAL_VALUES_STATE';

type LocalValuesAction = {
  +type: string;
  +error?: HTTPError;
  +countries?: CountryFull[];
  +productCategories?: { [key: string]: string }[];
  +couponTypes?: MetaObject;
  +couponDiscountTypes?: MetaObject;
  +couponRules?: MetaObject;
  +listRules?: { [key: string]: string };
  +orderStates?: { [key: string]: string };
  +cancellationMotives?: { [key: string]: string }[];
  +deliveryCouriers?: { [key: string]: string }[];
  +couriers?: Courier[];
  +serviceTiers?: string[];
};

export type LocalValuesState = {
  countries?: CountryFull[];
  isLoadingGetCountries?: boolean;
  getCountriesSuccess?: boolean;
  getCountriesError?: HTTPError;
  productCategories?: { [key: string]: any }[];
  isLoadingGetItemCategories?: boolean;
  getItemCategoriesSuccess?: boolean;
  getItemCategoriesError?: HTTPError;
  couponTypes?: MetaObject;
  isLoadingGetCouponTypes?: boolean;
  getCouponTypesSuccess?: boolean;
  getCouponTypesError?: HTTPError;
  couponDiscountTypes?: MetaObject;
  isLoadingGetCouponDiscountTypes?: boolean;
  getCouponDiscountTypesSuccess?: boolean;
  getCouponDiscountTypesError?: HTTPError,
  couponRules?: MetaObject;
  isLoadingGetCouponRules?: boolean;
  getCouponRulesSuccess?: boolean;
  getCouponRulesError?: HTTPError;
  listRules?: { [key: string]: any }[];
  isLoadingGetListRules?: boolean;
  getListRulesSuccess?: boolean;
  getListRulesError?: HTTPError;
  orderStates?: { [key: string]: any }[];
  isLoadingGetOrderStates?: boolean;
  getOrderStatesSuccess?: boolean;
  getOrderStatesError?: HTTPError;
  cancellationMotives?: { [key: string]: string }[];
  isLoadingGetCancellationMotives?: boolean;
  getCancellationMotivesSuccess?: boolean;
  getCancellationMotivesError?: HTTPError;
  deliveryCouriers?: { [key: string]: string }[];
  isLoadingGetDeliveryCouriers?: boolean;
  getDeliveryCouriersSuccess?: boolean;
  getDeliveryCouriersError?: HTTPError;
  couriers?: Courier[];
  isLoadingGetCouriers?: boolean;
  getCouriersError?: HTTPError;
  serviceTiers?: string[];
  isLoadingGetServiceTiers?: boolean;
  getServiceTiersSuccess?: boolean;
  getServiceTiersError?: HTTPError;
};

// Action Creators
export function executeGetCountries(): LocalValuesAction {
  return {
    type: GET_COUNTRIES_REQUEST,
  };
}

export function executeGetCountriesSuccess(countries: CountryFull[]): LocalValuesAction {
  return {
    type: GET_COUNTRIES_SUCCESS,
    countries,
  };
}

export function executeGetCountriesError(error: HTTPError): LocalValuesAction {
  return {
    type: GET_COUNTRIES_ERROR,
    error,
  };
}

export function executeGetItemCategories(): LocalValuesAction {
  return {
    type: GET_PRODUCT_CATEGORIES_REQUEST,
  };
}

export function executeGetItemCategoriesSuccess(productCategories: { [key: string]: string }[]): LocalValuesAction {
  return {
    type: GET_PRODUCT_CATEGORIES_SUCCESS,
    productCategories,
  };
}

export function executeGetItemCategoriesError(error: HTTPError): LocalValuesAction {
  return {
    type: GET_PRODUCT_CATEGORIES_ERROR,
    error,
  };
}

export function executeGetCouponTypes(): LocalValuesAction {
  return {
    type: GET_COUPON_TYPES_REQUEST,
  };
}

export function executeGetCouponTypesSuccess(couponTypes: MetaObject): LocalValuesAction {
  return {
    type: GET_COUPON_TYPES_SUCCESS,
    couponTypes,
  };
}

export function executeGetCouponTypesError(error: HTTPError): LocalValuesAction {
  return {
    type: GET_COUPON_TYPES_ERROR,
    error,
  };
}

export function executeGetCouponDiscountTypes(): LocalValuesAction {
  return {
    type: GET_COUPON_DISCOUNT_TYPES_REQUEST,
  };
}

export function executeGetCouponDiscountTypesSuccess(couponDiscountTypes: MetaObject): LocalValuesAction {
  return {
    type: GET_COUPON_DISCOUNT_TYPES_SUCCESS,
    couponDiscountTypes,
  };
}

export function executeGetCouponDiscountTypesError(error: HTTPError): LocalValuesAction {
  return {
    type: GET_COUPON_DISCOUNT_TYPES_ERROR,
    error,
  };
}

export function executeGetCouponRules(): LocalValuesAction {
  return {
    type: GET_COUPON_RULES_REQUEST,
  };
}

export function executeGetCouponRulesSuccess(couponRules: MetaObject): LocalValuesAction {
  return {
    type: GET_COUPON_RULES_SUCCESS,
    couponRules,
  };
}

export function executeGetCouponRulesError(error: HTTPError): LocalValuesAction {
  return {
    type: GET_COUPON_RULES_ERROR,
    error,
  };
}

export function executeGetListRules(): LocalValuesAction {
  return {
    type: GET_LIST_RULES_REQUEST,
  };
}

export function executeGetListRulesSuccess(listRules: { [key: string]: any }): LocalValuesAction {
  return {
    type: GET_LIST_RULES_SUCCESS,
    listRules,
  };
}

export function executeGetListRulesError(error: HTTPError): LocalValuesAction {
  return {
    type: GET_LIST_RULES_ERROR,
    error,
  };
}

export function executeGetOrderStates(): LocalValuesAction {
  return {
    type: GET_ORDER_STATES_REQUEST,
  };
}

export function executeGetOrderStatesSuccess(orderStates: { [key: string]: any }): LocalValuesAction {
  return {
    type: GET_ORDER_STATES_SUCCESS,
    orderStates,
  };
}

export function executeGetOrderStatesError(error: HTTPError): LocalValuesAction {
  return {
    type: GET_ORDER_STATES_ERROR,
    error,
  };
}

export function executeGetCancellationMotives(): LocalValuesAction {
  return {
    type: GET_CANCELLATION_MOTIVES_REQUEST,
  };
}

export function executeGetCancellationMotivesSuccess(cancellationMotives: { [key: string]: any }[]): LocalValuesAction {
  return {
    type: GET_CANCELLATION_MOTIVES_SUCCESS,
    cancellationMotives,
  };
}

export function executeGetCancellationMotivesError(error: HTTPError): LocalValuesAction {
  return {
    type: GET_CANCELLATION_MOTIVES_ERROR,
    error,
  };
}

export function executeGetDeliveryCouriers(): LocalValuesAction {
  return {
    type: GET_DELIVERY_COURIERS_REQUEST,
  };
}

export function executeGetDeliveryCouriersSuccess(deliveryCouriers: { [key: string]: any }[]): LocalValuesAction {
  return {
    type: GET_DELIVERY_COURIERS_SUCCESS,
    deliveryCouriers,
  };
}

export function executeGetDeliveryCouriersError(error: HTTPError): LocalValuesAction {
  return {
    type: GET_DELIVERY_COURIERS_ERROR,
    error,
  };
}

export function executeGetCouriers(): LocalValuesAction {
  return {
    type: GET_COURIERS_REQUEST,
  };
}

export function executeGetCouriersSuccess(couriers: Courier[]): LocalValuesAction {
  return {
    type: GET_COURIERS_SUCCESS,
    couriers,
  };
}

export function executeGetCouriersError(error: HTTPError): LocalValuesAction {
  return {
    type: GET_COURIERS_ERROR,
    error,
  };
}

export function executeGetServiceTiers(): LocalValuesAction {
  return {
    type: GET_SERVICE_TIERS_REQUEST,
  };
}

export function executeGetServiceTiersSuccess(serviceTiers: string[]): LocalValuesAction {
  return {
    type: GET_SERVICE_TIERS_SUCCESS,
    serviceTiers,
  };
}

export function executeGetServiceTiersError(error: HTTPError): LocalValuesAction {
  return {
    type: GET_SERVICE_TIERS_ERROR,
    error,
  };
}

export function executeResetLocalValues(): LocalValuesAction {
  return {
    type: RESET_LOCAL_VALUES_STATE,
  }
}

export const actions = {
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
  GET_COUPON_TYPES_REQUEST,
  GET_COUPON_TYPES_SUCCESS,
  GET_COUPON_TYPES_ERROR,
  GET_COUPON_DISCOUNT_TYPES_REQUEST,
  GET_COUPON_DISCOUNT_TYPES_SUCCESS,
  GET_COUPON_DISCOUNT_TYPES_ERROR,
  GET_COUPON_RULES_REQUEST,
  GET_COUPON_RULES_SUCCESS,
  GET_COUPON_RULES_ERROR,
  GET_PRODUCT_CATEGORIES_REQUEST,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCT_CATEGORIES_ERROR,
  GET_LIST_RULES_REQUEST,
  GET_LIST_RULES_SUCCESS,
  GET_LIST_RULES_ERROR,
  GET_ORDER_STATES_REQUEST,
  GET_ORDER_STATES_SUCCESS,
  GET_ORDER_STATES_ERROR,
  GET_CANCELLATION_MOTIVES_REQUEST,
  GET_CANCELLATION_MOTIVES_SUCCESS,
  GET_CANCELLATION_MOTIVES_ERROR,
  GET_DELIVERY_COURIERS_REQUEST,
  GET_DELIVERY_COURIERS_SUCCESS,
  GET_DELIVERY_COURIERS_ERROR,
  GET_COURIERS_REQUEST,
  GET_COURIERS_SUCCESS,
  GET_COURIERS_ERROR,
  GET_SERVICE_TIERS_REQUEST,
  GET_SERVICE_TIERS_SUCCESS,
  GET_SERVICE_TIERS_ERROR,
  RESET_LOCAL_VALUES_STATE,
  executeGetCountries,
  executeGetCountriesSuccess,
  executeGetCountriesError,
  executeGetItemCategories,
  executeGetItemCategoriesSuccess,
  executeGetItemCategoriesError,
  executeGetCouponTypes,
  executeGetCouponTypesSuccess,
  executeGetCouponTypesError,
  executeGetCouponDiscountTypes,
  executeGetCouponDiscountTypesSuccess,
  executeGetCouponDiscountTypesError,
  executeGetCouponRules,
  executeGetCouponRulesSuccess,
  executeGetCouponRulesError,
  executeGetListRules,
  executeGetListRulesSuccess,
  executeGetListRulesError,
  executeGetOrderStates,
  executeGetOrderStatesSuccess,
  executeGetOrderStatesError,
  executeGetCancellationMotives,
  executeGetCancellationMotivesSuccess,
  executeGetCancellationMotivesError,
  executeGetDeliveryCouriers,
  executeGetDeliveryCouriersSuccess,
  executeGetDeliveryCouriersError,
  executeGetCouriers,
  executeGetCouriersSuccess,
  executeGetCouriersError,
  executeGetServiceTiers,
  executeGetServiceTiersSuccess,
  executeGetServiceTiersError,
  executeResetLocalValues,
};

export const initialState = {};

// Reducer
export default function reducer(state: LocalValuesState = initialState,
                                action: LocalValuesAction): LocalValuesState {
  switch (action.type) {
    case GET_COUNTRIES_REQUEST:
      return {
        ...state,
        isLoadingGetCountries: true,
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        getCountriesSuccess: true,
        isLoadingGetCountries: false,
        countries: action.countries,
      };
    case GET_COUNTRIES_ERROR:
      return {
        ...state,
        getCountriesError: action.error,
      };
    case GET_PRODUCT_CATEGORIES_REQUEST:
      return {
        ...state,
        isLoadingGetItemCategories: true,
      };
    case GET_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoadingGetItemCategories: false,
        getItemCategoriesSuccess: true,
        productCategories: action.productCategories,
      };
    case GET_PRODUCT_CATEGORIES_ERROR:
      return {
        ...state,
        isLoadingGetItemCategories: false,
        getItemCategoriesError: action.error,
      };
    case GET_COUPON_TYPES_REQUEST:
      return {
        ...state,
        isLoadingGetCouponTypes: true,
      };
    case GET_COUPON_TYPES_SUCCESS:
      return {
        ...state,
        getCouponTypesSuccess: true,
        isLoadingGetCouponTypes: false,
        couponTypes: action.couponTypes,
      };
    case GET_COUPON_TYPES_ERROR:
      return {
        ...state,
        getCouponTypesError: action.error,
      };
    case GET_COUPON_DISCOUNT_TYPES_REQUEST:
      return {
        ...state,
        isLoadingGetCouponDiscountTypes: true,
      };
    case GET_COUPON_DISCOUNT_TYPES_SUCCESS:
      return {
        ...state,
        getCouponDiscountTypesSuccess: true,
        isLoadingGetCouponDiscountTypes: false,
        couponDiscountTypes: action.couponDiscountTypes,
      };
    case GET_COUPON_DISCOUNT_TYPES_ERROR:
      return {
        ...state,
        getCouponDiscountTypesError: action.error,
      };
    case GET_COUPON_RULES_REQUEST:
      return {
        ...state, isLoadingGetCouponRules: true,
      };
    case GET_COUPON_RULES_SUCCESS:
      return {
        ...state,
        getCouponRulesSuccess: true,
        isLoadingGetCouponRules: false,
        couponRules: action.couponRules,
      };
    case GET_COUPON_RULES_ERROR:
      return {
        ...state,
        getCouponRulesError: action.error,
      };
    case GET_LIST_RULES_REQUEST:
      return {
        ...state, isLoadingGetListRules: true,
      };
    case GET_LIST_RULES_SUCCESS:
      const listRules = Object.entries(action.listRules).map(element => {
        const id = parseInt(element[1], 10);
        return {
          id,
          name: element[0],
        }
      });
      return {
        ...state,
        getListRulesSuccess: true,
        isLoadingGetListRules: false,
        listRules,
      };
    case GET_LIST_RULES_ERROR:
      return {
        ...state,
        getListRulesError: action.error,
      };
    case GET_ORDER_STATES_REQUEST:
      return {
        ...state, isLoadingGetListRules: true,
      };
    case GET_ORDER_STATES_SUCCESS:
      const orderStates = Object.entries(action.orderStates).map(element => {
        const id = parseInt(element[1], 10);
        return {
          id,
          name: element[0],
        }
      });
      return {
        ...state,
        getListRulesSuccess: true,
        isLoadingGetListRules: false,
        orderStates,
      };
    case GET_ORDER_STATES_ERROR:
      return {
        ...state,
        getListRulesError: action.error,
      };
    case GET_CANCELLATION_MOTIVES_REQUEST:
      return {
        ...state,
        getCancellationMotivesSuccess: false,
        isLoadingGetCancellationMotives: true,
      };
    case GET_CANCELLATION_MOTIVES_SUCCESS:
      return {
        ...state,
        getCancellationMotivesSuccess: true,
        isLoadingGetCancellationMotives: false,
        cancellationMotives: action.cancellationMotives,
      };
    case GET_CANCELLATION_MOTIVES_ERROR:
      return {
        ...state,
        getCancellationMotivesError: action.error,
      };
    case GET_DELIVERY_COURIERS_REQUEST:
      return {
        ...state,
        getDeliveryCouriersSuccess: false,
        isLoadingGetDeliveryCouriers: true,
      };
    case GET_DELIVERY_COURIERS_SUCCESS:
      return {
        ...state,
        getDeliveryCouriersSuccess: true,
        isLoadingGetDeliveryCouriers: false,
        deliveryCouriers: action.deliveryCouriers,
      };
    case GET_DELIVERY_COURIERS_ERROR:
      return {
        ...state,
        getDeliveryCouriersError: action.error,
      };
    case GET_COURIERS_REQUEST:
      return {
        ...state,
        isLoadingGetCouriers: true,
      };
    case GET_COURIERS_SUCCESS:
      return {
        ...state,
        isLoadingGetCouriers: false,
        couriers: action.couriers,
      };
    case GET_COURIERS_ERROR:
      return {
        ...state,
        isLoadingGetCouriers: false,
        getCouriersError: action.error,
      };
    case GET_SERVICE_TIERS_REQUEST:
      return {
        ...state,
        getServiceTiersSuccess: false,
        isLoadingGetServiceTiers: true,
      };
    case GET_SERVICE_TIERS_SUCCESS:
      return {
        ...state,
        getServiceTiersSuccess: true,
        isLoadingGetServiceTiers: false,
        serviceTiers: action.serviceTiers,
      };
    case GET_SERVICE_TIERS_ERROR:
      return {
        ...state,
        getServiceTiersSuccess: false,
        isLoadingGetServiceTiers: false,
        getServiceTiersError: action.error,
      };
    case RESET_LOCAL_VALUES_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetCountriesEpic(action$: ActionsObservable<LocalValuesAction>) {
  return action$.ofType(GET_COUNTRIES_REQUEST).pipe(
    mergeMap(
      () => searchCountryApi(undefined, {
        route: endpoints.SEARCH_COUNTRY,
      }).pipe(
        map((countries) => executeGetCountriesSuccess(countries)),
        catchError((e: HTTPError) => of(executeGetCountriesError(e)))),
    ),
  );
}

export function executeGetItemCategoriesEpic(action$: ActionsObservable<LocalValuesAction>) {
  return action$.ofType(GET_PRODUCT_CATEGORIES_REQUEST).pipe(
    mergeMap(
      () => getItemCategoriesApi().pipe(
        map((productCategories) => executeGetItemCategoriesSuccess(productCategories)),
        catchError((e: HTTPError) => of(executeGetItemCategoriesError(e)))),
    ),
  );
}

export function executeGetCouponTypesEpic(action$: ActionsObservable<LocalValuesAction>) {
  return action$.ofType(GET_COUPON_TYPES_REQUEST).pipe(
    mergeMap(
      () => getCouponTypesApi(undefined, {
        route: endpoints.GET_COUPON_TYPES,
      }).pipe(
        map((couponTypes) => executeGetCouponTypesSuccess(couponTypes)),
        catchError((e: HTTPError) => of(executeGetCouponTypesError(e)))),
    ),
  );
}

export function executeGetCouponDiscountTypesEpic(action$: ActionsObservable<LocalValuesAction>) {
  return action$.ofType(GET_COUPON_DISCOUNT_TYPES_REQUEST).pipe(
    mergeMap(
      () => getCouponDiscountTypesApi(undefined, {
        route: endpoints.GET_COUPON_DISCOUNT_TYPES,
      }).pipe(
        map((couponDiscountTypes) => executeGetCouponDiscountTypesSuccess(couponDiscountTypes)),
        catchError((e: HTTPError) => of(executeGetCouponDiscountTypesError(e)))),
    ),
  );
}

export function executeGetCouponRulesEpic(action$: ActionsObservable<LocalValuesAction>) {
  return action$.ofType(GET_COUPON_RULES_REQUEST).pipe(
    mergeMap(
      () => getCouponRulesApi(undefined, {
        route: endpoints.GET_COUPON_RULES,
      }).pipe(
        map((rules) => executeGetCouponRulesSuccess(rules)),
        catchError((e: HTTPError) => of(executeGetCouponRulesError(e)))),
    ),
  );
}

export function executeGetListRulesEpic(action$: ActionsObservable<LocalValuesAction>) {
  return action$.ofType(GET_LIST_RULES_REQUEST).pipe(
    mergeMap(
      () => getListRulesApi(undefined, {
        route: endpoints.GET_LIST_RULES,
      }).pipe(
        map((rules) => executeGetListRulesSuccess(rules)),
        catchError((e: HTTPError) => of(executeGetListRulesError(e)))),
    ),
  );
}

export function executeGetOrderStatesEpic(action$: ActionsObservable<LocalValuesAction>) {
  return action$.ofType(GET_ORDER_STATES_REQUEST).pipe(
    mergeMap(
      () => getOrderStatesApi(undefined, {
        route: endpoints.GET_LIST_RULES,
      }).pipe(
        map((rules) => executeGetOrderStatesSuccess(rules)),
        catchError((e: HTTPError) => of(executeGetOrderStatesError(e)))),
    ),
  );
}

export function executeGetCancellationMotivesEpic(action$: ActionsObservable<LocalValuesAction>) {
  return action$.ofType(GET_CANCELLATION_MOTIVES_REQUEST).pipe(
    mergeMap(
      () => getCancellationMotivesApi(undefined, {
        route: endpoints.GET_CANCELLATION_MOTIVES,
      }).pipe(
        map((motives) => executeGetCancellationMotivesSuccess(motives)),
        catchError((e: HTTPError) => of(executeGetCancellationMotivesError(e)))),
    ),
  );
}

export function executeGetDeliveryCouriersEpic(action$: ActionsObservable<LocalValuesAction>) {
  return action$.ofType(GET_DELIVERY_COURIERS_REQUEST).pipe(
    mergeMap(
      () => getDeliveryCouriersApi(undefined, {
        route: endpoints.GET_DELIVERY_COURIERS,
      }).pipe(
        map((deliveryCouriers) => executeGetDeliveryCouriersSuccess(deliveryCouriers)),
        catchError((e: HTTPError) => of(executeGetDeliveryCouriersError(e)))),
    ),
  );
}

export function executeGetCouriersEpic(action$: ActionsObservable<LocalValuesAction>) {
  return action$.ofType(GET_COURIERS_REQUEST).pipe(
    mergeMap(
      () => getCouriersApi(undefined, {
        route: endpoints.GET_COURIERS,
      }).pipe(
        map((couriers: Courier) => executeGetCouriersSuccess(couriers)),
        catchError((e: HTTPError) => of(executeGetCouriersError(e)))),
    ),
  );
}

export function executeGetServiceTiersEpic(action$: ActionsObservable<LocalValuesAction>) {
  return action$.ofType(GET_SERVICE_TIERS_REQUEST).pipe(
    mergeMap(
      () => {
        return getServiceTiersApi().pipe(
          map((serviceTiers) => executeGetServiceTiersSuccess(serviceTiers)),
          catchError((e: HTTPError) => of(executeGetServiceTiersError(e))))
      },
    ),
  );
}
