// @flow
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect, Route } from 'react-router-dom';
// import { Base64 } from 'js-base64';

import type { AdminUser } from '../entities';
import type { Node } from 'react';

type Props = {
  path: string;
  user: ?AdminUser;
  authenticated: ?boolean;
  render: (any) => Node;
  isLoadingGetSession?: boolean;
};

export const PrivateRoute = ({ render, user, authenticated, isLoadingGetSession, ...rest }: Props) => (
  <Route {...rest}
         render={(props) => {
           if (isLoadingGetSession) {
             return <CircularProgress style={{
               position: 'absolute',
               top: '50%',
               left: '50%',
             }} size={80}/>;
           }
           // const location = Base64.encode(location.pathname);
           return user && authenticated ? (render(props)) :
             (<Redirect
               to={{
                 pathname: '/',
                 state: { from: props.location }
               }}/>)
         }}/>
);

PrivateRoute.propTypes = {
  user: PropTypes.object,
  authenticated: PropTypes.bool.isRequired,
  isLoadingGetSession: PropTypes.bool,
  render: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};
