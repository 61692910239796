// @flow
import { Observable } from 'rxjs';
// import { getCreator, postCreator, putCreator, deleteCreator } from '@qempo/qempo-web-common/http';
import environment from '../../environment';
import type { Coupon } from '../../entities/webapp';
import apiCoupons from './coupons';

export const endpoints = Object.freeze({
  GET_COUPONS: `${environment.apiUrl}/administrative/coupon/list`
});

function desc(a, b, sort) {
  if (b[sort] < a[sort]) {
    return -1;
  }
  if (b[sort] > a[sort]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order: string, sort: string) {
  return order === 'desc' ? (a, b) => desc(a, b, sort) : (a, b) => -desc(a, b, sort);
}

export function searchCoupon(CouponCode: string): Coupon {
  for (let i = 0; i < apiCoupons.length; i++) {
    if (apiCoupons[i].couponCode === CouponCode) {
      return apiCoupons[i];
    }
  }
}

export function searchCouponById(id: number): Coupon {
  for (let i = 0; i < apiCoupons.length; i++) {
    if (apiCoupons[i].id === id) {
      return apiCoupons[i];
    }
  }
}

// export const getCouponsApi = getCreator(fetch, endpoints.GET_USERS);
export const getCouponsApi = (queryParams: any) => Observable.create(
  (observer) => {
    let coupons;
    const { page, limit, ord, sort, filterId } = queryParams;

    coupons = stableSort(apiCoupons, getSorting(ord, sort))
      .slice(page * limit, page * limit + limit);
    const totalCoupons = apiCoupons.length;

    if (filterId !== '') {
      let preCoupons = [searchCoupon(filterId)];
      if (preCoupons[0] !== undefined) {
        coupons = preCoupons
      } else {
        coupons = [];
      }
    }
    observer.next({ coupons, totalCoupons });
  });
