// @flow
import { fade } from '@material-ui/core/styles/colorManipulator';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

export default function tableStyles(theme: { [key: string]: any }) {
  return ({
    redBorder: {
      borderColor: `${red[500]} !important`,
    },
    greenBorder: {
      borderColor: `${green[500]} !important`,
    },
    redText: {
      color: red[500],
    },
    greenText: {
      color: green[500],
    },
    tableRowFlight: {
      backgroundColor: theme.palette.primary.main,
    },
    tableCellFlight: {
      textAlign: 'center',
      color: theme.palette.primary.contrastText,
    },
    tableRowOrder: {
      backgroundColor: '#ade4ff',
    },
    button: {
      // position: 'relative',
      // right: 11,
      // top: 5,
      padding: 6,
    },
    tableProgress: {
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        left: '50%',
      },
      left: '40%',
    },
    tab: {
      minHeight: 35
    },
    rightCell: {
      textAlign: 'right',
    },
    noMargin: {
      margin: 0,
    },
    leftCell: {
      textAlign: 'left',
    },
    divider: {
      marginBottom: 10,
    },
    tableCell: {
      textAlign: 'center',
    },
    tableCellIncomplete: {
      textAlign: 'center',
      color: red[500],
    },
    tableCellComplete: {
      textAlign: 'center',
      color: green[500],
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    link: {
      color: 'black',
      textDecoration: 'none',
    },
    tableBody: {
      backgroundColor: 'rgba(153,153,153,0.2)',
    },
    fabContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column-reverse',
      position: 'fixed',
      bottom: 40,
      right: 40,
    },
    tableRoot: {
      width: '100%',
    },
    tableSpacer: {
      flexGrow: 1,
    },
    tableSearch: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade('#656565', 0.15),
      '&:hover': {
        backgroundColor: fade('#656565', 0.25),
      },
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing.unit,
        width: 'auto',
      },
    },
    tableSearchIcon: {
      width: theme.spacing.unit * 9,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tableInputRoot: {
      color: '#656565',
      overflow: 'hidden',
      width: '100%',
    },
    tableSearchInput: {
      color: '#656565',
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 10,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
    iconButton: {
      position: 'relative',
      padding: 6,
    },
  });
}
