// @flow
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import update from 'immutability-helper';
import ShippingTableHead from './ShippingTableHead';
import ShippingTableToolbar from './ShippingTableToolbar';
import ShippingTableBody from './ShippingTableBody';
import { FormValidator } from '../../../common/form';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import TablePagination from '@material-ui/core/TablePagination';
import './Shipping.css';
import moment from 'moment';
import TablePaginationActions from '../../../elements/TablePaginationActions/TablePaginationActions';

import styles from './style';

import type { HTTPError } from '../../../common/error';
import type { GetDeliveryCouriersResponse } from '../../local-values/api';
import type { PerformShippingRequest } from '../api';

type Props = {
  +classes: { [key: string]: any };
  +shippingOrders: { [key: string]: any }[];
  +deliveryCouriers?: GetDeliveryCouriersResponse[];
  +totalShippingOrders: number;
  +executeGetShipping: () => void;
  +executeGetDeliveryCouriers: () => void;
  +executePerformShipping: (data: PerformShippingRequest) => void;
  +changeShippingPagination: (changes: { [key: string]: any }) => void;
  +executeResetShippingState: () => void;
  +isLoadingGetShipping: boolean;
  +getShippingSuccess: boolean;
  +getShippingSuccessError: HTTPError;
  +isLoadingGetDeliveryCouriers: boolean;
  +getDeliveryCouriersSuccess: boolean;
  +getDeliveryCouriersError: HTTPError;
  +isLoadingPerformShipping: boolean;
  +performShippingSuccess: boolean;
  +performShippingError: HTTPError;
  +page: number;
  +limit: number;
  +search: string;
  +filterKey: string;
};

type State = {
  deliveryCourierIndex: number;
  orders: {
    [id: string]: {
      selected: boolean,
      size: {
        value: 'SMALL' | 'MEDIUM' | 'LARGE',
        errors?: string[],
      },
    },
  };
  openModal: boolean;
  page: number,
  rowsPerPage: number,
};

class ShippingTableMain extends Component<Props, State> {
  shippingFormValidator: FormValidator;
  state = {
    deliveryCourierIndex: 0,
    orders: {},
    openModal: false,
    page: 0,
    rowsPerPage: 100,
  };

  constructor(props: Props) {
    super(props);
    this.shippingFormValidator = new FormValidator({
      size: {
        required: 'required',
      },
    });
  }

  componentDidMount() {
    const { executeGetShipping, executeGetDeliveryCouriers } = this.props;
    executeGetDeliveryCouriers();
    executeGetShipping();
  }

  componentWillUnmount() {
    const { executeResetShippingState } = this.props;
    executeResetShippingState();
  }

  getSelectedOrders = () => {
    const { orders } = this.state;
    if (orders) {
      return Object.keys(orders).filter(k => orders[k].selected);
    }
  };

  setStateHelper = (orderId: string, key: string, newState: any) => {
    const { orders } = this.state;
    if (orders[orderId]) {
      this.setState(prevState => update(prevState, {
        orders: {
          [orderId]: {
            [key]: { $set: newState },
          },
        },
      }));
    } else {
      this.setState(prevState => update(prevState, {
        orders: {
          [orderId]: {
            $set: {
              [key]: newState,
            },
          },
        },
      }));
    }
  };

  handleSelect = (e: Event, orderId: string) => {
    if (e.target instanceof HTMLInputElement) {
      const { checked } = e.target;
      this.setStateHelper(orderId, 'selected', checked);
    }
  };

  handleDeliveryCourier = (e: Event) => {
    if (e.target instanceof HTMLSelectElement) {
      const { selectedIndex } = e.target;
      this.setState(prevState => update(prevState, {
        deliveryCourierIndex: { $set: selectedIndex },
      }));
    }
  };

  handleSize = (e: Event, orderId: string) => {
    const { value } = (e.target: { [key: string]: any });
    this.setStateHelper(orderId, 'size', { value });
  };

  handleModal = () => {
    this.setState(prevState => update(prevState, {
      openModal: { $set: !prevState.openModal },
    }));
  };

  handleShip = () => {
    const { executePerformShipping, deliveryCouriers } = this.props;
    const { orders, deliveryCourierIndex } = this.state;
    if (deliveryCouriers) {
      const deliveryCourier = deliveryCouriers[deliveryCourierIndex];
      const deliveryCourierId = deliveryCourier.id;
      if (deliveryCourier.shippingSizes.length > 1) {
        const selectedOrders: { [key: string]: any } = this.getSelectedOrdersState();
        const requestOrders = Object.keys(selectedOrders).map(key => {
          return {
            size: selectedOrders[key].size ? selectedOrders[key].size.value : 'MEDIUM',
            orderId: key,
          };
        });
        deliveryCourierId && executePerformShipping({ orders: requestOrders, deliveryCourierId });
        this.setState({ orders: {}, openModal: false });
      } else {
        const requestOrders = [];
        Object.keys(orders).forEach(key => {
          if (orders[key].selected) {
            requestOrders.push({ orderId: key });
          }
        });
        deliveryCourierId && executePerformShipping({ orders: requestOrders, deliveryCourierId });
        this.setState({ orders: {}, openModal: false });
      }
    }
  };

  handleChangePage = (e: Event, page: number) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({ page: 0, rowsPerPage: parseInt(value) });
  };

  getSelectedOrdersState = (): { [key: string]: any } => {
    const { orders } = this.state;
    const selectedOrders = {};
    Object.keys(orders).forEach(key => {
      if (orders[key].selected) {
        selectedOrders[key] = orders[key];
      }
    });
    return selectedOrders;
  };

  atLeastOneSelected = () => {
    const { orders } = this.state;
    return Object.keys(orders).some(key => orders[key].selected);
  };

  render() {
    const {
      classes,
      shippingOrders,
      isLoadingGetShipping,
      isLoadingPerformShipping,
      executeGetShipping,
      deliveryCouriers,
      isLoadingGetDeliveryCouriers,
    } = this.props;
    const {
      orders,
      deliveryCourierIndex,
      openModal,
      rowsPerPage,
      page,
    } = this.state;
    return (
      <Fragment>
        <div id="shipping-main-wrap">
          <Paper>
            <ShippingTableToolbar executeGetShipping={executeGetShipping}
                                  deliveryCourierIndex={deliveryCourierIndex}
                                  deliveryCouriers={deliveryCouriers}
                                  handleDeliveryCourier={this.handleDeliveryCourier}
                                  atLeastOneSelected={this.atLeastOneSelected}
                                  handleModal={this.handleModal}
                                  selectedOrdersIds={this.getSelectedOrders()} />
          </Paper>
          <div className={classes.tableWrapper}>
            <ShippingTableHead/>
            <ShippingTableBody
              shippingOrders={shippingOrders}
              stateOrders={orders}
              deliveryCourier={deliveryCouriers && deliveryCouriers[deliveryCourierIndex]}
              handleSelect={this.handleSelect}
              handleSize={this.handleSize}
              isLoading={isLoadingGetShipping || isLoadingPerformShipping || isLoadingGetDeliveryCouriers}
              rowsPerPage={rowsPerPage}
              page={page}
            />
            <Dialog open={openModal}
                    onClose={this.handleModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
                Are you sure you want to ship this orders?
              </DialogTitle>
              <DialogContent>
                {Object.keys(this.getSelectedOrdersState()).map((key, i) =>
                  <Typography key={key} variant="body1">{i + 1}. {key}</Typography>)}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleModal} style={{ margin: 'auto' }}>
                  NO
                </Button>
                <Button color="primary" onClick={this.handleShip} style={{ margin: 'auto' }}>
                  YES
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <Paper>
            <TablePagination
              component="div"
              rowsPerPageOptions={[100, 200, 300, 500]}
              count={shippingOrders ? shippingOrders.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </div>
        <div id="shipping-print-wrap">
          {shippingOrders && shippingOrders.map((order, index: number) => {
            if (orders[order.id] && orders[order.id].selected) {
              return <Fragment key={index}>
                <section className="shipping-label-wrap">
                  <div>
                    <b>
                      QEMPO - {order.id}
                    </b>
                  </div>
                  <div>
                    <b>
                      BeforeDate: {moment(order.beforeDate).format('DD/MM/YYYY')}
                    </b>
                  </div>
                  <div>
                    <b>
                      {order.shippingAddress.firstName} {order.shippingAddress.lastName}
                    </b>
                  </div>
                  <div>
                    <b>
                      {order.shippingAddress.idNumber}
                    </b>
                  </div>
                  <div>
                    <b>
                      {order.shippingAddress.phone}
                    </b>
                  </div>
                  <div>
                    <b>
                      {order.shippingAddress.address1}
                    </b>
                  </div>
                  <div>
                    <b>
                      {order.shippingAddress.address2}
                    </b>
                  </div>
                  <div>
                    <b>
                      {order.shippingAddress.admin3 && `${order.shippingAddress.admin3.name}, `}{order.shippingAddress.admin2.name}, {order.shippingAddress.admin1.name}
                    </b>
                  </div>
                </section>
                {/*<section className="shipping-label-wrap">
                  {order.items.map((item, i: number) =>
                    <div key={i}>
                      <b>
                        {item.receivedCount} x {item.name.substring(0, 30)}
                      </b>
                    </div>)}
                </section>*/}
              </Fragment>;
            } else return false;
          })}
        </div>
      </Fragment>);
  }
}

ShippingTableMain.propTypes = {
  classes: PropTypes.object.isRequired,
  executeGetShipping: PropTypes.func.isRequired,
  executeGetDeliveryCouriers: PropTypes.func.isRequired,
  executePerformShipping: PropTypes.func.isRequired,
  changeShippingPagination: PropTypes.func.isRequired,
  executeResetShippingState: PropTypes.func.isRequired,
  shippingOrders: PropTypes.array,
  deliveryCouriers: PropTypes.array,
  totalShippingOrders: PropTypes.number,
  isLoadingGetShipping: PropTypes.bool,
  getShippingSuccess: PropTypes.bool,
  getShippingSuccessError: PropTypes.object,
  isLoadingGetDeliveryCouriers: PropTypes.bool,
  getDeliveryCouriersSuccess: PropTypes.bool,
  getDeliveryCouriersError: PropTypes.object,
  isLoadingPerformShipping: PropTypes.bool,
  performShippingSuccess: PropTypes.bool,
  performShippingError: PropTypes.object,
  limit: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

export default withStyles(styles)(ShippingTableMain);
