// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import DeliveryConfirmTableHead from './DeliveryConfirmTableHead';
import DeliveryConfirmTableToolbar from './DeliveryConfirmTableToolbar';
import DeliveryConfirmTableBody from './table-body/DeliveryConfirmTableBody';
import ReceptionTableDialog from './DeliveryConfirmTableDialog';
import TablePagination from '@material-ui/core/TablePagination';
import update from 'immutability-helper';
// import { FormValidator } from '../../../common/form';
import TablePaginationActions from '../../../elements/TablePaginationActions/TablePaginationActions';

import tableStyles from '../../../style.table';

import type { HTTPError } from '../../../common/error';
import type { UpdateDeliveryConfirmRequest, ConfirmDeliveryConfirmRequest, CancelDeliveryConfirmRequest } from '../api';
import ShippingTableBody from '../../shipping/components/ShippingTableBody';

type Props = {
  +classes: { [key: string]: any };
  +deliveryConfirmOrders: { [key: string]: any }[];
  +totalDeliveryConfirmOrders: number;
  +executeGetDeliveryConfirm: () => void;
  +executeConfirmDeliveryConfirm: (request: ConfirmDeliveryConfirmRequest) => void;
  +executeUpdateDeliveryConfirm: (request: UpdateDeliveryConfirmRequest) => void;
  +executeCancelDeliveryConfirm: (request: CancelDeliveryConfirmRequest) => void;
  +changeDeliveryConfirmPagination: (changes: { [key: string]: any }) => void;
  +executeResetDeliveryConfirmState: () => void;
  +isLoadingGetDeliveryConfirm: boolean;
  +getDeliveryConfirmSuccess: boolean;
  +getDeliveryConfirmError: HTTPError;
  +isLoadingConfirmDeliveryConfirm: boolean;
  +confirmDeliveryConfirmSuccess: boolean;
  +confirmDeliveryConfirmError: HTTPError;
  +isLoadingUpdateDeliveryConfirm: boolean;
  +updateDeliveryConfirmSuccess: boolean;
  +updateDeliveryConfirmError: HTTPError;
  +isLoadingCancelDeliveryConfirm: boolean;
  +cancelDeliveryConfirmSuccess: boolean;
  +cancelDeliveryConfirmError: HTTPError;
  +limit: number;
  +page: number;
  +search: string;
  +filterKey: string;
};

type State = {
  orders: {
    [id: string]: {
      selected: boolean,
      editing: boolean,
      trackingNumber: string,
    },
  };
  openModal: boolean;
  lastMileShippingId: number;
  page: number,
  rowsPerPage: number,
};

class DeliveryConfirmTableMain extends Component<Props, State> {
  state = {
    orders: {},
    openModal: false,
    lastMileShippingId: -1,
    page: 0,
    rowsPerPage: 100,
  };

  componentDidMount() {
    const { executeGetDeliveryConfirm } = this.props;
    executeGetDeliveryConfirm();
  }

  componentWillUnmount() {
    const { executeResetDeliveryConfirmState } = this.props;
    executeResetDeliveryConfirmState();
  }

  setStateHelper = (orderId: string, key: string, newState: any) => {
    const { orders } = this.state;
    if (orders[orderId]) {
      this.setState(prevState => update(prevState, {
        orders: {
          [orderId]: {
            [key]: { $set: newState },
          },
        },
      }));
    } else {
      this.setState(prevState => update(prevState, {
        orders: {
          [orderId]: {
            $set: {
              [key]: newState,
            },
          },
        },
      }))
    }
  };

  handleDeliveryNumberChange = (e: Event, orderId: string) => {
    if (e.target instanceof HTMLInputElement) {
      const { value } = e.target;
      this.setStateHelper(orderId, 'trackingNumber', value);
    }
  };

  handleConfirmDeliveryConfirm = (lastMileShippingId?: number) => {
    const { deliveryConfirmOrders, executeConfirmDeliveryConfirm } = this.props;
    let lastMileShippingIds = [];
    if (lastMileShippingId) {
      lastMileShippingIds.push(lastMileShippingId);
    } else {
      const { orders } = this.state;
      lastMileShippingIds = Object.keys(orders).map(key => {
        const order: any = deliveryConfirmOrders.find(order => order.order.id === key);
        return order.lastMileShippingId;
      });
    }
    executeConfirmDeliveryConfirm({ lastMileShippingIds });
    // Reset State
    this.setState({
      orders: {},
      openModal: false,
      lastMileShippingId: -1,
    });
  };

  handleCancelDeliveryConfirm = (message: string) => {
    const { executeCancelDeliveryConfirm } = this.props;
    const { lastMileShippingId } = this.state;
    executeCancelDeliveryConfirm({ lastMileShippingId, message });
    //Reset State
    this.setState({
      orders: {},
      openModal: false,
      lastMileShippingId: -1,
    });
  };

  handleEditing = (orderId: string) => {
    const { orders } = this.state;
    const value = !(!!orders[orderId] && !!orders[orderId].editing);
    if (!value) {
      this.setState(prevState => update(prevState, {
        orders: {
          [orderId]: {
            editing: { $set: value },
            $unset: ['trackingNumber'],
          },
        },
      }));
    } else {
      this.setStateHelper(orderId, 'editing', value);
    }
  };

  handleSelect = (e: Event, orderId: string) => {
    if (e.target instanceof HTMLInputElement) {
      const { checked } = e.target;
      this.setStateHelper(orderId, 'selected', checked);
    }
  };

  handleModal = (lastMileShippingId: number) => {
    this.setState(prevState => update(prevState, {
      openModal: { $set: !prevState.openModal },
      lastMileShippingId: { $set: lastMileShippingId },
    }));
  };

  handleUpdateDeliveryConfirm = (request: { [key: string]: any }) => {
    const { executeUpdateDeliveryConfirm } = this.props;
    executeUpdateDeliveryConfirm(request);
    //Reset State
    this.setState({
      orders: {},
      openModal: false,
      lastMileShippingId: -1,
    });
  };

  handleChangePage = (e: Event, page: number) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({ page: 0, rowsPerPage: parseInt(value) });
  };

  atLeastOneSelected = (): boolean => {
    const { orders } = this.state;
    return Object.keys(orders).some(key => orders[key].selected);
  };

  render() {
    const {
      classes,
      isLoadingGetDeliveryConfirm,
      changeDeliveryConfirmPagination,
      isLoadingConfirmDeliveryConfirm,
      executeGetDeliveryConfirm,
      executeCancelDeliveryConfirm,
      executeConfirmDeliveryConfirm,
      deliveryConfirmOrders,
      isLoadingUpdateDeliveryConfirm,
      isLoadingCancelDeliveryConfirm,
    } = this.props;
    const {
      orders,
      openModal,
      page,
      rowsPerPage,
    } = this.state;
    return (
      <div>
        <Paper>
          <DeliveryConfirmTableToolbar changeDeliveryConfirmPagination={changeDeliveryConfirmPagination}
                                       executeGetDeliveryConfirm={executeGetDeliveryConfirm}
                                       atLeastOneSelected={this.atLeastOneSelected}
                                       handleConfirmDeliveryConfirm={this.handleConfirmDeliveryConfirm}/>
        </Paper>
        <div className={classes.tableWrapper}>
          <DeliveryConfirmTableHead/>
          <DeliveryConfirmTableBody
            deliveryConfirmOrders={deliveryConfirmOrders}
            stateOrders={orders}
            handleEditing={this.handleEditing}
            handleModal={this.handleModal}
            handleDeliveryNumberChange={this.handleDeliveryNumberChange}
            executeCancelDeliveryConfirm={executeCancelDeliveryConfirm}
            executeConfirmDeliveryConfirm={executeConfirmDeliveryConfirm}
            handleConfirmDeliveryConfirm={this.handleConfirmDeliveryConfirm}
            handleUpdateDeliveryConfirm={this.handleUpdateDeliveryConfirm}
            handleSelect={this.handleSelect}
            isLoading={isLoadingGetDeliveryConfirm || isLoadingUpdateDeliveryConfirm || isLoadingConfirmDeliveryConfirm || isLoadingCancelDeliveryConfirm}
            rowsPerPage={rowsPerPage}
            page={page}
          />
          <ReceptionTableDialog open={openModal}
                                handleCancelDeliveryConfirm={this.handleCancelDeliveryConfirm}
                                closeModal={this.handleModal}/>
        </div>
        <Paper>
          <TablePagination
            component="div"
            rowsPerPageOptions={[100, 200, 300, 500]}
            count={deliveryConfirmOrders ? deliveryConfirmOrders.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </div>);
  }
}

DeliveryConfirmTableMain.propTypes = {
  classes: PropTypes.object.isRequired,
  executeGetDeliveryConfirm: PropTypes.func.isRequired,
  executeConfirmDeliveryConfirm: PropTypes.func.isRequired,
  executeUpdateDeliveryConfirm: PropTypes.func.isRequired,
  executeCancelDeliveryConfirm: PropTypes.func.isRequired,
  changeDeliveryConfirmPagination: PropTypes.func.isRequired,
  executeResetDeliveryConfirmState: PropTypes.func.isRequired,
  deliveryConfirmOrders: PropTypes.array,
  totalDeliveryConfirmOrders: PropTypes.number,
  isLoadingGetDeliveryConfirm: PropTypes.bool,
  getDeliveryConfirmSuccess: PropTypes.bool,
  getDeliveryConfirmError: PropTypes.object,
  isLoadingConfirmDeliveryConfirm: PropTypes.bool,
  confirmDeliveryConfirmSuccess: PropTypes.bool,
  confirmDeliveryConfirmError: PropTypes.object,
  isLoadingUpdateDeliveryConfirm: PropTypes.bool,
  updateDeliveryConfirmSuccess: PropTypes.bool,
  updateDeliveryConfirmError: PropTypes.object,
  isLoadingCancelDeliveryConfirm: PropTypes.bool,
  cancelDeliveryConfirmSuccess: PropTypes.bool,
  cancelDeliveryConfirmError: PropTypes.object,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  search: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
};

export default withStyles(tableStyles)(DeliveryConfirmTableMain);
