// @flow
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { endpoints, getPopularProductApi, savePopularProductApi, createPopularProductApi } from './api';

import type { HTTPError } from '../../common/error';

// Actions
const GET_POPULAR_PRODUCT_REQUEST = 'GET_POPULAR_PRODUCT_REQUEST';
const GET_POPULAR_PRODUCT_SUCCESS = 'GET_POPULAR_PRODUCT_SUCCESS';
const GET_POPULAR_PRODUCT_ERROR = 'GET_POPULAR_PRODUCT_ERROR';
const SAVE_POPULAR_PRODUCT_REQUEST = 'SAVE_POPULAR_PRODUCT_REQUEST';
const SAVE_POPULAR_PRODUCT_SUCCESS = 'SAVE_POPULAR_PRODUCT_SUCCESS';
const SAVE_POPULAR_PRODUCT_ERROR = 'SAVE_POPULAR_PRODUCT_ERROR';
const CREATE_POPULAR_PRODUCT_REQUEST = 'CREATE_POPULAR_PRODUCT_REQUEST';
const CREATE_POPULAR_PRODUCT_SUCCESS = 'CREATE_POPULAR_PRODUCT_SUCCESS';
const CREATE_POPULAR_PRODUCT_ERROR = 'CREATE_POPULAR_PRODUCT_ERROR';
const RESET_POPULAR_PRODUCT_STATE = 'RESET_POPULAR_PRODUCT_STATE';


type PopularProductAction = {
  +type: string;
  +popularProduct?: { [key: string]: any };
  +data?: { [key: string]: any };
  +error?: HTTPError;
};

export type PopularProductState = {
  popularProduct?: { [key: string]: any };
  isLoadingGetPopularProduct?: boolean;
  getPopularProductSuccess?: boolean;
  getPopularProductError?: HTTPError;
  isLoadingSavePopularProduct?: boolean;
  savePopularProductSuccess?: boolean;
  savePopularProductError?: HTTPError;
  isLoadingCreatePopularProduct?: boolean;
  createPopularProductSuccess?: boolean;
  createPopularProductError?: HTTPError;
};

// Action Creators
export function executeGetPopularProduct(id: string): PopularProductAction {
  return {
    type: GET_POPULAR_PRODUCT_REQUEST,
    id,
  };
}

export function executeGetPopularProductSuccess(popularProduct: { [key: string]: any }): PopularProductAction {
  return {
    type: GET_POPULAR_PRODUCT_SUCCESS,
    popularProduct,
  };
}

export function executeGetPopularProductError(error: HTTPError): PopularProductAction {
  return {
    type: GET_POPULAR_PRODUCT_ERROR,
    error,
  };
}

export function executeSavePopularProduct(id: number, req: { [key: string]: any }): PopularProductAction {
  return {
    type: SAVE_POPULAR_PRODUCT_REQUEST,
    id,
    req,
  };
}

export function executeSavePopularProductSuccess(popularProduct: { [key: string]: any }): PopularProductAction {
  return {
    type: SAVE_POPULAR_PRODUCT_SUCCESS,
    popularProduct,
  };
}

export function executeSavePopularProductError(error: HTTPError): PopularProductAction {
  return {
    type: SAVE_POPULAR_PRODUCT_ERROR,
    error,
  };
}

export function executeCreatePopularProduct(req: { [key: string]: any }): PopularProductAction {
  return {
    type: CREATE_POPULAR_PRODUCT_REQUEST,
    req,
  };
}

export function executeCreatePopularProductSuccess(popularProduct: { [key: string]: any }): PopularProductAction {
  return {
    type: CREATE_POPULAR_PRODUCT_SUCCESS,
    popularProduct,
  };
}

export function executeCreatePopularProductError(error: HTTPError): PopularProductAction {
  return {
    type: CREATE_POPULAR_PRODUCT_ERROR,
    error,
  };
}

export function executeResetPopularProductState(): PopularProductAction {
  return {
    type: RESET_POPULAR_PRODUCT_STATE,
  };
}

export const actions = {
  GET_POPULAR_PRODUCT_REQUEST,
  GET_POPULAR_PRODUCT_SUCCESS,
  GET_POPULAR_PRODUCT_ERROR,
  executeGetPopularProduct,
  executeGetPopularProductSuccess,
  executeGetPopularProductError,
  executeSavePopularProduct,
  executeSavePopularProductSuccess,
  executeSavePopularProductError,
  executeResetPopularProductState,
  executeCreatePopularProduct,
  executeCreatePopularProductSuccess,
  executeCreatePopularProductError,
};

export const initialState: PopularProductState = {};

// Reducer
export default function reducer(state: PopularProductState = initialState,
                                action: PopularProductAction): PopularProductState {
  switch (action.type) {
    case GET_POPULAR_PRODUCT_REQUEST:
      return {
        ...state,
        isLoadingGetPopularProduct: true,
        getPopularProductSuccess: false,
      };
    case GET_POPULAR_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoadingGetPopularProduct: false,
        getPopularProductSuccess: true,
        popularProduct: action.popularProduct,
      };
    case GET_POPULAR_PRODUCT_ERROR:
      return {
        ...state,
        getPopularProductSuccess: false,
        error: action.error,
      };
    case SAVE_POPULAR_PRODUCT_REQUEST:
      return {
        ...state,
        isLoadingSavePopularProduct: true,
        savePopularProductSuccess: false,
      };
    case SAVE_POPULAR_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoadingSavePopularProduct: false,
        savePopularProductSuccess: true,
        popularProduct: action.popularProduct,
      };
    case SAVE_POPULAR_PRODUCT_ERROR:
      return {
        ...state,
        isLoadingSavePopularProduct: false,
        error: action.error,
      };
    case CREATE_POPULAR_PRODUCT_REQUEST:
      return {
        ...state,
        isLoadingCreatePopularProduct: true,
        createPopularProductSuccess: false,
      };
    case CREATE_POPULAR_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoadingCreatePopularProduct: false,
        createPopularProductSuccess: true,
        popularProduct: action.popularProduct,
      };
    case CREATE_POPULAR_PRODUCT_ERROR:
      return {
        ...state,
        isLoadingCreatePopularProduct: false,
        error: action.error,
      };
    case RESET_POPULAR_PRODUCT_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetPopularProductEpic(action$: ActionsObservable<PopularProductAction>) {
  return action$.ofType(GET_POPULAR_PRODUCT_REQUEST).pipe(
    delay(2000),
    mergeMap(
      ({ id }) => getPopularProductApi(undefined, {
        route: endpoints.GET_POPULAR_PRODUCT.replace(':id', id)
      }).pipe(
        map(data => executeGetPopularProductSuccess(data)),
        catchError((e: HTTPError) => of(executeGetPopularProductError(e))))
    )
  );
}

export function executeSavePopularProductEpic(action$: ActionsObservable<PopularProductAction>) {
  return action$.ofType(SAVE_POPULAR_PRODUCT_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ req, id }) => savePopularProductApi(req, {
        route: endpoints.SAVE_POPULAR_PRODUCT.replace(':id', id)
      }).pipe(
        map((data) => executeSavePopularProductSuccess(data)),
        catchError((e: HTTPError) => of(executeSavePopularProductError(e))))
    )
  );
}

export function executeCreatePopularProductEpic(action$: ActionsObservable<PopularProductAction>) {
  return action$.ofType(CREATE_POPULAR_PRODUCT_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ req }) => createPopularProductApi(req).pipe(
        map((data) => executeCreatePopularProductSuccess(data)),
        catchError((e: HTTPError) => of(executeCreatePopularProductError(e))))
    )
  );
}
