// @flow
import { getCreator, postCreator } from '../../common/http';
import environment from '../../environment';
// import { of } from 'rxjs';
// import { delay } from 'rxjs/operators';

export const endpoints = Object.freeze({
  GET_AVAILABLE_ORDERS: `${environment.apiUrl}/operations/available-orders/list`,
  GET_AVAILABLE_ORDER: `${environment.apiUrl}/operations/available-orders/:orderId`,
  SHIP_AVAILABLE_ORDER: `${environment.apiUrl}/operations/available-orders/:orderId/ship`,
});

export const getAvailableOrdersApi = getCreator(fetch, endpoints.GET_AVAILABLE_ORDERS);

export const getAvailableOrderApi = getCreator(fetch, endpoints.GET_AVAILABLE_ORDER);

export const shipAvailableOrder = postCreator(fetch, endpoints.SHIP_AVAILABLE_ORDER);
// export const shipAvailableOrder = () => of({}).pipe(delay(1000));

