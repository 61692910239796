// @flow
let WEBAPP_ENV = 'local';

if (window.location.hostname === 'backoffice.staging.qempo.com') {
  WEBAPP_ENV = 'staging';
} else if (window.location.hostname === 'backoffice.4da1c7b7.qempo.com') {
  WEBAPP_ENV = 'production';
}

const { name, version } = require('../../package.json');
console.log(name, version);

const env = {
  env: 'local',
  baseUrl: 'http://backoffice.giwiro.com',
  apiUrl: 'http://api.backoffice.giwiro.com',
  webUrl: 'https://staging.qempo.com',
  bitlyToken: "743896dbd9f1279257ebf357388894b7d457d109",
};
if (WEBAPP_ENV === 'local') {
  console.log('WEBAPP_ENV=local');
} else if (WEBAPP_ENV === 'development') {
  console.log('WEBAPP_ENV=development');
  env.env = 'development';
  env.baseUrl = 'https://backoffice.dev.qempo.com';
  env.apiUrl = 'https://backoffice.dev.qempo.com/api';
} else if (WEBAPP_ENV === 'staging') {
  console.log('WEBAPP_ENV=staging');
  env.env = 'staging';
  env.baseUrl = 'https://backoffice.staging.qempo.com';
  env.apiUrl = 'https://backoffice.staging.qempo.com/api';
} else if (WEBAPP_ENV === 'production') {
  console.log('WEBAPP_ENV=production');
  env.env = 'production';
  env.baseUrl = 'https://backoffice.4da1c7b7.qempo.com';
  env.apiUrl = 'https://backoffice.4da1c7b7.qempo.com/api';
  env.webUrl = 'https://www.qempo.com';
}

// export default env;
module.exports = env;
