// @flow
import { Observable } from 'rxjs';
// import { getCreator, postCreator, putCreator, deleteCreator } from '@qempo/qempo-web-common/http';
import environment from '../../environment';
import reviewOrders from './orders';

export const endpoints = Object.freeze({
  GET_REVIEW: `${environment.apiUrl}/operations/review/list`,
  APPROVE_REVIEW: `${environment.apiUrl}/operations/review`,
  CANCEL_REVIEW: `${environment.apiUrl}/operations/review`,
  FIX_REVIEW: `${environment.apiUrl}/operations/review`,
});

export const getReviewApi = (queryParams: any, o: any) => Observable.create(
  (observer) => {
    // let progressOrders;
    // const { filterId } = queryParams;
    const totalReviewOrders = reviewOrders.length;
    /*if (filterId !== '') {
      progressOrders = [apiOrders.find(e => e.id === filterId)];
      if (progressOrders[0] === undefined) {
        progressOrders = [];
      }
    }*/

    observer.next({ reviewOrders, totalReviewOrders });
  });

export const approveReviewApi = (data: any, o: any) => Observable.create(
  (observer) => {
    data.forEach(order => {
      const { id } = order;
      const orderIndex = reviewOrders.findIndex(e => e.id === id);
      reviewOrders.splice(orderIndex, 1);
    });
    observer.next(reviewOrders);
  });

export const cancelReviewApi = (data: any, o: any) => Observable.create(
  (observer) => {
    data.forEach(order => {
      const { id } = order;
      const orderIndex = reviewOrders.findIndex(e => e.id === id);
      reviewOrders.splice(orderIndex, 1);
    });
    observer.next(reviewOrders);
  });

export const fixReviewApi = (data: any, o: any) => Observable.create(
  (observer) => {
    data.forEach(order => {
      const { id } = order;
      const orderIndex = reviewOrders.findIndex(e => e.id === id);
      reviewOrders.splice(orderIndex, 1);
    });
    observer.next(reviewOrders);
  });

export type ReviewRequest = any;
