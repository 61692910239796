// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import update from 'immutability-helper';

import styles from '../styles';
import {
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import CreateIcon from '@material-ui/icons/Create';
import { withStyles } from '@material-ui/core/styles';
import CourierReceptionOrderItemNotes from './CourierReceptionOrderItemNotes';

import type { OrderItem } from '../../../../entities';
import type { OrderItemInputs } from '../CourierReception';
import type { TrackingOperation, StoreOrderIdOperation } from '../../duck';

type Props = {
  +classes: { [key: string]: any },
  +orderItem: OrderItem,
  +orderItemInputs?: OrderItemInputs,
  +handleChangeItemTrackingNumber: (trackingNumber: string, trackingUrl?: string) => void,
  +handleChangeItemStoreOrderId: (storeOrderId: string) => void,
  +updateTrackingNumber: () => void,
  +updateStoreOrderId: () => void,
  +operations: {
    tracking: ?TrackingOperation,
    storeOrderId: ?StoreOrderIdOperation,
  },
};
type State = {
  storeOrderIdText: ?string,
  trackingNumberText: ?string,
  trackingUrlText: ?string,
};

const initialState: State = {
  storeOrderIdText: null,
  trackingNumberText: null,
  trackingUrlText: null,
};

class CourierReceptionOrderItemTracking extends Component<Props, State> {
  state = initialState;
  storeOrderIdSubject$: Subject<string>;
  trackingNumberSubject$: Subject<string>;

  constructor(props: Props) {
    super(props);
    this.storeOrderIdSubject$ = new Subject();
    this.trackingNumberSubject$ = new Subject();
  }

  componentDidMount() {
    const { handleChangeItemTrackingNumber, handleChangeItemStoreOrderId } = this.props;
    // Subscribe Subjects to push user input into the main State with a debounceTime
    this.storeOrderIdSubject$.pipe(debounceTime(500)).subscribe((itemStoreOrderId) => handleChangeItemStoreOrderId(itemStoreOrderId));
    this.trackingNumberSubject$
      .pipe(debounceTime(500))
      .subscribe(({ trackingNumber, trackingUrl }) => handleChangeItemTrackingNumber(trackingNumber, trackingUrl));
  }

  componentDidUpdate(prevProps: Props) {
    // After Tracking Operation Success reset the user Input
    if (prevProps.operations.tracking && this.props.operations.tracking) {
      const prevTracking: TrackingOperation = prevProps.operations.tracking;
      const currentTracking: TrackingOperation = this.props.operations.tracking;
      if (prevTracking.updateOrderItemTrackingSuccess && !currentTracking.updateOrderItemTrackingSuccess) {
        this.setState(
          (prevState) =>
            update(prevState, {
              trackingNumberText: {
                $set: null,
              },
              trackingUrlText: {
                $set: null,
              },
            }),
          () => this.trackingNumberSubject$.next({ trackingNumber: null, trackingUrl: null }),
        );
      }
    }
    // After StoreOrderId Operation Success reset the user Input
    if (prevProps.operations.storeOrderId && this.props.operations.storeOrderId) {
      const prevOrderId: StoreOrderIdOperation = prevProps.operations.storeOrderId;
      const currentOrderId: StoreOrderIdOperation = this.props.operations.storeOrderId;
      if (prevOrderId.updateOrderItemStoreOrderIdSuccess && !currentOrderId.updateOrderItemStoreOrderIdSuccess) {
        this.setState(
          (prevState) =>
            update(prevState, {
              storeOrderIdText: {
                $set: null,
              },
            }),
          () => this.storeOrderIdSubject$.next(null),
        );
      }
    }
  }

  componentWillUnmount(): * {
    if (this.storeOrderIdSubject$) {
      this.storeOrderIdSubject$.unsubscribe();
    }
    if (this.trackingNumberSubject$) {
      this.trackingNumberSubject$.unsubscribe();
    }
  }

  renderInputAdornment(disabled: boolean, isLoading: boolean, success: boolean, error: boolean, onClick: () => void) {
    const { classes } = this.props;
    if (isLoading) {
      return <CircularProgress className={classes.circularProgressAdornment} size={24} thickness={2}/>;
    } else {
      if (success) {
        return <DoneIcon className={classes.doneIcon}/>;
      } else if (error) {
        return <ErrorIcon className={classes.errorIcon}/>;
      } else {
        return (
          <IconButton disabled={disabled} color="primary" className={classes.editIconButton} onClick={onClick}>
            <CreateIcon/>
          </IconButton>
        );
      }
    }
  }

  render() {
    const { orderItem, orderItemInputs, updateTrackingNumber, updateStoreOrderId, operations, classes } = this.props;
    const { storeOrderIdText, trackingNumberText, trackingUrlText } = this.state;
    return (
      <>
        <Card className={classes.topSeparation}>
        <CardContent>
          <Typography component="h5" variant="h5">
            Tracking
          </Typography>
          <FormControl className={classes.trackingFormControl}>
            <InputLabel>Store Order Id</InputLabel>
            <Input
              type="text"
              value={storeOrderIdText ? storeOrderIdText : orderItem.tracking.storeOrderId || ''}
              disabled={
                operations.storeOrderId
                  ? operations.storeOrderId.isLoading ||
                  operations.storeOrderId.updateOrderItemStoreOrderIdSuccess ||
                  !!operations.storeOrderId.error
                  : false
              }
              onChange={(ev: SyntheticInputEvent<HTMLInputElement>) => {
                ev.preventDefault();
                const newValue = ev.target.value;
                this.setState(
                  (prevState) =>
                    update(prevState, {
                      storeOrderIdText: {
                        $set: newValue,
                      },
                    }),
                  () => this.storeOrderIdSubject$.next(newValue),
                );
              }}
              endAdornment={
                <InputAdornment position="end">
                  {this.renderInputAdornment(
                    !orderItemInputs || !orderItemInputs.storeOrderId || orderItemInputs.storeOrderId === orderItem.tracking.storeOrderId,
                    operations.storeOrderId ? operations.storeOrderId.isLoading : false,
                    operations.storeOrderId ? !!operations.storeOrderId.updateOrderItemStoreOrderIdSuccess : false,
                    operations.storeOrderId ? !!operations.storeOrderId.error : false,
                    () => updateStoreOrderId(),
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
          {/*TODO: Duplicate and obtain sellerId*/}
          <FormControl className={classes.trackingFormControl}>
            <InputLabel>Tracking Number</InputLabel>
            <Input
              type="text"
              value={trackingNumberText ? trackingNumberText : orderItem.tracking.trackingNumber || ''}
              disabled={
                operations.tracking
                  ? operations.tracking.isLoading || operations.tracking.updateOrderItemTrackingSuccess || !!operations.tracking.error
                  : false
              }
              onChange={(ev: SyntheticInputEvent<HTMLInputElement>) => {
                ev.preventDefault();
                const newValue = ev.target.value;
                this.setState(
                  (prevState) =>
                    update(prevState, {
                      trackingNumberText: {
                        $set: newValue,
                      },
                    }),
                  () => this.trackingNumberSubject$.next({ trackingNumber: newValue }),
                );
              }}
              endAdornment={
                <InputAdornment position="end">
                  {this.renderInputAdornment(
                    !orderItemInputs ||
                    !orderItemInputs.trackingNumber ||
                    orderItemInputs.trackingNumber === orderItem.tracking.trackingNumber,
                    operations.tracking ? operations.tracking.isLoading : false,
                    operations.tracking ? !!operations.tracking.updateOrderItemTrackingSuccess : false,
                    operations.tracking ? !!operations.tracking.error : false,
                    () => updateTrackingNumber(),
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl className={classes.trackingFormControl}>
            <InputLabel>Tracking Url</InputLabel>
            <Input
              type="text"
              value={trackingUrlText ? trackingUrlText : orderItem.tracking.trackingUrl || ''}
              disabled={
                operations.tracking
                  ? operations.tracking.isLoading || operations.tracking.updateOrderItemTrackingSuccess || !!operations.tracking.error
                  : false
              }
              onChange={(ev: SyntheticInputEvent<HTMLInputElement>) => {
                ev.preventDefault();
                const newValue = ev.target.value;
                this.setState(
                  (prevState) =>
                    update(prevState, {
                      trackingUrlText: {
                        $set: newValue,
                      },
                    }),
                  () => this.trackingNumberSubject$.next({ trackingNumber: trackingNumberText, trackingUrl: newValue }),
                );
              }}
              endAdornment={
                <InputAdornment position="end">
                  {this.renderInputAdornment(
                    !orderItemInputs ||
                    !orderItemInputs.trackingNumber ||
                    orderItemInputs.trackingNumber === orderItem.tracking.trackingNumber,
                    operations.tracking ? operations.tracking.isLoading : false,
                    operations.tracking ? !!operations.tracking.updateOrderItemTrackingSuccess : false,
                    operations.tracking ? !!operations.tracking.error : false,
                    () => updateTrackingNumber(),
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={operations.tracking ? !!operations.tracking.error : false}
            autoHideDuration={6000}
            message={
              <span id="message-id">
                {operations.tracking && operations.tracking.error && operations.tracking.error.data && operations.tracking.error.data.Error
                  ? operations.tracking.error.data.Error.message
                  : 'Generic Error'}
              </span>
            }
          />
        </CardContent>
      </Card>
        <CourierReceptionOrderItemNotes
          orderItemId={orderItem.orderItemId}
          notes={orderItem.tracking ? orderItem.tracking.notes : ''}
          storeOrderId={orderItem.tracking ? orderItem.tracking.storeOrderId : ''} />
      </>
    );
  }
}

CourierReceptionOrderItemTracking.propTypes = {
  classes: PropTypes.object.isRequired,
  orderItem: PropTypes.object.isRequired,
  orderItemInputs: PropTypes.object,
  handleChangeItemTrackingNumber: PropTypes.func.isRequired,
  handleChangeItemStoreOrderId: PropTypes.func.isRequired,
  updateTrackingNumber: PropTypes.func.isRequired,
  updateStoreOrderId: PropTypes.func.isRequired,
  operations: PropTypes.object.isRequired,
};

export default withStyles(styles)(CourierReceptionOrderItemTracking);
