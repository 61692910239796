// @flow
import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { getListsApi } from './api';

import type { RootState } from '../index';
import type { HTTPError } from '../../common/error';
import type { MailingList } from '../../entities/webapp';

// Actions
const GET_LISTS_REQUEST = 'GET_LISTS_REQUEST';
const GET_LISTS_SUCCESS = 'GET_LISTS_SUCCESS';
const GET_LISTS_ERROR = 'GET_LISTS_ERROR';
const DELETE_LISTS_REQUEST = 'DELETE_LISTS_REQUEST';
const DELETE_LISTS_SUCCESS = 'DELETE_LISTS_SUCCESS';
const DELETE_LISTS_ERROR = 'DELETE_LISTS_ERROR';
const CHANGE_LISTS_PAGINATION_REQUEST = 'CHANGE_LISTS_PAGINATION_REQUEST';
const RESET_LISTS_STATE = 'RESET_LISTS_STATE';

type ListsAction = {
  +type: string;
  +lists?: MailingList[];
  +error?: HTTPError;
  +page?: number;
  +size?: number;
  +ord?: string;
  +sort?: string;
  +changes?: { [key: string]: any };
  +totalLists?: number;
};

export type ListsState = {
  isLoadingGetLists?: boolean;
  getListsSuccess?: boolean;
  getListsError?: HTTPError;
  page?: number;
  limit?: number;
  ord?: string;
  sort?: string;
  filterName?: string;
  lists?: MailingList[];
  totalLists?: number;
};

// Action Creators
export function executeGetLists(): ListsAction {
  return {
    type: GET_LISTS_REQUEST,
  };
}

export function executeGetListsSuccessFn(data: { [key: string]: any }): ListsAction {
  return {
    type: GET_LISTS_SUCCESS,
    lists: data.lists,
    totalLists: data.totalLists,
  };
}

export function executeGetListsErrorFn(error: HTTPError): ListsAction {
  return {
    type: GET_LISTS_ERROR,
  };
}

export function executeDeleteLists(id: string): ListsAction {
  return {
    type: DELETE_LISTS_REQUEST,
    id,
  };
}

export function executeDeleteListsSuccess(lists: { [key: string]: any }[]): ListsAction {
  return {
    type: DELETE_LISTS_SUCCESS,
    lists,
  };
}

export function executeDeleteListsError(error: HTTPError): ListsAction {
  return {
    type: DELETE_LISTS_ERROR,
    error,
  };
}

export function changeListsPagination(changes: { [key: string]: any }): ListsAction {
  return {
    type: CHANGE_LISTS_PAGINATION_REQUEST,
    changes,
  };
}

export function executeResetListsState(): ListsAction {
  return {
    type: RESET_LISTS_STATE,
  };
}

export const actions = {
  GET_LISTS_REQUEST,
  GET_LISTS_SUCCESS,
  GET_LISTS_ERROR,
  DELETE_LISTS_REQUEST,
  DELETE_LISTS_SUCCESS,
  DELETE_LISTS_ERROR,
  CHANGE_LISTS_PAGINATION_REQUEST,
  RESET_LISTS_STATE,
  executeGetLists,
  executeGetListsSuccessFn,
  executeGetListsErrorFn,
  executeDeleteLists,
  executeDeleteListsSuccess,
  executeDeleteListsError,
  changeListsPagination,
  executeResetListsState,
};

export const initialState: ListsState = {
  page: 0,
  limit: 20,
  filterName: '',
  ord: 'asc',
  sort: 'id',
};

// Reducer
export default function reducer(state: ListsState = initialState,
                                action: ListsAction): ListsState {
  switch (action.type) {
    case GET_LISTS_REQUEST:
      return {
        ...state,
        isLoadingGetLists: true,
        totalLists: action.totalLists,
      };
    case GET_LISTS_SUCCESS:
      return {
        ...state,
        isLoadingGetLists: false,
        getListsSuccess: true,
        lists: action.lists,
        totalLists: action.totalLists,
      };
    case GET_LISTS_ERROR:
      return {
        getListsError: action.error,
      };
    case CHANGE_LISTS_PAGINATION_REQUEST:
      const data = { ...action.changes };
      return { ...state, ...data };
    case DELETE_LISTS_REQUEST:
      return {
        ...state,
        isLoadingDeleteLists: true,
        deleteListsSuccess: false,
      };
    case DELETE_LISTS_SUCCESS:
      return {
        ...state,
        isLoadingDeleteLists: false,
        deleteListsSuccess: true,
        lists: action.lists,
      };
    case DELETE_LISTS_ERROR:
      return {
        ...state,
        isLoadingDeleteLists: false,
        error: action.error,
      };
    case RESET_LISTS_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetListsEpic(action$: ActionsObservable<ListsAction>, state$: StateObservable<RootState>) {
  return action$.ofType(GET_LISTS_REQUEST).pipe(
    delay(1000),
    mergeMap(
      () => getListsApi({
        page: state$.value.lists.page,
        limit: state$.value.lists.limit,
        ord: state$.value.lists.ord,
        sort: state$.value.lists.sort,
        filterName: state$.value.lists.filterName,
      }).pipe(
        map((data: { [key: string]: any }) => executeGetListsSuccessFn(data)),
        catchError((e: HTTPError) => of(executeGetListsErrorFn(e))))
    )
  );
}

export function changeListsPaginationEpic(action$: ActionsObservable<ListsAction>) {
  return action$.ofType(CHANGE_LISTS_PAGINATION_REQUEST).pipe(
    map(() => executeGetLists()
    )
  );
}


