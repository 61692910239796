// @flow
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { endpoints, getFeaturedProductsApi, deleteFeaturedProductsApi } from './api';

import type { RootState } from '../index';
import type { HTTPError } from '../../common/error';

// Actions
const GET_FEATURED_PRODUCTS_REQUEST = 'GET_FEATURED_PRODUCTS_REQUEST';
const GET_FEATURED_PRODUCTS_SUCCESS = 'GET_FEATURED_PRODUCTS_SUCCESS';
const GET_FEATURED_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';
const CHANGE_FEATURED_PRODUCTS_PAGINATION_REQUEST = 'CHANGE_FEATURED_PRODUCTS_PAGINATION_REQUEST';
const DELETE_FEATURED_PRODUCTS_REQUEST = 'DELETE_FEATURED_PRODUCTS_REQUEST';
const DELETE_FEATURED_PRODUCTS_SUCCESS = 'DELETE_FEATURED_PRODUCTS_SUCCESS';
const DELETE_FEATURED_PRODUCTS_ERROR = 'DELETE_FEATURED_PRODUCTS_ERROR';
const RESET_FEATURED_PRODUCTS_STATE = 'RESET_FEATURED_PRODUCTS_STATE';

type FeaturedProductsAction = {
  +type: string;
  +featuredProducts?: { [key: string]: any }[];
  +totalFeaturedProducts?: number,
  +changes?: { [key: string]: any };
  +error?: HTTPError;
}

export type FeaturedProductsState = {
  isLoadingGetFeaturedProducts?: boolean;
  getFeaturedProductsSuccess?: boolean;
  getFeaturedProductsError?: HTTPError;
  isLoadingCreateFeaturedProducts?: boolean;
  createFeaturedProductsSuccess?: boolean;
  createFeaturedProductsError?: HTTPError;
  isLoadingDeleteFeaturedProducts?: boolean;
  deleteFeaturedProductsSuccess?: boolean;
  deleteFeaturedProductsError?: HTTPError;
  page: number;
  limit: number;
  sort: string;
  ord: string;
  featuredProducts?: { [key: string]: any }[];
  totalFeaturedProducts?: number,
};

// Action Creators
export function executeGetFeaturedProducts(): FeaturedProductsAction {
  return {
    type: GET_FEATURED_PRODUCTS_REQUEST,
  };
}

export function executeGetFeaturedProductsSuccess(data: { [key: string]: any }): FeaturedProductsAction {
  return {
    type: GET_FEATURED_PRODUCTS_SUCCESS,
    featuredProducts: data.featuredProducts,
    totalFeaturedProducts: data.totalFeaturedProducts,
  };
}

export function executeGetFeaturedProductsError(error: HTTPError): FeaturedProductsAction {
  return {
    type: GET_FEATURED_PRODUCTS_ERROR,
    error,
  };
}

export function changeFeaturedProductsPagination(changes: { [key: string]: any }): FeaturedProductsAction {
  return {
    type: CHANGE_FEATURED_PRODUCTS_PAGINATION_REQUEST,
    changes,
  };
}

export function executeDeleteFeaturedProducts(id: string): FeaturedProductsAction {
  return {
    type: DELETE_FEATURED_PRODUCTS_REQUEST,
    id,
  };
}

export function executeDeleteFeaturedProductsSuccess(): FeaturedProductsAction {
  return {
    type: DELETE_FEATURED_PRODUCTS_SUCCESS,
  };
}

export function executeDeleteFeaturedProductsError(error: HTTPError): FeaturedProductsAction {
  return {
    type: DELETE_FEATURED_PRODUCTS_ERROR,
    error,
  };
}

export function executeResetFeaturedProductsState(): FeaturedProductsAction {
  return {
    type: RESET_FEATURED_PRODUCTS_STATE,
  };
}

export const actions = {
  GET_FEATURED_PRODUCTS_REQUEST,
  GET_FEATURED_PRODUCTS_SUCCESS,
  GET_FEATURED_PRODUCTS_ERROR,
  CHANGE_FEATURED_PRODUCTS_PAGINATION_REQUEST,
  DELETE_FEATURED_PRODUCTS_REQUEST,
  DELETE_FEATURED_PRODUCTS_SUCCESS,
  DELETE_FEATURED_PRODUCTS_ERROR,
  executeGetFeaturedProducts,
  executeGetFeaturedProductsSuccess,
  executeGetFeaturedProductsError,
  changeFeaturedProductsPagination,
  executeDeleteFeaturedProducts,
  executeDeleteFeaturedProductsSuccess,
  executeDeleteFeaturedProductsError,
  executeResetFeaturedProductsState,
};

export const initialState: FeaturedProductsState = {
  page: 0,
  limit: 20,
  ord: 'asc',
  sort: 'id',
};

// Reducer
export default function reducer(state: FeaturedProductsState = initialState,
                                action: FeaturedProductsAction): FeaturedProductsState {
  switch (action.type) {
    case GET_FEATURED_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoadingGetFeaturedProducts: true,
        getFeaturedProductsSuccess: false,
      };
    case GET_FEATURED_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoadingGetFeaturedProducts: false,
        getFeaturedProductsSuccess: true,
        featuredProducts: action.featuredProducts,
        totalFeaturedProducts: action.totalFeaturedProducts,
      };
    case GET_FEATURED_PRODUCTS_ERROR:
      return {
        ...state,
        getFeaturedProductsSuccess: false,
        error: action.error,
      };
    case CHANGE_FEATURED_PRODUCTS_PAGINATION_REQUEST:
      const data = { ...action.changes };
      return { ...state, ...data };
    case DELETE_FEATURED_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoadingDeleteFeaturedProducts: true,
        deleteFeaturedProductsSuccess: false,
      };
    case DELETE_FEATURED_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoadingDeleteFeaturedProducts: false,
        deleteFeaturedProductsSuccess: true,
      };
    case DELETE_FEATURED_PRODUCTS_ERROR:
      return {
        ...state,
        isLoadingDeleteFeaturedProducts: false,
        error: action.error,
      };
    case RESET_FEATURED_PRODUCTS_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetFeaturedProductsEpic(action$: ActionsObservable<FeaturedProductsAction>, state$: StateObservable<RootState>) {
  return action$.ofType(GET_FEATURED_PRODUCTS_REQUEST).pipe(
    delay(1000),
    mergeMap(
      () => getFeaturedProductsApi({
        page: state$.value.featuredProducts.page,
        limit: state$.value.featuredProducts.limit,
        ord: state$.value.featuredProducts.ord,
        sort: state$.value.featuredProducts.sort,
      }).pipe(
        map((data: { [key: string]: any }) => executeGetFeaturedProductsSuccess(data)),
        catchError((e: HTTPError) => of(executeGetFeaturedProductsError(e))))
    )
  );
}

export function changeFeaturedProductsPaginationEpic(action$: ActionsObservable<FeaturedProductsAction>) {
  return action$.ofType(CHANGE_FEATURED_PRODUCTS_PAGINATION_REQUEST).pipe(
    map(() => executeGetFeaturedProducts()
    )
  );
}

export function executeDeleteFeaturedProductsEpic(action$: ActionsObservable<FeaturedProductsAction>) {
  return action$.ofType(DELETE_FEATURED_PRODUCTS_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ id }) => deleteFeaturedProductsApi(undefined, {
        route: endpoints.DELETE_FEATURED_PRODUCTS.replace(':id', id),
      }).pipe(
        map(() => executeDeleteFeaturedProductsSuccess()),
        catchError((e: HTTPError) => of(executeDeleteFeaturedProductsError(e))))
    )
  );
}

export function executeGetFeaturedProductsAgain(action$: ActionsObservable<FeaturedProductsAction>) {
  return action$.ofType(DELETE_FEATURED_PRODUCTS_SUCCESS).pipe(
    map(() => executeGetFeaturedProducts()
    )
  );
}
