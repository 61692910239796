// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../../../router';
import AvailableOrdersListContainer from '../containers/AvailableOrdersListContainer';
import AvailableOrderContainer from '../containers/AvailableOrderContainer';

import type { AdminUser } from '../../../entities';
import type { Node } from 'react';

type Props = {
  +authenticated?: boolean;
  +user?: AdminUser;
  +isLoadingGetSession?: boolean;
}

export default function AvailableOrders(props: Props): Node {
  const { user, authenticated, isLoadingGetSession } = props;
  return (
    <Switch>
      <PrivateRoute exact path="/operations/available-orders/list" user={user} authenticated={authenticated}
                    isLoadingGetSession={isLoadingGetSession}
                    render={props => <AvailableOrdersListContainer/>}/>
      <PrivateRoute exact path="/operations/available-orders/:orderId" user={user} authenticated={authenticated}
                    isLoadingGetSession={isLoadingGetSession}
                    render={props => <AvailableOrderContainer/>}/>
    </Switch>
  );
}

AvailableOrders.propTypes = {
  user: PropTypes.object,
  authenticated: PropTypes.bool.isRequired,
  isLoadingGetSession: PropTypes.bool,
};
