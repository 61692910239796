// @flow
import { merge, of, pipe } from 'rxjs';
import { ActionsObservable } from 'redux-observable';
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { loginFirstStepApi, loginSecondStepApi, getSessionApi, logoutApi } from './api'
import { push } from 'connected-react-router';

import type { HTTPError } from '../../common/error';
import type { AdminUser } from '../../entities/admin-user';

type AuthAction = {
  +type: string;
  +user?: AdminUser;
  +firstName?: string;
  +lastName?: string;
  +email?: string;
  +password?: string;
  +error?: HTTPError;
  +token?: string;
};

export type AuthState = {
  isLoadingGetSession?: boolean;
  getSessionSuccess?: boolean;
  getSessionError?: boolean;
  isLoadingLoginFirstStep?: boolean;
  loginFirstStepSuccess?: boolean;
  loginFirstStepError?: HTTPError;
  isLoadingLoginSecondStep?: boolean;
  loginSecondStepSuccess?: boolean;
  loginSecondStepError?: HTTPError;
  // Both are writable
  authenticated?: boolean;
  user?: AdminUser;
};

// Actions
const AUTH_GET_SESSION_REQUEST = 'AUTH_GET_SESSION_REQUEST';
const AUTH_GET_SESSION_SUCCESS = 'AUTH_GET_SESSION_SUCCESS';
const AUTH_GET_SESSION_ERROR = 'AUTH_GET_SESSION_ERROR';
const AUTH_LOGIN_FIRST_STEP_REQUEST = 'AUTH_LOGIN_FIRST_STEP_REQUEST';
const AUTH_LOGIN_FIRST_STEP_SUCCESS = 'AUTH_LOGIN_FIRST_STEP_SUCCESS';
const AUTH_LOGIN_FIRST_STEP_ERROR = 'AUTH_LOGIN_FIRST_STEP_ERROR';
const AUTH_LOGIN_SECOND_STEP_REQUEST = 'AUTH_LOGIN_SECOND_STEP_REQUEST';
const AUTH_LOGIN_SECOND_STEP_SUCCESS = 'AUTH_LOGIN_SECOND_STEP_SUCCESS';
const AUTH_LOGIN_SECOND_STEP_ERROR = 'AUTH_LOGIN_SECOND_STEP_ERROR';
const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

//Action Creators
export function executeGetSession(): AuthAction {
  return {
    type: AUTH_GET_SESSION_REQUEST,
  };
}

export function executeGetSessionSuccessFn(user: AdminUser): AuthAction {
  return {
    type: AUTH_GET_SESSION_SUCCESS,
    user,
  };
}

export function executeGetSessionErrorFn(error: HTTPError): AuthAction {
  return {
    type: AUTH_GET_SESSION_ERROR,
    error,
  };
}

export function loginFirstStep(email: string, password: string): AuthAction {
  return {
    type: AUTH_LOGIN_FIRST_STEP_REQUEST,
    email,
    password,
  };
}

export function loginFirstStepSuccessFn(user: AdminUser): AuthAction {
  return {
    type: AUTH_LOGIN_FIRST_STEP_SUCCESS,
    user,
  };
}

export function loginFirstStepErrorFn(error: HTTPError): AuthAction {
  return {
    type: AUTH_LOGIN_FIRST_STEP_ERROR,
    error,
  };
}

export function loginSecondStep(token: string): AuthAction {
  return {
    type: AUTH_LOGIN_SECOND_STEP_REQUEST,
    token,
  };
}

export function loginSecondStepSuccessFn(user: AdminUser): AuthAction {
  return {
    type: AUTH_LOGIN_SECOND_STEP_SUCCESS,
    user,
  };
}

export function loginSecondStepErrorFn(error: HTTPError): AuthAction {
  return {
    type: AUTH_LOGIN_SECOND_STEP_ERROR,
    error,
  };
}


export function logout(): AuthAction {
  return {
    type: AUTH_LOGOUT_REQUEST,
  };
}

export function logoutSuccess(): AuthAction {
  return {
    type: AUTH_LOGOUT_SUCCESS,
  };
}

export const actions = {
  AUTH_GET_SESSION_REQUEST,
  AUTH_GET_SESSION_SUCCESS,
  AUTH_GET_SESSION_ERROR,
  AUTH_LOGIN_FIRST_STEP_REQUEST,
  AUTH_LOGIN_FIRST_STEP_SUCCESS,
  AUTH_LOGIN_FIRST_STEP_ERROR,
  AUTH_LOGIN_SECOND_STEP_REQUEST,
  AUTH_LOGIN_SECOND_STEP_SUCCESS,
  AUTH_LOGIN_SECOND_STEP_ERROR,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  executeGetSession,
  executeGetSessionSuccessFn,
  executeGetSessionErrorFn,
  loginFirstStep,
  loginFirstStepSuccessFn,
  loginFirstStepErrorFn,
  loginSecondStep,
  loginSecondStepSuccessFn,
  loginSecondStepErrorFn,
  logout,
  logoutSuccess,
};

export const initialState: AuthState = {
  isLoadingGetSession: true,
  authenticated: false,
};
// Reducer
export default function reducer(state: AuthState = initialState,
                                action: AuthAction): AuthState {
  switch (action.type) {
    case AUTH_GET_SESSION_REQUEST:
      return {
        ...state,
        isLoadingGetSession: true,
      };
    case AUTH_GET_SESSION_SUCCESS:
      return {
        ...state,
        isLoadingGetSession: false,
        getSessionSuccess: true,
        user: action.user,
        authenticated: true,
      };
    case AUTH_GET_SESSION_ERROR:
      return {
        ...state,
        isLoadingGetSession: false,
        authError: action.error,
        authenticated: false,
      };
    case AUTH_LOGIN_FIRST_STEP_REQUEST:
      return {
        ...state,
        authenticated: false,
        isLoadingLoginFirstStep: true,
      };
    case AUTH_LOGIN_FIRST_STEP_SUCCESS:
      return {
        ...state,
        user: action.user,
        isLoadingLoginFirstStep: false,
        authenticated: true,
        loginFirstStepSuccess: true,
        loginSecondStepSuccess: true,
      };
    case AUTH_LOGIN_FIRST_STEP_ERROR:
      return {
        ...state,
        loginFirstStepError: action.error,
        isLoadingLoginFirstStep: false,
        authenticated: false,
      };
    case AUTH_LOGIN_SECOND_STEP_REQUEST:
      return {
        ...state,
        isLoadingLoginSecondStep: true,
        authenticated: false,
      };
    case AUTH_LOGIN_SECOND_STEP_SUCCESS:
      return {
        ...state,
        user: action.user,
        isLoadingLoginSecondStep: true,
        authenticated: true,
        loginSecondStepSuccess: true,
      };
    case AUTH_LOGIN_SECOND_STEP_ERROR:
      return {
        ...state,
        loginSecondStepError: action.error,
        authenticated: false,
      };
    case AUTH_LOGOUT_REQUEST:
    case AUTH_LOGOUT_SUCCESS:
      return {
        authenticated: false,
      };
    default:
      return state;
  }
}

// Epics
export function executeGetSessionEpic(action$: ActionsObservable<AuthAction>) {
  return action$.ofType(AUTH_GET_SESSION_REQUEST).pipe(
    delay(200),
    mergeMap(
      () => getSessionApi().pipe(
        map((user) => executeGetSessionSuccessFn(user)),
        catchError((e: HTTPError) => of(executeGetSessionErrorFn(e))))
    )
  );
}

export function loginFirstStepEpic(action$: ActionsObservable<AuthAction>) {
  return action$.ofType(AUTH_LOGIN_FIRST_STEP_REQUEST).pipe(
    mergeMap(
      ({ email, password }) => loginFirstStepApi({ email, password }).pipe(
        map((response: AdminUser) => loginFirstStepSuccessFn(response)),
        catchError((e: HTTPError) => of(loginFirstStepErrorFn(e))))
    )
  );
}

export function loginSecondStepEpic(action$: ActionsObservable<AuthAction>) {
  return action$.ofType(AUTH_LOGIN_SECOND_STEP_REQUEST).pipe(
    mergeMap(
      ({ token }) => loginSecondStepApi({ token }).pipe(
        map((response: AdminUser) => loginSecondStepSuccessFn(response)),
        catchError((e: HTTPError) => of(loginSecondStepErrorFn(e))))
    )
  );
}

export function logoutEpic(action$: ActionsObservable<AuthAction>) {
  return action$.ofType(AUTH_LOGOUT_REQUEST).pipe(
    mergeMap(() => pipe(
      merge(
        of(push('/')),
        logoutApi().pipe(
          map(() => logoutSuccess()),
          catchError((e: HTTPError) => of(logoutSuccess()))
        )
      )
    ))
  );
}
