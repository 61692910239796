// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import DeliveryConfirmTableMain from '../components/DeliveryConfirmTableMain';
import { actions as DeliveryConfirmActions } from '../duck';
import { actions as LocalValuesActions } from '../../local-values/duck';
import { withRouter } from 'react-router-dom';

import type { RootState } from '../../index';

const mapStateToProps = (state: RootState) => {
  return {
    page: state.deliveryConfirm.page,
    limit: state.deliveryConfirm.limit,
    search: state.deliveryConfirm.search,
    filterKey: state.deliveryConfirm.filterKey,
    deliveryConfirmOrders: state.deliveryConfirm.deliveryConfirmOrders,
    totalDeliveryConfirmOrders: state.deliveryConfirm.totalDeliveryConfirmOrders,
    isLoadingGetDeliveryConfirm: state.deliveryConfirm.isLoadingGetDeliveryConfirm,
    getDeliveryConfirmSuccess: state.deliveryConfirm.getDeliveryConfirmSuccess,
    getDeliveryConfirmError: state.deliveryConfirm.getDeliveryConfirmError,
    isLoadingConfirmDeliveryConfirm: state.deliveryConfirm.isLoadingConfirmDeliveryConfirm,
    confirmDeliveryConfirmSuccess: state.deliveryConfirm.confirmDeliveryConfirmSuccess,
    confirmDeliveryConfirmError: state.deliveryConfirm.confirmDeliveryConfirmError,
    isLoadingUpdateDeliveryConfirm: state.deliveryConfirm.isLoadingUpdateDeliveryConfirm,
    updateDeliveryConfirmSuccess: state.deliveryConfirm.updateDeliveryConfirmSuccess,
    updateDeliveryConfirmError: state.deliveryConfirm.updateDeliveryConfirmError,
    isLoadingCancelDeliveryConfirm: state.deliveryConfirm.isLoadingCancelDeliveryConfirm,
    cancelDeliveryConfirmSuccess: state.deliveryConfirm.cancelDeliveryConfirmSuccess,
    cancelDeliveryConfirmError: state.deliveryConfirm.cancelDeliveryConfirmError,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return bindActionCreators({ ...DeliveryConfirmActions, ...LocalValuesActions }, dispatch);
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryConfirmTableMain));
