// @flow
import { Observable } from 'rxjs';
// import { getCreator, postCreator, putCreator, deleteCreator } from '@qempo/qempo-web-common/http';
import environment from '../../environment';
import apiPopularProducts from '../popular-products/popularProducts';

export const endpoints = Object.freeze({
  GET_POPULAR_PRODUCT: `${environment.apiUrl}/administrative/featured-product/:id`,
  SAVE_POPULAR_PRODUCT: `${environment.apiUrl}/administrative/featured-product/:id`,
  CREATE_POPULAR_PRODUCT: `${environment.apiUrl}/administrative/featured-product/new`,
});

export const getPopularProductApi = (queryParams: any, o: any) => Observable.create(
  (observer) => {
    // const id = parseInt(o.route.substr(64), 10);
    const product = apiPopularProducts[0];
    observer.next(product);
  });

export const savePopularProductApi = (data: any, o: any) => Observable.create(
  (observer) => {
    const id = parseInt(o.route.substr(64), 10);
    const product = apiPopularProducts.find(e => e.id === id);
    product.name = data.name !== undefined ? data.name : product.name;
    product.visibility = data.visibility !== undefined ? data.visibility : product.visibility;
    product.url = data.url !== undefined ? data.url : product.url;
    product.image = data.image !== undefined ? data.image : product.image;
    product.description = data.description !== undefined ? data.description : product.description;
    product.price = data.price !== undefined ? data.price : product.price;
    product.quantity = data.quantity !== undefined ? data.quantity : product.quantity;
    product.category = data.category !== undefined ? data.category : product.category;
    product.weight = data.weight !== undefined ? data.weight : product.weight;
    observer.next(product);
  });

export const createPopularProductApi = (data: any, o: any) => Observable.create(
  (observer) => {
    apiPopularProducts.push(data);
    const product = apiPopularProducts[apiPopularProducts.length - 1];
    observer.next(product);
  });

export type UpdatePopularProductRequest = {
  id?: number;
  name?: string;
  url?: string;
  image?: string;
  description?: string;
  price?: string;
  category?: string;
  quantity?: number;
  weight?: string;
};
