// @flow
import { Observable, Observer } from 'rxjs';
import { getCreator/*, postCreator, putCreator, deleteCreator */ } from '../../common/http';
import environment from '../../environment';

export const endpoints = Object.freeze({
  SEARCH_COUNTRY: `${environment.apiUrl}/geography/countries`,
  GET_ITEM_CATEGORIES: `${environment.apiUrl}/meta/item-categories`,
  GET_COUPON_TYPES: `${environment.apiUrl}/meta/coupon/types`,
  GET_COUPON_DISCOUNT_TYPES: `${environment.apiUrl}/meta/coupon/discount-types`,
  GET_COUPON_RULES: `${environment.apiUrl}/meta/coupon/rules`,
  GET_LIST_RULES: `${environment.apiUrl}/meta/list/rules`,
  GET_CANCELLATION_MOTIVES: `${environment.apiUrl}/meta/cancellation-motives`,
  GET_DELIVERY_COURIERS: `${environment.apiUrl}/meta/delivery-couriers/PE`,
  GET_COURIERS: `${environment.apiUrl}/meta/couriers`,
  GET_SERVICE_TIERS: `${environment.apiUrl}/meta/service-tiers`,
});

export const getCancellationMotivesApi = getCreator(fetch, endpoints.GET_CANCELLATION_MOTIVES);

export const countries = [{ type: 'country', code: 'US', code3: 'USA', name: 'United States' }, {
  type: 'country',
  code: 'PE',
  code3: 'PER',
  name: 'Peru',
}];

export const getDeliveryCouriersApi = getCreator(fetch, endpoints.GET_DELIVERY_COURIERS);

export const getCouriersApi = getCreator(fetch, endpoints.GET_COURIERS);

export const searchCountryApi = (queryParams: any, o: any) => Observable.create(
  (observer: Observer) => {
    observer.next(countries);
  },
);

export const getItemCategoriesApi = getCreator(fetch, endpoints.GET_ITEM_CATEGORIES);

export const getServiceTiersApi = getCreator(fetch, endpoints.GET_SERVICE_TIERS);

export const getCouponTypesApi = (queryParams: any, o: any) => Observable.create(
  (observer: Observer) => {
    const couponTypes = {
      'PERSONAL': 1,
      'CAMPAIGN': 2,
    };
    observer.next(couponTypes);
  },
);

export const getCouponDiscountTypesApi = (queryParams: any, o: any) => Observable.create(
  (observer: Observer) => {
    const couponDiscountTypes = {
      "FIXED": 1,
      "RELATIVE": 2,
    };
    observer.next(couponDiscountTypes);
  },
);

export const getCouponRulesApi = (queryParams: any, o: any) => Observable.create(
  (observer: Observer) => {
    const couponRules = {
      "UrlMatchesValidator": 1,
      "MaxValidUsesValidator": 2,
      "MaxValidUsesPerUserValidator": 3,
      "DaysNoOrderValidator": 4,
      "DaysNoPurchaseValidator": 5,
      "FirstPurchaseValidator": 6,
    };
    observer.next(couponRules);
  },
);

export const getListRulesApi = (queryParams: any, o: any) => Observable.create(
  (observer: Observer) => {
    const listRules = {
      "HasAtLeastSomeOrders": 1,
      "HasAtMaxSomeOrders": 2,
      "ScrappedItemsByKeywords": 3,
      "ScrappedItemsByKeywordsLists": 4,
    };
    observer.next(listRules);
  },
);

export const getOrderStatesApi = (queryParams: any, o: any) => Observable.create(
  (observer: Observer) => {
    const orderStates = {
      "IN_QEMPO": 1,
      "SHIPPING": 2,
    };
    observer.next(orderStates);
  },
);

export type GetDeliveryCouriersResponse = {
  id: number;
  name: string;
  defaultShippingSize: string;
  shippingSizes: string[];
};
