// @flow
import { connect } from 'react-redux';
import AvailableOrders from '../components/AvailableOrders';
import { withRouter } from 'react-router-dom';

import type { RootState } from "../../index";

const mapStateToProps = (state: RootState) => {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
    isLoadingGetSession: state.auth.isLoadingGetSession,
  }
};

export default withRouter(connect(
  mapStateToProps,
)(AvailableOrders));
