// @flow
import React, { Component, Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PrivateRoute } from './router';
import { withRouter } from 'react-router-dom';

import LoginContainer from './modules/auth/containers/LoginContainer';
import MainLayoutContainer from './modules/main-layout/containers/MainLayoutContainer';
// import FeaturedProductsContainer from './modules/featured-products/containers/FeaturedProductsContainer';
// import FeaturedProductContainerInfo from './modules/featured-product/containers/FeaturedProductContainerInfo';
// import FeaturedProductContainerNew from './modules/featured-product/containers/FeaturedProductContainerNew';
// import PopularProductsContainer from './modules/popular-products/containers/PopularProductsContainer';
// import PopularProductContainerInfo from './modules/popular-product/containers/PopularProductContainerInfo';
// import PopularProductContainerNew from './modules/popular-product/containers/PopularProductContainerNew';
// import CouponsContainer from './modules/coupons/containers/CouponsContainer';
// import CouponContainerInfo from './modules/coupon/containers/CouponContainerInfo';
// import CouponContainerNew from './modules/coupon/containers/CouponContainerNew';
// import OrdersContainer from './modules/orders/containers/OrdersContainer';
// import OrderContainer from './modules/order/containers/OrderContainer';
// import ListsContainer from './modules/mailing-lists/containers/ListsContainer';
// import ListContainerNew from './modules/mailing-list/containers/ListContainerNew';
// import ListContainerInfo from './modules/mailing-list/containers/ListContainerInfo';
// import RefundsContainer from './modules/refunds/containers/RefundsContainer';
// import ProgressOrdersContainer from './modules/progress-orders/containers/ProgressOrdersContainer';
// import ShipProductsContainer from './modules/ship-products/containers/ShipProductsContainer';
import ShippingContainer from './modules/shipping/containers/ShippingContainer';
import DeliveryConfirmContainer from './modules/delivery-confirm/containers/DeliveryConfirmContainer';
import ReceptionContainer from './modules/reception/containers/ReceptionContainer';
// import PendingContainer from './modules/pending/containers/PendingContainer';
// import ReviewContainer from './modules/review/containers/ReviewContainer';
import AvailableOrdersContainer from './modules/available-orders/containers/AvailableOrdersContainer';
import CourierTrackingContainer from './modules/courier-tracking/containers/CourierTrackingContainer';
import CourierReceptionContainer from './modules/courier-reception/containers/CourierReceptionContainer';
import ProductCrafterContainer from './modules/product-crafter/containers/ProductCrafterContainer';
//import BagCrafterContainer from './modules/bag-crafter/containers/BagCrafterContainer';
import { actions as AuthActions } from './modules/auth/duck';
import { actions as LocalValuesActions } from './modules/local-values/duck';

import type { AdminUser } from './entities';
import type { RootState } from './modules';

type Props = {
  +authenticated?: boolean;
  +user?: AdminUser;
  +getSessionSuccess?: boolean;
  +loginSecondStepSuccess?: boolean;
  +executeGetSession: () => void;
  +isLoadingGetSession?: boolean;
  +executeGetCountries: () => void;
  +executeResetLocalValues: () => void;
};

const theme = createMuiTheme({
  palette: {
    primary: { main: '#11aefa', contrastText: '#fff' },
    secondary: { main: '#f79020', contrastText: '#747474' },
  },
  typography: {
    useNextVariants: true,
    secondary: {
      fontSize: '0.5em',
    },
  },
});


class App extends Component<Props> {
  componentDidMount() {
    const { executeGetSession, executeGetCountries } = this.props;
    executeGetSession();
    executeGetCountries();
  };

  render() {
    const { user, authenticated, getSessionSuccess, isLoadingGetSession, loginSecondStepSuccess } = this.props;
    return (
      <Fragment>
        <CssBaseline/>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MuiThemeProvider theme={theme}>
            <Router>
              <Switch>
                <Route exact
                       path="/"
                       render={props => (user && authenticated && loginSecondStepSuccess) || getSessionSuccess ?
                         <MainLayoutContainer/> :
                         <LoginContainer/>}/>
                {/*<PrivateRoute exact path="/administrative/featured-product/list" user={user}
                              authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Featured Products">
                                  <FeaturedProductsContainer/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/administrative/featured-product/new" user={user}
                              authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Featured Products">
                                  <FeaturedProductContainerNew/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/administrative/featured-product/:id" user={user}
                              authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Featured Products">
                                  <FeaturedProductContainerInfo/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/administrative/popular-product/list" user={user}
                              authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Popular Products">
                                  <PopularProductsContainer/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/administrative/popular-product/new" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Popular Products">
                                  <PopularProductContainerNew/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/administrative/popular-product/:id" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Popular Products">
                                  <PopularProductContainerInfo/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/administrative/coupon/list" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Coupons">
                                  <CouponsContainer/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/administrative/coupon/new" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Coupons">
                                  <CouponContainerNew/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/administrative/coupon/:id" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Coupons">
                                  <CouponContainerInfo/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/administrative/order/list" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Orders">
                                  <OrdersContainer/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/administrative/transaction/:id" user={user}
                              authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Orders">
                                  <OrderContainer/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/mailing/list/list" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Lists">
                                  <ListsContainer/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/mailing/list/new" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Lists">
                                  <ListContainerNew/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/mailing/list/:id" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Lists">
                                  <ListContainerInfo/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/operations/refunds/list" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Refunds">
                                  <RefundsContainer/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/operations/progress-orders/list" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Progress Orders">
                                  <ProgressOrdersContainer/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/operations/pending/list" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Pending">
                                  <PendingContainer/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/operations/review/list" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Under Review">
                                  <ReviewContainer/>
                                </MainLayoutContainer>)}/>*/}
                {/*<PrivateRoute exact path="/operations/progress-orders/ship-products/:id" user={user}
                              authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Progress Orders">
                                  <ShipProductsContainer/>
                                </MainLayoutContainer>)}/>*/}
                <PrivateRoute exact path="/operations/shipping" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Shipping">
                                  <ShippingContainer/>
                                </MainLayoutContainer>)}/>
                <PrivateRoute exact path="/operations/delivery-confirm" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Delivery Confirm">
                                  <DeliveryConfirmContainer/>
                                </MainLayoutContainer>)}/>
                <PrivateRoute exact path="/operations/reception" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Reception">
                                  <ReceptionContainer/>
                                </MainLayoutContainer>)}/>
                <PrivateRoute path="/operations/courier-reception" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Courier reception">
                                  <CourierReceptionContainer/>
                                </MainLayoutContainer>)}/>
                <PrivateRoute path="/operations/available-orders" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Available orders">
                                  <AvailableOrdersContainer/>
                                </MainLayoutContainer>)}/>
                <PrivateRoute path="/operations/courier-tracking" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="Courier tracking">
                                  <CourierTrackingContainer/>
                                </MainLayoutContainer>)}/>
                <PrivateRoute exact path="/webtools/product-crafter" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="ProductCrafter">
                                  <ProductCrafterContainer/>
                                </MainLayoutContainer>)}/>
                {/*<PrivateRoute exact path="/webtools/bag-crafter" user={user} authenticated={authenticated}
                              isLoadingGetSession={isLoadingGetSession}
                              render={props => (
                                <MainLayoutContainer label="BagCrafter">
                                  <BagCrafterContainer/>
                                </MainLayoutContainer>)}/>*/}
              </Switch>
            </Router>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </Fragment>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
    isLoadingGetSession: state.auth.isLoadingGetSession,
    getSessionSuccess: state.auth.getSessionSuccess,
    isLoadingLoginFirstStep: state.auth.isLoadingLoginFirstStep,
    loginFirstStepSuccess: state.auth.loginFirstStepSuccess,
    isLoadingLoginSecondStep: state.auth.isLoadingLoginSecondStep,
    loginSecondStepSuccess: state.auth.loginSecondStepSuccess,
  };
}

function mapDispatchToProps(dispatch: Function) {
  return bindActionCreators({ ...AuthActions, ...LocalValuesActions }, dispatch);
}

App.propTypes = {
  authenticated: PropTypes.bool,
  user: PropTypes.object,
  getSessionSuccess: PropTypes.bool,
  loginSecondStepSuccess: PropTypes.bool,
  executeGetSession: PropTypes.func.isRequired,
  executeGetCountries: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
