// @flow
import { getCreator, postCreator } from '../../common/http';
import environment from '../../environment';

export const endpoints = Object.freeze({
  UPLOAD_INVOICE_FILE: `${environment.apiUrl}/stored-file/upload/file`,
  GET_COURIERS_ORDERS: `${environment.apiUrl}/operations/courier-tracking/list`,
  UPDATE_ORDER_ITEM_INVOICE: `${environment.apiUrl}/operations/courier-tracking/:orderItemId/invoice`,
  UPDATE_ORDER_ITEM_TRACKING: `${environment.apiUrl}/operations/courier-tracking/:orderItemId/tracking`,
  UPDATE_ORDER_ITEM_TRACKING_ORDER_ID: `${environment.apiUrl}/operations/courier-tracking/:orderItemId/tracking_order_id`,
  CANCEL_RESERVATION: `${environment.apiUrl}/operations/courier-tracking/cancel-shipment/:shipmentId`,
  RECEIVE_ORDER_ITEM: `${environment.apiUrl}/operations/courier-tracking/:orderItemId/receive`,
  MISSING_TRACKING: `${environment.apiUrl}/operations/available-orders/:orderId/missing-tracking`,
  GET_TRACKING_NOTIFICATION_STATUS: `${environment.apiUrl}/operations/available-orders/:orderId/notify-missing-tracking`,
  POST_TRACKING_NOTIFICATION_STATUS: `${environment.apiUrl}/operations/available-orders/:orderId/notify-missing-tracking`,
  SET_REAL_COST: `${environment.apiUrl}/operations/available-orders/invoice/:invoiceId/actual-total`,
});

export const uploadInvoiceFileApi = postCreator(fetch, endpoints.UPLOAD_INVOICE_FILE);
export const getCouriersOrdersApi = getCreator(fetch, endpoints.GET_COURIERS_ORDERS);
export const updateOrderItemInvoiceApi = postCreator(fetch, endpoints.UPDATE_ORDER_ITEM_INVOICE);
export const updateOrderItemTrackingApi = postCreator(fetch, endpoints.UPDATE_ORDER_ITEM_TRACKING);
export const updateOrderItemStoreOrderIdApi = postCreator(fetch, endpoints.UPDATE_ORDER_ITEM_TRACKING_ORDER_ID);
export const cancelReservationApi = postCreator(fetch, endpoints.CANCEL_RESERVATION);
export const receiveOrderItemApi = postCreator(fetch, endpoints.RECEIVE_ORDER_ITEM);
export const missingTrackingApi = postCreator(fetch, endpoints.MISSING_TRACKING);
export const getTrackingNotificationStatusApi = getCreator(fetch, endpoints.GET_TRACKING_NOTIFICATION_STATUS);
export const postTrackingNotificationStatusApi = postCreator(fetch, endpoints.POST_TRACKING_NOTIFICATION_STATUS);
export const setRealCostApi = postCreator(fetch, endpoints.SET_REAL_COST);
