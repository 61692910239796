// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Provider from 'react-redux/es/components/Provider';
import { ConnectedRouter } from 'connected-react-router';
import configureStore from './store/configureStore';
import { history } from './store/configureStore';
// import * as serviceWorker from './serviceWorker';

import './index.css';

const store = configureStore();

const main = (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App/>
        </ConnectedRouter>
    </Provider>);

const root = document.getElementById('root');

if (root !== null) {
    ReactDOM.render(main, root);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
