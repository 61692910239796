// @flow
import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { getCouponsApi  } from './api';

import type { RootState } from '../index';
import type { HTTPError } from '../../common/error';
import type { Coupon } from '../../entities/webapp';

// Actions
const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST';
const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
const GET_COUPONS_ERROR = 'GET_COUPONS_ERROR';
const CHANGE_COUPONS_PAGINATION_REQUEST = 'CHANGE_COUPONS_PAGINATION_REQUEST';
const RESET_COUPONS_STATE = 'RESET_COUPONS_STATE';

type CouponsAction = {
  +type: string;
  +coupons?: Coupon [];
  +error?: HTTPError;
  +page?: number;
  +limit?: number;
  +ord?: string;
  +sort?: string;
  +changes?: { [key: string]: any };
  +totalCoupons?: number;
};

export type CouponsState = {
  isLoadingGetCoupons?: boolean;
  getCouponsSuccess?: boolean;
  getCouponsError?: HTTPError;
  page?: number;
  limit?: number;
  ord?: string;
  sort?: string;
  filter?: string;
  coupons?: Coupon [];
  totalCoupons?: number;
};

// Action Creators
export function executeGetCoupons(): CouponsAction {
  return {
    type: GET_COUPONS_REQUEST,
  };
}

export function executeGetCouponsSuccessFn(data: { [key: string]: any }): CouponsAction {
  return {
    type: GET_COUPONS_SUCCESS,
    coupons: data.coupons,
    totalCoupons: data.totalCoupons,
  };
}

export function executeGetCouponsErrorFn(error: HTTPError): CouponsAction {
  return {
    type: GET_COUPONS_ERROR,
  };
}

export function changeCouponsPagination(changes: { [key: string]: any }): CouponsAction {
  return {
    type: CHANGE_COUPONS_PAGINATION_REQUEST,
    changes,
  };
}

export function executeResetCouponsState(): CouponsAction {
  return {
    type: RESET_COUPONS_STATE,
  };
}

export const actions = {
  GET_COUPONS_REQUEST,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_ERROR,
  executeGetCoupons,
  executeGetCouponsSuccessFn,
  executeGetCouponsErrorFn,
  changeCouponsPagination,
  executeResetCouponsState,
};

export const initialState: CouponsState = {
  page: 0,
  limit: 20,
  filterId: '',
  ord: 'asc',
  sort: 'id',
};

// Reducer
export default function reducer(state: CouponsState = initialState,
                                action: CouponsAction): CouponsState {
  switch (action.type) {
    case GET_COUPONS_REQUEST:
      return {
        ...state,
        isLoadingGetCoupons: true,
        totalCoupons: action.totalCoupons,
      };
    case GET_COUPONS_SUCCESS:
      return {
        ...state,
        isLoadingGetCoupons: false,
        getCouponsSuccess: true,
        coupons: action.coupons,
        totalCoupons: action.totalCoupons,
      };
    case GET_COUPONS_ERROR:
      return {
        getCouponsError: action.error,
      };
    case CHANGE_COUPONS_PAGINATION_REQUEST:
      const data = { ...action.changes };
      return { ...state, ...data };
    case RESET_COUPONS_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetCouponsEpic(action$: ActionsObservable<CouponsAction>, state$: StateObservable<RootState>) {
  return action$.ofType(GET_COUPONS_REQUEST).pipe(
    delay(1000),
    mergeMap(
      () => getCouponsApi({
        page: state$.value.coupons.page,
        limit: state$.value.coupons.limit,
        ord: state$.value.coupons.ord,
        sort: state$.value.coupons.sort,
        filterId: state$.value.coupons.filterId,
      }).pipe(
        map((data: { [key: string]: any }) => executeGetCouponsSuccessFn(data)),
        catchError((e: HTTPError) => of(executeGetCouponsErrorFn(e))))
    )
  );
}

export function changeCouponsPaginationEpic(action$: ActionsObservable<CouponsAction>) {
  return action$.ofType(CHANGE_COUPONS_PAGINATION_REQUEST).pipe(
    map(() => executeGetCoupons()
    )
  );
}


