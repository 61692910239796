// @flow
import { map, mergeMap, catchError, debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import {
  getAvailableOrderApi,
  getAvailableOrdersApi,
  shipAvailableOrder,
  endpoints,
} from './api';
import { ofType } from 'redux-observable';

import type { HTTPError } from "../../common/error";
import type { Order, OrderItem } from '../../entities';

// Actions
const GET_AVAILABLE_ORDERS_REQUEST = 'GET_AVAILABLE_ORDERS_REQUEST';
const GET_AVAILABLE_ORDERS_SUCCESS = 'GET_AVAILABLE_ORDERS_SUCCESS';
const GET_AVAILABLE_ORDERS_ERROR = 'GET_AVAILABLE_ORDERS_ERROR';
const GET_AVAILABLE_ORDER_REQUEST = 'GET_AVAILABLE_ORDER_REQUEST';
const GET_AVAILABLE_ORDER_SUCCESS = 'GET_AVAILABLE_ORDER_SUCCESS';
const GET_AVAILABLE_ORDER_ERROR = 'GET_AVAILABLE_ORDER_ERROR';
const SHIP_AVAILABLE_ORDER_REQUEST = 'SHIP_AVAILABLE_ORDER_REQUEST';
const SHIP_AVAILABLE_ORDER_SUCCESS = 'SHIP_AVAILABLE_ORDER_SUCCESS';
const SHIP_AVAILABLE_ORDER_ERROR = 'SHIP_AVAILABLE_ORDER_ERROR';

export type AvailableOrderType = Order & {
  items: OrderItem[];
  serviceTier: string;
};

type AvailableOrdersAction = {
  +type: string;
  +availableOrders?: AvailableOrderType[];
  +queryFilter?: { [key: string]: string };
  +availableOrder?: AvailableOrderType;
  +orderId?: string;
  +courierId?: string;
  +error?: HTTPError
};

export type AvailableOrdersState = {
  isLoadingGetAvailableOrders?: boolean;
  availableOrders?: AvailableOrderType[];
  getAvailableOrdersError?: HTTPError;
  isLoadingGetAvailableOrder?: boolean;
  availableOrder?: AvailableOrderType;
  getAvailableOrderError?: HTTPError;
  isLoadingShipAvailableOrder?: boolean;
  shipAvailableOrderSuccess?: boolean;
  shipAvailableOrderError?: HTTPError;
};

// Actions Creators
export function executeGetAvailableOrders(queryFilter?: { [key: string]: string }): AvailableOrdersAction {
  return {
    type: GET_AVAILABLE_ORDERS_REQUEST,
    queryFilter,
  };
}

export function executeGetAvailableOrdersSuccess(availableOrders: AvailableOrderType[]): AvailableOrdersAction {
  return {
    type: GET_AVAILABLE_ORDERS_SUCCESS,
    availableOrders,
  };
}


export function executeGetAvailableOrdersError(error: HTTPError): AvailableOrdersAction {
  return {
    type: GET_AVAILABLE_ORDERS_ERROR,
    error,
  };
}

export function executeGetAvailableOrder(orderId: string): AvailableOrdersAction {
  return {
    type: GET_AVAILABLE_ORDER_REQUEST,
    orderId,
  };
}

export function executeGetAvailableOrderSuccess(availableOrder: AvailableOrderType): AvailableOrdersAction {
  return {
    type: GET_AVAILABLE_ORDER_SUCCESS,
    availableOrder,
  };
}

export function executeGetAvailableOrderError(error: HTTPError): AvailableOrdersAction {
  return {
    type: GET_AVAILABLE_ORDER_ERROR,
    error,
  };
}

export function executeShipAvailableOrder(courierId: string, orderId: string): AvailableOrdersAction {
  return {
    type: SHIP_AVAILABLE_ORDER_REQUEST,
    courierId,
    orderId,
  };
}

export function executeShipAvailableOrderSuccess(): AvailableOrdersAction {
  return {
    type: SHIP_AVAILABLE_ORDER_SUCCESS,
  };
}

export function executeShipAvailableOrderError(error: HTTPError): AvailableOrdersAction {
  return {
    type: SHIP_AVAILABLE_ORDER_ERROR,
    error,
  };
}

export const actions = {
  GET_AVAILABLE_ORDERS_REQUEST,
  GET_AVAILABLE_ORDERS_SUCCESS,
  GET_AVAILABLE_ORDERS_ERROR,
  GET_AVAILABLE_ORDER_REQUEST,
  GET_AVAILABLE_ORDER_SUCCESS,
  GET_AVAILABLE_ORDER_ERROR,
  SHIP_AVAILABLE_ORDER_REQUEST,
  SHIP_AVAILABLE_ORDER_SUCCESS,
  SHIP_AVAILABLE_ORDER_ERROR,
  executeGetAvailableOrders,
  executeGetAvailableOrdersSuccess,
  executeGetAvailableOrdersError,
  executeGetAvailableOrder,
  executeGetAvailableOrderSuccess,
  executeGetAvailableOrderError,
  executeShipAvailableOrder,
  executeShipAvailableOrderSuccess,
  executeShipAvailableOrderError,
};

export const initialState: AvailableOrdersState = {};

// Reducer
export default function reducer(state: AvailableOrdersState = initialState,
                                action: AvailableOrdersAction): AvailableOrdersState {
  switch (action.type) {
    case GET_AVAILABLE_ORDERS_REQUEST:
      return {
        ...state,
        isLoadingGetAvailableOrders: true,
        setRealCostSuccess: false,
      };
    case GET_AVAILABLE_ORDERS_SUCCESS:
      return {
        ...state,
        isLoadingGetAvailableOrders: false,
        availableOrders: action.availableOrders,
      };
    case GET_AVAILABLE_ORDERS_ERROR:
      return {
        ...state,
        isLoadingGetAvailableOrders: false,
        getAvailableOrdersError: action.error,
      };
    case GET_AVAILABLE_ORDER_REQUEST:
      return {
        ...state,
        isLoadingGetAvailableOrder: true,
      };
    case GET_AVAILABLE_ORDER_SUCCESS:
      return {
        ...state,
        isLoadingGetAvailableOrder: false,
        availableOrder: action.availableOrder,
      };
    case GET_AVAILABLE_ORDER_ERROR:
      return {
        ...state,
        isLoadingGetAvailableOrder: false,
        getAvailableOrdersError: action.error,
      };
    case SHIP_AVAILABLE_ORDER_REQUEST:
      return {
        ...state,
        isLoadingShipAvailableOrder: true,
      };
    case SHIP_AVAILABLE_ORDER_SUCCESS:
      return {
        ...state,
        isLoadingShipAvailableOrder: false,
        shipAvailableOrderSuccess: true,
      };
    case SHIP_AVAILABLE_ORDER_ERROR:
      return {
        ...state,
        isLoadingShipAvailableOrder: false,
        shipAvailableOrderSuccess: false,
        shipAvailableOrderError: action.error,
      };
    default:
      return state;
  }
}

// Epics
export function executeGetAvailableOrdersEpic(action$: ActionsObservable<AvailableOrdersAction>) {
  return action$.pipe(
    ofType(GET_AVAILABLE_ORDERS_REQUEST),
    debounceTime(600),
    switchMap(({ queryFilter }) => getAvailableOrdersApi({ ...queryFilter }).pipe(
      takeUntil(action$.pipe(ofType(GET_AVAILABLE_ORDERS_REQUEST))),
      map((availableOrders: AvailableOrderType[]) => executeGetAvailableOrdersSuccess(availableOrders)),
      catchError((e: HTTPError) => of(executeGetAvailableOrdersError(e)))),
    ));
}

export function executeGetAvailableOrderEpic(action$: ActionsObservable<AvailableOrdersAction>) {
  return action$.ofType(GET_AVAILABLE_ORDER_REQUEST).pipe(
    mergeMap(({ orderId }) => getAvailableOrderApi(undefined, {
      route: endpoints.GET_AVAILABLE_ORDER.replace(':orderId', orderId),
    }).pipe(
      map((availableOrder: AvailableOrderType) => executeGetAvailableOrderSuccess(availableOrder)),
      catchError((e: HTTPError) => of(executeGetAvailableOrderError(e))),
    )),
  );
}

export function executeShipAvailableOrderEpic(action$: ActionsObservable<AvailableOrdersAction>) {
  return action$.ofType(SHIP_AVAILABLE_ORDER_REQUEST).pipe(
    mergeMap(({ courierId, orderId }) => shipAvailableOrder({ courierId }, {
      route: endpoints.SHIP_AVAILABLE_ORDER.replace(':orderId', orderId),
    }).pipe(
      map(executeShipAvailableOrderSuccess),
      catchError((e: HTTPError) => of(executeShipAvailableOrderError(e))),
    )),
  );
}
