// @flow
export const httpErrors: {[key: string]: number} = Object.freeze({
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  REQUEST_TIMEOUT: 408,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
});

const httpErrorsCodeMap = Object.keys(httpErrors).reduce((obj: {[key: number]: string}, value: string) => {
  obj[httpErrors[value]] = value;
  return obj;
}, {});

export function getHttpErrorName(code: number): string {
  return httpErrorsCodeMap[code];
}