// @flow
import { Observable } from 'rxjs';
// import { getCreator, postCreator, putCreator, deleteCreator } from '@qempo/qempo-web-common/http';
import environment from '../../environment';
import apiFeaturedProducts from '../featured-products/featuredProducts';

export const endpoints = Object.freeze({
  GET_FEATURED_PRODUCT: `${environment.apiUrl}/administrative/featured-product/:id`,
  SAVE_FEATURED_PRODUCT: `${environment.apiUrl}/administrative/featured-product/:id`,
  CREATE_FEATURED_PRODUCT: `${environment.apiUrl}/administrative/featured-product/new`,
});

export const getFeaturedProductApi = (queryParams: any, o: any) => Observable.create(
  (observer) => {
    const id = parseInt(o.route.substr(64), 10);
    const product = apiFeaturedProducts.find(e => e.id === id);
    observer.next(product);
  });

export const createFeaturedProductApi = (data: any, o: any) => Observable.create(
  (observer) => {
    data.data.id = (apiFeaturedProducts[apiFeaturedProducts.length-1].id) + 1;
    apiFeaturedProducts.push(data.data);
    observer.next(data.data);
  });

export const saveFeaturedProductApi = (data: any, o: any) => Observable.create(
  (observer) => {
    const id = parseInt(o.route.substr(64), 10);
    const product = apiFeaturedProducts.find(e => e.id === id);
    product.name = data.name !== undefined ? data.name : product.name;
    product.url = data.url !== undefined ? data.url : product.url;
    product.image = data.image !== undefined ? data.image : product.image;
    product.description = data.description !== undefined ? data.description : product.description;
    product.price = data.price !== undefined ? data.price : product.price;
    product.quantity = data.quantity !== undefined ? data.quantity : product.quantity;
    product.category = data.category !== undefined ? data.category : product.category;
    product.weight = data.weight !== undefined ? data.weight : product.weight;
    observer.next(product);
  });

export type UpdateFeaturedProductRequest = {
  id?: number;
  name?: string;
  url?: string;
  image?: string;
  description?: string;
  price?: string;
  category?: string;
  quantity?: number;
  weight?: string;
};
