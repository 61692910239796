// @flow
import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { defaultInitialState, rootEpic, rootReducer } from '../modules';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

// import type { History } from '../router';
import type { RootState } from '../modules';

export const history = createBrowserHistory();

const configureStore = (initialState?: RootState = defaultInitialState) => {
    const middleware = [];

    // Redux observable
    const epicMiddleWare = createEpicMiddleware();
    middleware.push(epicMiddleWare);

    const appliedMiddlewares = applyMiddleware(routerMiddleware(history), ...middleware);

    let store;

    if (process.env.WEBAPP_ENV !== 'production' && window) {
        const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        const enhancer = composeEnhancers(
            appliedMiddlewares,
        );
        store = createStore((rootReducer(history)), initialState, enhancer);
    } else {
        store = createStore((rootReducer(history)), initialState, appliedMiddlewares);
    }

    epicMiddleWare.run(rootEpic);
    return store;
};

export default configureStore;
