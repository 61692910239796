// @flow
import environment from '../../environment';
import { getCreator, postCreator } from '../../common/http';

import type { Order, User } from '../../entities/webapp';

export const endpoints = Object.freeze({
  GET_RECEPTION: `${environment.apiUrl}/operations/progress-transactions/reception/list`,
  CONFIRM_RECEPTION: `${environment.apiUrl}/operations/progress-transactions/reception/receive-products`,
  CANCEL_RECEPTION: `${environment.apiUrl}/operations/progress-transactions/reception/cancel-products`,
});

export const getReceptionApi = getCreator(fetch, endpoints.GET_RECEPTION);

export const confirmReceptionApi = postCreator(fetch, endpoints.CONFIRM_RECEPTION);

export const cancelReceptionApi = postCreator(fetch, endpoints.CANCEL_RECEPTION);

export type GetReceptionResponse = {
  flightDate: string;
  id: string;
  orders: Order[];
  type: string;
  user: User;
}[];

export type ConfirmReceptionRequest = {
  items: {
    orderItemId: number,
    amount: number,
  }[];
};

export type CancelReceptionRequest = {
  items: {
    orderItemId: number;
    amount: number;
    motive: string;
    message?: string;
  }[];
};
