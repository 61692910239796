// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../elements/TablePaginationActions/TablePaginationActions';

import styles from './styles';
import CourierReceptionShipment from './CourierReceptionShipment/CourierReceptionShipment';

import type { ConfirmReceptionRequest } from '../../reception/api';
import type { Shipment } from '../../../entities';
import type { ShipmentSelection, OrderItemInputs } from './CourierReception';
import type { OrderItemOperationState } from '../duck';
import type { HTTPError } from '../../../common/error';

type Props = {
  +classes: { [key: string]: any },
  +executeReceiveProductsAtQempo: (request: ConfirmReceptionRequest) => void,
  +handleSelectionUpdate: (shipmentId: number, orderItemId: ?number, checked: ?boolean, amount: ?number) => void,
  +isLoading: ?boolean,
  +pendingShipments: Shipment[],
  +selectedShipments: {
    [shipmentId: number]: ShipmentSelection,
  },
  +handleOrderItemOnFileChange: (shipmentId: number, orderItemId: number, ev: SyntheticInputEvent<HTMLInputElement>) => void,
  +updateOrderItemInvoice: (shipmentId: number, orderItemId: number) => void,
  +resetOrderItemInvoice: (shipmentId: number, orderItemId: number) => void,
  +handleChangeItemTrackingNumber: (shipmentId: number, orderItemId: number, trackingNumber: string, trackingUrl?: string) => void,
  +handleChangeItemStoreOrderId: (shipmentId: number, orderItemId: number, trackingNumber: string) => void,
  +userInputsMap: {
    [shipmentId: number]: {
      [orderItemId: number]: OrderItemInputs,
    },
  },
  +updateOrderItemTrackingNumber: (shipmentId: number, orderItemId: number) => void,
  +updateOrderItemStoreOrderId: (shipmentId: number, orderItemId: number) => void,
  +orderItemOperations: {
    [shipmentId: number]: {
      [orderItemId: number]: OrderItemOperationState,
    },
  },
  +executeMissingTrackingMail: (orderId: string) => void;
  +isPostingMissingTrackingMail?: boolean;
  +missingTrackingMailSuccess?: boolean;
  +missingTrackingMailError?: HTTPError;
};

type State = {
  openSnackbar: boolean;
  page: number,
  rowsPerPage: number,
};

class CourierReceptionBody extends Component<Props, State> {
  state = {
    openSnackbar: false,
    page: 0,
    rowsPerPage: 100,
  };

  componentDidUpdate(prevProps: Props) {
    const { missingTrackingMailSuccess, missingTrackingMailError } = this.props;
    if ((!!missingTrackingMailSuccess && !prevProps.missingTrackingMailSuccess) ||
      (!!missingTrackingMailError && !prevProps.missingTrackingMailError)) {
      this.setState({ openSnackbar: true });
    }
  }

  handleReceiveShipment = (shipment: Shipment): void => {
    const { selectedShipments, executeReceiveProductsAtQempo } = this.props;
    const selection = selectedShipments[shipment.shipmentId];
    if (!selection) {
      throw Error('Trying to receive an unselected shipment');
    }
    const request: ConfirmReceptionRequest = { items: [] };
    for (const orderItemSelectionId in selection.items) {
      request.items.push({
        orderItemId: parseInt(orderItemSelectionId),
        amount: selection.items[parseInt(orderItemSelectionId)],
      });
    }
    if (request.items.length) {
      executeReceiveProductsAtQempo(request);
    }
  };

  handleChangePage = (e: Event, page: number) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({ page: 0, rowsPerPage: parseInt(value) });
  };

  buildBody = (): React$Node | React$Node[] => {
    const { classes, isLoading, pendingShipments } = this.props;
    const { page, rowsPerPage } = this.state;
    if (isLoading) {
      return (
        <Paper className={classes.fullWidth}>
          <div className={classes.progressContainer}>
            <CircularProgress className={classes.tableProgress} size={70}/>
          </div>
        </Paper>
      );
    } else {
      if (pendingShipments.length > 0) {
        return this.buildShipments(
          pendingShipments.slice(
            page * rowsPerPage, page * rowsPerPage + rowsPerPage,
          ));
      } else {
        return (
          <Paper className={classes.fullWidth}>
            <div className={classes.bodyPaper}>
              <h5>No matches found</h5>
            </div>
          </Paper>
        );
      }
    }
  };

  buildShipments = (pendingShipments: Shipment[]): React$Node[] => {
    const {
      selectedShipments,
      handleSelectionUpdate,
      executeReceiveProductsAtQempo,
      handleOrderItemOnFileChange,
      updateOrderItemInvoice,
      handleChangeItemStoreOrderId,
      handleChangeItemTrackingNumber,
      updateOrderItemTrackingNumber,
      updateOrderItemStoreOrderId,
      userInputsMap,
      orderItemOperations,
      resetOrderItemInvoice,
      executeMissingTrackingMail,
      isPostingMissingTrackingMail,
      missingTrackingMailSuccess,
      missingTrackingMailError,
    } = this.props;
    return pendingShipments.map((shipment: Shipment, index) => {
      return (
        <CourierReceptionShipment
          key={shipment.shipmentId}
          shipment={shipment}
          selection={selectedShipments[shipment.shipmentId]}
          handleSelectionUpdate={handleSelectionUpdate}
          executeReceiveProductsAtQempo={executeReceiveProductsAtQempo}
          handleOrderItemOnFileChange={handleOrderItemOnFileChange}
          updateOrderItemInvoice={updateOrderItemInvoice}
          handleChangeItemTrackingNumber={handleChangeItemTrackingNumber}
          handleChangeItemStoreOrderId={handleChangeItemStoreOrderId}
          updateOrderItemTrackingNumber={updateOrderItemTrackingNumber}
          updateOrderItemStoreOrderId={updateOrderItemStoreOrderId}
          userInputsMap={userInputsMap}
          orderItemOperations={orderItemOperations[shipment.shipmentId]}
          resetOrderItemInvoice={resetOrderItemInvoice}
          sendMissingTrackingMail={() => executeMissingTrackingMail(shipment.order.id)}
          isPostingMissingTrackingMail={isPostingMissingTrackingMail}
          missingTrackingMailSuccess={missingTrackingMailSuccess}
          missingTrackingMailError={missingTrackingMailError}
        />
      );
    });
  };

  getColor = (amount: number, received: number): { [key: string]: any } => {
    const { classes } = this.props;
    if (amount === received) {
      return {
        root: classes.greenText,
        notchedOutline: classes.greenBorder,
      };
    } else {
      return {
        root: classes.redText,
        notchedOutline: classes.redBorder,
      };
    }
  };

  render() {
    const {
      classes,
      pendingShipments,
      missingTrackingMailError,
      missingTrackingMailSuccess,
    } = this.props;
    const { openSnackbar, page, rowsPerPage } = this.state;
    const error = missingTrackingMailError;
    let success = false;
    if (missingTrackingMailSuccess) success = "Mail sent successfully";
    return (
      <Grid container className={classes.rootContainer}>
        {this.buildBody()}
        <Grid item xs={12} className={classes.regularPadding}>
          <Paper>
            <TablePagination
              component="div"
              rowsPerPageOptions={[100, 200, 300, 500]}
              count={pendingShipments.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openSnackbar}
          onClose={() => this.setState({ openSnackbar: false })}
          autoHideDuration={1000}
          message={
            !!error ? (
              <span id="message-id">
                {error && error.data && error.data.Error ? error.data.Error.message : 'Generic Error'}
              </span>
            ) : (
              <span>{success}</span>
            )
          }
        />
      </Grid>
    );
  }
}

CourierReceptionBody.propTypes = {
  classes: PropTypes.object.isRequired,
  executeReceiveProductsAtQempo: PropTypes.func.isRequired,
  handleSelectionUpdate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  pendingShipments: PropTypes.array.isRequired,
  selectedShipments: PropTypes.object.isRequired,
  userInputsMap: PropTypes.object,
  updateOrderItemTrackingNumber: PropTypes.func.isRequired,
  updateOrderItemStoreOrderId: PropTypes.func.isRequired,
  orderItemOperations: PropTypes.object.isRequired,
  resetOrderItemInvoice: PropTypes.func.isRequired,
  executeMissingTrackingMail: PropTypes.func,
  isPostingMissingTrackingMail: PropTypes.bool,
  missingTrackingMailSuccess: PropTypes.bool,
  missingTrackingMailError: PropTypes.object,
};

export default withStyles(styles)(CourierReceptionBody);
