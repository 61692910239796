//@flow
import environment from '../../environment';
import { getCreator, postCreator } from '../../common/http';

export const endpoints = Object.freeze({
    SCRAP_PRODUCT: `${environment.apiUrl}/scrapping/scrap`,
    CREATE_BAG: `${environment.apiUrl}/administrative/bag`,
    UPLOAD_IMAGE_URL: `${environment.apiUrl}/stored-file/upload/url`,
    CREATE_LINK: `${environment.apiUrl}/tools/bitlify`,
});

export const scrapProductApi = getCreator(fetch, endpoints.SCRAP_PRODUCT);
export const createBagApi = postCreator(fetch, endpoints.CREATE_BAG);
export const uploadImageUrlApi = postCreator(fetch, endpoints.UPLOAD_IMAGE_URL);
export const createLinkApi = postCreator(fetch, endpoints.CREATE_LINK);

export type ScrapProductRequest = {
    url: string;
};

export type BagItemRequest = {
    name: string;
    url: string;
    detail: string;
    amount: number;
    price: string;
    Shipping: string;
    itemCategoryId: number;
    imageId: number;
    originalPackaging: boolean;
    volumeTier: number;
};

export type CreateBagRequest = {
    items: BagItemRequest[];
    serviceTier: string;
    commission: string;
    qempo: string;
    delivery: string;
    taxReserve: string;
};

export type uploadImageUrlRequest = {
    url: string;
    resourceGroup: string;
};