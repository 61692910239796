// @flow
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AvailableOrders from '../AvailableOrdersList/AvailableOrdersList';
import { actions } from '../duck';
import { withRouter } from 'react-router-dom';

import type { RootState } from "../../index";

const mapStateToProps = (state: RootState) => {
  return {
    isLoadingGetAvailableOrders: state.availableOrders.isLoadingGetAvailableOrders,
    availableOrders: state.availableOrders.availableOrders,
    getAvailableOrdersError: state.availableOrders.getAvailableOrdersError,
  }
};

const mapDispatchToProps = (dispatch: Function) => {
  return bindActionCreators({ ...actions }, dispatch);
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AvailableOrders));
