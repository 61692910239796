// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { actions as LocalValuesActions } from '../../local-values/duck';

import ProductCrafterMain from '../components/ProductCrafterMain';
import { actions as ProductCrafterActions } from '../duck';

import type { RootState } from '../../index';

const mapStateToProps = (state: RootState) => ({
    isLoadingGetItemCategories: state.localValues.isLoadingGetItemCategories,
    itemCategories: state.localValues.productCategories,
    loading: state.productCrafter.loading,
    error: state.productCrafter.error,
    product: state.productCrafter.product,
    shortUrl: state.productCrafter.shortUrl
});

const mapDispatchToProps = (dispatch: Function) => bindActionCreators({
    ...ProductCrafterActions,
    ...LocalValuesActions
}, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductCrafterMain));
