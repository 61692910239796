// @flow
import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import { Link } from 'react-router-dom';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { InlineDatePicker } from 'material-ui-pickers';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import update from 'immutability-helper';
import styles from './style';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { FormValidator } from '../../../common/form';

type Props = {
  +classes: { [key: string]: any };
  +executeGetDeliveryConfirm: (filter?: { [filterKey: string]: string }) => void;
  +handleConfirmDeliveryConfirm: () => void;
  +atLeastOneSelected: () => boolean;
};

type State = {
  search: {
    value: string,
    errors?: string[],
  };
  date: {
    value: moment,
    errors?: string[],
  },
  filterKey: string;
};

const filterOptions = ['orderId', 'beforeDate',  'buyerName', 'buyerEmail'];

class DeliveryConfirmTableToolbar extends Component<Props, State> {
  searchFormValidator: FormValidator;
  state = {
    search: {
      value: '',
    },
    date: {
      value: moment(),
    },
    filterKey: 'orderId',
  };

  constructor(props: Props) {
    super(props);
    this.searchFormValidator = new FormValidator({
      orderId: {
        orderIdFormat: 'orderIdFormat',
      },
      beforeDate: {
        dateFormat: 'dateFormat',
      },
      buyerEmail: {
        emailFormat: 'emailFormat',
      },
      buyerName: {
        none: () => false,
      },
    });
  }

  handleChange = (e: Event) => {
    const { name, value } = (e.target: { [filterKey: string]: any });
    if (name === 'filterKey') {
      this.setState(prevState => update(prevState, {
        [name]: { $set: value },
        search: {
          $unset: ['errors'],
        },
      }));
    } else {
      this.setState(prevState => update(prevState, {
        [name]: {
          value: { $set: value },
          $unset: ['errors'],
        },
      }));
    }
  };

  handleDateInput = (newDate: ?moment) => {
    const { executeGetDeliveryConfirm } = this.props;
    const { date } = this.state;
    if (!newDate || !date.value || newDate.format('YYYY-MM-DD') !== date.value.format('YYYY-MM-DD')) {
      this.setState(prevState => update(prevState, {
        date: {
          value: { $set: newDate },
        },
        search: {
          $unset: ['errors'],
        },
      }));
      if (newDate) {
        executeGetDeliveryConfirm({ paidAt: newDate.format('YYYY-MM-DD') });
      } else executeGetDeliveryConfirm();
    }
  };

  search = (e: Event) => {
    const { executeGetDeliveryConfirm } = this.props;
    const { filterKey } = this.state;
    if (e.target instanceof HTMLInputElement) {
      const { value } = e.target;
      if (value && value.length) {
        const errors = this.searchFormValidator.validateField(filterKey, value);
        if (errors.length) {
          this.setState(prevState => update(prevState, {
            search: {
              errors: { $set: errors },
            },
          }));
        } else {
          const filter = { [filterKey]: value, };
          executeGetDeliveryConfirm(filter);
        }
      } else {
        executeGetDeliveryConfirm();
      }
    }
  };

  getInputBox = () => {
    const { classes } = this.props;
    const { filterKey, search, date } = this.state;
    if (filterKey === 'beforeDate') {
      return (
        <InlineDatePicker format="DD-MM-YYYY"
                          className={classes.dateSelect}
                          keyboard
                          clearable
                          name="date"
                          placeholder={moment().format('DD-MM-YYYY')}
                          margin="none"
                          mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                          value={date.value}
                          onChange={this.handleDateInput}/>
      );
    } else {
      return (
        <div className={classes.tableSearch}>
          <div className={classes.tableSearchIcon}>
            <SearchIcon/>
          </div>
          <InputBase placeholder="Search…"
                     value={search.value}
                     name="search"
                     onChange={this.handleChange}
                     onKeyDown={(e) => e.keyCode === 13 ? this.search(e) : undefined}
                     classes={{
                       root: classes.tableInputRoot,
                       input: classes.tableSearchInput,
                     }}/>
        </div>);
    }
  };

  render() {
    const { classes, handleConfirmDeliveryConfirm, atLeastOneSelected } = this.props;
    const { search, filterKey } = this.state;
    return (
      <Toolbar style={{ display: 'block' }}>
        <div className={classes.toolbar}
             style={{ paddingBottom: 14, paddingTop: 14, alignItems: 'center' }}>
          <TextField value={filterKey}
                     name="filterKey"
                     variant="outlined"
                     error={!!search.errors && !!search.errors}
                     className={classes.filterSelect}
                     SelectProps={{
                       SelectDisplayProps: {
                         style: {
                           paddingTop: 9,
                           paddingBottom: 8,
                         },
                       },
                     }}
                     select
                     onChange={this.handleChange}>
            {filterOptions.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </TextField>

          {this.getInputBox()}

          <div className={classes.tableSpacer}/>
          <Button className={classes.toolbarButton}
                  disabled={!atLeastOneSelected()}
                  onClick={() => handleConfirmDeliveryConfirm()}
                  color="primary"
                  variant="contained">
            CONFIRM RECEPTION
          </Button>
        </div>

        <div style={{ paddingTop: 10, paddingBottom: 14 }}>
          <Tabs style={{ minHeight: 35, marginLeft: 10 }}
                value={1}>
            <Tab style={{ minHeight: 35 }}
                 component={Link}
                 to={`/operations/shipping`}
                 label="SHIPPING"/>
            <Tab style={{ minHeight: 35 }}
                 label="DELIVERY CONFIRM"/>
          </Tabs>
        </div>
      </Toolbar>);
  }
}

DeliveryConfirmTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  executeGetDeliveryConfirm: PropTypes.func.isRequired,
  handleConfirmDeliveryConfirm: PropTypes.func.isRequired,
  atLeastOneSelected: PropTypes.func.isRequired,
};

export default withStyles(styles)(DeliveryConfirmTableToolbar);
