// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import MainLayout from '../components/MainLayout';
import { actions as AuthActions } from '../../auth/duck';
import { withRouter } from 'react-router-dom';

const mapDispatchToProps = (dispatch: Function) => {
  return bindActionCreators({ ...AuthActions }, dispatch);
};

export default withRouter(connect(
  null,
  mapDispatchToProps,
)(MainLayout));
