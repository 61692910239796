// @flow
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import ArrivedOrderItem from '../../../elements/ArrivedOrderItem/ArrivedOrderItem';

import styles from './style';

import type { GetReceptionResponse, ConfirmReceptionRequest } from '../api';
import type { Order, OrderItem } from '../../../entities/webapp';

type Props = {
  +classes: { [key: string]: any };
  +executeConfirmReception: (request: ConfirmReceptionRequest) => void;
  +resetState: () => void;
  +handleSelectOrder: (e: Event, flightId: string, orderId: string, items: { [key: string]: any }[]) => void;
  +handleSelectItem: (e: Event, flightId: string, orderId: string, orderItemId: number) => void;
  +handleProductAmount: (e: Event, flightId: string, orderId: string, orderItemId: number, amount: number) => void;
  +handleAdd: (flightId: string, orderId: string, orderItemId: number, amount: number) => void;
  +handleSubtract: (flightId: string, orderId: string, orderItemId: number, amount: number) => void;
  +checkObjectKey: (flightId: string, orderId?: string, orderItemId?: string) => boolean;
  +getSelectedItems: () => OrderItem[];
  +isLoading?: boolean;
  +receptionFlights?: GetReceptionResponse;
  +stateFlights: { [key: string]: any };
  +page: number;
  +rowsPerPage: number;
};

class ReceptionTableBody extends Component<Props> {
  handleReceiveOrder = (flightId: string, orderId: string): void => {
    const { stateFlights, getSelectedItems, executeConfirmReception, resetState } = this.props;
    const order = stateFlights[flightId].orders[orderId];
    const selectedItems: OrderItem[] = getSelectedItems();
    const request = [];
    Object.keys(order.items).forEach(orderItemId => {
      if (order.items[orderItemId].selected) {
        const item: any = selectedItems.find(item => item.orderItemId === parseInt(orderItemId, 10));
        request.push({
          orderItemId: parseInt(orderItemId, 10),
          amount: order.items[orderItemId].received ? order.items[orderItemId].received : item.amount - item.receivedCount - item.cancelledCount,
        });
      }
    });
    if (request.length) {
      executeConfirmReception({ items: request });
      resetState();
    }
  };

  buildBody = (): React$Node | React$Node[] => {
    const {
      classes,
      isLoading,
      receptionFlights,
      page,
      rowsPerPage,
    } = this.props;
    if (isLoading) {
      return (
        <Paper style={{ width: '100%' }}>
          <div className={classes.progressContainer}>
            <CircularProgress className={classes.tableProgress} size={70}/>
          </div>
        </Paper>
      );
    } else {
      if (receptionFlights && receptionFlights.length) {
        return this.buildFlights(
          receptionFlights.slice(
            page * rowsPerPage, page * rowsPerPage + rowsPerPage,
          ));
      } else {
        return (
          <Paper style={{ width: '100%' }}>
            <div style={{ height: '58vh', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
              <h5>No matches found</h5>
            </div>
          </Paper>
        );
      }
    }
  };

  buildFlights = (receptionFlights: GetReceptionResponse): React$Node[] => {
    const { classes } = this.props;
    return receptionFlights.map(
      (flight: { [key: string]: any }, flightIndex: number) => {
        const title = (
          <Grid container>
            <Grid item container xs={4}>
              <Grid item xs={3}/>
              <Grid item xs={6}>
                <div className={classes.textContainer}>
                  <h6 className={classes.title}>{flight.id}</h6>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.textContainer}>
                <h6 className={classes.title}>{flight.user.firstName} {flight.user.lastName}</h6>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.textContainer}>
                <h6 className={classes.title}>{moment(flight.flightDate).format('DD/MM/YYYY')}</h6>
              </div>
            </Grid>
          </Grid>
        );
        const orders = this.buildOrders(flight.orders, flight.id);
        const marginBottom = (flightIndex === (receptionFlights.length - 1)) ? 0 : 36;
        return (
          <Grid item xs={12} key={flight.id} style={{ marginBottom }}>
            <Card>
              <CardHeader className={classes.flightHeader}
                          title={title}/>
              {orders}
            </Card>
          </Grid>
        );
      });
  };

  buildOrders = (orders: { [key: string]: any }[], flightId: string): React$Node[] => {
    const { classes, handleSelectOrder } = this.props;
    return orders.map((order: Order, index) => {
      const stateValues: { [key: string]: any } = this.getStateValues(flightId, order.id);
      const title = (
        <Grid container>
          <Grid item container xs={4}>
            <Grid item xs={3}>
              <Checkbox checked={stateValues.selected}
                        indeterminate={stateValues.indeterminate}
                        onChange={(e) => handleSelectOrder(e, flightId, order.id, order.items)}/>
            </Grid>
            <Grid item container xs={6}>
              <div className={classes.textContainer}>
                <h6 className={classes.title}>
                  {order.id}
                </h6>
              </div>
            </Grid>
          </Grid>
          <Grid item container xs={4}>
            <div className={classes.textContainer}>
              <h6 className={classes.title}>
                deliveryDate: {moment(order.beforeDate).format('DD/MM/YYYY')}
              </h6>
            </div>
          </Grid>
          <Grid item xs={2}/>
          <Grid item container xs={2}>
            <div className={classes.textContainer}>
              <Button onClick={() => this.handleReceiveOrder(flightId, order.id)}
                      disabled={!(stateValues.indeterminate || stateValues.selected)}
                      color="primary"
                      variant="outlined">
                RECEIVE ORDER
              </Button>
            </div>
          </Grid>
        </Grid>
      );
      const items = this.buildItems(order.items, flightId, order.id);
      return <Fragment key={index}>
        <CardHeader className={classes.orderHeader}
                    title={title}/>
        {items}
      </Fragment>;
    });
  };

  buildItems = (items: { [key: string]: any }[], flightId: string, orderId: string): React$Node[] => {
    const { classes, handleSelectItem, handleProductAmount, handleAdd, handleSubtract } = this.props;
    return items.map((item: any, itemIndex: number) => {
      const calculatedAmount = item.amount - item.receivedCount - item.cancelledCount;
      const stateValues = this.getStateValues(flightId, orderId, item.orderItemId, calculatedAmount);
      return (
        <CardContent key={itemIndex} style={{background: item.hasArrived ? '#9aea9c' : 'white'}}>
          <Grid container spacing={16} justify="space-evenly">
            <Grid item spacing={16} container xs={2}>
              <Grid item xs={4}>
                <Checkbox checked={stateValues.selected}
                          style={{ marginBottom: 5 }}
                          onChange={(e) => handleSelectItem(e, flightId, orderId, item.orderItemId)}/>
              </Grid>
              <Grid item container xs={8}>
                <Card className={classes.imageCard}>
                  <CardMedia component="img"
                             image={item.image}/>
                </Card>
              </Grid>
            </Grid>
            <Grid item container spacing={8} xs={4}>
              <Grid item xs={12}>
                {item.name}
              </Grid>
              <Grid item container justify="space-between" xs={12}>
                <Grid item container xs={3}>
                  <span className={classes.containerDiv}>
                  Amount:
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <IconButton className={classes.leftIconButton}
                              onClick={() => handleSubtract(flightId, orderId, item.orderItemId, calculatedAmount)}>
                    <RemoveIcon/>
                  </IconButton>
                  <TextField value={stateValues.received}
                             name="received"
                             type="number"
                             InputProps={{
                               endAdornment: `/${calculatedAmount}`,
                               classes: this.getColor(calculatedAmount, stateValues.received),
                             }}
                    // eslint-disable-next-line
                             inputProps={{
                               style: {
                                 paddingTop: 9,
                                 paddingBottom: 8,
                                 paddingLeft: 7,
                               },
                             }}
                             variant="outlined"
                             style={{ width: 60 }}
                             onKeyPress={(e) => handleProductAmount(e, flightId, orderId, item.orderItemId, calculatedAmount)}/>
                  <IconButton className={classes.rightIconButton}
                              onClick={() => handleAdd(flightId, orderId, item.orderItemId, calculatedAmount)}>
                    <AddIcon/>
                  </IconButton>
                </Grid>
                <Grid item xs={3}>
                  {this.getCompletenessCell(calculatedAmount, stateValues.received)}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ArrivedOrderItem orderItem={item} />
              </Grid>
            </Grid>
            <Grid item container xs={2}>
              <span className={classes.containerDiv}>
                {item.originalPackaging ? 'With Box' : 'Without Box'}
              </span>
            </Grid>
            <Grid item xs={3}>
              <span className={classes.containerDiv}
                    style={{
                      overflowY: 'auto',
                      height: 55,
                    }}>
                {item.detail}
              </span>
            </Grid>
          </Grid>
        </CardContent>
      )
    });
  };

  getStateValues = (flightId: string, orderId: string, orderItemId?: string, amount?: number): { selected: boolean, received: number, indeterminate: boolean } => {
    const { stateFlights, checkObjectKey } = (this.props: any);
    const response: { [key: string]: any } = {
      selected: false,
    };
    if (orderItemId && amount) {
      response.received = amount;
      if (checkObjectKey(flightId, orderId, orderItemId) && stateFlights[flightId].orders[orderId].items[orderItemId].selected) {
        response.selected = stateFlights[flightId].orders[orderId].items[orderItemId].selected;
      }
      if (checkObjectKey(flightId, orderId, orderItemId) && stateFlights[flightId].orders[orderId].items[orderItemId].received >= 0) {
        response.received = stateFlights[flightId].orders[orderId].items[orderItemId].received;
      }
    } else if (checkObjectKey(flightId, orderId)) {
      response.selected = !!stateFlights[flightId].orders[orderId].selected;
      response.indeterminate = !!stateFlights[flightId].orders[orderId].indeterminate;
    }
    return response;
  };

  getColor = (amount: number, received: number): { [key: string]: any } => {
    const { classes } = this.props;
    if (amount === received) {
      return {
        root: classes.greenText,
        notchedOutline: classes.greenBorder,
      };
    } else {
      return {
        root: classes.redText,
        notchedOutline: classes.redBorder,
      };
    }
  };

  getCompletenessCell = (amount: number, received: number): React$Node => {
    const { classes } = this.props;
    if (amount === received) {
      return (
        <Typography variant="subtitle1"
                    component="h6"
                    className={classes.completenessCell}
                    style={{
                      color: green[500],
                      borderColor: green[500],
                    }}>
          Complete
        </Typography>
      );
    } else {
      return (
        <Typography variant="subtitle1"
                    component="h6"
                    className={classes.completenessCell}
                    style={{
                      color: red[500],
                      borderColor: red[500],
                    }}>
          Incomplete
        </Typography>
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.rootContainer}>
        {this.buildBody()}
      </Grid>
    );
  }
}

ReceptionTableBody.propTypes = {
  classes: PropTypes.object.isRequired,
  executeConfirmReception: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  handleSelectOrder: PropTypes.func.isRequired,
  handleSelectItem: PropTypes.func.isRequired,
  handleProductAmount: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleSubtract: PropTypes.func.isRequired,
  checkObjectKey: PropTypes.func.isRequired,
  getSelectedItems: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  receptionFlights: PropTypes.array,
  stateFlights: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default withStyles(styles)(ReceptionTableBody);
