// @flow
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import {
  endpoints,
  getPendingShipmentsApi,
  receiveProductsAtQempoApi,
  cancelProductsApi,
  uploadInvoiceFileApi,
  updateOrderItemInvoiceApi,
  updateOrderItemTrackingNumberApi,
  updateOrderItemStoreOrderIdApi,
} from './api';

import type { HTTPError } from '../../common/error';
import type { Shipment, StoredFile } from '../../entities';
import type {
  GetPendingShipmentsRequest,
  ReceiveProductsAtQempoRequest,
  CancelProductsRequest,
  UpdateOrderItemInvoiceRequest,
  UpdateOrderItemTrackingRequest,
  UpdateOrderItemStoreOrderIdRequest,
  CourierReceptionRequest,
} from './api';
import update from 'immutability-helper';
import { missingTrackingApi } from '../courier-tracking/api';

// Actions
const COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_REQUEST = 'COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_REQUEST';
const COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_SUCCESS = 'COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_SUCCESS';
const COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_ERROR = 'COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_ERROR';
const COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_REQUEST = 'COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_REQUEST';
const COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_SUCCESS = 'COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_SUCCESS';
const COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_ERROR = 'COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_ERROR';
const COURIER_RECEPTION_CANCEL_PRODUCTS_REQUEST = 'COURIER_RECEPTION_CANCEL_PRODUCTS_REQUEST';
const COURIER_RECEPTION_CANCEL_PRODUCTS_SUCCESS = 'COURIER_RECEPTION_CANCEL_PRODUCTS_SUCCESS';
const COURIER_RECEPTION_CANCEL_PRODUCTS_ERROR = 'COURIER_RECEPTION_CANCEL_PRODUCTS_ERROR';
const COURIER_RECEPTION_UPLOAD_INVOICE_FILE_REQUEST = 'COURIER_RECEPTION_UPLOAD_INVOICE_FILE_REQUEST';
const COURIER_RECEPTION_UPLOAD_INVOICE_FILE_SUCCESS = 'COURIER_RECEPTION_UPLOAD_INVOICE_FILE_SUCCESS';
const COURIER_RECEPTION_UPLOAD_INVOICE_FILE_ERROR = 'COURIER_RECEPTION_UPLOAD_INVOICE_FILE_ERROR';
const COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_REQUEST = 'COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_REQUEST';
const COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_SUCCESS = 'COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_SUCCESS';
const COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_ERROR = 'COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_ERROR';
const COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_RESET = 'COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_RESET';
const COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_REQUEST = 'COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_REQUEST';
const COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_SUCCESS = 'COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_SUCCESS';
const COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_ERROR = 'COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_ERROR';
const COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_RESET = 'COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_RESET';
const COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_REQUEST = 'COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_REQUEST';
const COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_SUCCESS = 'COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_SUCCESS';
const COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_ERROR = 'COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_ERROR';
const COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_RESET = 'COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_RESET';
const COURIER_RECEPTION_MISSING_TRACKING_MAIL_REQUEST = 'COURIER_RECEPTION_MISSING_TRACKING_MAIL_REQUEST';
const COURIER_RECEPTION_MISSING_TRACKING_MAIL_SUCCESS = 'COURIER_RECEPTION_MISSING_TRACKING_MAIL_SUCCESS';
const COURIER_RECEPTION_MISSING_TRACKING_MAIL_ERROR = 'COURIER_RECEPTION_MISSING_TRACKING_MAIL_ERROR';

type CourierReceptionAction = {
  +type: string,
  +shipments?: Shipment[],
  +image?: StoredFile,
  +request?: CourierReceptionRequest,
  +orderId?: string;
  +error?: HTTPError,
};

type CourierReceptionOrderItemOperationAction = CourierReceptionAction & {
  +type: string,
  +shipmentId: number,
  +orderItemId: number,
  +image?: StoredFile,
  +trackingCourier?: string,
  +trackingNumber?: string,
  +trackingUrl?: string,
  +storeOrderId?: string,
};

type GenericOperation = {
  isLoading: boolean,
  error: ?HTTPError,
};
export type InvoiceOperation = GenericOperation & {
  uploadInvoiceFileSuccess?: boolean,
  updateOrderItemInvoiceSuccess?: boolean,
  image: ?StoredFile,
};
export type TrackingOperation = GenericOperation & {
  updateOrderItemTrackingSuccess?: boolean,
};
export type StoreOrderIdOperation = GenericOperation & {
  updateOrderItemStoreOrderIdSuccess?: boolean,
};
export type OrderItemOperationState = {
  invoice: InvoiceOperation,
  tracking: TrackingOperation,
  storeOrderId: StoreOrderIdOperation,
};

export type CourierReceptionState = {
  isLoadingGetPendingShipments?: boolean,
  pendingShipments: Shipment[],
  getPendingShipmentsError?: HTTPError,
  isLoadingReceiveProductsAtQempo?: boolean,
  receiveProductsAtQempoSuccess?: boolean,
  receiveProductsAtQempoError?: HTTPError,
  isCancellingProducts?: boolean,
  cancelProductsSuccess?: boolean,
  cancelProductsError?: HTTPError,
  orderItemOperations: {
    [shipmentId: number]: {
      [orderItemId: number]: OrderItemOperationState,
    },
  },
  isPostingMissingTrackingMail?: boolean,
  missingTrackingMailSuccess?: boolean,
  missingTrackingMailError?: HTTPError,
};

export const initialState: CourierReceptionState = {
  pendingShipments: [],
  orderItemOperations: {},
};

export const orderItemOperationInitialState: OrderItemOperationState = {
  invoice: {
    isLoading: false,
    uploadInvoiceFileSuccess: false,
    updateOrderItemInvoiceSuccess: false,
    error: null,
    image: null,
  },
  tracking: {
    isLoading: false,
    updateOrderItemTrackingSuccess: false,
    error: null,
  },
  storeOrderId: {
    isLoading: false,
    updateOrderItemStoreOrderIdSuccess: false,
    error: null,
  },
};

// Actions Creators
export function executeGetPendingShipments(request?: GetPendingShipmentsRequest): CourierReceptionAction {
  return {
    type: COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_REQUEST,
    request,
  };
}

export function executeGetPendingShipmentsSuccess(shipments: Shipment[]): CourierReceptionAction {
  return {
    type: COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_SUCCESS,
    shipments,
  };
}

export function executeGetPendingShipmentsError(error: HTTPError): CourierReceptionAction {
  return {
    type: COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_ERROR,
    error,
  };
}

export function executeReceiveProductsAtQempo(request: ReceiveProductsAtQempoRequest): CourierReceptionAction {
  return {
    type: COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_REQUEST,
    request,
  };
}

export function executeReceiveProductsAtQempoSuccess(shipments: Shipment[]): CourierReceptionAction {
  return {
    type: COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_SUCCESS,
    shipments,
  };
}

export function executeReceiveProductsAtQempoError(error: HTTPError): CourierReceptionAction {
  return {
    type: COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_ERROR,
    error,
  };
}

export function executeCancelProducts(request: CancelProductsRequest): CourierReceptionAction {
  return {
    type: COURIER_RECEPTION_CANCEL_PRODUCTS_REQUEST,
    request,
  };
}

export function executeCancelProductsSuccess(shipments: Shipment[]): CourierReceptionAction {
  return {
    type: COURIER_RECEPTION_CANCEL_PRODUCTS_SUCCESS,
    shipments,
  };
}

export function executeCancelProductsError(error: HTTPError): CourierReceptionAction {
  return {
    type: COURIER_RECEPTION_CANCEL_PRODUCTS_ERROR,
    error,
  };
}

export function executeUploadInvoice(shipmentId: number, orderItemId: number, request: FormData): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPLOAD_INVOICE_FILE_REQUEST,
    shipmentId,
    orderItemId,
    request,
  };
}

export function executeUploadInvoiceSuccess(
  shipmentId: number,
  orderItemId: number,
  image: StoredFile
): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPLOAD_INVOICE_FILE_SUCCESS,
    shipmentId,
    orderItemId,
    image,
  };
}

export function executeUploadInvoiceError(
  shipmentId: number,
  orderItemId: number,
  error: HTTPError
): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPLOAD_INVOICE_FILE_ERROR,
    shipmentId,
    orderItemId,
    error,
  };
}

export function executeUpdateOrderItemInvoice(
  shipmentId: number,
  orderItemId: number,
  request: UpdateOrderItemInvoiceRequest
): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_REQUEST,
    shipmentId,
    orderItemId,
    request,
  };
}

export function executeUpdateOrderItemInvoiceSuccess(shipmentId: number, orderItemId: number): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_SUCCESS,
    shipmentId,
    orderItemId,
  };
}

export function executeUpdateOrderItemInvoiceError(
  shipmentId: number,
  orderItemId: number,
  error: HTTPError
): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_ERROR,
    shipmentId,
    orderItemId,
    error,
  };
}

export function executeUpdateOrderItemInvoiceReset(shipmentId: number, orderItemId: number): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_RESET,
    shipmentId,
    orderItemId,
  };
}

export function executeUpdateOrderItemTrackingNumber(
  shipmentId: number,
  orderItemId: number,
  request: UpdateOrderItemTrackingRequest
): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_REQUEST,
    shipmentId,
    orderItemId,
    request,
  };
}

export function executeUpdateOrderItemTrackingNumberSuccess(
  shipmentId: number,
  orderItemId: number,
  trackingNumber: string,
  trackingUrl?: string,
): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_SUCCESS,
    shipmentId,
    orderItemId,
    trackingNumber,
    trackingUrl,
  };
}

export function executeUpdateOrderItemTrackingNumberError(
  shipmentId: number,
  orderItemId: number,
  error: HTTPError
): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_ERROR,
    shipmentId,
    orderItemId,
    error,
  };
}

export function executeUpdateOrderItemTrackingNumberReset(
  shipmentId: number,
  orderItemId: number
): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_RESET,
    shipmentId,
    orderItemId,
  };
}

export function executeUpdateOrderItemStoreOrderId(
  shipmentId: number,
  orderItemId: number,
  request: UpdateOrderItemStoreOrderIdRequest
): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_REQUEST,
    shipmentId,
    orderItemId,
    request,
  };
}

export function executeUpdateOrderItemStoreOrderIdSuccess(
  shipmentId: number,
  orderItemId: number,
  storeOrderId: string
): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_SUCCESS,
    shipmentId,
    orderItemId,
    storeOrderId,
  };
}

export function executeUpdateOrderItemStoreOrderIdError(
  shipmentId: number,
  orderItemId: number,
  error: HTTPError
): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_ERROR,
    shipmentId,
    orderItemId,
    error,
  };
}

export function executeUpdateOrderItemStoreOrderIdReset(shipmentId: number, orderItemId: number): CourierReceptionOrderItemOperationAction {
  return {
    type: COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_RESET,
    shipmentId,
    orderItemId,
  };
}

export function executeMissingTrackingMail(orderId: string): CourierReceptionAction {
  return {
    type: COURIER_RECEPTION_MISSING_TRACKING_MAIL_REQUEST,
    orderId,
  };
}

export function executeMissingTrackingMailSuccess(): CourierReceptionAction {
  return {
    type: COURIER_RECEPTION_MISSING_TRACKING_MAIL_SUCCESS,
  };
}

export function executeMissingTrackingMailError(error: HTTPError): CourierReceptionAction {
  return {
    type: COURIER_RECEPTION_MISSING_TRACKING_MAIL_ERROR,
    error,
  };
}

export const actions = {
  COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_REQUEST,
  COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_SUCCESS,
  COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_ERROR,
  COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_REQUEST,
  COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_SUCCESS,
  COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_ERROR,
  COURIER_RECEPTION_CANCEL_PRODUCTS_REQUEST,
  COURIER_RECEPTION_CANCEL_PRODUCTS_SUCCESS,
  COURIER_RECEPTION_CANCEL_PRODUCTS_ERROR,
  COURIER_RECEPTION_UPLOAD_INVOICE_FILE_REQUEST,
  COURIER_RECEPTION_UPLOAD_INVOICE_FILE_SUCCESS,
  COURIER_RECEPTION_UPLOAD_INVOICE_FILE_ERROR,
  COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_REQUEST,
  COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_SUCCESS,
  COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_ERROR,
  COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_RESET,
  COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_REQUEST,
  COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_SUCCESS,
  COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_ERROR,
  COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_RESET,
  COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_REQUEST,
  COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_SUCCESS,
  COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_ERROR,
  COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_RESET,
  COURIER_RECEPTION_MISSING_TRACKING_MAIL_REQUEST,
  COURIER_RECEPTION_MISSING_TRACKING_MAIL_SUCCESS,
  COURIER_RECEPTION_MISSING_TRACKING_MAIL_ERROR,
  executeGetPendingShipments,
  executeGetPendingShipmentsSuccess,
  executeGetPendingShipmentsError,
  executeReceiveProductsAtQempo,
  executeReceiveProductsAtQempoSuccess,
  executeReceiveProductsAtQempoError,
  executeCancelProducts,
  executeCancelProductsSuccess,
  executeCancelProductsError,
  executeUploadInvoice,
  executeUploadInvoiceSuccess,
  executeUploadInvoiceError,
  executeUpdateOrderItemInvoice,
  executeUpdateOrderItemInvoiceSuccess,
  executeUpdateOrderItemInvoiceError,
  executeUpdateOrderItemInvoiceReset,
  executeUpdateOrderItemTrackingNumber,
  executeUpdateOrderItemTrackingNumberSuccess,
  executeUpdateOrderItemTrackingNumberError,
  executeUpdateOrderItemTrackingNumberReset,
  executeUpdateOrderItemStoreOrderId,
  executeUpdateOrderItemStoreOrderIdSuccess,
  executeUpdateOrderItemStoreOrderIdError,
  executeUpdateOrderItemStoreOrderIdReset,
  executeMissingTrackingMail,
  executeMissingTrackingMailSuccess,
  executeMissingTrackingMailError,
};

function findShippingItemIndex(action: CourierReceptionOrderItemOperationAction, state: CourierReceptionState): number[] {
  if (!state.pendingShipments) {
    console.error('No pendingShipments to execute operation in');
    return [-1, -1];
  }
  const shipmentIndex = state.pendingShipments.findIndex((shipment) => {
    return shipment.shipmentId === action.shipmentId;
  });
  if (shipmentIndex === -1) {
    console.error('shipmentId not found in pendingShipments');
    return [-1, -1];
  }
  const shippingItemIndex = state.pendingShipments[shipmentIndex].items.findIndex((shippingItem) => {
    return shippingItem.orderItem.orderItemId === action.orderItemId;
  });
  if (shippingItemIndex === -1) {
    console.error('shippingItemIndex not found in Shipment');
    return [-1, -1];
  }
  return [shipmentIndex, shippingItemIndex];
}

// Reducer
export default function reducer(state: CourierReceptionState = initialState, action: CourierReceptionAction): CourierReceptionState {
  let shipmentIndex: number;
  let shippingItemIndex: number;
  // Type casting of action to CourierReceptionOrderItemOperationAction
  const oiAction = ((action: any): CourierReceptionOrderItemOperationAction);
  switch (action.type) {
    case COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_REQUEST:
      return {
        ...state,
        isLoadingGetPendingShipments: true,
      };
    case COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_SUCCESS:
      return {
        ...state,
        isLoadingGetPendingShipments: false,
        pendingShipments: action.shipments,
        receiveProductsAtQempoSuccess: false,
      };
    case COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_ERROR:
      return {
        ...state,
        isLoadingGetPendingShipments: false,
        getPendingShipmentsError: action.error,
      };
    case COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_REQUEST:
      return {
        ...state,
        isLoadingReceiveProductsAtQempo: true,
      };
    case COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_SUCCESS:
      return {
        ...state,
        receiveProductsAtQempoSuccess: true,
        isLoadingReceiveProductsAtQempo: false,
        isLoadingGetPendingShipments: true,
      };
    case COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_ERROR:
      return {
        ...state,
        isLoadingReceiveProductsAtQempo: false,
        receiveProductsAtQempoError: action.error,
      };
    case COURIER_RECEPTION_CANCEL_PRODUCTS_REQUEST:
      return {
        ...state,
        isCancellingProducts: true,
      };
    case COURIER_RECEPTION_CANCEL_PRODUCTS_SUCCESS:
      return {
        ...state,
        isCancellingProducts: false,
        isLoadingGetPendingShipments: true,
      };
    case COURIER_RECEPTION_CANCEL_PRODUCTS_ERROR:
      return {
        ...state,
        isCancellingProducts: false,
        cancelProductsError: action.error,
      };
    case COURIER_RECEPTION_UPLOAD_INVOICE_FILE_REQUEST:
      return update(state, {
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  invoice: {
                    isLoading: { $set: true },
                    uploadInvoiceFileSuccess: { $set: false },
                    updateOrderItemInvoiceSuccess: { $set: false },
                    error: { $set: null },
                    image: { $set: null },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_UPLOAD_INVOICE_FILE_SUCCESS:
      return update(state, {
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  invoice: {
                    isLoading: { $set: false },
                    uploadInvoiceFileSuccess: { $set: true },
                    image: { $set: oiAction.image },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_UPLOAD_INVOICE_FILE_ERROR:
      return update(state, {
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  invoice: {
                    isLoading: { $set: false },
                    error: { $set: oiAction.error },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_REQUEST:
      return update(state, {
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  invoice: {
                    isLoading: { $set: true },
                    updateOrderItemInvoiceSuccess: { $set: false },
                    error: { $set: null },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_SUCCESS:
      [shipmentIndex, shippingItemIndex] = findShippingItemIndex(oiAction, state);
      if (shipmentIndex === -1 || shippingItemIndex === -1) {
        return state;
      }
      const uploadedImageUrl =
        state.orderItemOperations[oiAction.shipmentId][oiAction.orderItemId].invoice.image &&
        state.orderItemOperations[oiAction.shipmentId][oiAction.orderItemId].invoice.image.url;

      return update(state, {
        pendingShipments: {
          [shipmentIndex]: {
            items: {
              [shippingItemIndex]: {
                orderItem: {
                  externalInvoice: (externalInvoice) =>
                    update(externalInvoice || {}, {
                      image: {
                        $set: uploadedImageUrl,
                      },
                    }),
                },
              },
            },
          },
        },
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  invoice: {
                    isLoading: { $set: false },
                    updateOrderItemInvoiceSuccess: { $set: true },
                    error: { $set: null },
                    image: { $set: null },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_ERROR:
      return update(state, {
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  invoice: {
                    isLoading: { $set: false },
                    error: { $set: oiAction.error },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_RESET:
      return update(state, {
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  invoice: {
                    isLoading: { $set: false },
                    uploadInvoiceFileSuccess: { $set: false },
                    updateOrderItemInvoiceSuccess: { $set: false },
                    image: { $set: null },
                    error: { $set: null },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_REQUEST:
      return update(state, {
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  tracking: {
                    isLoading: { $set: true },
                    updateOrderItemTrackingSuccess: { $set: false },
                    error: { $set: null },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_SUCCESS:
      [shipmentIndex, shippingItemIndex] = findShippingItemIndex(oiAction, state);
      if (shipmentIndex === -1 || shippingItemIndex === -1) {
        return state;
      }
      const trackingNumber: string = oiAction.trackingNumber || '';
      const trackingUrl: string = oiAction.trackingUrl || '';
      return update(state, {
        pendingShipments: {
          [shipmentIndex]: {
            items: {
              [shippingItemIndex]: {
                orderItem: {
                  tracking: (tracking) =>
                    update(tracking || {}, {
                      trackingNumber: {
                        $set: trackingNumber,
                      },
                      trackingUrl: {
                        $set: trackingUrl,
                      },
                    }),
                },
              },
            },
          },
        },
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  tracking: {
                    isLoading: { $set: false },
                    updateOrderItemTrackingSuccess: { $set: true },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_ERROR:
      return update(state, {
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  tracking: {
                    isLoading: { $set: false },
                    error: { $set: oiAction.error },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_RESET:
      return update(state, {
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  tracking: {
                    isLoading: { $set: false },
                    updateOrderItemTrackingSuccess: { $set: false },
                    error: { $set: null },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_REQUEST:
      return update(state, {
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  storeOrderId: {
                    isLoading: { $set: true },
                    updateOrderItemStoreOrderIdSuccess: { $set: false },
                    error: { $set: null },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_SUCCESS:
      [shipmentIndex, shippingItemIndex] = findShippingItemIndex(oiAction, state);
      if (shipmentIndex === -1 || shippingItemIndex === -1) {
        return state;
      }
      const storeOrderId: string = oiAction.storeOrderId || '';
      return update(state, {
        pendingShipments: {
          [shipmentIndex]: {
            items: {
              [shippingItemIndex]: {
                orderItem: {
                  tracking: (tracking) =>
                    update(tracking || {}, {
                      storeOrderId: {
                        $set: storeOrderId,
                      },
                    }),
                },
              },
            },
          },
        },
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  storeOrderId: {
                    isLoading: { $set: false },
                    updateOrderItemStoreOrderIdSuccess: { $set: true },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_ERROR:
      return update(state, {
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  storeOrderId: {
                    isLoading: { $set: false },
                    error: { $set: oiAction.error },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_RESET:
      return update(state, {
        orderItemOperations: {
          [oiAction.shipmentId]: (shipmentOperation) =>
            update(shipmentOperation || {}, {
              [oiAction.orderItemId]: (orderItemOperation) =>
                update(orderItemOperation || orderItemOperationInitialState, {
                  storeOrderId: {
                    isLoading: { $set: false },
                    updateOrderItemStoreOrderIdSuccess: { $set: false },
                    error: { $set: null },
                  },
                }),
            }),
        },
      });
    case COURIER_RECEPTION_MISSING_TRACKING_MAIL_REQUEST:
      return {
        ...state,
        isPostingMissingTrackingMail: true,
        missingTrackingMailSuccess: false,
        missingTrackingMailError: undefined,
      };
    case COURIER_RECEPTION_MISSING_TRACKING_MAIL_SUCCESS:
      return {
        ...state,
        isPostingMissingTrackingMail: false,
        missingTrackingMailSuccess: true,
        missingTrackingMailError: undefined,
      };
    case COURIER_RECEPTION_MISSING_TRACKING_MAIL_ERROR:
      return {
        ...state,
        isPostingMissingTrackingMail: false,
        missingTrackingMailSuccess: false,
        missingTrackingMailError: action.error,
      };
    default:
      return state;
  }
}

// Epics
export function executeCourierReceptionGetPendingShipmentsEpic(action$: ActionsObservable<CourierReceptionState>) {
  return action$
    .ofType(
      COURIER_RECEPTION_GET_SHIPMENTS_PENDING_RECEPTION_REQUEST,
      COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_SUCCESS,
      COURIER_RECEPTION_CANCEL_PRODUCTS_SUCCESS
    )
    .pipe(
      mergeMap(({ request }) =>
        getPendingShipmentsApi({ ...request }).pipe(
          map((shipments: Shipment[]) => executeGetPendingShipmentsSuccess(shipments)),
          catchError((e: HTTPError) => of(executeGetPendingShipmentsError(e)))
        )
      )
    );
}

export function executeCourierReceptionReceiveProductsAtQempoEpic(action$: ActionsObservable<CourierReceptionState>) {
  return action$.ofType(COURIER_RECEPTION_RECEIVE_PRODUCTS_AT_QEMPO_REQUEST).pipe(
    mergeMap(({ request }) =>
      receiveProductsAtQempoApi(request).pipe(
        map((shipments: Shipment[]) => executeReceiveProductsAtQempoSuccess(shipments)),
        catchError((e: HTTPError) => of(executeReceiveProductsAtQempoError(e)))
      )
    )
  );
}

export function executeCourierReceptionCancelProductsEpic(action$: ActionsObservable<CourierReceptionState>) {
  return action$.ofType(COURIER_RECEPTION_CANCEL_PRODUCTS_REQUEST).pipe(
    mergeMap(({ request }) =>
      cancelProductsApi(request).pipe(
        map((shipments: Shipment[]) => executeCancelProductsSuccess(shipments)),
        catchError((e: HTTPError) => of(executeCancelProductsError(e)))
      )
    )
  );
}

export function executeCourierReceptionUploadInvoiceFileEpic(action$: ActionsObservable<CourierReceptionState>) {
  return action$.ofType(COURIER_RECEPTION_UPLOAD_INVOICE_FILE_REQUEST).pipe(
    mergeMap(({ request, shipmentId, orderItemId }) =>
      uploadInvoiceFileApi(request, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).pipe(
        map((image: StoredFile) => executeUploadInvoiceSuccess(shipmentId, orderItemId, image)),
        catchError((e: HTTPError) => of(executeUploadInvoiceError(shipmentId, orderItemId, e)))
      )
    )
  );
}

export function executeCourierReceptionUploadInvoiceFileSucessEpic(action$: ActionsObservable<CourierReceptionState>) {
  return action$
    .ofType(COURIER_RECEPTION_UPLOAD_INVOICE_FILE_SUCCESS)
    .pipe(
      map(({ shipmentId, orderItemId, image }) =>
        executeUpdateOrderItemInvoice(shipmentId, orderItemId, { storedFileId: image.storedFileId })
      )
    );
}

export function executeCourierReceptionUpdateOrderItemInvoiceEpic(action$: ActionsObservable<CourierReceptionState>) {
  return action$.ofType(COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_REQUEST).pipe(
    mergeMap(({ shipmentId, orderItemId, request }) =>
      updateOrderItemInvoiceApi(request, {
        route: endpoints.UPDATE_ORDER_ITEM_INVOICE.replace(':orderItemId', orderItemId),
      }).pipe(
        map(() => executeUpdateOrderItemInvoiceSuccess(shipmentId, orderItemId)),
        catchError((e: HTTPError) => of(executeUpdateOrderItemInvoiceError(shipmentId, orderItemId, e)))
      )
    )
  );
}

export function executeCourierReceptionUpdateOrderItemInvoiceResetEpic(action$: ActionsObservable<CourierReceptionState>) {
  return action$
    .ofType(
      COURIER_RECEPTION_UPLOAD_INVOICE_FILE_ERROR,
      COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_ERROR,
      COURIER_RECEPTION_UPDATE_ORDER_ITEM_INVOICE_SUCCESS
    )
    .pipe(
      delay(3000),
      map(({ shipmentId, orderItemId }) => executeUpdateOrderItemInvoiceReset(shipmentId, orderItemId))
    );
}

export function executeCourierReceptionUpdateOrderItemTrackingNumberEpic(action$: ActionsObservable<CourierReceptionState>) {
  return action$.ofType(COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_REQUEST).pipe(
    mergeMap(({ shipmentId, orderItemId, request }) =>
      updateOrderItemTrackingNumberApi(request, {
        route: endpoints.UPDATE_ORDER_ITEM_TRACKING_NUMBER.replace(':orderItemId', orderItemId),
      }).pipe(
        map(() => executeUpdateOrderItemTrackingNumberSuccess(shipmentId, orderItemId, request.trackingNumber, request.trackingUrl)),
        catchError((e: HTTPError) => of(executeUpdateOrderItemTrackingNumberError(shipmentId, orderItemId, e)))
      )
    )
  );
}

export function executeCourierReceptionUpdateOrderItemTrackingNumberResetEpic(action$: ActionsObservable<CourierReceptionState>) {
  return action$
    .ofType(COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_ERROR, COURIER_RECEPTION_UPDATE_ORDER_ITEM_TRACKING_NUMBER_SUCCESS)
    .pipe(
      delay(3000),
      map(({ shipmentId, orderItemId }) => executeUpdateOrderItemTrackingNumberReset(shipmentId, orderItemId))
    );
}

export function executeCourierReceptionUpdateOrderItemStoreOrderIdEpic(action$: ActionsObservable<CourierReceptionState>) {
  return action$.ofType(COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_REQUEST).pipe(
    mergeMap(({ shipmentId, orderItemId, request }) =>
      updateOrderItemStoreOrderIdApi(request, {
        route: endpoints.UPDATE_ORDER_ITEM_TRACKING_ORDER_ID.replace(':orderItemId', orderItemId),
      }).pipe(
        map(() => executeUpdateOrderItemStoreOrderIdSuccess(shipmentId, orderItemId, request.storeOrderId)),
        catchError((e: HTTPError) => of(executeUpdateOrderItemStoreOrderIdError(shipmentId, orderItemId, e)))
      )
    )
  );
}

export function executeCourierReceptionUpdateOrderItemStoreOrderIdResetEpic(action$: ActionsObservable<CourierReceptionState>) {
  return action$
    .ofType(COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_ERROR, COURIER_RECEPTION_UPDATE_ORDER_ITEM_STORE_ORDER_ID_SUCCESS)
    .pipe(
      delay(3000),
      map(({ shipmentId, orderItemId }) => executeUpdateOrderItemStoreOrderIdReset(shipmentId, orderItemId))
    );
}


export function executeCourierReceptionMissingTrackingEmailEpic(action$: ActionsObservable<CourierReceptionState>) {
  return action$.ofType(COURIER_RECEPTION_MISSING_TRACKING_MAIL_REQUEST).pipe(
    mergeMap(({ orderId }) =>
      missingTrackingApi(undefined, {
        route: endpoints.MISSING_TRACKING.replace(':orderId', orderId),
      }).pipe(
        map(executeMissingTrackingMailSuccess),
        catchError((e: HTTPError) => of(executeMissingTrackingMailError(e))),
      ),
    ),
  );
}
