// @flow
import { of } from 'rxjs';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { getOrdersApi } from './api'

import type { RootState } from '../index';
import type { Order } from '../../entities/webapp';
import type { HTTPError } from '../../common/error';

// Actions
const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';
const CHANGE_ORDERS_PAGINATION_REQUEST = 'CHANGE_ORDERS_PAGINATION_REQUEST';
const RESET_ORDERS_STATE = 'RESET_ORDERS_STATE';

type OrdersAction = {
  +type: string;
  +orders?: Order[];
  +error?: HTTPError;
  +page?: number;
  +limit?: number;
  +ord?: string;
  +sort?: string;
  +changes?: { [key: string]: any };
  +totalOrders?: number;
  +filterId?: string;
};

export type OrdersState = {
  isLoadingGetOrders?: boolean;
  getOrdersSuccess?: boolean;
  getOrdersError?: HTTPError;
  page?: number;
  limit?: number;
  ord?: string;
  sort?: string;
  filterId?: string;
  orders?: Order [];
  totalOrders?: number;
};

//Action Creators
export function executeGetOrders(): OrdersAction {
  return {
    type: GET_ORDERS_REQUEST,
  };
}

export function executeGetOrdersSuccessFn(data: { [key: string]: any }): OrdersAction {
  console.log('data', data);
  return {
    type: GET_ORDERS_SUCCESS,
    orders: data.orders,
    totalOrders: data.totalOrders,
  };
}

export function executeGetOrdersErrorFn(error: HTTPError): OrdersAction {
  return {
    type: GET_ORDERS_ERROR,
    error,
  };
}

export function changeOrdersPagination(changes: { [key: string]: any }): OrdersAction {
  return {
    type: CHANGE_ORDERS_PAGINATION_REQUEST,
    changes,
  };
}

export function executeResetOrdersState(): OrdersAction {
  return {
    type: RESET_ORDERS_STATE,
  };
}

export const actions = {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  CHANGE_ORDERS_PAGINATION_REQUEST,
  executeGetOrders,
  executeGetOrdersSuccessFn,
  executeGetOrdersErrorFn,
  changeOrdersPagination,
  executeResetOrdersState,
};

export const initialState: OrdersState = {
  page: 0,
  limit: 20,
  filterId: '',
};

// Reducer
export default function reducer(state: OrdersState = initialState,
                                action: OrdersAction): OrdersState {
  switch (action.type) {
    case GET_ORDERS_REQUEST:
      return {
        ...state,
        isLoadingGetOrders: true,
        totalOrders: action.totalOrders,
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        isLoadingGetOrders: false,
        getOrdersSuccess: true,
        orders: action.orders,
        totalOrders: action.totalOrders,
      };
    case GET_ORDERS_ERROR:
      return {
        getOrdersError: action.error,
      };
    case CHANGE_ORDERS_PAGINATION_REQUEST:
      const data = { ...action.changes };
      return { ...state, ...data };
    case RESET_ORDERS_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetOrdersEpic(action$: ActionsObservable<OrdersAction>, state$: StateObservable<RootState>) {
  return action$.ofType(GET_ORDERS_REQUEST).pipe(
    delay(1000),
    mergeMap(
      () => getOrdersApi({
        page: state$.value.orders.page,
        size: state$.value.orders.size,
        limit: state$.value.orders.limit,
        filterId: state$.value.orders.filterId,
      }).pipe(
        map((data: { [key: string]: any }) => executeGetOrdersSuccessFn(data)),
        catchError((e: HTTPError) => of(executeGetOrdersErrorFn(e))))
    )
  );
}

export function changeOrdersPaginationEpic(action$: ActionsObservable<OrdersAction>) {
  return action$.ofType(CHANGE_ORDERS_PAGINATION_REQUEST).pipe(
    map(() => executeGetOrders()
    )
  );
}
