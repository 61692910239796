// @flow
import { fade } from '@material-ui/core/styles/colorManipulator';

export default function styles(theme: { [key: string]: any }) {
  return ({
    progress: {
      position: 'absolute',
      top: '50%',
      [theme.breakpoints.up('sm')]: {
        left: '50%',
      },
      left: '40%',
    },
    listWrap: {
      maxWidth: 1200,
      margin: 'auto',
    },
    toolBar: {
      width: '100%',
      padding: 16,
    },
    searchWrap: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      marginLeft: 20,
      display: 'inline-block',
      width: 'auto',
      backgroundColor: fade('#656565', 0.15),
      '&:hover': {
        backgroundColor: fade('#656565', 0.25),
      },
    },
    inputRoot: {
      color: '#656565',
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 6,
      transition: theme.transitions.create('width'),
      width: 200,
    },
    searchIcon: {
      width: theme.spacing.unit * 6,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonReset: {
      marginRight: 8,
    },
  });
}
