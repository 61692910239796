// @flow
import type { Node } from 'react';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import styles from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import { FormValidator } from '../../../common/form';
import Summary from '../../../elements/Summary/Summary';
import OrderItem from './OrderItem/OrderItem';
import classNames from 'classnames/bind';
import environment from '../../../environment';
import { Snackbar } from '@material-ui/core';
import TrackingNotificationStatusContainer from '../containers/TrackingNotificationStatusContainer';
import moment from 'moment';
import TablePaginationActions from '../../../elements/TablePaginationActions/TablePaginationActions';

import defaultPic from '../../../assets/img/profileplaceholder.jpg';

import type { HTTPError } from '../../../common/error';
import type { Courier, Shipment, ShipmentItem, ShipmentOrder } from '../../../entities';

type Props = {
  +classes: { [key: string]: any };
  +executeGetCouriers: () => void;
  +isLoadingGetCouriers?: boolean;
  +couriers?: Courier[];
  +getCouriersError?: HTTPError;
  +executeGetCouriersOrders: (queryFilters?: { [key: string]: string | number }) => void;
  +isLoadingGetCouriersOrders?: boolean;
  +couriersOrders?: ShipmentOrder[];
  +getCouriersOrdersError?: HTTPError;
  +executeUpdateOrderItemInvoice: (orderItemId: number, invoiceFormData: FormData) => void;
  +isLoadingUpdateInvoice?: boolean;
  +updateInvoiceSuccess?: boolean;
  +updateInvoiceError?: HTTPError;
  +executeUpdateOrderItemTracking: (
    orderItemId: number,
    trackingCourier: string,
    trackingNumber: string,
    trackingUrl?: string,
  ) => void;
  +isLoadingUpdateTracking?: boolean;
  +updateTrackingSuccess?: boolean;
  +updateTrackingError?: HTTPError;
  +executeUpdateOrderItemStoreOrderId: (orderItemId: number, storeOrderId: string) => void;
  +isLoadingUpdateStoreOrderId?: boolean;
  +updateStoreOrderIdSuccess?: boolean;
  +updateStoreOrderIdError?: HTTPError;
  +executeCancelReservation: (shipmentId: number, orderId: string) => void;
  +isCancellingReservation?: boolean;
  +cancelReservationSuccess?: boolean;
  +cancelReservationError?: HTTPError;
  +executeReceiveOrderItem: (orderItemId: number) => void;
  +isReceivingOrderItem?: boolean;
  +receiveOrderItemSuccess?: boolean;
  +receiveOrderItemError?: HTTPError;
  +executeMissingTrackingMail: (orderId: string) => void;
  +isPostingMissingTrackingMail?: boolean;
  +missingTrackingMailSuccess?: boolean;
  +missingTrackingMailError?: HTTPError;
  +executeSetRealCost: (realCost: string, invoiceId: string, orderId: string) => void;
  +isLoadingSetRealCost?: boolean;
  +setRealCostSuccess?: boolean;
  +realCostMap: { [key: string]: string };
  +setRealCostError?: HTTPError;
};

type State = {
  courierId: {
    value: string;
    errors?: string[];
  };
  orderState: {
    value: string;
    errors?: string[];
  };
  orderType: {
    value: string;
    errors?: string[];
  };
  orderId: {
    value: string;
    errors?: string[];
  };
  trackingId: {
    value: string;
  },
  hasTracking: {
    value: string;
    errors?: string[];
  };
  realCost: {
    [key: string]: string,
  };
  page: number,
  rowsPerPage: number,
};

const orderStates = ['AVAILABLE_FOR_COURIER', 'RESERVED', 'PURCHASED', 'IN_COURIER_FLOW'];
const orderTypes = ['FULL', 'LITE'];
const initialState = {
  courierId: {
    value: '',
  },
  orderState: {
    value: '',
  },
  orderType: {
    value: '',
  },
  orderId: {
    value: '',
  },
  trackingId: {
    value: '',
  },
  hasTracking: {
    value: '',
  },
  realCost: {},
  page: 0,
  rowsPerPage: 100,
};

class CourierTracking extends Component<Props, State> {
  formValidator: FormValidator;
  changesSubject$: Subject;
  state = initialState;

  constructor(props: Props) {
    super(props);
    this.formValidator = new FormValidator({
      orderId: {
        orderIdFormat: 'orderIdFormat',
      },
    });
    this.changesSubject$ = new Subject();
  }

  componentDidMount(): * {
    const {
      executeGetCouriers,
      couriers,
      executeGetCouriersOrders,
      couriersOrders,
      isLoadingGetCouriersOrders,
    } = this.props;
    if (!couriers) executeGetCouriers();
    if (!couriersOrders && !isLoadingGetCouriersOrders) executeGetCouriersOrders();
    this.changesSubject$.pipe(debounceTime(1000)).subscribe(() => {
      const { executeGetCouriersOrders } = this.props;
      executeGetCouriersOrders(this.buildQueryParams());
    });
  }

  componentDidUpdate(prevProps: Props): * {
    const {
      isCancellingReservation,
      couriersOrders,
      executeGetCouriersOrders,
      isLoadingGetCouriersOrders,
    } = this.props;
    if (prevProps.isCancellingReservation && !isCancellingReservation && !!couriersOrders && couriersOrders.length === 0) {
      executeGetCouriersOrders();
    }
    // if (prevProps.isLoadingGetCouriersOrders && !isLoadingGetCouriersOrders && !!couriersOrders && couriersOrders.length === 1) {
    //   this.setState({ orderId: { value: couriersOrders[0].id } });
    // }
    if (!prevProps.couriersOrders && couriersOrders) {
      const realCostMap = couriersOrders.reduce((map, courierOrder: ShipmentOrder) => ({
        ...map,
        [courierOrder.id]: courierOrder.invoice.accountingData ?
          courierOrder.invoice.accountingData.actual_total : '',
      }), {});
      this.setState({ realCost: realCostMap })
    }
  }

  componentWillUnmount(): * {
    if (this.changesSubject$) {
      this.changesSubject$.unsubscribe();
    }
  }

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>, orderId?: string) => {
    let { value, name } = (e.target: { [key: string]: string });
    if (name === 'orderId') {
      const errors = this.formValidator.validateField('orderId', value);
      if (errors.length) {
        this.setState({ [name]: { value: '', errors } });
        name = 'trackingId';
      } else {
        this.setState({ trackingId: { value: '' } });
      }
    } else if (name === 'realCost') {
      this.setState((state: State) => ({
        [name]: {
          ...state.realCost,
          [orderId]: value,
        },
      }));
      return;
    }
    this.setState({ [name]: { value } }, this.changesSubject$.next());
  };

  handleChangePage = (e: Event, page: number) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({ page: 0, rowsPerPage: parseInt(value) });
  };

  buildPurchaser = (order: ShipmentOrder): Node => {
    const { classes } = this.props;
    const { purchaser, shippingIdentity } = order;
    if (!purchaser) return <div/>;
    return (
      <Fragment>
        <div className={classes.userImageWrap}>
          <img className={classes.userImage} src={purchaser.image || defaultPic} alt={purchaser.firstName}/>
        </div>
        <p className={classes.userName}>
          {purchaser.firstName} {purchaser.lastName}
        </p>
        {!!shippingIdentity && (
          <div className={classes.shippingIdentityWrap}>
            <p className={classes.identityProperty}>
              DNI:&nbsp;
              <span className={classes.identitySpan}>{shippingIdentity.idNumber1}</span>
            </p>
            <p className={classes.identityProperty}>
              RUC:&nbsp;
              <span className={classes.identitySpan}>{shippingIdentity.idNumber2}</span>
            </p>
            <p className={classes.identityProperty}>
              ID:&nbsp;
              <a
                className={classes.identitySpanUrl}
                href={`${environment.apiUrl}/stored-file/shipping-identity/${order.id}/front`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Front
              </a>
              <a
                className={classes.identitySpanUrl}
                href={`${environment.apiUrl}/stored-file/shipping-identity/${order.id}/back`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Back
              </a>
            </p>
          </div>
        )}
      </Fragment>
    );
  };

  buildQueryParams = (): { [key: string]: string | number } => {
    const params = {};
    const { courierId, orderState, orderType, orderId, trackingId, hasTracking } = this.state;
    if (!!courierId.value) params.courierId = courierId.value;
    if (!!orderState.value) params.orderState = orderState.value;
    if (!!orderType.value) params.orderType = orderType.value;
    if (!!orderId.value) params.orderId = orderId.value;
    if (!!trackingId.value) params.trackingId = trackingId.value;
    if (!!hasTracking.value) params.hasTracking = hasTracking.value;
    return params;
  };

  handleReset = () => {
    this.setState(initialState, this.changesSubject$.next());
  };

  handleCancelReservation = (shipmentId: number, orderId: string) => {
    const { executeCancelReservation } = this.props;
    executeCancelReservation(shipmentId, orderId);
  };

  verifyHasTracking = (shipment: Shipment): boolean =>
    shipment.items.reduce(
      (acc: boolean, shipmentItem: ShipmentItem) =>
        acc && !!shipmentItem.orderItem.tracking && !!shipmentItem.orderItem.tracking.trackingNumber,
      true,
    );

  buildFromNow = (paidAt: string) => {
    const m = moment(paidAt);
    const days = Math.round(moment.duration(moment() - m).asDays());
    const date = m.format('DD-MM-YYYY');
    return `${days} days ago. ${date}`;
  }

  handleOnKeyPress = (e: SyntheticKeyboardEvent<HTMLInputElement>, invoiceId: string, orderId: string) => {
    const { charCode, target } = e;
    const { executeSetRealCost } = this.props;
    if (charCode === 13 && target instanceof HTMLInputElement) {
      if (target.value) {
        const realCostValue = parseFloat(target.value).toFixed(2);
        executeSetRealCost(realCostValue, invoiceId, orderId);
      }
    }
  };

  buildOrders = (couriersOrders: ShipmentOrder[]) => {
    const {
      classes,
      isLoadingUpdateInvoice,
      isLoadingUpdateTracking,
      isLoadingUpdateStoreOrderId,
      isCancellingReservation,
      executeUpdateOrderItemInvoice,
      executeUpdateOrderItemTracking,
      executeUpdateOrderItemStoreOrderId,
      executeReceiveOrderItem,
      isReceivingOrderItem,
      updateInvoiceError,
      executeMissingTrackingMail,
      isPostingMissingTrackingMail,
      isLoadingSetRealCost,
    } = this.props;
    const { realCost } = this.state;
    return couriersOrders.map((couriersOrder: ShipmentOrder, index: number) =>
      couriersOrder.shipments.map((shipment: Shipment) => (
        <Grid item xs={12} key={index}>
          <Paper
            className={classNames(classes.couriersOrderWrap, {
              [classes.cancellingReservation]: isCancellingReservation,
            })}
          >
            <Grid container spacing={16}>
              <Grid item container xs={8} direction="column">
                <Grid item className={classes.topLeftWrap}>
                  <h1 className={classes.orderId}>{couriersOrder.id}</h1>
                  <p className={classes.paidAt}>
                    Order bought <b>{this.buildFromNow(couriersOrder.paidAt)}</b>
                  </p>
                  {this.buildPurchaser(couriersOrder)}
                </Grid>
                {shipment.items.map((shipmentItem: ShipmentItem, i: number) => (
                  <Grid item className={classes.orderItemWrap} key={i}>
                    <OrderItem
                      item={shipmentItem.orderItem}
                      orderState={couriersOrder.state}
                      isDiy={couriersOrder.isDiy}
                      isLoading={
                        isLoadingUpdateInvoice ||
                        isLoadingUpdateTracking ||
                        isLoadingUpdateStoreOrderId ||
                        isReceivingOrderItem
                      }
                      executeUpdateOrderItemInvoice={executeUpdateOrderItemInvoice}
                      updateInvoiceError={updateInvoiceError}
                      executeUpdateOrderItemTracking={executeUpdateOrderItemTracking}
                      executeUpdateOrderItemStoreOrderId={executeUpdateOrderItemStoreOrderId}
                      executeReceiveOrderItem={executeReceiveOrderItem}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={4}>
                <Grid container justify="space-between" alignItems="center" className={classes.topRightWrap}>
                  <Grid item>
                    <div className={classes.courierImageWrap}>
                      <img
                        className={classes.courierImage}
                        src={shipment.courier.image || defaultPic}
                        alt={shipment.courier.name.substr(0, 8)}
                      />
                    </div>
                    <p className={classes.courierName}>{shipment.courier.name}</p>
                  </Grid>
                  <Grid item container xs className={classes.orderInfo}>
                    <Grid item>
                      <p className={classes.orderState}>{couriersOrder.state}</p>
                    </Grid>
                    <Grid item>
                      <p
                        className={classes.orderType}>{couriersOrder.isDiy ? 'Qempo Lite' : 'Qempo Full'}</p>
                    </Grid>
                  </Grid>
                </Grid>
                <div className={classes.summary}>
                  <Summary order={couriersOrder}/>
                </div>
                {couriersOrder.state === 'RESERVED' && (
                  <Button
                    className={classes.buttonCancelReservation}
                    fullWidth
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={() => this.handleCancelReservation(shipment.shipmentId, couriersOrder.id)}
                  >
                    Cancel reservation
                  </Button>
                )}
                {!this.verifyHasTracking(shipment) &&
                <Button
                  className={classes.buttonMissingTracking}
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={isPostingMissingTrackingMail}
                  onClick={() => executeMissingTrackingMail(couriersOrder.id)}
                >
                  Send missing tracking mail
                </Button>}
                <TrackingNotificationStatusContainer
                  orderId={couriersOrder.id}
                />
                <TextField
                  label="Real cost of order"
                  name="realCost"
                  className={isLoadingSetRealCost ? classes.realCostInput : ''}
                  value={realCost[couriersOrder.id] || ''}
                  onChange={
                    (e: SyntheticInputEvent<HTMLInputElement>) =>
                      this.handleChange(e, couriersOrder.id)
                  }
                  onKeyPress={
                    (e: SyntheticKeyboardEvent<HTMLInputElement>) =>
                      this.handleOnKeyPress(e, couriersOrder.invoice.id, couriersOrder.id)
                  }
                  helperText="Press Enter to save"
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )),
    )
  };

  render() {
    const {
      classes,
      isLoadingGetCouriers,
      couriers,
      isLoadingGetCouriersOrders,
      couriersOrders,
    } = this.props;
    const error =
      this.props.getCouriersError ||
      this.props.getCouriersOrdersError ||
      this.props.updateInvoiceError ||
      this.props.updateTrackingError ||
      this.props.updateStoreOrderIdError ||
      this.props.cancelReservationError ||
      this.props.receiveOrderItemError ||
      this.props.missingTrackingMailError ||
      this.props.setRealCostError;
    let success = false;
    if (this.props.updateInvoiceSuccess) success = 'Invoice Updated';
    if (this.props.updateTrackingSuccess) success = 'Tracking Number Updated';
    if (this.props.updateStoreOrderIdSuccess) success = 'Store OrderId Updated';
    if (this.props.receiveOrderItemSuccess) success = 'Reception successful';
    if (this.props.missingTrackingMailSuccess) success = 'Mail sent successfully';
    if (this.props.setRealCostSuccess) success = 'Real cost set successfully';
    const {
      courierId,
      orderState,
      orderType,
      orderId,
      trackingId,
      hasTracking,
      page,
      rowsPerPage,
    } = this.state;
    const couriersOptions: { [key: string]: string }[] = (couriers || []).map((courier: Courier) => {
      return {
        value: courier.id,
        label: courier.name,
        image: courier.image,
      };
    });
    const chosenCourier: ?Courier = (couriers || []).find(({ id }) => id === courierId.value);
    return (
      <div className={classes.courierTrackingWrap}>
        {isLoadingGetCouriers || !couriers ? (
          <div className={classes.topArea}>
            <CircularProgress className={classes.progressTop} size={50}/>
          </div>
        ) : (
          <Grid item container spacing={16}>
            <Grid item>
              <TextField
                value={!orderId.errors ? orderId.value : trackingId.value}
                // error={!!orderId.errors && !!orderId.errors}
                // helperText={!!orderId.errors && 'Incorrect order id'}
                label="Order id"
                name="orderId"
                variant="filled"
                onChange={this.handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon/>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              {!!chosenCourier && (
                <img
                  className={classes.courierSelectImage}
                  src={chosenCourier.image || defaultPic}
                  alt={chosenCourier.name.substring(0, 8)}
                />
              )}
              <FormControl className={classes.formControl}>
                <TextField value={courierId.value} label="Courier" name="courierId" variant="filled"
                           onChange={this.handleChange} select>
                  <MenuItem value="">None</MenuItem>
                  {couriersOptions.map((option: { [key: string]: string }, i: number) => (
                    <MenuItem key={i} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <TextField
                  value={orderState.value}
                  label="Order state"
                  name="orderState"
                  variant="filled"
                  onChange={this.handleChange}
                  select
                >
                  <MenuItem value="">None</MenuItem>
                  {orderStates.map((option: string, i: number) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <TextField value={orderType.value} label="Order type" name="orderType" variant="filled"
                           onChange={this.handleChange} select>
                  <MenuItem value="">None</MenuItem>
                  {orderTypes.map((option: string, i: number) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <TextField
                  value={hasTracking.value}
                  label="Has tracking"
                  name="hasTracking"
                  variant="filled"
                  onChange={this.handleChange}
                  select
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="true">Con tracking</MenuItem>
                  <MenuItem value="false">Sin tracking</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                className={classes.buttonReset}
                color="primary"
                disabled={isLoadingGetCouriersOrders}
                variant="contained"
                size="large"
                onClick={this.handleReset}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        )}
        {isLoadingGetCouriersOrders || !couriersOrders ? (
          <CircularProgress className={classes.progress} size={80}/>
        ) : (
          <Fragment>
            <Grid item container spacing={16}>
              {this.buildOrders(
                couriersOrders.slice(
                  page * rowsPerPage, page * rowsPerPage + rowsPerPage,
                ))}
              <Grid item xs={12}>
                <Paper>
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[100, 200, 300, 500]}
                    count={couriersOrders.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </Paper>
              </Grid>
            </Grid>
            {couriersOrders.length === 0 && <p className={classes.progress}>No orders available for tracking</p>}
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={!!error || !!success}
              autoHideDuration={2000}
              message={
                !!error ? (
                  <span
                    id="message-id">{error && error.data && error.data.Error ? error.data.Error.message : 'Generic Error'}</span>
                ) : (
                  <span>{success}</span>
                )
              }
            />
          </Fragment>
        )}
      </div>
    );
  }
}

CourierTracking.propTypes = {
  classes: PropTypes.object.isRequired,
  executeGetCouriers: PropTypes.func.isRequired,
  isLoadingGetCouriers: PropTypes.bool,
  couriers: PropTypes.array,
  getCouriersError: PropTypes.object,
  executeGetCouriersOrders: PropTypes.func.isRequired,
  isLoadingGetCouriersOrders: PropTypes.bool,
  couriersOrders: PropTypes.array,
  getCouriersOrdersError: PropTypes.object,
  executeUpdateOrderItemInvoice: PropTypes.func.isRequired,
  isLoadingUpdateInvoice: PropTypes.bool,
  updateInvoiceSuccess: PropTypes.bool,
  updateInvoiceError: PropTypes.object,
  executeUpdateOrderItemTracking: PropTypes.func.isRequired,
  isLoadingUpdateTracking: PropTypes.bool,
  updateTrackingSuccess: PropTypes.bool,
  updateTrackingError: PropTypes.object,
  executeUpdateOrderItemStoreOrderId: PropTypes.func.isRequired,
  isLoadingUpdateStoreOrderId: PropTypes.bool,
  updateStoreOrderIdSuccess: PropTypes.bool,
  updateStoreOrderIdError: PropTypes.object,
  executeCancelReservation: PropTypes.func.isRequired,
  isCancellingReservation: PropTypes.bool,
  cancelReservationSuccess: PropTypes.bool,
  cancelReservationError: PropTypes.object,
  executeReceiveOrderItem: PropTypes.func.isRequired,
  isReceivingOrderItem: PropTypes.bool,
  receiveOrderItemSuccess: PropTypes.bool,
  receiveOrderItemError: PropTypes.object,
  executeMissingTrackingMail: PropTypes.func,
  isPostingMissingTrackingMail: PropTypes.bool,
  missingTrackingMailSuccess: PropTypes.bool,
  missingTrackingMailError: PropTypes.object,
  executeSetRealCost: PropTypes.func.isRequired,
  isLoadingSetRealCost: PropTypes.bool,
  setRealCostSuccess: PropTypes.bool,
  setRealCostError: PropTypes.object,
};

export default withStyles(styles)(CourierTracking);
