// @flow
import Chip from '@material-ui/core/Chip';
import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import { Link } from 'react-router-dom';
// import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import moment from 'moment';
import ArrivedOrderItem from '../../../elements/ArrivedOrderItem/ArrivedOrderItem';

import styles from './style';

import type { GetDeliveryCouriersResponse } from '../../local-values/api';
import type { Shipment } from '../../../entities';

type Props = {
  +classes: { [key: string]: any };
  +handleSelect: (e: Event, orderId: string) => void;
  +handleSize: (e: Event, orderId: string) => void;
  +isLoading?: boolean;
  +stateOrders?: { [key: string]: any };
  +deliveryCourier?: GetDeliveryCouriersResponse;
  +shippingOrders?: { [key: string]: any }[];
  +page: number;
  +rowsPerPage: number;
};

class ShippingTableBody extends Component<Props> {

  getStateValues = (orderId: string) => {
    const { stateOrders } = this.props;
    const response: { [key: string]: any } = {
      selected: false,
      size: {
        value: 'MEDIUM',
        errors: false,
      },
    };
    if (stateOrders && stateOrders[orderId]) {
      if (stateOrders[orderId].size) {
        response.size = stateOrders[orderId].size;
      }
      if (stateOrders[orderId].selected) {
        response.selected = stateOrders[orderId].selected;
      }
    }
    return response;
  };

  getDeliveryDate = (shipments?: Shipment[]) => {
    if (!shipments) return;
    return shipments.reduce((acum, shipment: Shipment) =>
        shipment.deliveryDate ? shipment.deliveryDate : acum
      , "");
  }

  buildOrders = (shippingOrders: { [key: string]: any }[]) => {
    const { classes, handleSelect, handleSize, deliveryCourier } = this.props;
    if (shippingOrders && deliveryCourier) {
      return shippingOrders.map((order, index) => {
        const items = this.buildItems(order.items);
        const stateValues = this.getStateValues(order.id);
        const deliveryDate = this.getDeliveryDate(order.shipments);
        const title = (
          <Grid container>
            <Grid item container xs={6}>
              <Grid item container xs={2}>
                <Checkbox checked={stateValues.selected}
                          onChange={(e) => handleSelect(e, order.id)}/>
              </Grid>
              <Grid item container xs direction="row" alignItems="center">
                <Grid item>
                  <Paper className={classes.paper} elevation={2}>
                    <h6 className={classes.title}>{order.id}</h6>
                    <h6 className={classes.title}>
                      {order.shippingAddress.firstName} {order.shippingAddress.lastName}
                    </h6>
                    <h6 className={classes.title}>{order.purchaser.email}</h6>
                    <h6 className={classes.title}><span>DNI/RUC:</span> {order.shippingAddress.idNumber}</h6>
                    <h6 className={classes.title}>Phone: {order.shippingAddress.phone}</h6>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={classes.paper} elevation={2}>
                    <h6 className={classes.title}>Dirección:</h6>
                    <h6 className={classes.title}>
                      {order.shippingAddress.address1} {order.shippingAddress.address2 && order.shippingAddress.address2}
                    </h6>
                    <h6 className={classes.title}>
                      {order.shippingAddress.admin1.name} {order.shippingAddress.admin2.name}
                    </h6>
                    {order.shippingAddress.admin3 &&
                    <h6 className={classes.title}>{order.shippingAddress.admin3.name}</h6>}
                  </Paper>
                </Grid>
                {!!order.incomplete &&
                <Chip label="Incomplete"
                      style={{ backgroundColor: '#f44336', color: 'white', margin: 'auto 0 auto 12px' }}
                      color="secondary"/>}
              </Grid>
            </Grid>
            <Grid item container xs={4}>
              <div className={classes.textContainer}>
                {(order.beforeDate || deliveryDate) ?
                  <h6 className={classes.title}>{
                    moment(order.beforeDate || deliveryDate).format('DD/MM/YYYY')
                  }</h6> : <div>💁‍</div>}
              </div>
            </Grid>
            <Grid item container xs={2}>
              <div className={classes.textContainer}>
                {deliveryCourier.shippingSizes.length > 1 && <TextField value={stateValues.size.value}
                                                                        variant="outlined"
                                                                        name="size"
                                                                        error={!!stateValues.size.errors}
                                                                        style={{ width: 160 }}
                                                                        SelectProps={{
                                                                          SelectDisplayProps: {
                                                                            style: {
                                                                              paddingTop: 9,
                                                                              paddingBottom: 8,
                                                                            },
                                                                          },
                                                                        }}
                                                                        select
                                                                        onChange={(e) => handleSize(e, order.id)}>
                  {deliveryCourier.shippingSizes.map(option => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </TextField>}
              </div>
            </Grid>
          </Grid>
        );
        const marginBottom = (index === (shippingOrders.length - 1)) ? 0 : 36;
        return (
          <Grid item xs={12} key={index} style={{ marginBottom }}>
            <Card>
              <CardHeader className={classes.orderHeader}
                          title={title}/>
              {items}
            </Card>
          </Grid>
        );
      });
    }
  };

  buildItems = (items: { [key: string]: any }) => {
    const { classes } = this.props;
    return items.map((item, itemIndex) => {
      return (
        <CardContent key={itemIndex} style={{background: item.hasArrived ? '#9aea9c' : 'white'}}>
          <Grid container spacing={16} justify="space-evenly">
            <Grid item spacing={16} container xs={3}>
              <Grid item xs>
                <Card className={classes.imageCard}>
                  <CardMedia component="img"
                             image={item.image}/>
                </Card>
              </Grid>
              <Grid item xs container alignItems="center">
                <a className={classes.productUrl}
                   href={item.url}
                   target="_blank"
                   rel="noopener noreferrer">
                  {item.name}
                </a>
              </Grid>
            </Grid>
            <Grid item container spacing={8} xs={5}>
              <Grid item container xs={3}>
                <div className={classes.textContainer}>
                  Quantity:
                </div>
              </Grid>
              <Grid item container xs={2}>
                <div className={classes.textContainer}>
                  {this.getQuantityCell(item)}
                </div>
              </Grid>
              <Grid item container xs={4}>
                <div className={classes.textContainer}>
                  {this.getCompletenessCell(item.receivedCount - item.shippedCount, item.amount - item.cancelledCount - item.shippedCount)}
                </div>
              </Grid>
              <Grid item container xs={3}>
                <div className={classes.textContainer}>
                  {item.originalPackaging ? 'With Box' : 'Without Box'}
                </div>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <ArrivedOrderItem orderItem={item} />
            </Grid>
          </Grid>
        </CardContent>
      );
    });
  };

  getQuantityCell = (item: { [key: string]: any }) => {
    const { classes } = this.props;
    const { receivedCount, cancelledCount, shippedCount, amount } = item;
    if (receivedCount - shippedCount === amount - cancelledCount - shippedCount) {
      return (
        <div className={classes.textContainer} style={{ color: green[500] }}>
          {receivedCount - shippedCount}/{amount - cancelledCount - shippedCount}
        </div>
      );
    } else {
      return (
        <div className={classes.textContainer} style={{ color: red[500] }}>
          {receivedCount - shippedCount}/{amount - cancelledCount - shippedCount}
        </div>
      );
    }
  };

  getCompletenessCell = (receivedCount: number, amount: number) => {
    const { classes } = this.props;
    if (receivedCount === amount) {
      return (
        <Typography variant="subtitle1"
                    component="h6"
                    className={classes.completenessCell}
                    style={{
                      color: green[500],
                      borderColor: green[500],
                    }}>
          Complete
        </Typography>
      );
    } else {
      return (
        <Typography variant="subtitle1"
                    component="h6"
                    className={classes.completenessCell}
                    style={{
                      color: red[500],
                      borderColor: red[500],
                    }}>
          Incomplete
        </Typography>
      );
    }
  };

  buildBody = () => {
    const {
      classes,
      isLoading,
      shippingOrders,
      page,
      rowsPerPage,
    } = this.props;
    if (isLoading) {
      return (
        <Paper style={{ width: '100%' }}>
          <div className={classes.progressContainer}>
            <CircularProgress className={classes.tableProgress} size={70}/>
          </div>
        </Paper>
      );
    } else {
      if (shippingOrders && shippingOrders.length) {
        return this.buildOrders(
          shippingOrders.slice(
            page * rowsPerPage, page * rowsPerPage + rowsPerPage,
          ));
      } else {
        return (
          <Paper style={{ width: '100%' }}>
            <div style={{ height: '58vh', textAlign: 'center', verticalAlign: 'middle', lineHeight: '50vh' }}>
              <h5>No matches found</h5>
            </div>
          </Paper>
        );
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.rootContainer}>
        {this.buildBody()}
      </Grid>
    );
  }
}

ShippingTableBody.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleSize: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  deliveryCourier: PropTypes.object,
  stateOrders: PropTypes.object,
  shippingOrders: PropTypes.array,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default withStyles(styles)(ShippingTableBody);
