// @flow
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import styles from './style';

type Props = {
  +classes: { [key: string]: any };
}

class ReceptionTableHead extends PureComponent<Props> {
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.headContainer}>
        <Grid item container xs={4}>
          <Grid item xs={3}/>
          <Grid item xs={6} className={classes.headCell}>
            flightId
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.headCell}>
          traveler
        </Grid>
        <Grid item xs={4} className={classes.headCell}>
          flightDate
        </Grid>
      </Grid>
    );
  }
}

ReceptionTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReceptionTableHead);
