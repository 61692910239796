// @flow
import { Observable, from } from 'rxjs';
import { getHttpErrorName } from './errors';

import type { HTTPError } from '../error';

export const methods = Object.freeze({
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
});

export * from './errors';

export type Method = $Keys<typeof methods>;

export type ApiOptions = {
  // data?: any;
  credentials?: boolean;
  headers?: any;
  queryParams?: { [key: string]: string };
};

export type ApiCreatorOptions = ApiOptions & {
  route: string;
  method?: Method;
  data?: any;
};

function apiCreator(_fetch: any, o: ApiCreatorOptions): Observable {
  const option: Object = {
    method: o.method || methods.GET,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...o.headers,
    },
  };

  if (o.credentials !== false) {
    // option.credentials = 'same-origin';
    option.credentials = 'include';
  }

  if (o.data) {
    if (o.headers && o.headers['Content-Type'] === 'multipart/form-data') {
      option.body = o.data;
      // Hack to fetch in order to generate boundary to content type only in browser
      // We should't upload multipart from server
      delete option.headers['Content-Type'];
    } else option.body = JSON.stringify(o.data);
  }

  if (typeof o.queryParams === 'object') {
    const query = o.queryParams;
    const qp = Object.keys(o.queryParams).map(q => {
      if (typeof query[q] === 'string')
        return `${q}=${encodeURIComponent(query[q])}`;
      else if (query[q] instanceof Array)
        return `${q}=${encodeURI(query[q].join(','))}`;
      else if (typeof query[q] === 'number') {
        return `${q}=${query[q]}`;
      }
      return `${q}=${query[q]}`;
    });
    o.route = `${o.route}?${qp.join('&')}`;
  }
  // Return observable and catch http errors
  return from(
    _fetch(o.route, option)
      .then(response => new Promise((resolve, reject) =>
        response.json()
          .then((x = {}) => {
              if (response.ok) {
                return resolve(x);
              } else {
                const error: HTTPError = {
                  code: response.status,
                  name: getHttpErrorName(response.status),
                  data: x,
                  url: o.route,
                };
                return reject(error);
              }
            },
          )
          .catch(r => {
            // If it's 2XX family then resolve contentless
            const c = Math.floor(response.status / 100);
            if (c === 2) return resolve({});
            return reject({
              code: response.status,
              name: getHttpErrorName(response.status),
              url: o.route,
            });
          }),
      ).catch(e => Promise.reject(e))),
  );
}

// Why so much closure stuff ??
// > Because we want to make it testable with a stub fetch & we want to separate the url string
// > handling to an api file in order to maintain the code organized
export function getCreator(_fetch: any, route: string): (queryParams?: { [key: string]: any },
                                                         o?: ApiOptions) => Observable<any> {
  return (queryParams?: any, o?: ApiOptions) => apiCreator(_fetch, { route, ...o, queryParams, method: methods.GET });
}

export function postCreator(_fetch: any, route: string): (data?: any, o?: ApiOptions) => Observable<any> {
  return (data?: any, o?: ApiOptions) => apiCreator(_fetch, { route, ...o, data, method: methods.POST });
}

export function deleteCreator(_fetch: any, route: string): (data?: any, o?: ApiOptions) => Observable<any> {
  return (data?: any, o?: ApiOptions) => apiCreator(_fetch, { route, ...o, data, method: methods.DELETE });
}

export function putCreator(_fetch: any, route: string): (data?: any, o?: ApiOptions) => Observable<any> {
  return (data?: any, o?: ApiOptions) => apiCreator(_fetch, { route, ...o, data, method: methods.PUT });
}
