// @flow
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { getDeliveryConfirmApi, confirmDeliveryConfirmApi, updateDeliveryConfirmApi, cancelDeliveryConfirmApi } from './api';

import type { HTTPError } from '../../common/error';
import type { ConfirmDeliveryConfirmRequest, UpdateDeliveryConfirmRequest, CancelDeliveryConfirmRequest } from './api';

// Actions
const GET_DELIVERY_CONFIRM_REQUEST = 'GET_DELIVERY_CONFIRM_REQUEST';
const GET_DELIVERY_CONFIRM_SUCCESS = 'GET_DELIVERY_CONFIRM_SUCCESS';
const GET_DELIVERY_CONFIRM_ERROR = 'GET_DELIVERY_CONFIRM_ERROR';
const CONFIRM_DELIVERY_CONFIRM_REQUEST = 'CONFIRM_DELIVERY_CONFIRM_REQUEST';
const CONFIRM_DELIVERY_CONFIRM_SUCCESS = 'CONFIRM_DELIVERY_CONFIRM_SUCCESS';
const CONFIRM_DELIVERY_CONFIRM_ERROR = 'CONFIRM_DELIVERY_CONFIRM_ERROR';
const UPDATE_DELIVERY_CONFIRM_REQUEST = 'UPDATE_DELIVERY_CONFIRM_REQUEST';
const UPDATE_DELIVERY_CONFIRM_SUCCESS = 'UPDATE_DELIVERY_CONFIRM_SUCCESS';
const UPDATE_DELIVERY_CONFIRM_ERROR = 'UPDATE_DELIVERY_CONFIRM_ERROR';
const CANCEL_DELIVERY_CONFIRM_REQUEST = 'CANCEL_DELIVERY_CONFIRM_REQUEST';
const CANCEL_DELIVERY_CONFIRM_SUCCESS = 'CANCEL_DELIVERY_CONFIRM_SUCCESS';
const CANCEL_DELIVERY_CONFIRM_ERROR = 'CANCEL_DELIVERY_CONFIRM_ERROR';
const CHANGE_DELIVERY_CONFIRM_PAGINATION = 'CHANGE_DELIVERY_CONFIRM_PAGINATION';
const RESET_DELIVERY_CONFIRM_STATE = 'RESET_DELIVERY_CONFIRM_STATE';

type DeliveryConfirmAction = {
  +type: string;
  +deliveryConfirmOrders?: { [key: string]: any }[];
  +totalDeliveryConfirmOrders?: number;
  +shippingRequest?: { [key: string]: any };
  +changes?: { [key: string]: any };
  +error?: HTTPError;
};

export type DeliveryConfirmState = {
  page: number;
  limit: number;
  search: string;
  filterKey: string;
  isLoadingGetDeliveryConfirm?: boolean;
  getDeliveryConfirmSuccess?: boolean;
  getDeliveryConfirmError?: HTTPError;
  isLoadingConfirmDeliveryConfirm?: boolean;
  confirmDeliveryConfirmSuccess?: boolean;
  confirmDeliveryConfirmError?: HTTPError;
  isLoadingUpdateDeliveryConfirm?: boolean;
  updateDeliveryConfirmSuccess?: boolean;
  updateDeliveryConfirmError?: HTTPError;
  isLoadingCancelDeliveryConfirm?: boolean;
  cancelDeliveryConfirmSuccess?: boolean;
  cancelDeliveryConfirmError?: HTTPError;
  deliveryConfirmOrders?: { [key: string]: any }[];
  totalDeliveryConfirmOrders?: number;
};

// Action Creators
export function executeGetDeliveryConfirm(filter?: { [key: string]: any }): DeliveryConfirmAction {
  return {
    type: GET_DELIVERY_CONFIRM_REQUEST,
    filter,
  };
}

export function executeGetDeliveryConfirmSuccess(deliveryConfirmOrders: { [key: string]: any }[]): DeliveryConfirmAction {
  return {
    type: GET_DELIVERY_CONFIRM_SUCCESS,
    deliveryConfirmOrders,
    // totalDeliveryConfirmOrders,
  };
}

export function executeGetDeliveryConfirmError(error: HTTPError): DeliveryConfirmAction {
  return {
    type: GET_DELIVERY_CONFIRM_ERROR,
    error,
  };
}

export function executeConfirmDeliveryConfirm(request: ConfirmDeliveryConfirmRequest): DeliveryConfirmAction {
  return {
    type: CONFIRM_DELIVERY_CONFIRM_REQUEST,
    request,
  };
}

export function executeConfirmDeliveryConfirmSuccess(): DeliveryConfirmAction {
  return {
    type: CONFIRM_DELIVERY_CONFIRM_SUCCESS,
  };
}

export function executeConfirmDeliveryConfirmError(error: HTTPError): DeliveryConfirmAction {
  return {
    type: CONFIRM_DELIVERY_CONFIRM_ERROR,
    error,
  };
}

export function executeUpdateDeliveryConfirm(request: UpdateDeliveryConfirmRequest): DeliveryConfirmAction {
  return {
    type: UPDATE_DELIVERY_CONFIRM_REQUEST,
    request,
  };
}

export function executeUpdateDeliveryConfirmSuccess(): DeliveryConfirmAction {
  return {
    type: UPDATE_DELIVERY_CONFIRM_SUCCESS,
  };
}

export function executeUpdateDeliveryConfirmError(error: HTTPError): DeliveryConfirmAction {
  return {
    type: UPDATE_DELIVERY_CONFIRM_ERROR,
    error,
  };
}

export function executeCancelDeliveryConfirm(request: CancelDeliveryConfirmRequest): DeliveryConfirmAction {
  return {
    type: CANCEL_DELIVERY_CONFIRM_REQUEST,
    request,
  };
}

export function executeCancelDeliveryConfirmSuccess(): DeliveryConfirmAction {
  return {
    type: CANCEL_DELIVERY_CONFIRM_SUCCESS,
  };
}

export function executeCancelDeliveryConfirmError(error: HTTPError): DeliveryConfirmAction {
  return {
    type: CANCEL_DELIVERY_CONFIRM_ERROR,
    error,
  };
}

export function changeDeliveryConfirmPagination(changes: { [key: string]: any }) {
  return {
    type: CHANGE_DELIVERY_CONFIRM_PAGINATION,
    changes,
  };
}

export function executeResetDeliveryConfirmState(): DeliveryConfirmAction {
  return {
    type: RESET_DELIVERY_CONFIRM_STATE,
  };
}

export const actions = {
  GET_DELIVERY_CONFIRM_REQUEST,
  GET_DELIVERY_CONFIRM_SUCCESS,
  GET_DELIVERY_CONFIRM_ERROR,
  CONFIRM_DELIVERY_CONFIRM_REQUEST,
  CONFIRM_DELIVERY_CONFIRM_SUCCESS,
  CONFIRM_DELIVERY_CONFIRM_ERROR,
  UPDATE_DELIVERY_CONFIRM_REQUEST,
  UPDATE_DELIVERY_CONFIRM_SUCCESS,
  UPDATE_DELIVERY_CONFIRM_ERROR,
  CANCEL_DELIVERY_CONFIRM_REQUEST,
  CANCEL_DELIVERY_CONFIRM_SUCCESS,
  CANCEL_DELIVERY_CONFIRM_ERROR,
  CHANGE_DELIVERY_CONFIRM_PAGINATION,
  RESET_DELIVERY_CONFIRM_STATE,
  executeGetDeliveryConfirm,
  executeGetDeliveryConfirmSuccess,
  executeGetDeliveryConfirmError,
  executeConfirmDeliveryConfirm,
  executeConfirmDeliveryConfirmSuccess,
  executeConfirmDeliveryConfirmError,
  executeUpdateDeliveryConfirm,
  executeUpdateDeliveryConfirmSuccess,
  executeUpdateDeliveryConfirmError,
  executeCancelDeliveryConfirm,
  executeCancelDeliveryConfirmSuccess,
  executeCancelDeliveryConfirmError,
  changeDeliveryConfirmPagination,
  executeResetDeliveryConfirmState,
};

export const initialState: DeliveryConfirmState = {
  page: 0,
  limit: 10,
  search: '',
  filterKey: 'id',
};

// Reducer
export default function reducer(state: DeliveryConfirmState = initialState,
                                action: DeliveryConfirmAction): DeliveryConfirmState {
  switch (action.type) {
    case GET_DELIVERY_CONFIRM_REQUEST:
      return {
        ...state,
        isLoadingGetDeliveryConfirm: true,
        getDeliveryConfirmSuccess: false,
      };
    case GET_DELIVERY_CONFIRM_SUCCESS:
      return {
        ...state,
        isLoadingGetDeliveryConfirm: false,
        getDeliveryConfirmSuccess: true,
        deliveryConfirmOrders: action.deliveryConfirmOrders,
        totalDeliveryConfirmOrders: action.totalDeliveryConfirmOrders,
      };
    case GET_DELIVERY_CONFIRM_ERROR:
      return {
        ...state,
        getDeliveryConfirmSuccess: false,
        getDeliveryConfirmError: action.error,
      };
    case CONFIRM_DELIVERY_CONFIRM_REQUEST:
      return {
        ...state,
        isLoadingConfirmDeliveryConfirm: true,
        sendProductsSuccess: false,
      };
    case CONFIRM_DELIVERY_CONFIRM_SUCCESS:
      return {
        ...state,
        isLoadingConfirmDeliveryConfirm: false,
        confirmDeliveryConfirmSuccess: true,
      };
    case CONFIRM_DELIVERY_CONFIRM_ERROR:
      return {
        ...state,
        confirmDeliveryConfirmSuccess: false,
        confirmDeliveryConfirmError: action.error,
      };
    case UPDATE_DELIVERY_CONFIRM_REQUEST:
      return {
        ...state,
        isLoadingUpdateDeliveryConfirm: true,
        updateDeliveryConfirmSuccess: false,
      };
    case UPDATE_DELIVERY_CONFIRM_SUCCESS:
      return {
        ...state,
        isLoadingUpdateDeliveryConfirm: false,
        updateDeliveryConfirmSuccess: true,
      };
    case UPDATE_DELIVERY_CONFIRM_ERROR:
      return {
        ...state,
        updateDeliveryConfirmSuccess: false,
        updateDeliveryConfirmError: action.error,
      };
    case CANCEL_DELIVERY_CONFIRM_REQUEST:
      return {
        ...state,
        isLoadingCancelDeliveryConfirm: true,
        cancelDeliveryConfirmSuccess: false,
      };
    case CANCEL_DELIVERY_CONFIRM_SUCCESS:
      return {
        ...state,
        isLoadingCancelDeliveryConfirm: false,
        cancelDeliveryConfirmSuccess: true,
      };
    case CANCEL_DELIVERY_CONFIRM_ERROR:
      return {
        ...state,
        cancelDeliveryConfirmSuccess: false,
        cancelDeliveryConfirmError: action.error,
      };
    case CHANGE_DELIVERY_CONFIRM_PAGINATION:
      const data = { ...action.changes };
      return { ...state, ...data };
    case RESET_DELIVERY_CONFIRM_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetDeliveryConfirmEpic(action$: ActionsObservable<DeliveryConfirmAction>) {
  return action$.ofType(GET_DELIVERY_CONFIRM_REQUEST).pipe(
    mergeMap(
      ({ filter }) => getDeliveryConfirmApi(filter).pipe(
        map((deliveryConfirmOrders) => executeGetDeliveryConfirmSuccess(deliveryConfirmOrders)),
        catchError((e: HTTPError) => of(executeGetDeliveryConfirmError(e))))
    )
  );
}

export function executeConfirmDeliveryConfirmEpic(action$: ActionsObservable<DeliveryConfirmAction>) {
  return action$.ofType(CONFIRM_DELIVERY_CONFIRM_REQUEST).pipe(
    mergeMap(
      ({ request }) => confirmDeliveryConfirmApi(request).pipe(
        map(() => executeConfirmDeliveryConfirmSuccess()),
        catchError((e: HTTPError) => of(executeConfirmDeliveryConfirmError(e))))
    )
  );
}

export function executeUpdateDeliveryConfirmEpic(action$: ActionsObservable<DeliveryConfirmAction>) {
  return action$.ofType(UPDATE_DELIVERY_CONFIRM_REQUEST).pipe(
    mergeMap(
      ({ request }) => updateDeliveryConfirmApi(request).pipe(
        map(() => executeUpdateDeliveryConfirmSuccess()),
        catchError((e: HTTPError) => of(executeUpdateDeliveryConfirmError(e))))
    )
  );
}

export function executeCancelDeliveryConfirmEpic(action$: ActionsObservable<DeliveryConfirmAction>) {
  return action$.ofType(CANCEL_DELIVERY_CONFIRM_REQUEST).pipe(
    mergeMap(
      ({ request }) => cancelDeliveryConfirmApi(request).pipe(
        map(() => executeCancelDeliveryConfirmSuccess()),
        catchError((e: HTTPError) => of(executeCancelDeliveryConfirmError(e))))
    )
  );
}

export function changeDeliveryConfirmPaginationEpic(action$: ActionsObservable<DeliveryConfirmAction>) {
  return action$.ofType(CHANGE_DELIVERY_CONFIRM_PAGINATION).pipe(
    map(() => executeGetDeliveryConfirm()
    )
  );
}

export function executeGetDeliveryConfirmAfterPostEpic(action$: ActionsObservable<DeliveryConfirmAction>) {
  return action$.ofType(CONFIRM_DELIVERY_CONFIRM_SUCCESS, UPDATE_DELIVERY_CONFIRM_SUCCESS, CANCEL_DELIVERY_CONFIRM_SUCCESS).pipe(
    map(() => executeGetDeliveryConfirm()
    )
  );
}
