// @flow
export default function styles(theme: { [key: string]: any }) {
  return ({
    progress: {
      position: 'absolute',
      top: '50%',
      [theme.breakpoints.up('sm')]: {
        left: '50%',
      },
      left: '40%',
    },
    loading: {
      opacity: 0.35,
    },
    orderWrap: {
      maxWidth: 1200,
      margin: 'auto',
    },
    orderId: {
      fontSize: 32,
      lineHeight: '40px',
      fontWeight: 500,
      margin: '16px 0',
    },
    userImageWrap: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    userImage: {
      display: 'block',
      borderRadius: '50%',
      width: 50,
      height: 50,
      marginRight: 8,
    },
    userName: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    item: {
      margin: 8,
    },
    itemImage: {
      width: 250,
      height: 250,
    },
    property: {
      fontSize: 13,
      lineHeight: '16px',
      color: '#9E9E9E',
    },
    span: {
      fontSize: 16,
      lineHeight: '24px',
      color: '#212121',
    },
    name: {
      marginBottom: 0,
      color: '#2196F3',
      textDecoration: 'none',
    },
    topRightWrap: {
      minHeight: 138,
    },
    courierImage: {
      width: 56,
      height: 56,
      borderRadius: '50%',
      marginRight: 8,
    },
    formControl: {
      minWidth: 120,
    },
    summary: {
      padding: '20px 16px',
    },
    realCostInput: {
      opacity: 0.6,
      pointerEvents: 'none',
      userSelect: 'none',
    },
    reserved: {
      margin: 0,
      color: '#F44336',
    }
  });
}
