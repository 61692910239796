// @flow
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { endpoints, getOneCouponApi, updateCouponApi, createCouponApi } from './api';

import type { HTTPError } from '../../common/error';
import type { Coupon } from '../../entities/webapp';
import type { UpdateCouponRequest, UpdateRulesRequest } from './api';

// Actions
const GET_COUPON_REQUEST = 'GET_COUPON_REQUEST';
const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
const GET_COUPON_ERROR = 'GET_COUPON_ERROR';
const SAVE_COUPON_REQUEST = 'SAVE_COUPON_REQUEST';
const SAVE_COUPON_SUCCESS = 'SAVE_COUPON_SUCCESS';
const SAVE_COUPON_ERROR = 'SAVE_COUPON_ERROR';
const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST';
const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
const CREATE_COUPON_ERROR = 'CREATE_COUPON_ERROR';
const RESET_COUPON_STATE = 'RESET_COUPON_STATE';

type CouponAction = {
  +type: string;
  +coupon?: Coupon;
  +id?: number;
  +error?: HTTPError;
  +req?: UpdateCouponRequest | UpdateRulesRequest;
};

export type CouponState = {
  isLoadingGetCoupon?: boolean;
  getCouponSuccess?: boolean;
  getCouponError?: HTTPError;
  isLoadingSaveCoupon?: boolean;
  saveCouponSuccess?: boolean;
  saveCouponError?: HTTPError;
  isLoadingCreateCoupon?: boolean;
  createCouponSuccess?: boolean;
  createCouponError?: HTTPError;
  coupon?: Coupon;
};

// Action Creators
export function executeGetCoupon(id: number): CouponAction {
  return {
    type: GET_COUPON_REQUEST,
    id,
  };
}

export function executeGetCouponSuccess(coupon: Coupon): CouponAction {
  return {
    type: GET_COUPON_SUCCESS,
    coupon,
  };
}

export function executeGetCouponErrorFn(error: HTTPError): CouponAction {
  return {
    type: GET_COUPON_ERROR,
    error,
  };
}

export function executeSaveCoupon(id: number, req: UpdateCouponRequest): CouponAction {
  return {
    type: SAVE_COUPON_REQUEST,
    id,
    req,
  }
}

export function executeSaveCouponSuccess(coupon: Coupon): CouponAction {
  return {
    type: SAVE_COUPON_SUCCESS,
    coupon,
  };
}

export function executeSaveCouponError(error: HTTPError): CouponAction {
  return {
    type: SAVE_COUPON_ERROR,
    error,
  };
}

export function executeCreateCoupon(req: UpdateCouponRequest): CouponAction {
  return {
    type: CREATE_COUPON_REQUEST,
    req,
  };
}

export function executeCreateCouponSuccess(coupon: Coupon): CouponAction {
  return {
    type: CREATE_COUPON_SUCCESS,
    coupon,
  };
}

export function executeCreateCouponError(error: HTTPError): CouponAction {
  return {
    type: CREATE_COUPON_ERROR,
    error,
  };
}

export function executeResetCouponState(): CouponAction {
  return {
    type: RESET_COUPON_STATE,
  };
}

export const actions = {
  GET_COUPON_REQUEST,
  GET_COUPON_SUCCESS,
  GET_COUPON_ERROR,
  SAVE_COUPON_REQUEST,
  SAVE_COUPON_SUCCESS,
  SAVE_COUPON_ERROR,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_ERROR,
  executeGetCoupon,
  executeGetCouponSuccess,
  executeGetCouponErrorFn,
  executeSaveCoupon,
  executeSaveCouponSuccess,
  executeSaveCouponError,
  executeCreateCoupon,
  executeCreateCouponSuccess,
  executeCreateCouponError,
  executeResetCouponState,
};

export const initialState = {
  isLoadingGetCoupon: true,
  getCouponsSuccess: false,
};

// Reducer
export default function reducer(state: CouponState = initialState,
                                action: CouponAction): CouponState {
  switch (action.type) {
    case GET_COUPON_REQUEST:
      return {
        isLoadingGetCoupon: true,
        getCouponSuccess: false,
      };
    case GET_COUPON_SUCCESS:
      return {
        isLoadingGetCoupon: false,
        getCouponSuccess: true,
        coupon: action.coupon,
      };
    case GET_COUPON_ERROR:
      return {
        ...state,
        getCouponsError: action.error,
      };
    case SAVE_COUPON_REQUEST:
      return {
        ...state,
        isLoadingSaveCoupon: true,
        saveCouponSuccess: false,
      };
    case SAVE_COUPON_SUCCESS:
      return {
        ...state,
        coupon: action.coupon,
        isLoadingSaveCoupon: false,
        saveCouponSuccess: true,
      };
    case SAVE_COUPON_ERROR:
      return {
        ...state,
        saveCouponError: action.error,
      };
    case CREATE_COUPON_REQUEST:
      return {
        ...state,
        isLoadingCreateCoupon: true,
        createCouponSuccess: false,
      };
    case CREATE_COUPON_SUCCESS:
      return {
        ...state,
        coupon: action.coupon,
        isLoadingCreateCoupon: false,
        createCouponSuccess: true,
      };
    case CREATE_COUPON_ERROR:
      return {
        ...state,
        createCouponError: action.error
      };
    case RESET_COUPON_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetCouponEpic(action$: ActionsObservable<CouponAction>) {
  return action$.ofType(GET_COUPON_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ id }) => getOneCouponApi(undefined, {
        route: endpoints.GET_COUPON.replace(':id', id)
      }).pipe(
        map(data => executeGetCouponSuccess(data)),
        catchError((e: HTTPError) => of(executeGetCouponErrorFn(e))))
    )
  );
}

export function executeSaveCouponEpic(action$: ActionsObservable<CouponAction>) {
  return action$.ofType(SAVE_COUPON_REQUEST).pipe(
    mergeMap(
      ({ req, id }) => updateCouponApi(req, {
        route: endpoints.UPDATE_COUPON.replace(':id', id)
      }).pipe(
        map((coupon) => executeSaveCouponSuccess(coupon)),
        catchError((e: HTTPError) => of(executeSaveCouponError(e))))
    ), delay(1000)
  );
}

export function executeCreateCouponEpic(action$: ActionsObservable<CouponAction>) {
  return action$.ofType(CREATE_COUPON_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ req }) => createCouponApi(req, {
        route: endpoints.CREATE_COUPON,
      }).pipe(
        map((coupon) => executeCreateCouponSuccess(coupon)),
        catchError((e: HTTPError) => of(executeCreateCouponError(e))))
    )
  );
}


