// @flow
export default function styles(theme: { [key: string]: mixed }) {
  return ({
    link: {
      textDecoration: 'none',
      color: 'black',
    },
    itemWrap: {
      padding: 16,
      width: '100%',
      cursor: 'pointer',
    },
    imagesWrap: {
      width: 160,
      height: 160,
    },
    id: {
      fontSize: 24,
      lineHeight: '32px',
    },
    separator: {
      width: 1,
      height: '100%',
      backgroundColor: '#EEEEEE',
    },
    commission: {
      fontSize: 24,
      lineHeight: '32px',
    },
  });
}
