// @flow
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default function styles(theme: { [key: string]: any }) {
  return {
    toolbar: {
      paddingTop: 24,
      marginBottom: 24,
    },
    filterSelect: {
      minWidth: 120,
      marginLeft: 10,
      marginTop: 'auto',
      marginBottom: 'auto',
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        marginBottom: 10,
        display: 'inline-block',
      },
    },
    select: {
      marginLeft: 16,
    },
    dateSelect: {
      marginLeft: 20,
      marginTop: 'auto',
      marginBottom: 'auto',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 20,
        marginTop: 10,
        marginBottom: 10,
        display: 'inline-block',
      },
    },
    tableSearch: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      marginLeft: 20,
      display: 'inline-block',
      width: 'auto',
      backgroundColor: fade('#656565', 0.15),
      '&:hover': {
        backgroundColor: fade('#656565', 0.25),
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: 20,
        marginTop: 10,
        marginBottom: 10,
        width: 'auto',
      },
    },
    tableInputRoot: {
      color: '#656565',
      overflow: 'hidden',
      width: '100%',
    },
    tableSearchInput: {
      color: '#656565',
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 10,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
    tableSearchIcon: {
      width: theme.spacing.unit * 9,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    toolbarButton: {
      marginRight: 10,
      marginLeft: 10,
      marginTop: 'auto',
      marginBottom: 'auto',
      [theme.breakpoints.down('xs')]: {
        display: 'inline-block',
        marginLeft: 16,
        marginRight: 0,
      },
      [theme.breakpoints.down('sm')]: {
        display: 'inline-block',
        marginLeft: 24,
        marginRight: 0,
        marginTop: 10,
        marginBottom: 10,
      },
    },
    buttonReset: {
      marginLeft: 16,
    },
    tableWrapper: {
      // overflowX: 'auto',
    },
    headContainer: {
      minWidth: 1000,
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 24,
      paddingLeft: 24,
      backgroundColor: '#404040',
    },
    headCell: {
      color: '#ffffff',
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    progressContainer: {
      height: '60vh',
      width: '100%',
    },
    tableProgress: {
      position: 'absolute',
      [theme.breakpoints.down('sm')]: {
        left: '40%',
        right: '40%',
      },
      [theme.breakpoints.up('sm')]: {
        left: '50%',
        right: '50%',
      },
      top: '50%',
      bottom: '50%',
    },
    textContainer: {
      textAlign: 'left',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    title: {
      margin: 0,
    },
    centerText: {
      textAlign: 'center',
    },
    orderHeader: {
      backgroundColor: '#cacaca',
    },
    shipmentHeader: {
      backgroundColor: '#ececec',
      height: 55,
    },
    imageCard: {
      height: 240,
      width: 240,
      display: 'inline-block',
      margin: 'auto',
    },
    containerDiv: {
      marginTop: 'auto',
      marginBottom: 'auto',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    leftIconButton: {
      padding: 2,
      marginRight: 5,
      marginTop: 3,
      marginLeft: 3,
    },
    rightIconButton: {
      padding: 2,
      marginLeft: 5,
      marginTop: 3,
      marginRight: 3,
    },
    endAdornment: {
      fontSize: 10,
    },
    redBorder: {
      borderColor: `${red[500]} !important`,
    },
    greenBorder: {
      borderColor: `${green[500]} !important`,
    },
    redText: {
      color: red[500],
    },
    greenText: {
      color: green[500],
    },
    completenessCell: {
      width: 100,
      borderRadius: 5,
      textAlign: 'center',
      padding: 3,
      borderWidth: 1,
      borderStyle: 'solid',
    },
    completenessCellGreen: {
      color: green[500],
      borderColor: green[500],
    },
    completenessCellRed: {
      color: red[500],
      borderColor: red[500],
    },
    buttonMissingTracking: {
      marginLeft: '24px',
    },
    rootContainer: {
      // overflowX: 'auto',
      minWidth: 1000,
    },
    paper: {
      padding: 8,
    },
    multilineText: {
      whiteSpace: 'pre-line',
    },
    ctaRightContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cssLabel: {
      marginTop: -8,
    },
    cssFocused: {
      marginTop: 0,
    },
    invoice: {
      display: 'flex',
      padding: 8,
      marginTop: 8,
    },
    invoiceCard: {
      display: 'flex',
      flexDirection: 'column',
    },
    invoiceImage: {
      width: 151,
    },
    doneIcon: {
      marginRight: 8,
      color: green[500],
    },
    errorIcon: {
      marginRight: 8,
      color: red[500],
    },
    editIconButton: {
      padding: 8,
    },
    trackingFormControl: {
      width: '100%',
      marginTop: 8,
    },
    circularProgressAdornment: {
      marginRight: 8,
    },
    topSeparation: {
      marginTop: 8,
    },
    maxFullWidth: {
      maxWidth: '100%',
    },
    invoiceCtaGrid: {
      display: 'flex',
      justifyContent: 'center',
    },
    checkboxMarginBottom: {
      marginBottom: 5,
    },
    amountInput: {
      width: 64,
      whiteSpace: 'nowrap',
      cursorEvents: 'none',
    },
    lowProfileHr: {
      borderColor: 'transparent',
      boxShadow: 'rgba(0,0,0,0.3) 0px 1px 3px',
    },
    regularPadding: {
      padding: 8,
    },
    fullWidth: {
      width: '100%',
    },
    bodyPaper: {
      height: '58vh',
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: '50vh',
    },
    labelFont: {
      fontSize: 10,
    },
  };
}
