// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import ReceptionTableMain from '../components/ReceptionTableMain';
import { actions as ShippingActions } from '../duck';
import { actions as LocalValuesActions } from '../../local-values/duck';
import { withRouter } from 'react-router-dom';

import type { RootState } from '../../index';

const mapStateToProps = (state: RootState) => {
  return {
    page: state.reception.page,
    limit: state.reception.limit,
    search: state.reception.search,
    filterKey: state.reception.filterKey,
    receptionFlights: state.reception.receptionFlights,
    totalReceptionFlights: state.reception.totalReceptionFlights,
    isLoadingGetReception: state.reception.isLoadingGetReception,
    getReceptionSuccess: state.reception.getReceptionSuccess,
    getReceptionError: state.reception.getReceptionError,
    isLoadingConfirmReception: state.reception.isLoadingConfirmReception,
    confirmReceptionSuccess: state.reception.confirmReceptionSuccess,
    confirmReceptionError: state.reception.confirmReceptionError,
    isLoadingCancelReception: state.reception.isLoadingCancelReception,
    cancelReceptionSuccess: state.reception.cancelReceptionSuccess,
    cancelReceptionError: state.reception.cancelReceptionError,
    cancellationMotives: state.localValues.cancellationMotives,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return bindActionCreators({ ...ShippingActions, ...LocalValuesActions }, dispatch);
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReceptionTableMain));
