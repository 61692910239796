// @flow
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { endpoints, getFeaturedProductApi, saveFeaturedProductApi, createFeaturedProductApi } from './api';

import type { HTTPError } from '../../common/error';

// Actions
const GET_FEATURED_PRODUCT_REQUEST = 'GET_FEATURED_PRODUCT_REQUEST';
const GET_FEATURED_PRODUCT_SUCCESS = 'GET_FEATURED_PRODUCT_SUCCESS';
const GET_FEATURED_PRODUCT_ERROR = 'GET_FEATURED_PRODUCT_ERROR';
const CREATE_FEATURED_PRODUCT_REQUEST = 'CREATE_FEATURED_PRODUCT_REQUEST';
const CREATE_FEATURED_PRODUCT_SUCCESS = 'CREATE_FEATURED_PRODUCT_SUCCESS';
const CREATE_FEATURED_PRODUCT_ERROR = 'CREATE_FEATURED_PRODUCT_ERROR';
const SAVE_FEATURED_PRODUCT_REQUEST = 'SAVE_FEATURED_PRODUCT_REQUEST';
const SAVE_FEATURED_PRODUCT_SUCCESS = 'SAVE_FEATURED_PRODUCT_SUCCESS';
const SAVE_FEATURED_PRODUCT_ERROR = 'SAVE_FEATURED_PRODUCT_ERROR';
const RESET_FEATURED_PRODUCT_STATE = 'RESET_FEATURED_PRODUCT_STATE';


type FeaturedProductAction = {
  +type: string;
  +featuredProduct?: { [key: string]: any };
  +data?: { [key: string]: any };
  +error?: HTTPError;
};

export type FeaturedProductState = {
  featuredProduct?: { [key: string]: any };
  isLoadingGetFeaturedProduct?: boolean;
  getFeaturedProductSuccess?: boolean;
  getFeaturedProductError?: HTTPError;
  isLoadingCreateFeaturedProduct?: boolean;
  createFeaturedProductSuccess?: boolean;
  createFeaturedProductError?: HTTPError;
  isLoadingSaveFeaturedProduct?: boolean;
  saveFeaturedProductSuccess?: boolean;
  saveFeaturedProductError?: HTTPError;
};

// Action Creators
export function executeGetFeaturedProduct(id: string): FeaturedProductAction {
  return {
    type: GET_FEATURED_PRODUCT_REQUEST,
    id,
  }
}

export function executeGetFeaturedProductSuccess(featuredProduct: { [key: string]: any }): FeaturedProductAction {
  return {
    type: GET_FEATURED_PRODUCT_SUCCESS,
    featuredProduct,
  }
}

export function executeGetFeaturedProductError(error: HTTPError): FeaturedProductAction {
  return {
    type: GET_FEATURED_PRODUCT_ERROR,
    error,
  }
}

export function executeCreateFeaturedProduct(data: { [key: string]: any }): FeaturedProductAction {
  return {
    type: CREATE_FEATURED_PRODUCT_REQUEST,
    data,
  }
}

export function executeCreateFeaturedProductSuccess(data: { [key: string]: any }): FeaturedProductAction {
  return {
    type: CREATE_FEATURED_PRODUCT_SUCCESS,
    data,
  }
}

export function executeCreateFeaturedProductError(error: HTTPError): FeaturedProductAction {
  return {
    type: CREATE_FEATURED_PRODUCT_ERROR,
    error,
  }
}

export function executeSaveFeaturedProduct(id: number, req: { [key: string]: any }): FeaturedProductAction {
  return {
    type: SAVE_FEATURED_PRODUCT_REQUEST,
    id,
    req,
  }
}

export function executeSaveFeaturedProductSuccess(featuredProduct: { [key: string]: any }): FeaturedProductAction {
  return {
    type: SAVE_FEATURED_PRODUCT_SUCCESS,
    featuredProduct,
  }
}

export function executeSaveFeaturedProductError(error: HTTPError): FeaturedProductAction {
  return {
    type: SAVE_FEATURED_PRODUCT_ERROR,
    error,
  }
}

export function executeResetFeaturedProductState(): FeaturedProductAction {
  return {
    type: RESET_FEATURED_PRODUCT_STATE,
  };
}

export const actions = {
  GET_FEATURED_PRODUCT_REQUEST,
  GET_FEATURED_PRODUCT_SUCCESS,
  GET_FEATURED_PRODUCT_ERROR,
  CREATE_FEATURED_PRODUCT_REQUEST,
  CREATE_FEATURED_PRODUCT_SUCCESS,
  CREATE_FEATURED_PRODUCT_ERROR,
  executeGetFeaturedProduct,
  executeGetFeaturedProductSuccess,
  executeGetFeaturedProductError,
  executeCreateFeaturedProduct,
  executeCreateFeaturedProductSuccess,
  executeCreateFeaturedProductError,
  executeSaveFeaturedProduct,
  executeSaveFeaturedProductSuccess,
  executeSaveFeaturedProductError,
  executeResetFeaturedProductState,
};

export const initialState: FeaturedProductState = {};

// Reducer
export default function reducer(state: FeaturedProductState = initialState,
                                action: FeaturedProductAction): FeaturedProductState {
  switch (action.type) {
    case GET_FEATURED_PRODUCT_REQUEST:
      return {
        ...state,
        isLoadingGetFeaturedProduct: true,
        getFeaturedProductSuccess: false,
      };
    case GET_FEATURED_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoadingGetFeaturedProduct: false,
        getFeaturedProductSuccess: true,
        featuredProduct: action.featuredProduct,
      };
    case GET_FEATURED_PRODUCT_ERROR:
      return {
        ...state,
        getFeaturedProductSuccess: false,
        error: action.error,
      };
    case SAVE_FEATURED_PRODUCT_REQUEST:
      return {
        ...state,
        isLoadingSaveFeaturedProduct: true,
        saveFeaturedProductSuccess: false,
      };
    case SAVE_FEATURED_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoadingSaveFeaturedProduct: false,
        saveFeaturedProductSuccess: true,
        featuredProduct: action.featuredProduct,
      };
    case SAVE_FEATURED_PRODUCT_ERROR:
      return {
        ...state,
        isLoadingSaveFeaturedProduct: false,
        error: action.error,
      };
    case CREATE_FEATURED_PRODUCT_REQUEST:
      return {
        ...state,
        isLoadingCreateFeaturedProduct: true,
        createFeaturedProductSuccess: false,
      };
    case CREATE_FEATURED_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoadingCreateFeaturedProduct: false,
        createFeaturedProductSuccess: true,
        featuredProduct: action.data,
      };
    case CREATE_FEATURED_PRODUCT_ERROR:
      return {
        ...state,
        isLoadingCreateFeaturedProduct: false,
        error: action.error,
      };
    case RESET_FEATURED_PRODUCT_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetFeaturedProductEpic(action$: ActionsObservable<FeaturedProductAction>) {
  return action$.ofType(GET_FEATURED_PRODUCT_REQUEST).pipe(
    delay(200),
    mergeMap(
      ({ id }) => getFeaturedProductApi(undefined, {
        route: endpoints.GET_FEATURED_PRODUCT.replace(':id', id)
      }).pipe(
        map(data => executeGetFeaturedProductSuccess(data)),
        catchError((e: HTTPError) => of(executeGetFeaturedProductError(e))))
    )
  );
}


export function executeCreateFeaturedProductEpic(action$: ActionsObservable<FeaturedProductAction>) {
  return action$.ofType(CREATE_FEATURED_PRODUCT_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ data }) => createFeaturedProductApi(data).pipe(
        map((data) => executeCreateFeaturedProductSuccess(data)),
        catchError((e: HTTPError) => of(executeCreateFeaturedProductError(e))))
    )
  );
}

export function executeSaveFeaturedProductEpic(action$: ActionsObservable<FeaturedProductAction>) {
  return action$.ofType(SAVE_FEATURED_PRODUCT_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ req, id }) => saveFeaturedProductApi(req, {
        route: endpoints.SAVE_FEATURED_PRODUCT.replace(':id', id)
      }).pipe(
        map((data) => executeSaveFeaturedProductSuccess(data)),
        catchError((e: HTTPError) => of(executeSaveFeaturedProductError(e))))
    )
  );
}
