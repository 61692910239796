// @flow
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import type { Node } from 'react';
import type { AvailableOrderType } from '../../modules/available-orders/duck';
import type { ShipmentOrder } from '../../entities';

type Props = {
  +classes: { [key: string]: mixed };
  +order: AvailableOrderType | ShipmentOrder;
}

function Summary(props: Props): Node {
  const { classes, order } = props;

  let totalInUSA;

  if (!order.isDiy) {
    totalInUSA = parseFloat(order.invoice.subtotal) +
      parseFloat(order.invoice.taxReserve) + parseFloat(order.invoice.storeDelivery);
  }

  return (
    <Grid container spacing={16}>
      {!!order.beforeDate &&
      <Grid item container className={classes.beforeDate} justify="space-between">
        <div>Delivery before:</div>
        <div>{moment(order.beforeDate).format('dddd DD MMMM')}</div>
      </Grid>}
      {!order.isDiy &&
      <Fragment>
        <Grid item container justify="space-between">
          <div>Products total price:</div>
          <div>$ {parseFloat(order.invoice.subtotal).toFixed(2)}</div>
        </Grid>
        <Grid item container justify="space-between">
          <div>Taxes:</div>
          <div>$ {parseFloat(order.invoice.taxReserve).toFixed(2)}</div>
        </Grid>
        <Grid item container justify="space-between">
          <div>Store delivery:</div>
          <div>$ {parseFloat(order.invoice.storeDelivery).toFixed(2)}</div>
        </Grid>
        {typeof totalInUSA === 'number' &&
        <Grid item container justify="space-between"
              style={{borderTopStyle: 'solid', borderTopWidth: '1px', borderTopColor: 'grey', backgroundColor: 'rgba(1, 1, 1, 0.03)'}}>
          <div>Total in US:</div>
          <div>$ {totalInUSA.toFixed(2)}</div>
        </Grid>}
      </Fragment>}
      <Grid item container justify="space-between">
        <div>Customs:</div>
        <div>$ {parseFloat(order.invoice.customsDuties).toFixed(2)}</div>
      </Grid>
      <Grid item container justify="space-between">
        <div>{order.isDiy ? 'Courier' : 'Traveler'} commission:</div>
        <div>$ {parseFloat(order.invoice.commission).toFixed(2)}</div>
      </Grid>
      <Grid item container className={classes.qempoCommission} justify="space-between">
        <div>Qempo commission:</div>
        <div>$ {parseFloat(order.invoice.qempo).toFixed(2)}</div>
      </Grid>
      <Grid item container justify="space-between">
        <div>Delivery:</div>
        <div>$ {parseFloat(order.invoice.delivery).toFixed(2)}</div>
      </Grid>
      <Grid item container justify="space-between">
        <div>Warranty:</div>
        <div>$ {parseFloat(order.invoice.warranty).toFixed(2)}</div>
      </Grid>
    </Grid>
  );
}

Summary.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};

export default withStyles(styles)(Summary);
