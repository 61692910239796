// @flow
import { Observable } from 'rxjs';
// import { getCreator, postCreator, putCreator, deleteCreator } from '@qempo/qempo-web-common/http';
import environment from '../../environment';

export const endpoints = Object.freeze({
  GET_SHIP_ORDERS: `${environment.apiUrl}/administrative/progress-orders/ship-products`,
  SEND_SHIP_PRODUCTS: `${environment.apiUrl}/administrative/progress-orders/ship-products/:id`,
});

const shipProduct =
  {
    id: 'TR001',
    state: 'SHIPPING',
    traveler: 'Qempo Traveler',
    shippingAddress: {
      address1: "4th Avenue 123",
      address2: "no idea why this has_ but no can do nothing",
      admin1: {
        id: 2,
        name: "California"
      },
      admin2:{
        id: 33,
        name: "San Francisco"
      },
      admin3: null,
      country: {
        code: "US",
        countryId: 1,
        name: "United States"
      },
      firstName: "lele",
      lastName: "test",
      recipientId: "07968437",
      phone: "998-595-060",
      shippingAddressId: 7,
      zipCode: "15076"
    },
    products: [
      {
        id: 4321,
        name: 'THIS IS A GREAT PRODUCT',
        image: 'https://images-na.ssl-images-amazon.com/images/I/61ecsEuu6-L._AC_.jpg',
        color: 'red',
        options: 'extra cheese',
        withBox: true,
        weight: '0.5',
        price: '150.5',
        shipped: true,
      },
      {
        id: 4322,
        name: 'THIS IS A GREAT PRODUCT',
        image: 'https://images-na.ssl-images-amazon.com/images/I/61ecsEuu6-L._AC_.jpg',
        color: 'red',
        options: 'extra cheese',
        withBox: true,
        weight: '0.5',
        price: '150.5',
        shipped: true,
      },
      {
        id: 4323,
        name: 'THIS IS A GREAT PRODUCT',
        image: 'https://images-na.ssl-images-amazon.com/images/I/61ecsEuu6-L._AC_.jpg',
        color: 'red',
        options: 'extra cheese',
        withBox: false,
        weight: '0.5',
        price: '150.5',
        shipped: false,
      },
    ],
  };

export const getShipOrderApi = (queryParams: any, o: any) => Observable.create(
  (observer) => {
    observer.next(shipProduct);
  });

export const sendProductsApi = (data: any, o: any) => Observable.create(
  (observer) => {
    shipProduct.products[2].shipped = true;
    observer.next(shipProduct);
  });
