// @flow
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default function tableStyles(theme: { [key: string]: any }) {
  return ({
    title: {
      margin: 0,
    },
    tableSpacer: {
      flexGrow: 1,
    },
    endAdornment: {
      fontSize: 10,
    },
    redBorder: {
      borderColor: `${red[500]} !important`,
    },
    greenBorder: {
      borderColor: `${green[500]} !important`,
    },
    redText: {
      color: red[500],
    },
    greenText: {
      color: green[500],
    },
    flightHeader: {
      backgroundColor: '#cacaca',
    },
    orderHeader: {
      backgroundColor: '#ececec',
      height: 55,
    },
    textContainer: {
      textAlign: 'left',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    button: {
      padding: 6,
      marginLeft: 10,
    },
    imageCard: {
      height: 50,
      width: 50,
      display: 'inline-block',
      margin: 'auto',
    },
    rightIconButton: {
      padding: 2,
      marginLeft: 5,
      marginTop: 3,
      marginRight: 3,
    },
    leftIconButton: {
      padding: 2,
      marginRight: 5,
      marginTop: 3,
      marginLeft: 3,
    },
    containerDiv: {
      marginTop: 'auto',
      marginBottom: 'auto',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    completenessCell: {
      width: 100,
      borderRadius: 5,
      textAlign: 'center',
      padding: 3,
      borderWidth: 1,
      borderStyle: 'solid',
    },
    rootContainer: {
      minWidth: 1000,
    },
    headContainer: {
      minWidth: 1000,
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 24,
      paddingLeft: 24,
      backgroundColor: '#404040',
    },
    headCell: {
      color: '#ffffff',
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    toolbar: {
      width: '100%',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        display: 'inline',
      },
    },
    filterSelect: {
      minWidth: 120,
      marginLeft: 10,
      marginTop: 'auto',
      marginBottom: 'auto',
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        marginBottom: 10,
        display: 'inline-block',
      },
    },
    dateSelect: {
      marginLeft: 20,
      marginTop: 'auto',
      marginBottom: 'auto',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 20,
        marginTop: 10,
        marginBottom: 10,
        display: 'inline-block',
      },
    },
    tableSearch: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      marginLeft: 20,
      display: 'inline-block',
      width: 'auto',
      backgroundColor: fade('#656565', 0.15),
      '&:hover': {
        backgroundColor: fade('#656565', 0.25),
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: 20,
        marginTop: 10,
        marginBottom: 10,
        width: 'auto',
      },
    },
    tableInputRoot: {
      color: '#656565',
      overflow: 'hidden',
      width: '100%',
    },
    tableSearchInput: {
      color: '#656565',
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 10,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
    tableSearchIcon: {
      width: theme.spacing.unit * 9,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    toolbarButton: {
      marginRight: 10,
      marginLeft: 10,
      marginTop: 'auto',
      marginBottom: 'auto',
      [theme.breakpoints.down('md')]: {
        marginLeft: 24,
      },
      [theme.breakpoints.down('sm')]: {
        display: 'inline-block',
        marginTop: 10,
        marginLeft: 20,
        marginBottom: 10,
      },
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    progressContainer: {
      height: '60vh',
      width: '100%',
    },
    tableProgress: {
      position: 'absolute',
      [theme.breakpoints.down('sm')]: {
        left: '40%',
        right: '40%',
      },
      [theme.breakpoints.up('sm')]: {
        left: '50%',
        right: '50%',
      },
      top: '50%',
      bottom: '50%',
    },
  });
}
