// @flow
import { Observable } from 'rxjs';
// import { getCreator, postCreator, putCreator, deleteCreator } from '@qempo/qempo-web-common/http';
import environment from '../../environment';
import apiMailingLists from '../mailing-lists/mailingLists';

export const endpoints = Object.freeze({
  GET_LIST: `${environment.apiUrl}/mailing/list/:id`,
  SAVE_LIST: `${environment.apiUrl}/mailing/list/:id`,
  CREATE_LIST: `${environment.apiUrl}/mailing/list/new`,
  GET_SELECTED_USERS: `${environment.apiUrl}/mailing/list/:id/users`,
  UPDATE_SELECTED_USERS: `${environment.apiUrl}/mailing/list/:id/users`,
});

export const getListApi = (queryParams: any, o: any) => Observable.create(
  (observer) => {
    const id = parseInt(o.route.substr(45), 10);
    const list = {
      id,
      name: 'Whatever Mailing List',
      users: 400,
      lastUpdated: '2018/12/05',
      rootRule: {
        id: '2019a',
        operation: 'root',
        name: 'HasAtLeastSomeOrders',
        params: {
          min_orders: 4,
        },
        children: [
          {
            id: '2019b',
            operation: 'AND',
            name: 'HasAtMaxSomeOrders',
            params: {
              max_orders: 20,
            },
            children: [],
          },
          {
            id: '2019c',
            operation: 'OR',
            name: 'ScrappedItemsByKeywordsLists',
            params: {
              keywordsLists: [
                'Electronics',
                'Toy',
              ],
            },
            children: [
              {
                id: '2019d',
                operation: 'OR',
                name: 'ScrappedItemsByKeywords',
                params: {
                  regex: false,
                  keywords: [
                    'nike',
                    'adidas',
                  ],
                },
                children: [],
              },
            ],
          }
        ],
      },
    };
    observer.next(list);
  });

export const createListApi = (data: any, o: any) => Observable.create(
  (observer) => {
    const newData = { ...data };
    newData.id = (apiMailingLists[apiMailingLists.length - 1].id) + 1;
    newData.totalUsers = 200;
    newData.lastUpdated = '2018/04/12';
    apiMailingLists.push(newData.data);
    observer.next(newData);
  });

export const saveListApi = (data: any, o: any) => Observable.create(
  (observer) => {
    const id = parseInt(o.route.substr(45), 10);
    const list = apiMailingLists.find(e => e.id === id);
    list.name = data.name !== undefined ? data.name : list.name;
    list.rules = data.rules !== undefined ? data.rules : list.rules;
    list.totalUsers = 600;
    list.lastUpdated = '2019/03/07';
    observer.next(list);
  });

export const getSelectedUsersApi = (queryParams: any, o: any) => Observable.create(
  (observer) => {
    const users = [
      {
        id: 12,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com',
      },
      {
        id: 13,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com',
      },
      {
        id: 14,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com',
      },
    ];
    observer.next(users);
  });

export const updateSelectedUsersApi = (queryParams: any, o: any) => Observable.create(
  (observer) => {
    const users = [
      {
        id: 12,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com',
      },
      {
        id: 13,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com',
      },
      {
        id: 14,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com',
      },
      {
        id: 15,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com',
      },
      {
        id: 16,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com',
      },
      {
        id: 17,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com',
      },
    ];
    observer.next(users);
  });

export type UpdateListRequest = {
  id?: number;
  name?: string;
  url?: string;
  image?: string;
  description?: string;
  price?: string;
  category?: string;
  quantity?: number;
  weight?: string;
};
