// @flow
import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';

export function renderError(errors?: string[], errorLabels: { [key: string]: string }) {
  return errors && errors.length &&
    <FormHelperText>
      {errorLabels[errors[0]]}
    </FormHelperText>;
}
