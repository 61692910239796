// @flow
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { endpoints, getListApi, saveListApi, createListApi, getSelectedUsersApi, updateSelectedUsersApi } from './api';

import type { HTTPError } from '../../common/error';
import type { RootState } from '../index';

// Actions
const GET_LIST_REQUEST = 'GET_LIST_REQUEST';
const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';
const GET_LIST_ERROR = 'GET_LIST_ERROR';
const CREATE_LIST_REQUEST = 'CREATE_LIST_REQUEST';
const CREATE_LIST_SUCCESS = 'CREATE_LIST_SUCCESS';
const CREATE_LIST_ERROR = 'CREATE_LIST_ERROR';
const SAVE_LIST_REQUEST = 'SAVE_LIST_REQUEST';
const SAVE_LIST_SUCCESS = 'SAVE_LIST_SUCCESS';
const SAVE_LIST_ERROR = 'SAVE_LIST_ERROR';
const GET_SELECTED_USERS_REQUEST = 'GET_SELECTED_USERS_REQUEST';
const GET_SELECTED_USERS_SUCCESS = 'GET_SELECTED_USERS_SUCCESS';
const GET_SELECTED_USERS_ERROR = 'GET_SELECTED_USERS_ERROR';
const UPDATE_SELECTED_USERS_REQUEST = 'UPDATE_SELECTED_USERS_REQUEST';
const UPDATE_SELECTED_USERS_SUCCESS = 'UPDATE_SELECTED_USERS_SUCCESS';
const UPDATE_SELECTED_USERS_ERROR = 'UPDATE_SELECTED_USERS_ERROR';
const CHANGE_SELECTED_USERS_PAGINATION_REQUEST = 'CHANGE_SELECTED_USERS_PAGINATION_REQUEST';
const RESET_LIST_STATE = 'RESET_LIST_STATE';


type ListAction = {
  +type: string;
  +list?: { [key: string]: any };
  +data?: { [key: string]: any };
  +users?: { [key: string]: any }[];
  +changes?: { [key: string]: any };
  +error?: HTTPError;
};

export type ListState = {
  list?: { [key: string]: any };
  isLoadingGetList?: boolean;
  getListSuccess?: boolean;
  getListError?: HTTPError;
  isLoadingCreateList?: boolean;
  createListSuccess?: boolean;
  createListError?: HTTPError;
  isLoadingSaveList?: boolean;
  saveListSuccess?: boolean;
  saveListError?: HTTPError;
  isLoadingGetSelectedUsers?: boolean;
  getSelectedUsersSuccess?: boolean;
  getSelectedUsersError?: HTTPError;
  users?: { [key: string]: any } [];
  isLoadingUpdateSelectedUsers?: boolean;
  updateSelectedUsersSuccess?: boolean;
  page: number;
  limit: number;
};

// Action Creators
export function executeGetList(id: string): ListAction {
  return {
    type: GET_LIST_REQUEST,
    id,
  };
}

export function executeGetListSuccess(list: { [key: string]: any }): ListAction {
  return {
    type: GET_LIST_SUCCESS,
    list,
  };
}

export function executeGetListError(error: HTTPError): ListAction {
  return {
    type: GET_LIST_ERROR,
    error,
  };
}

export function executeCreateList(data: { [key: string]: any }): ListAction {
  return {
    type: CREATE_LIST_REQUEST,
    data,
  };
}

export function executeCreateListSuccess(data: { [key: string]: any }): ListAction {
  return {
    type: CREATE_LIST_SUCCESS,
    data,
  };
}

export function executeCreateListError(error: HTTPError): ListAction {
  return {
    type: CREATE_LIST_ERROR,
    error,
  };
}

export function executeSaveList(id: number, req: { [key: string]: any }): ListAction {
  return {
    type: SAVE_LIST_REQUEST,
    id,
    req,
  };
}

export function executeSaveListSuccess(list: { [key: string]: any }): ListAction {
  return {
    type: SAVE_LIST_SUCCESS,
    list,
  };
}

export function executeSaveListError(error: HTTPError): ListAction {
  return {
    type: SAVE_LIST_ERROR,
    error,
  };
}

export function executeGetSelectedUsers(id: string | number): ListAction {
  return {
    type: GET_SELECTED_USERS_REQUEST,
    id,
  };
}

export function executeGetSelectedUsersSuccess(users: { [key: string]: any }[]): ListAction {
  return {
    type: GET_SELECTED_USERS_SUCCESS,
    users,
  };
}

export function executeGetSelectedUsersError(error: HTTPError): ListAction {
  return {
    type: GET_SELECTED_USERS_ERROR,
    error,
  };
}

export function executeUpdateSelectedUsers(id: number): ListAction {
  return {
    type: UPDATE_SELECTED_USERS_REQUEST,
    id,
  };
}

export function executeUpdateSelectedUsersSuccess(users: { [key: string]: any }[]): ListAction {
  return {
    type: UPDATE_SELECTED_USERS_SUCCESS,
    users,
  };
}

export function executeUpdateSelectedUsersError(error: HTTPError): ListAction {
  return {
    type: UPDATE_SELECTED_USERS_ERROR,
    error
  };
}

export function changeSelectedUsersPagination(id: number, changes: { [key: string]: any }): ListAction {
  return {
    type: CHANGE_SELECTED_USERS_PAGINATION_REQUEST,
    changes,
    id,
  };
}

export function executeResetListState(): ListAction {
  return {
    type: RESET_LIST_STATE,
  };
}

export const actions = {
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
  GET_LIST_ERROR,
  CREATE_LIST_REQUEST,
  CREATE_LIST_SUCCESS,
  CREATE_LIST_ERROR,
  SAVE_LIST_REQUEST,
  SAVE_LIST_SUCCESS,
  SAVE_LIST_ERROR,
  GET_SELECTED_USERS_REQUEST,
  GET_SELECTED_USERS_SUCCESS,
  GET_SELECTED_USERS_ERROR,
  UPDATE_SELECTED_USERS_REQUEST,
  UPDATE_SELECTED_USERS_SUCCESS,
  UPDATE_SELECTED_USERS_ERROR,
  CHANGE_SELECTED_USERS_PAGINATION_REQUEST,
  RESET_LIST_STATE,
  executeGetList,
  executeGetListSuccess,
  executeGetListError,
  executeCreateList,
  executeCreateListSuccess,
  executeCreateListError,
  executeSaveList,
  executeSaveListSuccess,
  executeSaveListError,
  executeGetSelectedUsers,
  executeGetSelectedUsersSuccess,
  executeGetSelectedUsersError,
  executeUpdateSelectedUsers,
  executeUpdateSelectedUsersSuccess,
  executeUpdateSelectedUsersError,
  changeSelectedUsersPagination,
  executeResetListState,
};

export const initialState: ListState = {
  page: 0,
  limit: 10,
};

// Reducer
export default function reducer(state: ListState = initialState,
                                action: ListAction): ListState {
  switch (action.type) {
    case GET_LIST_REQUEST:
      return {
        ...state,
        isLoadingGetList: true,
        getListSuccess: false,
      };
    case GET_LIST_SUCCESS:
      return {
        ...state,
        isLoadingGetList: false,
        getListSuccess: true,
        list: action.list,
      };
    case GET_LIST_ERROR:
      return {
        ...state,
        getListSuccess: false,
        getListError: action.error,
      };
    case SAVE_LIST_REQUEST:
      return {
        ...state,
        isLoadingSaveList: true,
        saveListSuccess: false,
      };
    case SAVE_LIST_SUCCESS:
      return {
        ...state,
        isLoadingSaveList: false,
        saveListSuccess: true,
        list: action.list,
      };
    case SAVE_LIST_ERROR:
      return {
        ...state,
        isLoadingSaveList: false,
        saveListError: action.error,
      };
    case CREATE_LIST_REQUEST:
      return {
        ...state,
        isLoadingCreateList: true,
        createListSuccess: false,
      };
    case CREATE_LIST_SUCCESS:
      return {
        ...state,
        isLoadingCreateList: false,
        createListSuccess: true,
        list: action.data,
      };
    case CREATE_LIST_ERROR:
      return {
        ...state,
        isLoadingCreateList: false,
        createListError: action.error,
      };
    case GET_SELECTED_USERS_REQUEST:
      return {
        ...state,
        isLoadingGetSelectedUsers: true,
        getSelectedUsersSuccess: false,
      };
    case GET_SELECTED_USERS_SUCCESS:
      return {
        ...state,
        isLoadingGetSelectedUsers: false,
        getSelectedUsersSuccess: true,
        users: action.users,
      };
    case GET_SELECTED_USERS_ERROR:
      return {
        ...state,
        getSelectedUsersSuccess: false,
        getSelectedUsersError: action.error,
      };
    case UPDATE_SELECTED_USERS_REQUEST:
      return {
        ...state,
        isLoadingUpdateSelectedUsers: true,
        updateSelectedUsersSuccess: false,
      };
    case UPDATE_SELECTED_USERS_SUCCESS:
      return {
        ...state,
        isLoadingUpdateSelectedUsers: false,
        updateSelectedUsersSuccess: true,
        users: action.users,
      };
    case UPDATE_SELECTED_USERS_ERROR:
      return {
        ...state,
        updateSelectedUsersSuccess: false,
        updateSelectedUsersError: action.error,
      };
    case CHANGE_SELECTED_USERS_PAGINATION_REQUEST:
      const data = { ...action.changes };
      return { ...state, ...data };
    case RESET_LIST_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetListEpic(action$: ActionsObservable<ListAction>) {
  return action$.ofType(GET_LIST_REQUEST).pipe(
    delay(200),
    mergeMap(
      ({ id }) => getListApi(undefined, {
        route: endpoints.GET_SELECTED_USERS.replace(':id', id)
      }).pipe(
        map(data => executeGetListSuccess(data)),
        catchError((e: HTTPError) => of(executeGetListError(e))))
    )
  );
}


export function executeCreateListEpic(action$: ActionsObservable<ListAction>) {
  return action$.ofType(CREATE_LIST_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ data }) => createListApi(data).pipe(
        map((data) => executeCreateListSuccess(data)),
        catchError((e: HTTPError) => of(executeCreateListError(e))))
    )
  );
}

export function executeSaveListEpic(action$: ActionsObservable<ListAction>) {
  return action$.ofType(SAVE_LIST_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ req, id }) => saveListApi(req, {
        route: endpoints.SAVE_LIST.replace(':id', id),
      }).pipe(
        map((data) => executeSaveListSuccess(data)),
        catchError((e: HTTPError) => of(executeSaveListError(e))))
    )
  );
}

export function executeGetListSelectedUsersEpic(action$: ActionsObservable<ListAction>, state$: StateObservable<RootState>) {
  return action$.ofType(GET_SELECTED_USERS_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ id }) => {
        return getSelectedUsersApi({
            page: state$.value.list.page,
            limit: state$.value.list.limit,
          },
          {
            route: endpoints.GET_SELECTED_USERS.replace(':id', id),
          }).pipe(
          map((data) => executeGetSelectedUsersSuccess(data)),
          catchError((e: HTTPError) => of(executeGetSelectedUsersError(e))))
      }
    )
  );
}

export function executeUpdateListSelectedUsersEpic(action$: ActionsObservable<ListAction>, state$: StateObservable<RootState>) {
  return action$.ofType(UPDATE_SELECTED_USERS_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ id }) => updateSelectedUsersApi({
          page: state$.value.list.page,
          limit: state$.value.list.limit,
        },
        {
          route: endpoints.UPDATE_SELECTED_USERS.replace(':id', id),
        }).pipe(
        map(data => executeUpdateSelectedUsersSuccess(data)),
        catchError((e: HTTPError) => of(executeUpdateSelectedUsersError(e))))
    )
  );
}

export function changeListSelectedUsersPaginationEpic(action$: ActionsObservable<ListAction>, state$: StateObservable<RootState>) {
  return action$.ofType(CHANGE_SELECTED_USERS_PAGINATION_REQUEST, SAVE_LIST_SUCCESS, CREATE_LIST_SUCCESS).pipe(
    delay(200),
    map(
      ({ id }) => executeGetSelectedUsers(id),
    )
  );
}
