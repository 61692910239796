// @flow
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { endpoints, getOneOrderApi, updateOrderApi, updateShippingAddressApi } from './api';

import type { HTTPError } from '../../common/error';
import type { Order } from '../../entities/webapp';
import type { UpdateShippingAddressRequest, UpdateOrderRequest } from './api';

//Actions
const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
const GET_ORDER_ERROR = 'GET_ORDER_ERROR';
const SAVE_ORDER_REQUEST = 'SAVE_ORDER_REQUEST';
const SAVE_ORDER_SUCCESS = 'SAVE_ORDER_SUCCESS';
const SAVE_ORDER_ERROR = 'SAVE_ORDER_ERROR';
const SAVE_SHIPPING_ADDRESS_REQUEST = 'SAVE_SHIPPING_ADDRESS_REQUEST';
const SAVE_SHIPPING_ADDRESS_SUCCESS = 'SAVE_SHIPPING_ADDRESS_SUCCESS';
const SAVE_SHIPPING_ADDRESS_ERROR = 'SAVE_SHIPPING_ADDRESS_ERROR';
const RESET_ORDER_STATE = 'RESET_ORDER_STATE';

type OrderAction = {
  +type: string;
  +order?: Order;
  +id?: string;
  +error?: HTTPError;
  +req?: UpdateOrderRequest | UpdateShippingAddressRequest;
};

export type OrderState = {
  isLoadingGetOrder?: boolean;
  getOrderSuccess?: boolean;
  getOrderError?: HTTPError;
  isLoadingSaveOrder?: boolean;
  isLoadingSaveShippingAddress?: boolean;
  saveOrderSuccess?: boolean;
  saveShippingAddressSuccess?: boolean;
  saveOrderError?: HTTPError;
  saveShippingAddressError?: HTTPError;
  order?: Order;
};

//Action Creators
export function executeGetOrder(id: string): OrderAction {
  return {
    type: GET_ORDER_REQUEST,
    id,
  };
}

export function executeGetOrderSuccess(order: Order): OrderAction {
  return {
    type: GET_ORDER_SUCCESS,
    order,
  };
}

export function executeGetOrderErrorFn(error: HTTPError): OrderAction {
  return {
    type: GET_ORDER_ERROR,
    error,
  };
}

export function executeSaveOrder(id: string, req: UpdateOrderRequest): OrderAction {
  return {
    type: SAVE_ORDER_REQUEST,
    id,
    req,
  };

}

export function executeSaveOrderSuccess(order: Order): OrderAction {
  return {
    type: SAVE_ORDER_SUCCESS,
    order,
  };
}

export function executeSaveOrderErrorFn(error: HTTPError): OrderAction {
  return {
    type: SAVE_ORDER_ERROR,
    error,
  };
}

export function executeSaveShippingAddress(id: string, req: UpdateShippingAddressRequest): OrderAction {
  return {
    type: SAVE_SHIPPING_ADDRESS_REQUEST,
    id,
    req,
  };
}

export function executeSaveShippingAddressSuccess(order: Order): OrderAction {
  return {
    type: SAVE_SHIPPING_ADDRESS_SUCCESS,
    order,
  };
}

export function executeSaveShippingAddressErrorFn(error: HTTPError): OrderAction {
  return {
    type: SAVE_SHIPPING_ADDRESS_ERROR,
    error,
  };
}

export function executeResetOrderState(): OrderAction {
  return {
    type: RESET_ORDER_STATE,
  };
}

export const actions = {
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  SAVE_ORDER_REQUEST,
  SAVE_ORDER_SUCCESS,
  SAVE_ORDER_ERROR,
  SAVE_SHIPPING_ADDRESS_REQUEST,
  SAVE_SHIPPING_ADDRESS_SUCCESS,
  SAVE_SHIPPING_ADDRESS_ERROR,
  RESET_ORDER_STATE,
  executeGetOrder,
  executeGetOrderSuccess,
  executeGetOrderErrorFn,
  executeSaveOrder,
  executeSaveOrderSuccess,
  executeSaveOrderErrorFn,
  executeSaveShippingAddress,
  executeSaveShippingAddressSuccess,
  executeSaveShippingAddressErrorFn,
  executeResetOrderState,
};

export const initialState = {
  isLoadingGetOrder: true,
  getOrderSuccess: false,
};

// Reducer
export default function reducer(state: OrderState = initialState,
                                action: OrderAction): OrderState {
  switch (action.type) {
    case GET_ORDER_REQUEST:
      return {
        isLoadingGetOrder: true,
        getOrderSuccess: false,
      };
    case GET_ORDER_SUCCESS:
      return {
        isLoadingGetOrder: false,
        getOrderSuccess: true,
        order: action.order,
      };
    case GET_ORDER_ERROR:
      return {
        ...state,
        getOrderError: action.error,
      };
    case SAVE_ORDER_REQUEST:
      return {
        ...state,
        isLoadingSaveOrder: true,
        saveOrderSuccess: false,
      };
    case SAVE_ORDER_SUCCESS:
      return {
        ...state,
        order: action.order,
        isLoadingSaveOrder: false,
        saveOrderSuccess: true,
      };
    case SAVE_ORDER_ERROR:
      return {
        ...state,
        saveOrderError: action.error,
      };
    case SAVE_SHIPPING_ADDRESS_REQUEST:
      return {
        ...state,
        isLoadingSaveShippingAddress: true,
        saveShippingAddressSuccess: false,
      };
    case SAVE_SHIPPING_ADDRESS_SUCCESS:
      return {
        ...state,
        order: action.order,
        isLoadingSaveShippingAddress: false,
        saveShippingAddressSuccess: true,
      };
    case SAVE_SHIPPING_ADDRESS_ERROR:
      return {
        ...state,
        saveShippingAddressError: action.error,
      };
    case RESET_ORDER_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetOrderEpic(action$: ActionsObservable<OrderAction>) {
  return action$.ofType(GET_ORDER_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ id }) => getOneOrderApi(undefined, {
        route: endpoints.GET_ORDER.replace(':id', id)
      }).pipe(
        map(data => executeGetOrderSuccess(data)),
        catchError((e: HTTPError) => of(executeGetOrderErrorFn(e))))
    )
  );
}

export function executeSaveOrderEpic(action$: ActionsObservable<OrderAction>) {
  return action$.ofType(SAVE_ORDER_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ req, id }) => updateOrderApi(req, {
        route: endpoints.UPDATE_ORDER.replace(':id', id),
      }).pipe(
        map((order) => executeSaveOrderSuccess(order)),
        catchError((e: HTTPError) => of(executeSaveOrderErrorFn(e))))
    )
  );
}

export function executeSaveShippingAddressEpic(action$: ActionsObservable<OrderAction>) {
  return action$.ofType(SAVE_SHIPPING_ADDRESS_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ req, id }) => updateShippingAddressApi(req, {
        route: endpoints.UPDATE_SHIPPING_ADDRESS.replace(':id', id),
      }).pipe(
        map((order) => executeSaveShippingAddressSuccess(order)),
        catchError((e: HTTPError) => of(executeSaveShippingAddressErrorFn(e))))
    )
  );
}


