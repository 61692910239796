// @flow
export default function styles(theme: { [key: string]: any }) {
  return ({
    isLoading: {
      opacity: 0.5,
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
  });
};
