// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import styles from '../style';
import { FormValidator } from '../../../../common/form';

type Props = {
  +classes: { [key: string]: any };
  +editing: boolean;
  +orderId: string;
  +trackingNumber: string;
  +lastMileShippingId: number;
  +handleUpdateDeliveryConfirm: ({ lastMileShippingId: number, trackingNumber: string }) => void;
  +handleEditing: (orderId: string) => void;
  +handleDeliveryNumberChange: (e: Event, orderId: string) => void;
};


class EditDeliveryIdCell extends Component<Props> {
  updateTrackingValidator: FormValidator;

  constructor(props: Props) {
    super(props);
    this.updateTrackingValidator = new FormValidator({
      trackingId: {
        required: 'required',
      },
    });
  }

  buildCell = () => {
    const { editing } = this.props;
    if (editing) {
      return this.buildEditCell();
    } else {
      return this.buildReadCell();
    }
  };

  buildReadCell = () => {
    const { classes, trackingNumber, handleEditing, orderId } = this.props;
    return (
      <Grid item container xs={8}>
        <Grid item container xs={6}>
          <div className={classes.textContainer} style={{ width: '100%' }}>
            <h6 className={classes.title}>{trackingNumber}</h6>
          </div>
        </Grid>
        <Grid item container xs={6}>
          <div className={classes.textContainer} style={{ width: '100%' }}>
            <IconButton className={classes.button}
                        size="small"
                        style={{ marginLeft: 10, visibility: 'hidden' }}>
              <SaveIcon/>
            </IconButton>
            <IconButton className={classes.button}
                        style={{ marginLeft: 10 }}
                        onClick={() => handleEditing(orderId)}>
              <EditIcon/>
            </IconButton>
          </div>
        </Grid>
      </Grid>);
  };

  buildEditCell = () => {
    const { classes, trackingNumber, handleEditing, orderId, handleDeliveryNumberChange, handleUpdateDeliveryConfirm, lastMileShippingId } = this.props;
    return (
      <Grid item container xs={8}>
        <Grid item container xs={6}>
          <div className={classes.textContainer}>
            <TextField value={trackingNumber}
                       name="trackingId"
                       onChange={(e) => handleDeliveryNumberChange(e, orderId)}>
            </TextField>
          </div>
        </Grid>
        <Grid item container xs={6}>
          <div className={classes.textContainer}>
            <IconButton className={classes.button}
                        size="small"
                        style={{ marginLeft: 10 }}
                        onClick={() => handleUpdateDeliveryConfirm({ lastMileShippingId, trackingNumber })}>
              <SaveIcon/>
            </IconButton>
            <IconButton className={classes.button}
                        size="small"
                        style={{ marginLeft: 10 }}
                        onClick={() => handleEditing(orderId)}>
              <ClearIcon/>
            </IconButton>
          </div>
        </Grid>
      </Grid>);
  };

  render() {
    return this.buildCell();
  }
}

EditDeliveryIdCell.propTypes = {
  classes: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  handleEditing: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  trackingNumber: PropTypes.string.isRequired,
  lastMileShippingId: PropTypes.number.isRequired,
  handleUpdateDeliveryConfirm: PropTypes.func.isRequired,
  handleDeliveryNumberChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(EditDeliveryIdCell);
