//@flow
import environment from '../../environment';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { scrapProductApi, createLinkApi } from './api';
import update from 'immutability-helper';

import type { HTTPError } from '../../common/error';

// Actions
const PRODUCT_CRAFTER_SCRAP_PRODUCT_REQUEST = 'PRODUCT_CRAFTER_SCRAP_PRODUCT_REQUEST';
const PRODUCT_CRAFTER_SCRAP_PRODUCT_SUCCESS = 'PRODUCT_CRAFTER_SCRAP_PRODUCT_SUCCESS';
const PRODUCT_CRAFTER_SCRAP_PRODUCT_ERROR = 'PRODUCT_CRAFTER_SCRAP_PRODUCT_ERROR';
const PRODUCT_CRAFTER_CREATE_LINK_REQUEST = 'PRODUCT_CRAFTER_CREATE_LINK_REQUEST';
const PRODUCT_CRAFTER_CREATE_LINK_SUCCESS = 'PRODUCT_CRAFTER_CREATE_LINK_SUCCESS';
const PRODUCT_CRAFTER_CREATE_LINK_ERROR = 'PRODUCT_CRAFTER_CREATE_LINK_ERROR';

export const actions = {
    PRODUCT_CRAFTER_SCRAP_PRODUCT_REQUEST,
    PRODUCT_CRAFTER_SCRAP_PRODUCT_SUCCESS,
    PRODUCT_CRAFTER_SCRAP_PRODUCT_ERROR,
    PRODUCT_CRAFTER_CREATE_LINK_REQUEST,
    PRODUCT_CRAFTER_CREATE_LINK_SUCCESS,
    PRODUCT_CRAFTER_CREATE_LINK_ERROR,
    executeCreateLinkRequest,
    executeScrapProductRequest
};

export type ScrappedProduct = {
    +linkUrl ?: string;
    +name ?: string;
    +imageUrl ?: string;
    +price ?: string;
    +categoryId ?: number;
    +weight ?: string,
    +shipping ?: string
};

export type ProductCrafterAction = {
    +type: string;
    +longUrl ?: string;
    +shortUrl ?: string;
    +product ?: ScrappedProduct;
    +error ?: HTTPError;
};

export function executeScrapProductRequest(productUrl: string): ProductCrafterAction {
    return {
        type: PRODUCT_CRAFTER_SCRAP_PRODUCT_REQUEST,
        productUrl,
    };
};

export function executeScrapProductSuccess(product: ScrappedProduct): ProductCrafterAction {
    return {
        type: PRODUCT_CRAFTER_SCRAP_PRODUCT_SUCCESS,
        product
    };
};

export function executeScrapProductError(error: HTTPError): ProductCrafterAction {
    return {
        type: PRODUCT_CRAFTER_SCRAP_PRODUCT_ERROR,
        error
    };
};

export function executeCreateLinkRequest(longUrl: string): ProductCrafterAction {
    return {
        type: PRODUCT_CRAFTER_CREATE_LINK_REQUEST,
        longUrl,
    };
};

export function executeCreateLinkSuccess(shortUrl: string): ProductCrafterAction {
    return {
        type: PRODUCT_CRAFTER_CREATE_LINK_SUCCESS,
        shortUrl
    };
};

export function executeCreateLinkError(error: HTTPError): ProductCrafterAction {
    return {
        type: PRODUCT_CRAFTER_CREATE_LINK_ERROR,
        error
    };
};

// State
export type ProductCrafterState = {
    loading: boolean;
    error?: HTTPError;
    product?: ScrappedProduct;
    shortUrl?: string;
};

export const initialState: ProductCrafterState = {
    loading: false
};

// Reducer
export default function reducer(state: ProductCrafterState = initialState, action: ProductCrafterAction): ProductCrafterState {
    switch (action.type) {
        case PRODUCT_CRAFTER_SCRAP_PRODUCT_REQUEST:
            return update(state, {
                loading: { $set: true },
                $unset: ['error'],
            });
        case PRODUCT_CRAFTER_SCRAP_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                product: action.product
            };
        case PRODUCT_CRAFTER_SCRAP_PRODUCT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case PRODUCT_CRAFTER_CREATE_LINK_REQUEST:
            return update(state, {
                loading: { $set: true },
                $unset: ['error'],
            });
        case PRODUCT_CRAFTER_CREATE_LINK_SUCCESS:
            return {
                ...state,
                loading: false,
                shortUrl: action.shortUrl
            };
        case PRODUCT_CRAFTER_CREATE_LINK_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return {
                ...state
            };
    }
};

// Epics
export function executeScrapProductEpic(action$: ActionsObservable<ProductCrafterAction>) {
    return action$.ofType(PRODUCT_CRAFTER_SCRAP_PRODUCT_REQUEST).pipe(
        mergeMap(
            ({ productUrl }) => scrapProductApi({
                url: productUrl,
            }).pipe(
                map(({ url, categoryId, image, name, price, weight, shipping }) => executeScrapProductSuccess({
                    linkUrl: url,
                    name: name,
                    imageUrl: image,
                    categoryId: categoryId,
                    price: price,
                    weight: weight||"0.00",
                    shipping: shipping
                })),
                catchError((e: HTTPError) => of(executeScrapProductError(e))))
        )
    );
};

export function executeCreateLinkEpic(action$: ActionsObservable<ProductCrafterAction>) {
    return action$.ofType(PRODUCT_CRAFTER_CREATE_LINK_REQUEST).pipe(
        mergeMap(
            ({ longUrl }) => createLinkApi({
                longUrl: longUrl,
                title: `NewLink:${Date.now()}`
            }, {
                headers: {
                    "Authorization": `Bearer ${environment.bitlyToken}`,
                    "Content-Type": "application/json"
                }
            }).pipe(
                map(({ link }) => executeCreateLinkSuccess(link)),
                catchError((e: HTTPError) => of(executeCreateLinkError(e))))
        )
    );
};

