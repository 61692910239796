// @flow
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AvailableOrder from '../AvailableOrder/AvailableOrder';
import { actions as AvailableOrdersActions } from '../duck';
import { actions as CourierTrackingActions } from '../../courier-tracking/duck';
import { actions as LocalValuesActions } from '../../local-values/duck';
import { withRouter } from 'react-router-dom';

import type { RootState } from "../../index";

const mapStateToProps = (state: RootState) => {
  return {
    isLoadingGetAvailableOrder: state.availableOrders.isLoadingGetAvailableOrder,
    availableOrder: state.availableOrders.availableOrder,
    getAvailableOrderError: state.availableOrders.getAvailableOrderError,
    isLoadingShipAvailableOrder: state.availableOrders.isLoadingShipAvailableOrder,
    shipAvailableOrderSuccess: state.availableOrders.shipAvailableOrderSuccess,
    shipAvailableOrderError: state.availableOrders.shipAvailableOrderError,
    couriers: state.localValues.couriers,
    isLoadingGetCouriers: state.localValues.isLoadingGetCouriers,
    getCouriersError: state.localValues.getCouriersError,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return bindActionCreators({
    ...AvailableOrdersActions,
    ...CourierTrackingActions,
    ...LocalValuesActions,
  }, dispatch);
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AvailableOrder));
