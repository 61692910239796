// @flow
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Summary from '../../../elements/Summary/Summary';
import OrderItem from '../../courier-tracking/components/OrderItem/OrderItem';
import classNames from 'classnames/bind';

import defaultPic from '../../../assets/img/profileplaceholder.jpg';

import type { HTTPError } from '../../../common/error';
import type { AvailableOrderType } from '../duck';
import type { Match } from '../../../router';
import type { Node } from 'react';
import type { Courier, OrderItem as OrderItemType } from '../../../entities';
import type { History } from '../../../router';

type Props = {
  +history: History;
  +match: Match;
  +classes: { [key: string]: any };
  +executeGetAvailableOrder: (orderId: string) => void;
  +isLoadingGetAvailableOrder?: boolean;
  +availableOrder?: AvailableOrderType;
  +getAvailableOrderError?: HTTPError;
  +executeGetCouriers: () => void;
  +couriers?: Courier[];
  +isLoadingGetCouriers?: boolean;
  +getCouriersError?: HTTPError;
  +executeShipAvailableOrder: (courierId: string, orderId: string) => void;
  +isLoadingShipAvailableOrder?: boolean;
  +shipAvailableOrderSuccess?: boolean;
  +shipAvailableOrderError?: HTTPError;
  +executeGetCouriersOrders: (queryFilter?: { [key: string]: string }) => void;
};

type State = {
  courier: {
    value: string;
    errors?: string[];
  };
};

class AvailableOrder extends Component<Props, State> {
  state = {
    courier: {
      value: '',
    },
  };

  componentDidMount(): * {
    const {
      availableOrder, executeGetAvailableOrder, match, couriers, executeGetCouriers, isLoadingGetAvailableOrder,
    } = this.props;
    if (!availableOrder && !isLoadingGetAvailableOrder) {
      executeGetAvailableOrder(match.params.orderId);
    }
    if (!couriers) {
      executeGetCouriers();
    }
  }

  componentDidUpdate(prevProps: Props): * {
    const {
      isLoadingShipAvailableOrder,
      shipAvailableOrderSuccess,
      match,
      history,
      executeGetCouriersOrders,
      availableOrder,
    } = this.props;
    if (prevProps.isLoadingShipAvailableOrder && !isLoadingShipAvailableOrder &&
      shipAvailableOrderSuccess) {
      executeGetCouriersOrders({ orderId: match.params.orderId });
      history.push(`/operations/courier-tracking`);
    }
  }

  buildPurchaser = (availableOrder: AvailableOrderType): Node => {
    const { classes } = this.props;
    const { purchaser } = availableOrder;
    if (!purchaser) return <div/>;
    return (
      <Fragment>
        <div className={classes.userImageWrap}>
          <img className={classes.userImage}
               src={(purchaser.image || defaultPic)}
               alt={purchaser.firstName}/>
        </div>
        <p className={classes.userName}>{purchaser.firstName} {purchaser.lastName}</p>
      </Fragment>);
  };

  handleChange = (e: Event) => {
    const { name, value } = (e.target: { [key: string]: string });
    this.setState({ [name]: { value } });
  };

  handleShipAvailableOrder = () => {
    const { executeShipAvailableOrder, match } = this.props;
    const { courier } = this.state;
    executeShipAvailableOrder(courier.value, match.params.orderId);
  };

  render() {
    const {
      classes,
      isLoadingGetAvailableOrder,
      availableOrder,
      couriers,
      isLoadingShipAvailableOrder,
      shipAvailableOrderError,
    } = this.props;
    const { courier } = this.state;
    const couriersOptions: { [key: string]: string }[] = (couriers || [])
      .map((courier: Courier) => {
        return ({
          value: courier.id,
          label: courier.name,
          image: courier.image,
        });
      });
    const chosenCourier: ?Courier = (couriers || []).find(({ id }) => id === courier.value);
    const error = shipAvailableOrderError;
    return (
      <div>
        {(isLoadingGetAvailableOrder || !availableOrder) ?
          <CircularProgress className={classes.progress} size={80}/> :
          <Grid container spacing={16} className={classNames(classes.orderWrap,
            { [classes.loading]: isLoadingShipAvailableOrder })}>
            <Grid item container xs={8} direction='column' spacing={16}>
              <Grid item>
                <h1 className={classes.orderId}>{availableOrder.id}</h1>
                <p>{availableOrder.serviceTier} ({availableOrder.serviceTier === 'FLEXIBLE' ? 'Viajeros' : 'Carga'})</p>
                {this.buildPurchaser(availableOrder)}
              </Grid>
              {availableOrder.items.map((item: OrderItemType, index: number) =>
                <Paper className={classes.item} key={index}>
                  <OrderItem item={item}
                             orderState={availableOrder.state}/>
                </Paper>)}
            </Grid>
            <Grid item container xs={4} direction="column">
              <Grid item container
                    justify="space-around"
                    alignItems="center"
                    className={classes.topRightWrap}>
                <Grid item>
                  {!!chosenCourier &&
                  <img className={classes.courierImage}
                       src={chosenCourier.image || defaultPic}
                       alt={chosenCourier.name.substring(0, 5)}/>}
                  <FormControl className={classes.formControl}>
                    <TextField value={courier.value}
                               label="Courier"
                               name="courier"
                               variant="filled"
                               onChange={this.handleChange}
                               select>
                      <MenuItem value="">
                        None
                      </MenuItem>
                      {couriersOptions.map((option: { [key: string]: string }, i: number) =>
                        <MenuItem key={i}
                                  value={option.value}>{option.label}</MenuItem>)}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button variant="contained"
                          color="primary"
                          size="large"
                          disabled={!courier.value || (availableOrder && availableOrder.state === 'RESERVED')}
                          onClick={this.handleShipAvailableOrder}>
                    RESERVE ORDER
                  </Button>
                  {(availableOrder && availableOrder.state === 'RESERVED') &&
                  <p className={classes.reserved}>Order is already reserved</p>}
                </Grid>
              </Grid>
              <Grid item container justify="center">
                <Paper className={classes.summary}>
                  <Summary order={availableOrder}/>
                </Paper>
              </Grid>
            </Grid>
          </Grid>}
        {isLoadingShipAvailableOrder &&
        <CircularProgress className={classes.progress} size={80}/>}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={error}
          autoHideDuration={2000}
          message={
            !!error ? (
              <span id="message-id">{
                error &&
                error.data &&
                error.data.Error ? error.data.Error.message : 'Generic Error'
              }</span>
            ) : (
              <span>Costo real guardado</span>
            )
          }
        />
      </div>
    );
  }
}

AvailableOrder.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  executeGetAvailableOrder: PropTypes.func.isRequired,
  isLoadingGetAvailableOrder: PropTypes.bool,
  availableOrder: PropTypes.object,
  getAvailableOrderError: PropTypes.object,
  executeGetCouriers: PropTypes.func.isRequired,
  couriers: PropTypes.array,
  isLoadingGetCouriers: PropTypes.bool,
  getCouriersError: PropTypes.object,
  executeShipAvailableOrder: PropTypes.func.isRequired,
  isLoadingShipAvailableOrder: PropTypes.bool,
  shipAvailableOrderSuccess: PropTypes.bool,
  shipAvailableOrderError: PropTypes.object,
  executeGetCouriersOrders: PropTypes.func.isRequired,
};

export default withStyles(styles)(AvailableOrder);
