// @flow
export default function style(theme: { [key: string]: mixed }) {
  return ({
    multiImageWrap: {
      display: 'grid',
      height: '100%',
      width: '100%',
      boxSizing: 'content-box',
      border: '1px solid #EEE',
    },
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
    imageMulti: {
      margin: -1,
      border: '1px solid #EEEEEE',
    },
    sizeAlone: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    sizeMulti: {
      gridTemplateColumns: 'repeat(2, 50%)',
      gridTemplateRows: 'repeat(2, 50%)',
      position: 'relative',
    },
    default1: {
      backgroundColor: '#F5F5F5',
    },
    default2: {
      backgroundColor: '#EEEEEE',
    },
    hasMore: {
      backgroundColor: '#212121',
      color: 'white',
      fontSize: 16,
      lineHeight: '19px',
      opacity: 0.6,
      gridRow: 2,
      gridColumn: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    'image-0': {
      gridRow: 1,
      gridColumn: 1,

    },
    'image-1': {
      gridRow: 1,
      gridColumn: 2,
    },
    'image-2': {
      gridRow: 2,
      gridColumn: 1,
    },
    'image-3': {
      position: 'relative',
      gridRow: 2,
      gridColumn: 2,
    },
  });
}
