// @flow
import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import localValues, {
  executeGetCountriesEpic,
  executeGetItemCategoriesEpic,
  executeGetCouponTypesEpic,
  executeGetCouponDiscountTypesEpic,
  executeGetCouponRulesEpic,
  executeGetListRulesEpic,
  executeGetOrderStatesEpic,
  executeGetCancellationMotivesEpic,
  executeGetDeliveryCouriersEpic,
  executeGetCouriersEpic,
  executeGetServiceTiersEpic,
  initialState as LocalValuesInitialState,
} from './local-values/duck';
import auth, {
  actions as AuthActions,
  executeGetSessionEpic,
  loginFirstStepEpic,
  loginSecondStepEpic,
  logoutEpic,
  initialState as AuthInitialState,
} from './auth/duck';
import featuredProducts, {
  executeGetFeaturedProductsEpic,
  changeFeaturedProductsPaginationEpic,
  executeDeleteFeaturedProductsEpic,
  executeGetFeaturedProductsAgain,
  initialState as FeaturedProductsInitialState,
} from './featured-products/duck';
import featuredProduct, {
  executeGetFeaturedProductEpic,
  executeSaveFeaturedProductEpic,
  executeCreateFeaturedProductEpic,
  initialState as FeaturedProductInitialState,
} from './featured-product/duck';
import popularProducts, {
  executeGetPopularProductsEpic,
  changePopularProductsPaginationEpic,
  executeChangeVisibilityPopularProductsEpic,
  initialState as PopularProductsInitialState,
} from './popular-products/duck';
import popularProduct, {
  executeGetPopularProductEpic,
  executeSavePopularProductEpic,
  executeCreatePopularProductEpic,
  initialState as PopularProductInitialState,
} from './popular-product/duck';
import coupons, {
  executeGetCouponsEpic,
  changeCouponsPaginationEpic,
  initialState as CouponsInitialState,
} from './coupons/duck';
import coupon, {
  executeGetCouponEpic,
  executeSaveCouponEpic,
  executeCreateCouponEpic,
  initialState as CouponInitialState,
} from './coupon/duck';
import orders, {
  executeGetOrdersEpic,
  changeOrdersPaginationEpic,
  initialState as OrdersInitialState,
} from './orders/duck';
import order, {
  executeGetOrderEpic,
  executeSaveOrderEpic,
  executeSaveShippingAddressEpic,
  initialState as OrderInitialState,
} from './order/duck';
import lists, {
  executeGetListsEpic,
  changeListsPaginationEpic,
  initialState as ListsInitialState,
} from './mailing-lists/duck';
import list, {
  executeGetListEpic,
  executeSaveListEpic,
  executeGetListSelectedUsersEpic,
  executeCreateListEpic,
  executeUpdateListSelectedUsersEpic,
  changeListSelectedUsersPaginationEpic,
  initialState as ListInitialState,
} from './mailing-list/duck';
import refunds, {
  executeGetRefundsEpic,
  changeRefundsPaginationEpic,
  executeUploadImageRefundsEpic,
  executeUploadRefundsActionEpic,
  executeUploadRefundsEpic,
  executeGetRefundsAgainEpic,
  initialState as RefundsInitialState,
} from './refunds/duck';
import progressOrders, {
  executeGetProgressOrdersEpic,
  changeProgressOrdersPaginationEpic,
  // executeConfirmReceptionEpic,
  executeShipOrdersEpic,
  executeGetProgressOrdersAgainEpic,
  initialState as ProgressOrdersInitialState,
} from './progress-orders/duck';
import shipProducts, {
  executeGetShipOrderEpic,
  executeSendProductsEpic,
  initialState as ShipOrdersInitialState,
} from './ship-products/duck';
import shipping, {
  executeGetShippingEpic,
  executePerformShippingEpic,
  executeGetShippingAfterSuccessEpic,
  changeShippingPaginationEpic,
  initialState as ShippingInitialState,
} from './shipping/duck';
import deliveryConfirm, {
  executeGetDeliveryConfirmEpic,
  executeConfirmDeliveryConfirmEpic,
  executeUpdateDeliveryConfirmEpic,
  executeCancelDeliveryConfirmEpic,
  executeGetDeliveryConfirmAfterPostEpic,
  changeDeliveryConfirmPaginationEpic,
  initialState as DeliveryConfirmInitialState,
} from './delivery-confirm/duck';
import reception, {
  executeGetReceptionEpic,
  executeConfirmReceptionEpic,
  executeCancelReceptionEpic,
  executeGetReceptionAfterPostEpic,
  changeReceptionPaginationEpic,
  initialState as ReceptionInitialState,
} from './reception/duck';
import pending, {
  executeGetPendingEpic,
  executeSendPendingEpic,
  executeReportPendingEpic,
  changePendingPaginationEpic,
  initialState as PendingInitialState,
} from './pending/duck';
import review, {
  executeGetReviewEpic,
  executeCancelReviewEpic,
  executeApproveReviewEpic,
  executeFixReviewEpic,
  changeReviewPaginationEpic,
  initialState as ReviewInitialState,
} from './review/duck';
import availableOrders, {
  executeGetAvailableOrdersEpic,
  executeGetAvailableOrderEpic,
  executeShipAvailableOrderEpic,
  initialState as AvailableOrdersInitialState,
} from "./available-orders/duck";
import courierTracking, {
  executeGetCouriersOrdersEpic,
  executeUpdateOrderItemInvoiceEpic,
  executeUpdateOrderItemInvoiceFileEpic,
  executeUpdateOrderItemTrackingEpic,
  executeUpdateOrderItemStoreOrderIdEpic,
  executeCancelReservationEpic,
  executeReceiveOrderItemEpic,
  executeMissingTrackingEmailEpic,
  executeCourierTrackingStatusResetEpic,
  getTrackingNotificationStatusEpic,
  postTrackingNotificationStatusEpic,
  executeSetRealCostEpic,
  initialState as CourierTrackingInitialState,
} from './courier-tracking/duck';
import courierReception, {
  executeCourierReceptionGetPendingShipmentsEpic,
  executeCourierReceptionReceiveProductsAtQempoEpic,
  executeCourierReceptionCancelProductsEpic,
  executeCourierReceptionUploadInvoiceFileEpic,
  executeCourierReceptionUploadInvoiceFileSucessEpic,
  executeCourierReceptionUpdateOrderItemInvoiceEpic,
  executeCourierReceptionUpdateOrderItemInvoiceResetEpic,
  executeCourierReceptionUpdateOrderItemTrackingNumberEpic,
  executeCourierReceptionUpdateOrderItemTrackingNumberResetEpic,
  executeCourierReceptionUpdateOrderItemStoreOrderIdEpic,
  executeCourierReceptionUpdateOrderItemStoreOrderIdResetEpic,
  executeCourierReceptionMissingTrackingEmailEpic,
  initialState as CourierReceptionInitialState,
} from './courier-reception/duck';
import productCrafter, {
  executeCreateLinkEpic,
  executeScrapProductEpic as executeProductCrafterScrapProductEpic,
  initialState as ProductCrafterInitialState,
} from './product-crafter/duck';
import bagCrafter, {
  executeScrapProductEpic as executeBagCrafterScrapProductEpic,
  executeCreateBagEpic,
  initialState as BagCrafterInitialState,
} from './bag-crafter/duck';

import type { AuthState } from './auth/duck';
import type { LocalValuesState } from './local-values/duck';
import type { FeaturedProductsState } from './featured-products/duck';
import type { FeaturedProductState } from './featured-product/duck';
import type { PopularProductsState } from './popular-products/duck';
import type { PopularProductState } from './popular-product/duck';
import type { OrdersState } from './orders/duck';
import type { OrderState } from './order/duck';
import type { CouponsState } from './coupons/duck';
import type { CouponState } from './coupon/duck';
import type { ListsState } from './mailing-lists/duck';
import type { ListState } from './mailing-list/duck';
import type { RefundsState } from './refunds/duck';
import type { ProgressOrdersState } from './progress-orders/duck';
import type { ShipProductsState } from './ship-products/duck';
import type { ShippingState } from './shipping/duck';
import type { DeliveryConfirmState } from './delivery-confirm/duck';
import type { ReceptionState } from './reception/duck';
import type { PendingState } from './pending/duck';
import type { ReviewState } from './review/duck';
import type { AvailableOrdersState } from './available-orders/duck';
import type { CourierTrackingState } from './courier-tracking/duck';
import type { CourierReceptionState } from './courier-reception/duck';
import type { ProductCrafterState } from './product-crafter/duck';
import type { BagCrafterState } from './bag-crafter/duck';


export type RootState = {
  localValues: LocalValuesState;
  auth: AuthState;
  featuredProducts: FeaturedProductsState,
  featuredProduct: FeaturedProductState,
  popularProducts: PopularProductsState,
  popularProduct: PopularProductState,
  coupons: CouponsState;
  coupon: CouponState;
  orders: OrdersState;
  order: OrderState;
  lists: ListsState;
  list: ListState;
  refunds: RefundsState;
  progressOrders: ProgressOrdersState;
  shipProducts: ShipProductsState;
  shipping: ShippingState;
  deliveryConfirm: DeliveryConfirmState;
  reception: ReceptionState;
  pending: PendingState;
  review: ReviewState;
  availableOrders: AvailableOrdersState;
  courierTracking: CourierTrackingState;
  courierReception: CourierReceptionState;
  productCrafter: ProductCrafterState;
  bagCrafter: BagCrafterState;
};

export const defaultInitialState = {
  localValues: LocalValuesInitialState,
  auth: AuthInitialState,
  featuredProducts: FeaturedProductsInitialState,
  featuredProduct: FeaturedProductInitialState,
  popularProducts: PopularProductsInitialState,
  popularProduct: PopularProductInitialState,
  coupons: CouponsInitialState,
  coupon: CouponInitialState,
  orders: OrdersInitialState,
  order: OrderInitialState,
  lists: ListsInitialState,
  list: ListInitialState,
  refunds: RefundsInitialState,
  progressOrders: ProgressOrdersInitialState,
  shipProducts: ShipOrdersInitialState,
  shipping: ShippingInitialState,
  deliveryConfirm: DeliveryConfirmInitialState,
  reception: ReceptionInitialState,
  pending: PendingInitialState,
  review: ReviewInitialState,
  availableOrders: AvailableOrdersInitialState,
  courierTracking: CourierTrackingInitialState,
  courierReception: CourierReceptionInitialState,
  productCrafter: ProductCrafterInitialState,
  bagCrafter: BagCrafterInitialState,
};

export const rootEpic = combineEpics(
  executeGetCountriesEpic,
  executeGetItemCategoriesEpic,
  executeGetCouponTypesEpic,
  executeGetCouponDiscountTypesEpic,
  executeGetCouponRulesEpic,
  executeGetListRulesEpic,
  executeGetOrderStatesEpic,
  executeGetCancellationMotivesEpic,
  executeGetDeliveryCouriersEpic,
  executeGetCouriersEpic,
  executeGetSessionEpic,
  loginFirstStepEpic,
  loginSecondStepEpic,
  logoutEpic,
  executeGetFeaturedProductsEpic,
  changeFeaturedProductsPaginationEpic,
  executeDeleteFeaturedProductsEpic,
  executeGetFeaturedProductEpic,
  executeSaveFeaturedProductEpic,
  executeCreateFeaturedProductEpic,
  executeGetFeaturedProductsAgain,
  executeGetPopularProductsEpic,
  changePopularProductsPaginationEpic,
  executeChangeVisibilityPopularProductsEpic,
  executeGetPopularProductEpic,
  executeSavePopularProductEpic,
  executeCreatePopularProductEpic,
  executeGetCouponsEpic,
  changeCouponsPaginationEpic,
  executeGetCouponEpic,
  executeSaveCouponEpic,
  executeCreateCouponEpic,
  executeGetOrdersEpic,
  changeOrdersPaginationEpic,
  executeGetOrderEpic,
  executeSaveOrderEpic,
  executeSaveShippingAddressEpic,
  executeGetListsEpic,
  changeListsPaginationEpic,
  executeGetListEpic,
  executeSaveListEpic,
  executeGetListSelectedUsersEpic,
  executeCreateListEpic,
  executeUpdateListSelectedUsersEpic,
  changeListSelectedUsersPaginationEpic,
  executeGetRefundsEpic,
  changeRefundsPaginationEpic,
  executeUploadImageRefundsEpic,
  executeUploadRefundsActionEpic,
  executeUploadRefundsEpic,
  executeGetRefundsAgainEpic,
  executeGetProgressOrdersEpic,
  changeProgressOrdersPaginationEpic,
  executeShipOrdersEpic,
  executeGetProgressOrdersAgainEpic,
  executeGetShipOrderEpic,
  executeSendProductsEpic,
  executeGetShippingEpic,
  executePerformShippingEpic,
  executeGetShippingAfterSuccessEpic,
  changeShippingPaginationEpic,
  executeGetDeliveryConfirmEpic,
  executeConfirmDeliveryConfirmEpic,
  executeUpdateDeliveryConfirmEpic,
  executeCancelDeliveryConfirmEpic,
  executeGetDeliveryConfirmAfterPostEpic,
  changeDeliveryConfirmPaginationEpic,
  executeGetReceptionEpic,
  executeConfirmReceptionEpic,
  executeCancelReceptionEpic,
  executeGetReceptionAfterPostEpic,
  changeReceptionPaginationEpic,
  executeGetPendingEpic,
  executeSendPendingEpic,
  executeReportPendingEpic,
  changePendingPaginationEpic,
  executeGetReviewEpic,
  executeCancelReviewEpic,
  executeApproveReviewEpic,
  executeFixReviewEpic,
  changeReviewPaginationEpic,
  // Available Orders
  executeGetAvailableOrdersEpic,
  executeGetAvailableOrderEpic,
  executeShipAvailableOrderEpic,
  // Courier Tracking
  executeGetCouriersOrdersEpic,
  executeUpdateOrderItemInvoiceEpic,
  executeUpdateOrderItemInvoiceFileEpic,
  executeUpdateOrderItemTrackingEpic,
  executeUpdateOrderItemStoreOrderIdEpic,
  executeCancelReservationEpic,
  executeReceiveOrderItemEpic,
  executeMissingTrackingEmailEpic,
  executeCourierTrackingStatusResetEpic,
  getTrackingNotificationStatusEpic,
  postTrackingNotificationStatusEpic,
  executeSetRealCostEpic,
  // Courier Reception
  executeCourierReceptionGetPendingShipmentsEpic,
  executeCourierReceptionReceiveProductsAtQempoEpic,
  executeCourierReceptionCancelProductsEpic,
  executeCourierReceptionUploadInvoiceFileEpic,
  executeCourierReceptionUploadInvoiceFileSucessEpic,
  executeCourierReceptionUpdateOrderItemInvoiceEpic,
  executeCourierReceptionUpdateOrderItemInvoiceResetEpic,
  executeCourierReceptionUpdateOrderItemTrackingNumberEpic,
  executeCourierReceptionUpdateOrderItemTrackingNumberResetEpic,
  executeCourierReceptionUpdateOrderItemStoreOrderIdEpic,
  executeCourierReceptionUpdateOrderItemStoreOrderIdResetEpic,
  executeCourierReceptionMissingTrackingEmailEpic,
  executeCreateLinkEpic,
  executeProductCrafterScrapProductEpic,
  executeBagCrafterScrapProductEpic,
  executeCreateBagEpic,
  executeGetServiceTiersEpic,
);

export const appReducer = (history: { [key: string]: any }) => combineReducers({
  localValues,
  auth,
  featuredProducts,
  featuredProduct,
  popularProducts,
  popularProduct,
  coupons,
  coupon,
  orders,
  order,
  lists,
  list,
  refunds,
  progressOrders,
  shipProducts,
  shipping,
  deliveryConfirm,
  reception,
  pending,
  review,
  availableOrders,
  courierTracking,
  courierReception,
  productCrafter,
  bagCrafter,
  router: connectRouter(history),
});

export const rootReducer = (history: { [key: string]: any }) => (state: RootState, action: { type: string }) => {
  // Clear higher order state when logout
  if (action.type === AuthActions.AUTH_LOGOUT_REQUEST) {
    // But keep localValue (these are agnostic)
    // TODO: Check if locavalues are needed to persist even if logged out
    state = { ...defaultInitialState, localValues: state.localValues };
  }
  return appReducer(history)(state, action);
};
