// @flow
export default function styles(theme: { [key: string]: any }) {
  return ({
    courierTrackingWrap: {
      maxWidth: 1200,
      margin: 'auto',
    },
    progress: {
      position: 'absolute',
      top: '50%',
      [theme.breakpoints.up('sm')]: {
        left: '50%',
      },
      left: '40%',
    },
    progressTop: {
      position: 'absolute',
      top: '50%',
      [theme.breakpoints.up('sm')]: {
        left: '50%',
      },
      left: '40%',
      margin: '-25px 0 0 -25px',
    },
    topArea: {
      position: 'relative',
      width: '100%',
      height: 100,
    },
    cancellingReservation: {
      opacity: 0.35,
      pointerEvents: 'none',
      userSelect: 'none',
    },
    courierSelectImage: {
      display: 'inline-block',
      width: 56,
      height: 56,
      borderRadius: '50%',
      marginRight: 8,
    },
    formControl: {
      minWidth: 120,
    },
    couriersOrderWrap: {
      padding: 16,
    },
    orderId: {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 500,
      margin: '8px 0',
    },
    paidAt: {
      fontSize: 20,
      lineHeight: '28px',
      color: '#2196F3',
    },
    userImageWrap: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    userImage: {
      display: 'block',
      borderRadius: '50px',
      width: 50,
      height: 50,
      marginRight: 8,
    },
    userName: {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: 8,
    },
    shippingIdentityWrap: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    identityProperty: {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: 8,
      color: '#9E9E9E',
      fontSize: 13,
      lineHeight: '16px',
    },
    identitySpan: {
      color: '#212121',
      fontSize: 16,
      lineHeight: '24px',
    },
    identitySpanUrl: {
      color: '#212121',
      fontSize: 16,
      lineHeight: '24px',
      marginRight: 8,
    },
    orderItemWrap: {
      borderRadius: 5,
      border: '1px solid #BDBDBD',
      marginBottom: 8,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    topLeftWrap: {
      marginBottom: 8,
    },
    topRightWrap: {
      flexDirection: 'column',
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
      },
    },
    courierImageWrap: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    courierImage: {
      display: 'block',
      borderRadius: '50%',
      width: 50,
      height: 50,
      marginRight: 8,
    },
    courierName: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    orderInfo: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 8,
      [theme.breakpoints.up('lg')]: {
        alignItems: 'flex-end',
        flexDirection: 'column',
        marginTop: 0,
      },
    },
    orderState: {
      margin: '8px 0',
      [theme.breakpoints.up('lg')]: {
        margin: '0 0 8px',
      },
    },
    orderType: {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 500,
      margin: '8px 0',
      [theme.breakpoints.up('lg')]: {
        margin: '8px 0 0',
      },
      color: '#2196F3',
    },
    summary: {
      margin: '16px 0 0',
      padding: 16,
      backgroundColor: '#EEEEEE',
      borderRadius: 5,
    },
    buttonReset: {
      margin: '8px 0',
    },
    buttonCancelReservation: {
      marginTop: '16px',
      backgroundColor: '#F44336',
      color: '#FFF',
      '&:hover': {
        backgroundColor: '#CC1A07',
      },
    },
    buttonMissingTracking: {
      marginTop: '16px',
    },
    closeBtn: {
      padding: theme.spacing.unit / 2,
    },
  });
};
