// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import CourierReception from '../components/CourierReception';
import { actions as CourierReceptionActions } from '../duck';
import { actions as LocalValuesActions } from '../../local-values/duck';
import { withRouter } from 'react-router-dom';

import type { RootState } from '../../index';

const mapStateToProps = (state: RootState) => {
  return {
    isLoadingGetPendingShipments: state.courierReception.isLoadingGetPendingShipments,
    pendingShipments: state.courierReception.pendingShipments,
    getPendingShipmentsError: state.courierReception.getPendingShipmentsError,
    isLoadingReceiveProductsAtQempo: state.courierReception.isLoadingReceiveProductsAtQempo,
    receiveProductsAtQempoSuccess: state.courierReception.receiveProductsAtQempoSuccess,
    receiveProductsAtQempoError: state.courierReception.receiveProductsAtQempoError,
    isCancellingProducts: state.courierReception.isCancellingProducts,
    cancelProductsSuccess: state.courierReception.cancelProductsSuccess,
    cancelProductsError: state.courierReception.cancelProductsError,
    isLoadingCancelReception: state.reception.isLoadingCancelReception,
    cancellationMotives: state.localValues.cancellationMotives,
    isLoadingGetCancellationMotives: state.localValues.isLoadingGetCancellationMotives,
    getCancellationMotivesSuccess: state.localValues.getCancellationMotivesSuccess,
    getCancellationMotivesError: state.localValues.getCancellationMotivesError,
    isLoadingGetCouriers: state.localValues.isLoadingGetCouriers,
    couriers: state.localValues.couriers,
    getCouriersError: state.localValues.getCouriersError,
    orderItemOperations: state.courierReception.orderItemOperations    ,
    isPostingMissingTrackingMail: state.courierReception.isPostingMissingTrackingMail,
    missingTrackingMailSuccess: state.courierReception.missingTrackingMailSuccess,
    missingTrackingMailError: state.courierReception.missingTrackingMailError,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return bindActionCreators({ ...CourierReceptionActions, ...LocalValuesActions }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourierReception));
