// @flow
import { Observable, Observer } from 'rxjs';
// import { getCreator, postCreator, putCreator, deleteCreator } from '@qempo/qempo-web-common/http';
import environment from '../../environment';
// import { searchTransaction } from '../orders/api';
import update from 'immutability-helper';
// import { countries } from '../local-values/api';
import apiOrders from '../orders/orders';

export const endpoints = Object.freeze({
  GET_ORDER: `${environment.apiUrl}administrative/order/:id`,
  UPDATE_ORDER: `${environment.apiUrl}administrative/order/:id`,
  UPDATE_SHIPPING_ADDRESS: `${environment.apiUrl}administrative/order/:id`,
  SEARCH_ADMIN1: `${environment.apiUrl}/geography/location/:country`,
  SEARCH_ADMIN2: `${environment.apiUrl}/geography/location/:country/:admin1`,
  SEARCH_ADMIN3: `${environment.apiUrl}/geography/location/:country/:admin1/:admin2`,
});

const admin1Peru = [{ id: 6, name: 'Lima' }, { id: 7, name: 'Junín' }];
const admin2Lima = [{ id: 17, name: 'Lima Metropolitana' }, { id: 19, name: 'Barranca' }];
const admin3Lima = [{ id: 172, name: 'Magdalena del Mar' }, { id: 154, name: 'La Victoria' }];
const admin3Barranca = [{ id: 286, name: 'Paramonga' }, { id: 295, name: 'Supe' }];
const admin2Junin = [{ id: 27, name: 'Chanchamayo' }, { id: 28, name: 'Huancayo' }];
const admin3Chanchamayo = [{ id: 327, name: 'San Ramon' }, { id: 391, name: 'La Merced' }];
const admin3Huancayo = [{ id: 475, name: 'Huancayo' }, { id: 418, name: 'El Tambo' }];
const admin1Usa = [{ id: 2, name: 'California' }, { id: 1, name: 'Florida' }];
const admin2California = [{ id: 33, name: 'San Francisco' }, { id: 35, name: 'Anaheim' }];
const admin2Florida = [{ id: 49, name: 'Miami' }, { id: 57, name: 'Orlando' }];

export const getOneOrderApi = (queryParams: any, o: any) => Observable.create(
  (observer: Observer) => {
    observer.next(apiOrders[0]);
  }
);

export const updateOrderApi = (data: any, o: any) => Observable.create(
  (observer: Observer) => {
    let order = apiOrders.find(e => e.id === o.route.substr(52));
    order.expiresAt = data.expiresAt !== undefined ? data.expiresAt : order.expiresAt;
    order.state = data.state !== undefined ? data.state : order.state;
    observer.next(order);
  }
);

export const updateShippingAddressApi = (data: any, o: any) => Observable.create(
  (observer: Observer) => {
    const keys = Object.keys(data);
    let order = apiOrders.find(e => e.id === o.route.substr(52));
    keys.forEach(key => {
      order = update(order, {
        [key]: { $set: data[key] },
      });
    });
    observer.next(order);
  }
);

export const searchAdmin1Api = (queryParams: any, o: any) => Observable.create(
  (observer: Observer) => {
    const country = o.route.substr(51);
    let admin1Items;
    if (country === 'PE') {
      admin1Items = admin1Peru;
    }
    if (country === 'US') {
      admin1Items = admin1Usa;
    }
    observer.next(admin1Items);
  }
);

export const searchAdmin2Api = (queryParams: any, o: any) => Observable.create(
  (observer: Observer) => {
    const country = o.route.substr(51, 2);
    const admin1 = parseInt(o.route.substr(54), 10);
    let admin2Items;
    if (country === 'PE') {
      if (admin1 === 6) {
        admin2Items = admin2Lima;
      }
      if (admin1 === 7) {
        admin2Items = admin2Junin;
      }
    }
    if (country === 'US') {
      if (admin1 === 2) {
        admin2Items = admin2California;
      }
      if (admin1 === 1) {
        admin2Items = admin2Florida;
      }
    }
    observer.next(admin2Items);
  }
);

export const searchAdmin3Api = (queryParams: any, o: any) => Observable.create(
  (observer: Observer) => {
    const country = o.route.substr(51, 2);
    const admin1 = parseInt(o.route.substr(54, 1), 10);
    const admin2 = parseInt(o.route.substr(56), 10);
    let admin3Items;
    if (country === 'PE') {
      if (admin1 === 6) {
        if (admin2 === 17) {
          admin3Items = admin3Lima;
        }
        if (admin2 === 19) {
          admin3Items = admin3Barranca;
        }
      }
      if (admin1 === 7) {
        if (admin2 === 27) {
          admin3Items = admin3Chanchamayo;
        }
        if (admin2 === 28) {
          admin3Items = admin3Huancayo;
        }
      }
    }
    if (country === 'US') {
      return null;
    }
    observer.next(admin3Items);
  }
);

export type UpdateOrderRequest = {
  purchaser?: {
    firstName?: string,
    lastName?: string,
  };
  traveler?: {
    firstName?: string,
    lastName?: string,
  };
  state?: string;
  expiresAtDate?: string;
};

export type UpdateShippingAddressRequest = {
  address1?: string;
  address2?: string;
  country?: string;
  admin1?: number;
  admin2?: number;
  admin3?: number;
  zipCode?: string;
  firstName?: string;
  lastName?: string;
  recipientId?: string;
  phone?: string;
};
