// @flow
export default function styles(theme: { [key: string]: any }) {
  return ({
    beforeDate: {
      fontSize: 20,
      lineHeight: '24px',
      color: '#FF9800',
    },
    qempoCommission: {
      fontWeight: 500,
      color: '#2196F3',
    },
  });
}
