// @flow
import React, { Component } from 'react';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  TextField,
  FormControl,
  InputAdornment,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  LinearProgress,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { FormValidator } from '../../../common/form';

import styles from './style';

import type { ScrappedProduct } from '../duck';
import type { HTTPError } from '../../../common/error';

import environment from '../../../environment';

type BaseRequest = {
  url: string,
  name: string,
  price: string,
  image: string,
  amount: number,
  categoryId: number,
  detail?: string,
  originalPackaging?: boolean,
  weight?: string,
  shipping?: string,
  volumeTier?: string,
};

type VirtualEvent = {
  target: { [key: string]: string, ... }
};

type Props = {
  +classes: { [key: string]: any },
  +itemCategories?: { [key: string]: any }[],
  +loading: Boolean,
  +error?: HTTPError,
  +product?: ScrappedProduct,
  +shortUrl?: string,
  +isLoadingGetItemCategories: Boolean,
  +executeScrapProductRequest: (productUrl: string) => void,
  +executeCreateLinkRequest: (longUrl: string) => void,
  +executeGetItemCategories: () => void
};

type State = {
  formProductUrl: string,
  formProductImageUrl: string,
  formProductName: string,
  formProductDetail: string,
  formProductPrice: string,
  formProductWeight: string,
  formProductVolumetricId: string,
  formProductAmount: number,
  formProductCategoryId: number,
  formProductOriginalPackaging: boolean,
  formProductShipping: string,
  formUtmCampaignSource: string,
  formUtmCampaignMedium: string,
  formUtmCampaignName: string,
  formPreferredImportationMethod: string,
  showSnackbarMessage: boolean,
  snackbarMessage: string,
  snackbarButtonText: string
};

const INITIAL_STATE: State = {
  formProductUrl: '',
  formProductImageUrl: '',
  formProductName: '',
  formProductDetail: '',
  formProductPrice: '0.00',
  formProductWeight: '0.00',
  formProductVolumetricId: 'NA',
  formProductAmount: 1,
  formProductCategoryId: 1,
  formProductOriginalPackaging: false,
  formProductShipping: '0.00',
  formUtmCampaignSource: 'QempoCrafter',
  formUtmCampaignMedium: 'direct_contact',
  formUtmCampaignName: 'support',
  formPreferredImportationMethod: '',
  showSnackbarMessage: false,
  snackbarMessage: '',
  snackbarButtonText: 'COPY',
};

const importationMethods = [
  { id: '0', value: 'full' },
  { id: '1', value: 'lite' },
];

class ProductCrafterMain extends Component<Props, State> {
  inputsFormValidator: FormValidator;
  formUrlChangeSubject$: Subject;
  formUrlChangeSubscription: Subscription;
  state = INITIAL_STATE;

  constructor(props: Props) {
    super(props);
    this.inputsFormValidator = new FormValidator({
      url: { required: 'required' },
      name: { required: 'required' },
    });
    this.formUrlChangeSubject$ = new Subject();
  }

  componentDidMount() {
    if (!this.props.itemCategories) {
      this.props.executeGetItemCategories();
    }
    this.formUrlChangeSubscription = this.formUrlChangeSubject$
      .pipe(debounceTime(1000))
      .subscribe(newUrl => {
        if (!!newUrl) {
          this.props.executeScrapProductRequest(newUrl);
        }
      });
  }

  componentWillUnmount() {
    if (!!this.formUrlChangeSubscription) {
      this.formUrlChangeSubscription.unsubscribe();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!!this.props.product && prevProps.product !== this.props.product) {
      const {
        categoryId,
        imageUrl,
        name,
        price,
        weight,
        shipping,
      } = this.props.product;
      let newState: $Shape<State> = {
        formProductName: name,
        formProductImageUrl: imageUrl,
        formProductDetail: '',
        formProductPrice: price || '0.00',
        formProductWeight: weight || '0.00',
        formProductCategoryId: categoryId,
        formProductShipping: shipping || '0.00',
      };
      if (!!newState.formProductCategoryId && !!this.props.itemCategories) {
        const category = this.props.itemCategories.find(
          category => category.id === newState.formProductCategoryId,
        );
        if (category) {
          newState.formProductVolumetricId =
            !!category.volumeTiers.length > 0
              ? category.volumeTiers[0].code
              : 'NA';
        }
      }
      this.setState(newState);
    }
    if (!!this.props.shortUrl && prevProps.shortUrl !== this.props.shortUrl) {
      this.setState({
        showSnackbarMessage: true,
        snackbarMessage: `Product Link: ${this.props.shortUrl}`,
        snackbarButtonText: 'COPY',
      });
    }
  }

  buildCategoryMenuOptions() {
    const { itemCategories } = this.props;
    if (!itemCategories) {
      return <MenuItem value={1}>Loading</MenuItem>;
    }
    return itemCategories
      .filter(category => category.enabled)
      .map(category => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ),
      );
  }

  buildPreferredImportationMethodMenuOptions = () =>
    importationMethods.map((method: { [string]: string }, index: number) =>
      <MenuItem key={index} value={method.id}>
        {method.value}
      </MenuItem>);

  buildVolumeTierOptions() {
    const { itemCategories } = this.props;
    const itemCategory =
      !!itemCategories &&
      itemCategories.find(x => x.id === this.state.formProductCategoryId);
    return !!itemCategory ? (
      itemCategory.volumeTiers.map(vt => (
        <MenuItem key={vt.code} value={vt.code}>
          {vt.code}
        </MenuItem>
      ))
    ) : (
      <MenuItem value="NA">Loading</MenuItem>
    );
  }

  cleanForm = () => this.setState(INITIAL_STATE);

  handleChangeValueSelect = (ev: VirtualEvent) => {
    const { name, value } = ev.target;
    const newState: $Shape<State> = { [name]: (value: any) };
    switch (name) {
      case 'formProductCategoryId':
        if (!!this.props.itemCategories) {
          const category = this.props.itemCategories.find(
            category => category.id === newState.formProductCategoryId,
          );
          if (category) {
            newState.formProductVolumetricId =
              !!category.volumeTiers.length > 0
                ? category.volumeTiers[0].code
                : 'NA';
            newState.formProductOriginalPackaging = !!category.originalPackagingSelectable
              ? false
              : category.originalPackagingDefault;
          }
        }
        break;
      default:
        break;
    }
    this.setState(newState);
  };

  handleChangeValue = (ev: Event) => {
    if (
      ev.target instanceof HTMLInputElement ||
      ev.target instanceof HTMLTextAreaElement
    ) {
      const { name, value } = ev.target;
      let newState: $Shape<State> = { [name]: value };
      this.setState(newState, () => {
        if (name === 'formProductUrl') {
          this.formUrlChangeSubscription.next(value);
        }
      });
    }
  };

  handleChangeChecked = (ev: VirtualEvent) => {
    const { name, checked } = ev.target;
    this.setState({ [name]: checked });
  };

  createProductLink = () => {
    const {
      formProductAmount,
      formProductCategoryId,
      formProductDetail,
      formProductImageUrl,
      formProductName,
      formProductOriginalPackaging,
      formProductPrice,
      formProductWeight,
      formProductShipping,
      formProductUrl,
      formProductVolumetricId,
      formUtmCampaignMedium,
      formUtmCampaignName,
      formUtmCampaignSource,
      formPreferredImportationMethod,
    } = this.state;

    const baseRequest: BaseRequest = {
      url: encodeURIComponent(formProductUrl || ''),
      name: encodeURIComponent(formProductName),
      price: formProductPrice,
      image: encodeURIComponent(formProductImageUrl),
      amount: parseInt(formProductAmount, 10),
      categoryId: formProductCategoryId,
    };

    if (formProductDetail)
      baseRequest.detail = encodeURIComponent(formProductDetail);
    if (formProductOriginalPackaging)
      baseRequest.originalPackaging = formProductOriginalPackaging;
    if (formProductWeight)
      baseRequest.weight = formProductWeight;
    if (formProductShipping)
      baseRequest.shipping = formProductShipping;
    if (formProductVolumetricId)
      baseRequest.volumeTier = formProductVolumetricId;
    let preferredImportationMethodString = '';
    if (formPreferredImportationMethod) {
      const preferredImportationMethod = importationMethods.find(
        method => method.id === formPreferredImportationMethod,
      );
      preferredImportationMethodString =
        `&preferred_importation_method=${preferredImportationMethod ? preferredImportationMethod.value : ''}`;
    }
    const base64Product = btoa(JSON.stringify(baseRequest));
    const longUrl = `${environment.webUrl}/new-order/product?utm_source=${formUtmCampaignSource}` +
      `&utm_medium=${formUtmCampaignMedium}&utm_campaign=${formUtmCampaignName}` +
      `${preferredImportationMethodString}&product=${base64Product}`;
    this.props.executeCreateLinkRequest(longUrl);
  };

  handleCloseSnackbar = (ev: Event, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      showSnackbarMessage: false,
    });
  };

  copyLink = () => {
    if (this.props.shortUrl) {
      this.copyTextToClipboard(this.props.shortUrl);
    }
  };

  copyTextToClipboard = (text: string) => {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(() =>
        this.setState({
          snackbarButtonText: 'COPIED',
        }),
      (err) => {
        console.error('Async: Could not copy text: ', err);
        prompt('Could not copy, please copy this link:', text);
      },
    );
  };

  fallbackCopyTextToClipboard(text: string) {
    const docBody = document.body;
    if (docBody) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      docBody.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand('copy');
        if (!successful) {
          throw new Error('Could not copy to clipboard');
        } else {
          this.setState({
            snackbarButtonText: 'COPIED',
          });
        }
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
        prompt('Could not copy, please copy this link:', text);
      }
      docBody.removeChild(textArea);
    }
  }

  render() {
    const emptyImageUrl = 'https://placekitten.com/300/300';
    const ITEM_HEIGHT = 48;
    const {
      classes,
      itemCategories,
      isLoadingGetItemCategories,
      loading,
    } = this.props;
    const {
      formProductUrl,
      formProductImageUrl,
      formProductName,
      formProductDetail,
      formProductPrice,
      formProductWeight,
      formProductVolumetricId,
      formProductAmount,
      formProductCategoryId,
      formProductOriginalPackaging,
      formProductShipping,
      formUtmCampaignSource,
      formUtmCampaignMedium,
      formUtmCampaignName,
      formPreferredImportationMethod,
      showSnackbarMessage,
      snackbarMessage,
      snackbarButtonText,
    } = this.state;
    const mergedLoading = isLoadingGetItemCategories || loading;
    const selectedCategory =
      !!itemCategories &&
      itemCategories.find(category => category.id === formProductCategoryId);
    return (
      <div>
        <Paper className={classes.container}>
          <form>
            {!!mergedLoading && <LinearProgress/>}
            <Grid container spacing={16}>
              <Grid item md={6} sm={12}>
                <Grid container spacing={16}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="formProductUrl"
                      label='Product Link (Url)'
                      value={formProductUrl}
                      className={classes.textField}
                      margin="normal"
                      onChange={this.handleChangeValue}
                      disabled={mergedLoading}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="formProductName"
                      label="Product Name"
                      value={formProductName}
                      className={classes.textField}
                      margin="normal"
                      disabled={mergedLoading}
                      onChange={this.handleChangeValue}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      name="formProductDetail"
                      label="Detail"
                      multiline
                      value={formProductDetail}
                      className={classes.textField}
                      margin="normal"
                      disabled={mergedLoading}
                      onChange={this.handleChangeValue}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      name="formProductWeight"
                      label="Weight"
                      className={classes.textField}
                      value={formProductWeight}
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Kg.</InputAdornment>
                        ),
                      }}
                      disabled={mergedLoading}
                      onChange={this.handleChangeValue}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      name="formProductPrice"
                      label="Price"
                      className={classes.textField}
                      value={formProductPrice}
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      disabled={mergedLoading}
                      onChange={this.handleChangeValue}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      select
                      fullWidth
                      label="Volumetric"
                      name="formProductVolumetricId"
                      value={formProductVolumetricId}
                      className={classes.formControlMarginNormal}
                      disabled={
                        mergedLoading || formProductVolumetricId === 'NA'
                      }
                      onChange={this.handleChangeValueSelect}
                    >
                      {this.buildVolumeTierOptions()}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      name="formProductAmount"
                      label="Amount"
                      type="number"
                      value={formProductAmount}
                      margin="normal"
                      disabled={mergedLoading}
                      onChange={this.handleChangeValue}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      select
                      fullWidth
                      label="Product Category"
                      name="formProductCategoryId"
                      value={formProductCategoryId}
                      className={classes.formControlMarginNormal}
                      disabled={mergedLoading || formProductCategoryId === 'NA'}
                      onChange={this.handleChangeValueSelect}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                            },
                          },
                        },
                      }}
                    >
                      {this.buildCategoryMenuOptions()}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <div className={classes.formControlMarginNormal}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="formProductOriginalPackaging"
                              checked={formProductOriginalPackaging}
                              color="primary"
                            />
                          }
                          label={
                            formProductOriginalPackaging
                              ? 'With Box'
                              : 'Without Box'
                          }
                          disabled={
                            mergedLoading ||
                            (!!selectedCategory &&
                              !selectedCategory.originalPackagingSelectable)
                          }
                          onChange={this.handleChangeChecked}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      name="formProductShipping"
                      label="Shipping"
                      value={formProductShipping}
                      className={classes.textField}
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      disabled={mergedLoading}
                      onChange={this.handleChangeValue}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} sm={12}>
                <Grid
                  container
                  direction="column"
                  justify="flex-end"
                  alignItems="stretch"
                  spacing={16}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <Paper className={classes.imagePreview} elevation={2}>
                        <img
                          className={classes.imageElement}
                          src={
                            formProductImageUrl
                              ? formProductImageUrl
                              : emptyImageUrl
                          }
                          alt="ProductImage"
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      name="formProductImageUrl"
                      label="Image Url"
                      value={formProductImageUrl}
                      className={classes.textField}
                      margin="normal"
                      disabled={mergedLoading}
                      onChange={this.handleChangeValue}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={16}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    name="formUtmCampaignSource"
                    value={formUtmCampaignSource}
                    label="UTM Campaign Source"
                    className={classes.textField}
                    margin="normal"
                    onChange={this.handleChangeValue}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    name="formUtmCampaignMedium"
                    value={formUtmCampaignMedium}
                    label="UTM Campaign Medium"
                    className={classes.textField}
                    margin="normal"
                    onChange={this.handleChangeValue}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    name="formUtmCampaignName"
                    value={formUtmCampaignName}
                    label="UTM Campaign Name"
                    className={classes.textField}
                    margin="normal"
                    onChange={this.handleChangeValue}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    label="Preferred importation method"
                    name="formPreferredImportationMethod"
                    value={formPreferredImportationMethod}
                    margin="normal"
                    disabled={false}
                    onChange={this.handleChangeValueSelect}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {this.buildPreferredImportationMethodMenuOptions()}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={16}
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Button
                    color="secondary"
                    className={classes.button}
                    onClick={this.cleanForm}
                  >
                    CLEAN FORM
                  </Button>
                  <Button
                    color="primary"
                    className={classes.button}
                    onClick={this.createProductLink}
                  >
                    CREATE PRODUCT LINK
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={showSnackbarMessage}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
          message={<span>{snackbarMessage}</span>}
          action={[
            <Button
              key="ok"
              color="primary"
              size="small"
              onClick={this.copyLink}
            >
              {snackbarButtonText}
            </Button>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

ProductCrafterMain.propTypes = {
  classes: PropTypes.object.isRequired,
  itemCategories: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
  product: PropTypes.object,
  shortUrl: PropTypes.string,
  isLoadingGetItemCategories: PropTypes.bool,
  executeScrapProductRequest: PropTypes.func,
  executeCreateLinkRequest: PropTypes.func,
  executeGetItemCategories: PropTypes.func,
};

export default withStyles(styles)(ProductCrafterMain);
