const apiMailingLists = [
  {
    id: 4,
    name: 'Mejores Usuarios',
    totalUsers: 400,
    lastUpdated: '2018/04/12',
    rules: [
      [
        {
          name: 'HasAtLeastSomeOrders',
          params: {
            min_orders: 1,
          },
        },
        {
          name: 'HasAtMaxSomeOrders',
          params: {
            max_orders: 5,
          },
        },
      ],
      [
        {
          name: 'ScrappedItemsByKeywordsLists',
          params: {
            keywordsLists: [
              'Electronics',
              'Toy',
            ],
          },
        },
        {
          name: 'ScrappedItemsByKeywords',
          params: {
            regex: false,
            keywords: [
              'nike',
              'adidas',
            ],
          },
        },
      ],
    ],
    users: [
      {
        id: 11,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com'
      },
      {
        id: 12,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com'
      },
      {
        id: 13,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com'
      },
      {
        id: 14,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com'
      },
    ],
  },
  {
    id: 5,
    name: 'Peores Usuarios',
    totalUsers: 400,
    lastUpdated: '2018/04/12',
    rules: [
      [
        {
          name: 'HasAtLeastSomeOrders',
          params: {
            min_orders: 1,
          },
        },
        {
          name: 'HasAtMaxSomeOrders',
          params: {
            max_orders: 5,
          },
        },
      ],
      [
        {
          name: 'ScrappedItemsByKeywordsLists',
          params: {
            keywordsLists: [
              'Electronics',
              'Toy',
            ],
          },
        },
        {
          name: 'ScrappedItemsByKeywords',
          params: {
            regex: false,
            keywords: [
              'nike',
              'adidas',
            ],
          },
        },
      ],
    ],
    users: [
      {
        id: 11,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com'
      },
      {
        id: 12,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com'
      },
      {
        id: 13,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com'
      },
      {
        id: 14,
        name: 'Gonzalo Diaz',
        email: 'gonzalo@qempo.com'
      },
    ],
  },
];

export default apiMailingLists;
