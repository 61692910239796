// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import DrawerMenu from './DrawerMenu'
import './MainLayout.css';

// import Badge from '@material-ui/core/Badge';
// import NotificationsIcon from '@material-ui/icons/Notifications';

type Props = {
  +classes: { [key: string]: any };
  +logout: () => void;
  +logout: () => void;
  +label?: string;
  +children?: { [key: string]: any } | [{ [key: string]: any }];
  +match: { [key: string]: any };
};

type State = {
  open: boolean;
};

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - 100vw)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${240}px)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  content: {
    flexGrow: 1,
    marginTop: theme.spacing.unit * 8,
    padding: theme.spacing.unit * 3,
    height: '100%',
    overflow: 'auto',
  },
});

class MainLayout extends Component<Props, State> {
  state = {
    open: false,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, logout, label, children } = this.props;
    const { open } = this.state;
    return (
      <div className={classes.root}>
        <AppBar id="app-bar"
                position="absolute"
                className={classNames(classes.appBar, this.state.open && classes.appBarShift)}>
          <Toolbar disableGutters={!this.state.open}
                   className={classes.toolbar}>
            <IconButton color="inherit"
                        aria-label="Open drawer"
                        onClick={this.handleDrawerOpen}
                        className={classNames(
                          classes.menuButton,
                          this.state.open && classes.menuButtonHidden)}>
              <MenuIcon/>
            </IconButton>
            <Typography component="h1"
                        variant="h5"
                        color="inherit"
                        noWrap
                        className={classes.title}>
              {label}
            </Typography>
            {/*TODO: enable notifications button*/}
            {/*<IconButton color="inherit">*/}
            {/*<Badge badgeContent={4} color="secondary">*/}
            {/*<NotificationsIcon/>*/}
            {/*</Badge>*/}
            {/*</IconButton>*/}
          </Toolbar>
        </AppBar>
        <DrawerMenu id="drawer-menu"
                    variant="permanent"
                    open={open}
                    onClick={this.handleDrawerClose}
                    logout={logout}/>
        <main id="main-container"
              className={classes.content}>
          {children}
        </main>
      </div>
    );
  }
}

MainLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  label: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default withStyles(styles)(MainLayout);
// export default MainLayout;
