// @flow
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import CourierReceptionToolbar from './CourierReceptionToolbar';
import update from 'immutability-helper';
import CourierReceptionBody from './CourierReceptionBody';
// import CourierReceptionCancelDialog from './CourierReceptionCancelDialog';
import CourierReceptionConfirmDialog from './CourierReceptionConfirmDialog';
import moment from 'moment';

import './CourierReception.css';

import type { HTTPError } from '../../../common/error';
import type { Courier, Order, Shipment, ShipmentItem, ShipmentOrder } from '../../../entities';
import type {
  CancelProductsRequest,
  ReceiveProductsAtQempoRequest,
  UpdateOrderItemTrackingRequest,
  UpdateOrderItemStoreOrderIdRequest,
} from '../api';
import type { OrderItemOperationState } from '../duck';

type Props = {
  +classes: { [key: string]: any },
  +executeGetPendingShipments: (queryFilter?: {
    [key: string]: string,
  }) => void,
  +isLoadingGetPendingShipments?: boolean,
  +pendingShipments: Shipment[],
  +getPendingShipmentsError?: HTTPError,
  +executeReceiveProductsAtQempo: (request: ReceiveProductsAtQempoRequest) => void,
  +isLoadingReceiveProductsAtQempo?: boolean,
  +receiveProductsAtQempoSuccess?: boolean,
  +receiveProductsAtQempoError?: HTTPError,
  +executeCancelProducts: (request: CancelProductsRequest) => void,
  +isCancellingProducts?: boolean,
  +cancelProductsSuccess?: boolean,
  +cancelProductsError?: HTTPError,
  +executeGetCancellationMotives: () => void,
  +isLoadingGetCancellationMotives?: boolean,
  +cancellationMotives?: { [key: string]: any }[],
  +getCancellationMotivesSuccess?: boolean,
  +getCancellationMotivesError?: HTTPError,
  +executeGetCouriers: () => void,
  +isLoadingGetCouriers?: boolean,
  +couriers?: Courier[],
  +getCouriersError?: HTTPError,
  +executeUploadInvoice: (shipmentId: number, orderItemId: number, request: FormData) => void,
  +executeUpdateOrderItemTrackingNumber: (shipmentId: number, orderItemId: number, request: UpdateOrderItemTrackingRequest) => void,
  +executeUpdateOrderItemStoreOrderId: (shipmentId: number, orderItemId: number, request: UpdateOrderItemStoreOrderIdRequest) => void,
  +orderItemOperations: {
    [shipmentId: number]: {
      [orderItemId: number]: OrderItemOperationState,
    },
  },
  +executeMissingTrackingMail: (orderId: string) => void;
  +isPostingMissingTrackingMail?: boolean;
  +missingTrackingMailSuccess?: boolean;
  +missingTrackingMailError?: HTTPError;
};

type SelectedShipmentLabel = {
  +shipmentId: number,
  +deliveryDate: Date,
  +order: Order,
  +items: ShipmentItem[],
};

export type ShipmentSelection = {
  shipmentId: number,
  items: {
    [orderItemId: number]: number,
  },
};

export type OrderItemInputs = {
  trackingCourier?: string,
  trackingNumber?: string,
  trackingUrl?: string,
  storeOrderId?: string,
  invoiceImagePreview?: string,
  invoiceImageFileInputKey?: number,
  invoiceImageFormData?: FormData,
};

type State = {
  selectedShipments: {
    [shipmentId: number]: ShipmentSelection,
  },
  openCancelModal: boolean,
  openConfirmModal: boolean,
  userInputsMap: {
    [shipmentId: number]: {
      [orderItemId: number]: OrderItemInputs,
    },
  },
};

const initialState: State = {
  selectedShipments: {},
  openCancelModal: false,
  openConfirmModal: false,
  userInputsMap: {},
};

class CourierReception extends Component<Props, State> {
  state = initialState;

  componentDidMount() {
    const { executeGetPendingShipments, executeGetCancellationMotives } = this.props;
    executeGetPendingShipments();
    executeGetCancellationMotives();
  }

  componentDidUpdate(prevProps: Props) {
    //If Received Products Successfully
    if (!prevProps.receiveProductsAtQempoSuccess && this.props.receiveProductsAtQempoSuccess) {
      this.resetState();
    }
    if (!prevProps.isLoadingReceiveProductsAtQempo && this.props.isLoadingReceiveProductsAtQempo) {
      this.setState((prevState) => ({ openConfirmModal: false }));
    }
  }

  resetState = () => {
    this.setState(initialState);
  };

  handleModal = (modalName: 'openCancelModal' | 'openConfirmModal') => {
    this.setState((prevState) => ({ [modalName]: !prevState[modalName] }));
  };

  getSelectedShipmentsForLabels = (): SelectedShipmentLabel[] => {
    // This function prepares an Array of populated Shipments to render the Shipping Labels
    const { selectedShipments } = this.state;
    const { pendingShipments } = this.props;
    const dirtyLabels: (?SelectedShipmentLabel)[] = pendingShipments.map((shipment) => {
      const selectedShipment: ?ShipmentSelection = selectedShipments[shipment.shipmentId];
      // If not in selection then return null to be filtered out
      if (!selectedShipment) return null;
      const selection: SelectedShipmentLabel = {
        shipmentId: shipment.shipmentId,
        deliveryDate: shipment.deliveryDate,
        order: shipment.order,
        items: shipment.items.filter((item) => selectedShipment.items[item.orderItem.orderItemId]),
      };
      // This check is made so that if it was a partial selection and all the items from the selection are not in the shipment anymore but the shipment is still present
      if (!selection.items.length) return null;
      return selection;
    });
    // Clean labels removing null values
    return dirtyLabels.reduce((arr, item) => (item ? [...arr, item] : arr), []);
  };

  handleSelectionUpdate = (shipmentId: number, orderItemId: ?number, checked: ?boolean, amount: ?number) => {
    const { pendingShipments } = this.props;
    this.setState((prevState) => {
      // Search SelectedShipment in pendingShipments
      const shipment: Shipment = pendingShipments.find((pendingShipment) => pendingShipment.shipmentId === shipmentId);
      if (!shipment) {
        console.error('Tried to select a shipment that is not in pendingShipments');
        return prevState;
      }

      if (!orderItemId) {
        if (checked) {
          if (amount == null) {
            // Add new Shipment to Selection
            return update(prevState, {
              selectedShipments: {
                [shipmentId]: {
                  $set: {
                    shipmentId,
                    items: shipment.items.reduce((map, shipmentItem) => {
                      map[shipmentItem.orderItem.orderItemId] =
                        shipmentItem.orderItem.amount - shipmentItem.orderItem.receivedCount - shipmentItem.orderItem.cancelledCount;
                      return map;
                    }, {}),
                  },
                },
              },
            });
          } else {
            // This should not happen
            console.error('Amount should not be specified if there is no orderItemId specified');
            return prevState;
          }
        } else {
          if (amount == null) {
            // Remove Shipment from Selection
            return update(prevState, {
              selectedShipments: { $unset: [shipmentId] },
            });
          } else {
            // This should not happen
            console.error('Amount should not be specified if there is no orderItemId specified');
            return prevState;
          }
        }
      } else {
        // Get selectedShipment
        const selectedShipment: ShipmentSelection = prevState.selectedShipments[shipmentId];
        // Get ShipmentItem specified with orderItemId
        const shipmentItem: ShipmentItem = shipment.items.find((shipmentItem) => shipmentItem.orderItem.orderItemId === orderItemId);
        if (!shipmentItem) {
          console.error('Tried to select a shipmentItem that is not in pendingShipments');
          return prevState;
        }
        // Positive scenarios
        if (checked) {
          if (amount == null) {
            // If Shipment already Selected then add only the item
            if (selectedShipment) {
              return update(prevState, {
                selectedShipments: {
                  [shipmentId]: {
                    items: {
                      [orderItemId]: {
                        $set: shipmentItem.orderItem.amount - shipmentItem.orderItem.receivedCount - shipmentItem.orderItem.cancelledCount,
                      },
                    },
                  },
                },
              });
            } else {
              // If Shipment is not selected then add the Shipment along with the item
              return update(prevState, {
                selectedShipments: {
                  [shipmentId]: {
                    $set: {
                      items: {
                        [orderItemId]:
                        shipmentItem.orderItem.amount - shipmentItem.orderItem.receivedCount - shipmentItem.orderItem.cancelledCount,
                      },
                    },
                  },
                },
              });
            }
          } else if (amount === 0) {
            if (Object.keys(selectedShipment.items).length > 1) {
              // If there are more items in the shipment only remove the specified item
              return update(prevState, {
                selectedShipments: {
                  [shipmentId]: {
                    items: { $unset: [orderItemId] },
                  },
                },
              });
            } else {
              // If removing the last item then remove the shipment
              return update(prevState, {
                selectedShipments: { $unset: [shipmentId] },
              });
            }
          } else {
            // If There is an amount greater than 0 specified then just update the amount
            return update(prevState, {
              selectedShipments: {
                [shipmentId]: {
                  items: {
                    [orderItemId]: {
                      $set: amount,
                    },
                  },
                },
              },
            });
          }
        } else {
          // When removing an orderItem from the selection
          if (amount == null) {
            if (Object.keys(selectedShipment.items).length > 1) {
              // If there are more items in the shipment only remove the specified item
              return update(prevState, {
                selectedShipments: {
                  [shipmentId]: {
                    items: { $unset: [orderItemId] },
                  },
                },
              });
            } else {
              // If removing the last item then remove the shipment
              return update(prevState, {
                selectedShipments: { $unset: [shipmentId] },
              });
            }
          } else {
            // This should not happen
            console.error('Amount should not be specified when deselecting an orderItem');
            return prevState;
          }
        }
      }
    });
  };

  handleConfirmReception = () => {
    const { executeReceiveProductsAtQempo } = this.props;
    const { selectedShipments } = this.state;
    // Flatten OrderItems from SelectedShipments into a single array for the request
    const request: ReceiveProductsAtQempoRequest = { items: [] };
    for (const shipmentSelectionKey in selectedShipments) {
      const shipmentSelection = selectedShipments[parseInt(shipmentSelectionKey)];
      for (const orderItemSelectionId in shipmentSelection.items) {
        request.items.push({
          orderItemId: parseInt(orderItemSelectionId),
          amount: shipmentSelection.items[parseInt(orderItemSelectionId)],
        });
      }
    }
    executeReceiveProductsAtQempo(request);
  };

  generateOrderItemUserInput = (shipmentId: number, orderItemId: number, operation: Object) => {
    return {
      userInputsMap: {
        [shipmentId]: (shipment) =>
          update(shipment || {}, {
            [orderItemId]: (orderItem) =>
              update(
                orderItem ||
                Object.keys(operation).reduce((acc, current) => {
                  acc[current] = null;
                  return acc;
                }, {}),
                operation,
              ),
          }),
      },
    };
  };

  handleChangeItemStoreOrderId = (shipmentId: number, orderItemId: number, storeOrderId: string) => {
    this.setState((prevState) => {
      return update(
        prevState,
        this.generateOrderItemUserInput(shipmentId, orderItemId, {
          storeOrderId: {
            $set: storeOrderId,
          },
        }),
      );
    });
  };

  handleChangeItemTrackingNumber = (
    shipmentId: number,
    orderItemId: number,
    trackingNumber: string,
    trackingUrl?: string,
  ) => {
    this.setState((prevState) => {
      let operation = {
        trackingNumber: {
          $set: trackingNumber,
        },
      }
      if (typeof trackingUrl !== 'undefined' && trackingUrl !== null) {
        operation = {
          ...operation,
          trackingUrl: {
            $set: trackingUrl,
          },
        }
      }
      return update(
        prevState,
        this.generateOrderItemUserInput(shipmentId, orderItemId, operation),
      );
    });
  };

  handleOrderItemOnFileChange = (shipmentId: number, orderItemId: number, ev: SyntheticInputEvent<HTMLInputElement>) => {
    ev.preventDefault();
    const { files } = ev.target;
    if (files.length) {
      const formData = new FormData();
      const reader = new FileReader();
      formData.append('file', files[0]);
      formData.append('resourceGroup', 'EXTERNAL_INVOICE');
      reader.onload = () => {
        this.setState((prevState) => {
          return update(
            prevState,
            this.generateOrderItemUserInput(shipmentId, orderItemId, {
              invoiceImagePreview: {
                $set: reader.result.toString(),
              },
              invoiceImageFormData: {
                $set: formData,
              },
            }),
          );
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };

  updateOrderItemInvoice = (shipmentId: number, orderItemId: number) => {
    if (
      this.state.userInputsMap[shipmentId] &&
      this.state.userInputsMap[shipmentId][orderItemId] &&
      this.state.userInputsMap[shipmentId][orderItemId].invoiceImageFormData
    ) {
      const request: FormData = this.state.userInputsMap[shipmentId][orderItemId].invoiceImageFormData;
      this.props.executeUploadInvoice(shipmentId, orderItemId, request);
    } else {
      throw Error('File not found to upload');
    }
  };

  resetOrderItemInvoice = (shipmentId: number, orderItemId: number) => {
    this.setState((prevState) => {
      return update(
        prevState,
        this.generateOrderItemUserInput(shipmentId, orderItemId, {
          $unset: ['invoiceImagePreview'],
          invoiceImageFileInputKey: {
            $set: Date.now(),
          },
        }),
      );
    });
  };

  buildShippingLabels = (order: ShipmentOrder) => {
    const { classes } = this.props;
    const selectedShipmentsForLabels = this.getSelectedShipmentsForLabels();
    return selectedShipmentsForLabels.map((shippingLabel) => (
      <Fragment key={shippingLabel.order.id}>
        <section className="courier-reception-label-wrap">
          <div>
            <b>QEMPO - {shippingLabel.order.id}</b>
          </div>
          <div>
            <b>BeforeDate: {moment(shippingLabel.deliveryDate).format('DD/MM/YYYY')}</b>
          </div>
          <div>
            <b>
              {shippingLabel.order.shippingAddress.firstName} {shippingLabel.order.shippingAddress.lastName}
            </b>
          </div>
          <div>
            <b>{shippingLabel.order.shippingAddress.idNumber}</b>
          </div>
          <div>
            <b>{shippingLabel.order.shippingAddress.phone}</b>
          </div>
          {!!shippingLabel.order.shippingAddress.address1 && (
            <div>
              {shippingLabel.order.shippingAddress.address1.length > 30 ? (
                <b className={classes.labelFont}>{shippingLabel.order.shippingAddress.address1}</b>
              ) : (
                <b>{shippingLabel.order.shippingAddress.address1}</b>
              )}
            </div>
          )}
          {!!shippingLabel.order.shippingAddress.address2 && (
            <div>
              {shippingLabel.order.shippingAddress.address2.length > 30 ? (
                <b className={classes.labelFont}>{shippingLabel.order.shippingAddress.address2}</b>
              ) : (
                <b>{shippingLabel.order.shippingAddress.address2}</b>
              )}
            </div>
          )}
          <div>
            <b>
              {shippingLabel.order.shippingAddress.admin3 && `${shippingLabel.order.shippingAddress.admin3.name}, `}
              {shippingLabel.order.shippingAddress.admin2.name}, {shippingLabel.order.shippingAddress.admin1.name}
            </b>
          </div>
        </section>
        <section className="courier-reception-label-wrap">
          {shippingLabel.items.map((shipmentItem: ShipmentItem) => {
            const { orderItem } = shipmentItem;
            return (
              <div key={`${orderItem.orderItemId}`}>
                <b>
                  {orderItem.amount - orderItem.cancelledCount - orderItem.receivedCount} x {orderItem.name.substring(0, 30)}
                </b>
              </div>
            );
          })}
        </section>
      </Fragment>
    ));
  };

  updateOrderItemTrackingNumber = (shipmentId: number, orderItemId: number) => {
    const { userInputsMap } = this.state;
    const { executeUpdateOrderItemTrackingNumber } = this.props;
    if (!userInputsMap[shipmentId] || !userInputsMap[shipmentId][orderItemId]
      || !userInputsMap[shipmentId][orderItemId].trackingNumber
    ) {
      throw Error('Missing TrackingNumber Input');
    }
    const trackingCourier: string = 'OTHER';
    const trackingNumber: string = userInputsMap[shipmentId][orderItemId].trackingNumber;
    const trackingUrl: ?string = userInputsMap[shipmentId][orderItemId].trackingUrl;
    const request: UpdateOrderItemTrackingRequest = {
      trackingCourier: trackingCourier,
      trackingNumber: trackingNumber,
    };
    if (trackingUrl) {
      request.trackingCourier = 'AMZL';
      request.trackingUrl = trackingUrl;
    }
    executeUpdateOrderItemTrackingNumber(shipmentId, orderItemId, request);
  };

  updateOrderItemStoreOrderId = (shipmentId: number, orderItemId: number) => {
    const { userInputsMap } = this.state;
    const { executeUpdateOrderItemStoreOrderId } = this.props;
    if (!userInputsMap[shipmentId] || !userInputsMap[shipmentId][orderItemId] || !userInputsMap[shipmentId][orderItemId].storeOrderId) {
      throw Error('Missing StoreOrderId Input');
    }
    const storeOrderId: string = userInputsMap[shipmentId][orderItemId].storeOrderId;
    const request: UpdateOrderItemStoreOrderIdRequest = {
      storeOrderId: storeOrderId,
      orderItemId: orderItemId,
    };
    executeUpdateOrderItemStoreOrderId(shipmentId, orderItemId, request);
  };

  render(): React$Node {
    const {
      classes,
      executeGetPendingShipments,
      executeGetCouriers,
      isLoadingGetCouriers,
      couriers,
      getCouriersError,
      isLoadingGetPendingShipments,
      executeReceiveProductsAtQempo,
      pendingShipments,
      isCancellingProducts,
      isLoadingReceiveProductsAtQempo,
      // cancellationMotives,
      // executeCancelProducts,
      executeUploadInvoice,
      orderItemOperations,
      executeMissingTrackingMail,
      isPostingMissingTrackingMail,
      missingTrackingMailSuccess,
      missingTrackingMailError,
    } = this.props;
    const {
      // openCancelModal,
      openConfirmModal,
      selectedShipments,
      userInputsMap,
    } = this.state;
    return (
      <Fragment>
        <div id="courier-reception-main-wrap">
          <CourierReceptionToolbar
            executeGetPendingShipments={executeGetPendingShipments}
            executeGetCouriers={executeGetCouriers}
            isLoadingGetCouriers={isLoadingGetCouriers}
            couriers={couriers}
            getCouriersError={getCouriersError}
            isLoadingGetPendingShipments={isLoadingGetPendingShipments}
            selectedShipmentsCount={Object.keys(selectedShipments).length}
            atLeastOneSelected={pendingShipments.length > 0}
            handleModal={this.handleModal}
          />
          {/*Header*/}
          <div className={classes.tableWrapper}>
            <CourierReceptionBody
              executeReceiveProductsAtQempo={executeReceiveProductsAtQempo}
              handleSelectionUpdate={this.handleSelectionUpdate}
              isLoading={isLoadingGetPendingShipments || isCancellingProducts || isLoadingReceiveProductsAtQempo}
              pendingShipments={pendingShipments}
              selectedShipments={selectedShipments}
              handleOrderItemOnFileChange={this.handleOrderItemOnFileChange}
              handleChangeItemTrackingNumber={this.handleChangeItemTrackingNumber}
              handleChangeItemStoreOrderId={this.handleChangeItemStoreOrderId}
              updateOrderItemInvoice={this.updateOrderItemInvoice}
              userInputsMap={userInputsMap}
              executeUploadInvoice={executeUploadInvoice}
              updateOrderItemTrackingNumber={this.updateOrderItemTrackingNumber}
              updateOrderItemStoreOrderId={this.updateOrderItemStoreOrderId}
              orderItemOperations={orderItemOperations}
              resetOrderItemInvoice={this.resetOrderItemInvoice}
              executeMissingTrackingMail={executeMissingTrackingMail}
              isPostingMissingTrackingMail={isPostingMissingTrackingMail}
              missingTrackingMailSuccess={missingTrackingMailSuccess}
              missingTrackingMailError={missingTrackingMailError}
            />
          </div>
          {/* <CourierReceptionCancelDialog
            open={openCancelModal}
            handleModal={this.handleModal}
            cancellationMotives={cancellationMotives}
            executeCancelProducts={executeCancelProducts}
            selectedShipments={selectedShipments}
            pendingShipments={pendingShipments}
          /> */}
          <CourierReceptionConfirmDialog
            open={openConfirmModal}
            handleModal={this.handleModal}
            selectedShipments={selectedShipments}
            handleConfirmReception={this.handleConfirmReception}
          />
        </div>
        <div id="courier-reception-print-wrap">{this.buildShippingLabels()}</div>
      </Fragment>
    );
  }
}

CourierReception.propTypes = {
  classes: PropTypes.object.isRequired,
  executeGetPendingShipments: PropTypes.func.isRequired,
  isLoadingGetPendingShipments: PropTypes.bool,
  pendingShipments: PropTypes.array,
  getPendingShipmentsError: PropTypes.object,
  executeReceiveProductsAtQempo: PropTypes.func.isRequired,
  isLoadingReceiveProductsAtQempo: PropTypes.bool,
  receiveProductsAtQempoSuccess: PropTypes.bool,
  receiveProductsAtQempoError: PropTypes.object,
  executeCancelProducts: PropTypes.func.isRequired,
  isCancellingProducts: PropTypes.bool,
  cancelProductsSuccess: PropTypes.bool,
  cancelProductsError: PropTypes.object,
  executeGetCancellationMotives: PropTypes.func.isRequired,
  isLoadingGetCancellationMotives: PropTypes.bool,
  cancellationMotives: PropTypes.array,
  getCancellationMotivesSuccess: PropTypes.bool,
  getCancellationMotivesError: PropTypes.object,
  executeGetCouriers: PropTypes.func.isRequired,
  isLoadingGetCouriers: PropTypes.bool,
  couriers: PropTypes.array,
  getCouriersError: PropTypes.object,
  orderItemOperations: PropTypes.object,
  executeMissingTrackingMail: PropTypes.func,
  isPostingMissingTrackingMail: PropTypes.bool,
  missingTrackingMailSuccess: PropTypes.bool,
  missingTrackingMailError: PropTypes.object,
};

export default withStyles(styles)(CourierReception);
