// @flow
import { getCreator, postCreator } from '../../common/http';
import environment from '../../environment';

export const endpoints = Object.freeze({
  GET_DELIVERY_CONFIRM: `${environment.apiUrl}/operations/progress-transactions/shipped/list`,
  CONFIRM_DELIVERY_CONFIRM: `${environment.apiUrl}/operations/progress-transactions/shipped/confirm-delivery`,
  UPDATE_DELIVERY_CONFIRM: `${environment.apiUrl}/operations/progress-transactions/shipped/update`,
  CANCEL_DELIVERY_CONFIRM: `${environment.apiUrl}/operations/progress-transactions/shipped/cancel-delivery`,
});

export const getDeliveryConfirmApi = getCreator(fetch, endpoints.GET_DELIVERY_CONFIRM);

export const confirmDeliveryConfirmApi = postCreator(fetch, endpoints.CONFIRM_DELIVERY_CONFIRM);

export const updateDeliveryConfirmApi = postCreator(fetch, endpoints.UPDATE_DELIVERY_CONFIRM);

export const cancelDeliveryConfirmApi = postCreator(fetch, endpoints.CANCEL_DELIVERY_CONFIRM);

  export type ConfirmDeliveryConfirmRequest = {
  lastMileShippingIds: number[];
};

export type UpdateDeliveryConfirmRequest = {
  lastMileShippingId: number;
  trackingNumber: string;
};

export type CancelDeliveryConfirmRequest = {
  lastMileShippingId: number;
  message: string;
};
