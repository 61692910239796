// @flow
import { Observable } from 'rxjs';
// import { getCreator, postCreator, putCreator, deleteCreator } from '@qempo/qempo-web-common/http';
import environment from '../../environment';
import pendingOrders from './orders';

export const endpoints = Object.freeze({
  GET_PENDING: `${environment.apiUrl}/operations/pending/list`,
  SEND_PENDING: `${environment.apiUrl}/operations/pending`,
});

export const getPendingApi = (queryParams: any, o: any) => Observable.create(
  (observer) => {
    // let progressOrders;
    // const { filterId } = queryParams;
    const totalShippingOrders = pendingOrders.length;
    /*if (filterId !== '') {
      progressOrders = [apiOrders.find(e => e.id === filterId)];
      if (progressOrders[0] === undefined) {
        progressOrders = [];
      }
    }*/
    const travelers = [
      {
        name: 'Toro Valenzuela',
        email: 'toro@gmail.com',
        phone: '968472513',
        currentOrders: 15,
      },
      {
        name: 'Pájaro Valenzuela',
        email: 'pajaro@gmail.com',
        phone: '968472513',
        currentOrders: 14,
      },
      {
        name: 'Oso Valenzuela',
        email: 'tigre@gmail.com',
        phone: '968472513',
        currentOrders: 16,
      },
    ];

    observer.next({ pendingOrders, totalShippingOrders, travelers });
  });

export const sendPendingApi = (data: any, o: any) => Observable.create(
  (observer) => {
    data.forEach(order => {
      const { id } = order;
      const orderIndex = pendingOrders.findIndex(e => e.id === id);
      pendingOrders.splice(orderIndex, 1);
    });
    observer.next(pendingOrders);
  });

export const reportPendingApi = (data: any, o: any) => Observable.create(
  (observer) => {
    data.forEach(order => {
      const { id } = order;
      const orderIndex = pendingOrders.findIndex(e => e.id === id);
      pendingOrders.splice(orderIndex, 1);
    });
    observer.next(pendingOrders);
  });

export type PendingRequest = any;
