// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import CourierTracking from '../components/CourierTracking';
import { actions as CourierTrackingActions } from '../duck';
import { actions as LocalValuesActions } from '../../local-values/duck';
import { withRouter } from 'react-router-dom';

import type { RootState } from '../../index';

const mapStateToProps = (state: RootState) => {
  return {
    isLoadingGetCouriers: state.localValues.isLoadingGetCouriers,
    couriers: state.localValues.couriers,
    getCouriersError: state.localValues.getCouriersError,
    isLoadingGetCouriersOrders: state.courierTracking.isLoadingGetCouriersOrders,
    couriersOrders: state.courierTracking.couriersOrdersOM && state.courierTracking.couriersOrdersOM.toList().toArray(),
    getCouriersOrdersError: state.courierTracking.getCouriersOrdersError,
    isLoadingUpdateInvoice: state.courierTracking.isLoadingUpdateInvoice,
    updateInvoiceSuccess: state.courierTracking.updateInvoiceSuccess,
    updateInvoiceError: state.courierTracking.updateInvoiceError,
    isLoadingUpdateTracking: state.courierTracking.isLoadingUpdateTracking,
    updateTrackingSuccess: state.courierTracking.updateTrackingSuccess,
    updateTrackingError: state.courierTracking.updateTrackingError,
    isLoadingUpdateStoreOrderId: state.courierTracking.isLoadingUpdateStoreOrderId,
    updateStoreOrderIdSuccess: state.courierTracking.updateStoreOrderIdSuccess,
    updateStoreOrderIdError: state.courierTracking.updateStoreOrderIdError,
    isCancellingReservation: state.courierTracking.isCancellingReservation,
    cancelReservationSuccess: state.courierTracking.cancelReservationSuccess,
    cancelReservationError: state.courierTracking.cancelReservationError,
    isReceivingOrderItem: state.courierTracking.isReceivingOrderItem,
    receiveOrderItemSuccess: state.courierTracking.receiveOrderItemSuccess,
    receiveOrderItemError: state.courierTracking.receiveOrderItemError,
    isPostingMissingTrackingMail: state.courierTracking.isPostingMissingTrackingMail,
    missingTrackingMailSuccess: state.courierTracking.missingTrackingMailSuccess,
    missingTrackingMailError: state.courierTracking.missingTrackingMailError,
    isLoadingSetRealCost: state.courierTracking.isLoadingSetRealCost,
    setRealCostSuccess: state.courierTracking.setRealCostSuccess,
    setRealCostError: state.courierTracking.setRealCostError,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return bindActionCreators({ ...CourierTrackingActions, ...LocalValuesActions }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourierTracking));
