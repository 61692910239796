// @flow
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { getPopularProductsApi, changeVisibilityPopularProductsApi } from './api';

import type { RootState } from '../index';
import type { HTTPError } from '../../common/error';

// Actions
const GET_POPULAR_PRODUCTS_REQUEST = 'GET_POPULAR_PRODUCTS_REQUEST';
const GET_POPULAR_PRODUCTS_SUCCESS = 'GET_POPULAR_PRODUCTS_SUCCESS';
const GET_POPULAR_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';
const CHANGE_POPULAR_PRODUCTS_PAGINATION_REQUEST = 'CHANGE_POPULAR_PRODUCTS_PAGINATION_REQUEST';
const CHANGE_VISIBILITY_POPULAR_PRODUCTS_REQUEST = 'CHANGE_VISIBILITY_POPULAR_PRODUCTS_REQUEST';
const CHANGE_VISIBILITY_POPULAR_PRODUCTS_SUCCESS = 'CHANGE_VISIBILITY_POPULAR_PRODUCTS_SUCCESS';
const CHANGE_VISIBILITY_POPULAR_PRODUCTS_ERROR = 'CHANGE_VISIBILITY_POPULAR_PRODUCTS_ERROR';
const RESET_POPULAR_PRODUCTS_STATE = 'RESET_POPULAR_PRODUCTS_STATE';

type PopularProductsAction = {
  +type: string;
  +popularProducts?: { [key: string]: any }[];
  +totalPopularProducts?: number,
  +changes?: { [key: string]: any };
  +error?: HTTPError;
};

export type PopularProductsState = {
  isLoadingGetPopularProducts?: boolean;
  getPopularProductsSuccess?: boolean;
  getPopularProductsError?: HTTPError;
  isLoadingChangeVisibilityPopularProducts?: boolean;
  changeVisibilityPopularProductsSuccess?: boolean;
  changeVisibilityPopularProductsError?: HTTPError;
  page: number;
  limit: number;
  filterName: string;
  popularProducts?: { [key: string]: any }[];
  totalPopularProducts?: number,
};

// Action Creators
export function executeGetPopularProducts(): PopularProductsAction {
  return {
    type: GET_POPULAR_PRODUCTS_REQUEST,
  };
}

export function executeGetPopularProductsSuccess(data: { [key: string]: any }[]): PopularProductsAction {
  return {
    type: GET_POPULAR_PRODUCTS_SUCCESS,
    popularProducts: data,
    totalPopularProducts: 20,
  };
}

export function executeGetPopularProductsError(error: HTTPError): PopularProductsAction {
  return {
    type: GET_POPULAR_PRODUCTS_ERROR,
    error,
  };
}

export function changePopularProductsPagination(changes: { [key: string]: any }): PopularProductsAction {
  return {
    type: CHANGE_POPULAR_PRODUCTS_PAGINATION_REQUEST,
    changes,
  };
}

export function executeChangeVisibilityPopularProducts(url: string, name: string, enabled: boolean): PopularProductsAction {
  return {
    type: CHANGE_VISIBILITY_POPULAR_PRODUCTS_REQUEST,
    url,
    name,
    enabled,
  };
}

export function executeChangeVisibilityPopularProductsSuccess(popularProducts: { [key: string]: any }[]): PopularProductsAction {
  return {
    type: CHANGE_VISIBILITY_POPULAR_PRODUCTS_SUCCESS,
    popularProducts,
  };
}

export function executeChangeVisibilityPopularProductsError(error: HTTPError): PopularProductsAction {
  return {
    type: CHANGE_VISIBILITY_POPULAR_PRODUCTS_ERROR,
    error,
  };
}

export function executeResetPopularProductsState(): PopularProductsAction {
  return {
    type: RESET_POPULAR_PRODUCTS_STATE,
  };
}

export const actions = {
  GET_POPULAR_PRODUCTS_REQUEST,
  GET_POPULAR_PRODUCTS_SUCCESS,
  GET_POPULAR_PRODUCTS_ERROR,
  CHANGE_POPULAR_PRODUCTS_PAGINATION_REQUEST,
  CHANGE_VISIBILITY_POPULAR_PRODUCTS_REQUEST,
  CHANGE_VISIBILITY_POPULAR_PRODUCTS_SUCCESS,
  CHANGE_VISIBILITY_POPULAR_PRODUCTS_ERROR,
  executeGetPopularProducts,
  executeGetPopularProductsSuccess,
  executeGetPopularProductsError,
  changePopularProductsPagination,
  executeChangeVisibilityPopularProducts,
  executeChangeVisibilityPopularProductsSuccess,
  executeChangeVisibilityPopularProductsError,
  executeResetPopularProductsState,
};

export const initialState: PopularProductsState = {
  page: 0,
  limit: 20,
  filterName: '',
};

// Reducer
export default function reducer(state: PopularProductsState = initialState,
                                action: PopularProductsAction): PopularProductsState {
  switch (action.type) {
    case GET_POPULAR_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoadingGetPopularProducts: true,
        getPopularProductsSuccess: false,
      };
    case GET_POPULAR_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoadingGetPopularProducts: false,
        getPopularProductsSuccess: true,
        popularProducts: action.popularProducts,
        totalPopularProducts: action.totalPopularProducts,
      };
    case GET_POPULAR_PRODUCTS_ERROR:
      return {
        ...state,
        getPopularProductsSuccess: false,
        error: action.error,
      };
    case CHANGE_POPULAR_PRODUCTS_PAGINATION_REQUEST:
      const data = { ...action.changes };
      return { ...state, ...data };
    case CHANGE_VISIBILITY_POPULAR_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoadingChangeVisibilityPopularProducts: true,
        deletePopularProductsSuccess: false,
      };
    case CHANGE_VISIBILITY_POPULAR_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoadingChangeVisibilityPopularProducts: false,
        deletePopularProductsSuccess: true,
        popularProducts: action.popularProducts,
      };
    case CHANGE_VISIBILITY_POPULAR_PRODUCTS_ERROR:
      return {
        ...state,
        isLoadingChangeVisibilityPopularProducts: false,
        error: action.error,
      };
    case RESET_POPULAR_PRODUCTS_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetPopularProductsEpic(action$: ActionsObservable<PopularProductsAction>, state$: StateObservable<RootState>) {
  return action$.ofType(GET_POPULAR_PRODUCTS_REQUEST).pipe(
    delay(1000),
    mergeMap(
      () => getPopularProductsApi({
        page: state$.value.popularProducts.page,
        limit: state$.value.popularProducts.limit,
        filterName: state$.value.popularProducts.filterName,
      }).pipe(
        map((data: { [key: string]: any }[]) => executeGetPopularProductsSuccess(data)),
        catchError((e: HTTPError) => of(executeGetPopularProductsError(e))))
    )
  );
}

export function changePopularProductsPaginationEpic(action$: ActionsObservable<PopularProductsAction>) {
  return action$.ofType(CHANGE_POPULAR_PRODUCTS_PAGINATION_REQUEST).pipe(
    map(() => executeGetPopularProducts()
    )
  );
}

export function executeChangeVisibilityPopularProductsEpic(action$: ActionsObservable<PopularProductsAction>) {
  return action$.ofType(CHANGE_VISIBILITY_POPULAR_PRODUCTS_REQUEST).pipe(
    mergeMap(
      ({ url, name, enabled }) => changeVisibilityPopularProductsApi({ url, name, enabled }).pipe(
        map((data) => executeChangeVisibilityPopularProductsSuccess(data)),
        catchError((e: HTTPError) => of(executeChangeVisibilityPopularProductsError(e))))
    )
  );
}
