const apiOrders = [
  {
    id: 'TRPE001',
    state: 'IN_QEMPO',
    traveler: 'Qempo Traveler',
    beforeDate: '2018/05/26',
    tracking: '1245',
    subTotal: '150',
    shippingAddress: 'Calle Tomas Ramsey',
    items: [
      {
        name: 'PaperWhite Kindle',
        quantity: 4,
        inQempo: 3,
        price: '214.00',
        detail: 'ya se envio a Andres, NO COMPRAR - Talla M, Color aguamarina asdhj asjd asd asd asdl kj lkjsd alsdkj lakjsd lakjds lakjsd laksjd lakjsd lakjsd lakjsd lakjs dlakj sdl kajsldk jalsdkj alksdj lakjsd laksjd l aksj   alsdlk  lk jasdlk jlaksjd la kjasldkj',
        weight: '3.5',
        withBox: true,
        image: 'https://images-na.ssl-images-amazon.com/images/I/51nK1fTYrdL._SL1500_.jpg',

      },
      {
        name: 'Whatever PaperWhite',
        quantity: 3,
        inQempo: 3,
        price: '214.00',
        weight: '3.5',
        withBox: true,
        image: 'https://images-na.ssl-images-amazon.com/images/I/51nK1fTYrdL._SL1500_.jpg',

      },
      {
        name: 'PaperWhite Book',
        quantity: 5,
        inQempo: 5,
        price: '214.00',
        weight: '3.5',
        withBox: false,
        image: 'https://images-na.ssl-images-amazon.com/images/I/51nK1fTYrdL._SL1500_.jpg',

      },
    ],
  },
  {
    id: 'TRPE002',
    state: 'IN_QEMPO',
    traveler: 'Qempo Traveler',
    beforeDate: '2018/05/26',
    tracking: '1245',
    subTotal: '150',
    shippingAddress: 'Calle Tomas Ramsey',
    items: [
      {
        name: 'PaperWhite Kindle',
        quantity: 4,
        inQempo: 3,
        price: '214.00',
        weight: '3.5',
        withBox: true,
        image: 'https://images-na.ssl-images-amazon.com/images/I/51nK1fTYrdL._SL1500_.jpg',

      },
      {
        name: 'Whatever PaperWhite',
        quantity: 3,
        inQempo: 3,
        price: '214.00',
        weight: '3.5',
        withBox: true,
        image: 'https://images-na.ssl-images-amazon.com/images/I/51nK1fTYrdL._SL1500_.jpg',

      },
      {
        name: 'PaperWhite Book',
        quantity: 5,
        inQempo: 5,
        price: '214.00',
        weight: '3.5',
        withBox: false,
        image: 'https://images-na.ssl-images-amazon.com/images/I/51nK1fTYrdL._SL1500_.jpg',

      },
    ],
  },
  {
    id: 'TRPE003',
    state: 'IN_QEMPO',
    traveler: 'Qempo Traveler',
    beforeDate: '2018/05/26',
    tracking: '1245',
    subTotal: '150',
    shippingAddress: 'Calle Tomas Ramsey',
    items: [
      {
        name: 'PaperWhite Kindle',
        quantity: 4,
        inQempo: 3,
        price: '214.00',
        weight: '3.5',
        withBox: true,
        image: 'https://images-na.ssl-images-amazon.com/images/I/51nK1fTYrdL._SL1500_.jpg',

      },
      {
        name: 'Whatever PaperWhite',
        quantity: 3,
        inQempo: 3,
        price: '214.00',
        weight: '3.5',
        withBox: true,
        image: 'https://images-na.ssl-images-amazon.com/images/I/51nK1fTYrdL._SL1500_.jpg',

      },
      {
        name: 'PaperWhite Book',
        quantity: 5,
        inQempo: 5,
        price: '214.00',
        weight: '3.5',
        withBox: false,
        image: 'https://images-na.ssl-images-amazon.com/images/I/51nK1fTYrdL._SL1500_.jpg',

      },
    ],
  },
];

export default apiOrders;
