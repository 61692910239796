// @flow
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { getProgressOrdersApi, confirmReceptionApi, shipOrdersApi } from './api';

import type { RootState } from '../index';
import type { HTTPError } from '../../common/error';

// Actions
const GET_PROGRESS_ORDERS_REQUEST = 'GET_PROGRESS_ORDERS_REQUEST';
const GET_PROGRESS_ORDERS_SUCCESS = 'GET_PROGRESS_ORDERS_SUCCESS';
const GET_PROGRESS_ORDERS_ERROR = 'GET_PROGRESS_ORDERS_ERROR';
const CHANGE_PROGRESS_ORDERS_PAGINATION_REQUEST = 'CHANGE_PROGRESS_ORDERS_PAGINATION_REQUEST';
const CONFIRM_RECEPTION_REQUEST = 'CONFIRM_RECEPTION_REQUEST';
const CONFIRM_RECEPTION_SUCCESS = 'CONFIRM_RECEPTION_SUCCESS';
const CONFIRM_RECEPTION_ERROR = 'CONFIRM_RECEPTION_ERROR';
const SHIP_ORDERS_REQUEST = 'SHIP_ORDERS_REQUEST';
const SHIP_ORDERS_SUCCESS = 'SHIP_ORDERS_SUCCESS';
const SHIP_ORDERS_ERROR = 'SHIP_ORDERS_ERROR';
const RESET_PROGRESS_ORDERS_STATE = 'RESET_PROGRESS_ORDERS_STATE';

type ProgressOrdersAction = {
  +type: string;
  +progressOrders?: { [key: string]: any }[];
  +totalProgressOrders?: number,
  +changes?: { [key: string]: any };
  +error?: HTTPError;
};

export type ProgressOrdersState = {
  isLoadingGetProgressOrders?: boolean;
  getProgressOrdersSuccess?: boolean;
  getProgressOrdersError?: HTTPError;
  isLoadingConfirmReception?: boolean;
  confirmReceptionSuccess?: boolean;
  confirmReceptionError?: HTTPError;
  isLoadingShipOrders?: boolean;
  shipOrdersSuccess?: boolean;
  shipOrdersError?: HTTPError;
  page: number;
  limit: number;
  sort: string;
  ord: string;
  filterId: string;
  progressOrders?: { [key: string]: any }[];
  totalProgressOrders?: number,
};

// Action Creators
export function executeGetProgressOrders(): ProgressOrdersAction {
  return {
    type: GET_PROGRESS_ORDERS_REQUEST,
  };
}

export function executeGetProgressOrdersSuccess(data: { [key: string]: any }): ProgressOrdersAction {
  return {
    type: GET_PROGRESS_ORDERS_SUCCESS,
    progressOrders: data.progressOrders,
    totalProgressOrders: data.totalProgressOrders,
  };
}

export function executeGetProgressOrdersError(error: HTTPError): ProgressOrdersAction {
  return {
    type: GET_PROGRESS_ORDERS_ERROR,
    error,
  };
}

export function executeConfirmReception(id: string): ProgressOrdersAction {
  return {
    type: CONFIRM_RECEPTION_REQUEST,
    id,
  };
}

export function executeConfirmReceptionSuccess(): ProgressOrdersAction {
  return {
    type: CONFIRM_RECEPTION_SUCCESS,
  };
}

export function executeConfirmReceptionError(error: HTTPError): ProgressOrdersAction {
  return {
    type: CONFIRM_RECEPTION_ERROR,
    error,
  };
}

export function executeShipOrders(orders: { [key: string]: any }[]): ProgressOrdersAction {
  return {
    type: SHIP_ORDERS_REQUEST,
    orders,
  };
}

export function executeShipOrdersSuccess(): ProgressOrdersAction {
  return {
    type: SHIP_ORDERS_SUCCESS,
  };
}

export function executeShipOrdersError(error: HTTPError): ProgressOrdersAction {
  return {
    type: SHIP_ORDERS_ERROR,
    error,
  };
}

export function changeProgressOrdersPagination(changes: { [key: string]: any }): ProgressOrdersAction {
  return {
    type: CHANGE_PROGRESS_ORDERS_PAGINATION_REQUEST,
    changes,
  };
}

export function executeResetProgressOrdersState(): ProgressOrdersAction {
  return {
    type: RESET_PROGRESS_ORDERS_STATE,
  };
}

export const actions = {
  GET_PROGRESS_ORDERS_REQUEST,
  GET_PROGRESS_ORDERS_SUCCESS,
  GET_PROGRESS_ORDERS_ERROR,
  CONFIRM_RECEPTION_REQUEST,
  CONFIRM_RECEPTION_SUCCESS,
  CONFIRM_RECEPTION_ERROR,
  SHIP_ORDERS_REQUEST,
  SHIP_ORDERS_SUCCESS,
  SHIP_ORDERS_ERROR,
  CHANGE_PROGRESS_ORDERS_PAGINATION_REQUEST,
  RESET_PROGRESS_ORDERS_STATE,
  executeGetProgressOrders,
  executeGetProgressOrdersSuccess,
  executeGetProgressOrdersError,
  executeShipOrders,
  executeShipOrdersSuccess,
  executeShipOrdersError,
  changeProgressOrdersPagination,
  executeResetProgressOrdersState,
  executeConfirmReception,
};

export const initialState: ProgressOrdersState = {
  page: 0,
  limit: 20,
  ord: 'asc',
  sort: 'id',
  filterId: '',
};

// Reducer
export default function reducer(state: ProgressOrdersState = initialState,
                                action: ProgressOrdersAction): ProgressOrdersState {
  switch (action.type) {
    case GET_PROGRESS_ORDERS_REQUEST:
      return {
        ...state,
        isLoadingGetProgressOrders: true,
        getProgressOrdersSuccess: false,
      };
    case GET_PROGRESS_ORDERS_SUCCESS:
      return {
        ...state,
        isLoadingGetProgressOrders: false,
        getProgressOrdersSuccess: true,
        progressOrders: action.progressOrders,
        totalProgressOrders: action.totalProgressOrders,
      };
    case GET_PROGRESS_ORDERS_ERROR:
      return {
        ...state,
        getProgressOrdersSuccess: false,
        getProgressOrdersError: action.error,
      };
    case CHANGE_PROGRESS_ORDERS_PAGINATION_REQUEST:
      const data = { ...action.changes };
      return { ...state, ...data };
    case CONFIRM_RECEPTION_REQUEST:
      return {
        ...state,
        isLoadingConfirmReception: true,
        confirmReceptionSuccess: false,
      };
    case CONFIRM_RECEPTION_SUCCESS:
      return {
        ...state,
        isLoadingConfirmReception: false,
        confirmReceptionSuccess: true,
      };
    case CONFIRM_RECEPTION_ERROR:
      return {
        ...state,
        confirmReceptionSuccess: false,
        confirmReceptionError: action.error,
      };
    case SHIP_ORDERS_REQUEST:
      return {
        ...state,
        isLoadingShipOrders: true,
        shipOrdersSuccess: false,
      };
    case SHIP_ORDERS_SUCCESS:
      return {
        ...state,
        isLoadingShipOrders: false,
        shipOrdersSuccess: true,
      };
    case SHIP_ORDERS_ERROR:
      return {
        ...state,
        shipOrdersSuccess: false,
        shipOrdersError: action.error,
      };
    case RESET_PROGRESS_ORDERS_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetProgressOrdersEpic(action$: ActionsObservable<ProgressOrdersAction>, state$: StateObservable<RootState>) {
  return action$.ofType(GET_PROGRESS_ORDERS_REQUEST).pipe(
    delay(1000),
    mergeMap(
      () => getProgressOrdersApi({
        page: state$.value.progressOrders.page,
        limit: state$.value.progressOrders.limit,
        ord: state$.value.progressOrders.ord,
        sort: state$.value.progressOrders.sort,
        filterId: state$.value.progressOrders.filterId,
      }).pipe(
        map((data: { [key: string]: any }) => executeGetProgressOrdersSuccess(data)),
        catchError((e: HTTPError) => of(executeGetProgressOrdersError(e))))
    )
  );
}

export function changeProgressOrdersPaginationEpic(action$: ActionsObservable<ProgressOrdersAction>) {
  return action$.ofType(CHANGE_PROGRESS_ORDERS_PAGINATION_REQUEST).pipe(
    map(() => executeGetProgressOrders()
    )
  );
}

export function executeConfirmReceptionEpic(action$: ActionsObservable<ProgressOrdersAction>) {
  return action$.ofType(CONFIRM_RECEPTION_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ id }) => confirmReceptionApi({ id }).pipe(
        map(() => executeConfirmReceptionSuccess()),
        catchError((e: HTTPError) => of(executeConfirmReceptionError(e))))
    )
  );
}

export function executeShipOrdersEpic(action$: ActionsObservable<ProgressOrdersAction>) {
  return action$.ofType(SHIP_ORDERS_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ orders }) => shipOrdersApi(orders).pipe(
        map(() => executeShipOrdersSuccess()),
        catchError((e: HTTPError) => of(executeShipOrdersError(e))))
    )
  );
}

export function executeGetProgressOrdersAgainEpic(action$: ActionsObservable<ProgressOrdersAction>) {
  return action$.ofType(CONFIRM_RECEPTION_SUCCESS, SHIP_ORDERS_SUCCESS).pipe(
    map(() => executeGetProgressOrders()
    )
  );
}
