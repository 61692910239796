// @flow
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import update from 'immutability-helper';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import red from '@material-ui/core/colors/red';
import { FormValidator } from '../../../common/form';

import styles from './style';

import type { CancelReceptionRequest } from '../api';
import type { OrderItem } from '../../../entities/webapp';

type Props = {
  +classes: { [key: string]: any };
  +getSelectedItems: () => OrderItem[];
  +getSelectedItemsState: () => { [key: string]: any };
  +executeCancelReception: (CancelReceptionRequest) => void;
  +resetState: () => void;
  +handleModal: (modalName: 'openCancelModal' | 'openConfirmModal') => void;
  +open: boolean;
  +cancellationMotives: { [key: string]: any };
};

type State = {
  items: { [key: string]: any };
};

const innerTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    secondary: {
      main: red[500],
    },
  },
});

class RefundsTableDialog extends PureComponent<Props, State> {
  cancellationFormValidator: FormValidator;
  state = {
    items: {},
  };

  constructor(props: Props) {
    super(props);
    this.cancellationFormValidator = new FormValidator({
      motive: {
        required: 'required',
      },
      message: {
        required: 'required',
      },
    });
  }

  handleClose = (): void => {
    const { handleModal } = this.props;
    handleModal('openCancelModal');
    this.setState({ items: {} });
  };

  handleChange = (e: Event, orderItemId: string): void => {
    const { value, name } = (e.target: { [key: string]: any });
    const { items } = this.state;
    if (!!items[orderItemId]) {
      this.setState(prevState => update(prevState, {
        items: {
          [orderItemId]: {
            [name]: {
              $set: { value },
            },
          },
        },
      }));
    } else {
      this.setState(prevState => update(prevState, {
        items: {
          [orderItemId]: {
            $set: {
              [name]: { value },
            },
          },
        },
      }))
    }
  };

  setErrorHelper = (orderItemId: string, key: string, error: string[]): void => {
    const { items } = this.state;
    if (items[orderItemId]) {
      this.setState(prevState => update(prevState, {
        items: {
          [orderItemId]: {
            [key]: {
              $set: {
                value: '',
                errors: error,
              },
            },
          },
        },
      }));
    } else {
      this.setState(prevState => update(prevState, {
        items: {
          [orderItemId]: {
            $set: {
              [key]: {
                value: '',
                errors: error,
              },
            },
          },
        },
      }));
    }
  };

  handleConfirmCancellation = (): void => {
    const { executeCancelReception, resetState, getSelectedItems, getSelectedItemsState } = this.props;
    const receptionItems: OrderItem[] = getSelectedItems();
    const stateItems: { [key: string]: any } = getSelectedItemsState();
    const { items } = this.state;
    if (items) {
      let checkError = false;
      receptionItems.forEach(({ orderItemId }) => {
        const item = items[orderItemId];
        let motiveValue = '';
        if (!!item) motiveValue = item.motive.value;
        const motiveError = this.cancellationFormValidator.validateField('motive', motiveValue);
        if (motiveError.length) {
          checkError = true;
          this.setErrorHelper(orderItemId, 'motive', motiveError)
        } else if (item.motive.value === 'OTHER') {
          let messageValue = '';
          if (!!item.message) messageValue = item.message.value;
          const messageError = this.cancellationFormValidator.validateField('message', messageValue);
          if (messageError.length) {
            checkError = true;
            this.setErrorHelper(orderItemId, 'message', messageError)
          }
        }
      });
      if (!checkError) {
        const request = {
          items: [],
        };
        Object.keys(items).forEach((orderItemId: string) => {
          const item = items[orderItemId];
          const apiItem = (receptionItems.find((item) => item.orderItemId + '' === orderItemId): any);
          const requestItem: { [key: string]: any } = {
            orderItemId: orderItemId,
            motive: item.motive.value,
          };
          if (requestItem.motive === 'OTHER') {
            requestItem.message = item.message.value;
          }
          if (stateItems[orderItemId].amount) {
            requestItem.amount = stateItems[orderItemId].amount;
          } else {
            requestItem.amount = apiItem.amount - apiItem.cancelledCount - apiItem.receivedCount;
          }
          request.items.push(requestItem);
        });
        executeCancelReception(request);
        this.setState({
          items: {},
        }, () => {
          resetState();
        });
      }
    }
  };

  getStateValue = (orderItemId: string, field: string): {[key: string]: any} => {
    const { items } = this.state;
    let response = {};
    if (items[orderItemId] && items[orderItemId][field]) {
      response = items[orderItemId][field];
    } else {
      response = {
        value: '',
        errors: undefined,
      };
    }
    return response
  };

  buildMessageTextField = (motiveValue: string, orderItemId) => {
    if (motiveValue === 'OTHER') {
      const stateValues = this.getStateValue(orderItemId, 'message');
      return (
        <Grid item container xs={12}>
          <TextField value={stateValues.value}
                     error={!!stateValues.errors}
                     style={{
                       margin: 'auto',
                       width: '90%',
                     }}
                     inputProps={{
                       style: {
                         paddingTop: 8,
                         paddingBottom: 8,
                         fontSize: '0.8em',
                       },
                     }}
                     onChange={(e) => this.handleChange(e, orderItemId)}
                     fullWidth
                     variant="outlined"
                     name="message"/>
        </Grid>
      );
    }
  };

  buildItems = () => {
    const { classes, cancellationMotives, getSelectedItems, open } = this.props;
    const receptionItems = getSelectedItems();
    if (open && !!receptionItems.length) {
      return receptionItems.map(({ orderItemId, image, name }) => {
        const stateValues = this.getStateValue(orderItemId, 'motive');
        return (
          <Grid item container key={orderItemId} spacing={8} xs={12}>
            <Grid item container xs={3}>
              <Card className={classes.imageCard} style={{}}>
                <CardMedia component="img"
                           image={image}/>
              </Card>
            </Grid>
            <Grid item container xs={4}>
              <DialogContentText className={classes.containerDiv}>
              <span className={classes.containerDiv}>
                {name}
              </span>
              </DialogContentText>
            </Grid>
            <Grid item container xs={5}>
              <TextField value={stateValues.value}
                         error={!!stateValues.errors}
                         name="motive"
                         variant="outlined"
                         style={{
                           width: 160,
                           margin: 'auto',
                         }}
                         SelectProps={{
                           SelectDisplayProps: {
                             style: {
                               paddingTop: 9,
                               paddingBottom: 8,
                             },
                           },
                         }}
                         select
                         onChange={(e) => this.handleChange(e, orderItemId)}>
                {cancellationMotives.map(option => (
                  <MenuItem key={option.name} value={option.name}>{option.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            {this.buildMessageTextField(stateValues.value, orderItemId)}
          </Grid>)
      });
    }
  };

  render() {
    const { open } = this.props;
    return (
      <Dialog open={open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
          Why are you cancelling these items?
        </DialogTitle>
        <DialogContent>
          {this.buildItems()}
        </DialogContent>
        <MuiThemeProvider theme={innerTheme}>
          <DialogActions>
            <Button onClick={this.handleClose} style={{ margin: 'auto' }}>
              CANCEL
            </Button>
            <Button color="secondary" onClick={this.handleConfirmCancellation} style={{ margin: 'auto' }}>
              CONFIRM
            </Button>
          </DialogActions>
        </MuiThemeProvider>
      </Dialog>
    );
  }
}

RefundsTableDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  getSelectedItems: PropTypes.func.isRequired,
  getSelectedItemsState: PropTypes.func.isRequired,
  executeCancelReception: PropTypes.func.isRequired,
  handleModal: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  cancellationMotives: PropTypes.array,
};

export default withStyles(styles)(RefundsTableDialog);
