// @flow
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { getReceptionApi, confirmReceptionApi, cancelReceptionApi } from './api';

import type { RootState } from '../index';
import type { HTTPError } from '../../common/error';
import type { ConfirmReceptionRequest, CancelReceptionRequest } from './api';

// Actions
const GET_RECEPTION_REQUEST = 'GET_RECEPTION_REQUEST';
const GET_RECEPTION_SUCCESS = 'GET_RECEPTION_SUCCESS';
const GET_RECEPTION_ERROR = 'GET_RECEPTION_ERROR';
const CONFIRM_RECEPTION_REQUEST = 'CONFIRM_RECEPTION_REQUEST';
const CONFIRM_RECEPTION_SUCCESS = 'CONFIRM_RECEPTION_SUCCESS';
const CONFIRM_RECEPTION_ERROR = 'CONFIRM_RECEPTION_ERROR';
const CANCEL_RECEPTION_REQUEST = 'CANCEL_RECEPTION_REQUEST';
const CANCEL_RECEPTION_SUCCESS = 'CANCEL_RECEPTION_SUCCESS';
const CANCEL_RECEPTION_ERROR = 'CANCEL_RECEPTION_ERROR';
const CHANGE_RECEPTION_PAGINATION = 'CHANGE_RECEPTION_PAGINATION';
const RESET_RECEPTION_STATE = 'RESET_RECEPTION_STATE';

type ReceptionAction = {
  +type: string;
  +receptionFlights?: { [key: string]: any }[];
  +totalReceptionFlights?: number;
  +receptionRequest?: { [key: string]: any };
  +changes?: { [key: string]: any };
  +error?: HTTPError;
};

export type ReceptionState = {
  page: number;
  limit: number;
  search: string;
  filterKey: string;
  isLoadingGetReception?: boolean;
  getReceptionSuccess?: boolean;
  getReceptionError?: HTTPError;
  isLoadingConfirmReception?: boolean;
  confirmReceptionSuccess?: boolean;
  confirmReceptionError?: HTTPError;
  isLoadingCancelReception?: boolean;
  cancelReceptionSuccess?: boolean;
  cancelReceptionError?: HTTPError;
  receptionFlights?: { [key: string]: any }[];
  totalReceptionFlights?: number;
};

// Action Creators
export function executeGetReception(filter?: { [key: string]: any }): ReceptionAction {
  return {
    type: GET_RECEPTION_REQUEST,
    filter,
  };
}

export function executeGetReceptionSuccess(receptionFlights: { [key: string]: any }[]/*, totalReceptionFlights: number*/): ReceptionAction {
  return {
    type: GET_RECEPTION_SUCCESS,
    receptionFlights,
    // totalReceptionFlights,
  };
}

export function executeGetReceptionError(error: HTTPError): ReceptionAction {
  return {
    type: GET_RECEPTION_ERROR,
    error,
  };
}

export function executeConfirmReception(request: ConfirmReceptionRequest): ReceptionAction {
  return {
    type: CONFIRM_RECEPTION_REQUEST,
    request,
  };
}

export function executeConfirmReceptionSuccess(): ReceptionAction {
  return {
    type: CONFIRM_RECEPTION_SUCCESS,
  };
}

export function executeConfirmReceptionError(error: HTTPError): ReceptionAction {
  return {
    type: CONFIRM_RECEPTION_ERROR,
    error,
  };
}

export function executeCancelReception(request: CancelReceptionRequest): ReceptionAction {
  return {
    type: CANCEL_RECEPTION_REQUEST,
    request,
  };
}

export function executeCancelReceptionSuccess(): ReceptionAction {
  return {
    type: CANCEL_RECEPTION_SUCCESS,
  };
}

export function executeCancelReceptionError(error: HTTPError): ReceptionAction {
  return {
    type: CANCEL_RECEPTION_ERROR,
    error,
  };
}

export function changeReceptionPagination(changes: { [key: string]: any }) {
  return {
    type: CHANGE_RECEPTION_PAGINATION,
    changes,
  };
}

export function executeResetReceptionState(): ReceptionAction {
  return {
    type: RESET_RECEPTION_STATE,
  };
}

export const actions = {
  GET_RECEPTION_REQUEST,
  GET_RECEPTION_SUCCESS,
  GET_RECEPTION_ERROR,
  CONFIRM_RECEPTION_REQUEST,
  CONFIRM_RECEPTION_SUCCESS,
  CONFIRM_RECEPTION_ERROR,
  CANCEL_RECEPTION_REQUEST,
  CANCEL_RECEPTION_SUCCESS,
  CANCEL_RECEPTION_ERROR,
  CHANGE_RECEPTION_PAGINATION,
  RESET_RECEPTION_STATE,
  executeGetReception,
  executeGetReceptionSuccess,
  executeGetReceptionError,
  executeConfirmReception,
  executeConfirmReceptionSuccess,
  executeConfirmReceptionError,
  executeCancelReception,
  executeCancelReceptionSuccess,
  executeCancelReceptionError,
  changeReceptionPagination,
  executeResetReceptionState,
};

export const initialState: ReceptionState = {
  page: 0,
  limit: 10,
  search: '',
  filterKey: 'flightId',
};

// Reducer
export default function reducer(state: ReceptionState = initialState,
                                action: ReceptionAction): ReceptionState {
  switch (action.type) {
    case GET_RECEPTION_REQUEST:
      return {
        ...state,
        isLoadingGetReception: true,
        getReceptionSuccess: false,
      };
    case GET_RECEPTION_SUCCESS:
      return {
        ...state,
        isLoadingGetReception: false,
        getReceptionSuccess: true,
        receptionFlights: action.receptionFlights,
        totalReceptionFlights: action.totalReceptionFlights,
      };
    case GET_RECEPTION_ERROR:
      return {
        ...state,
        getReceptionSuccess: false,
        getReceptionError: action.error,
      };
    case CONFIRM_RECEPTION_REQUEST:
      return {
        ...state,
        isLoadingConfirmReception: true,
        confirmReceptionSuccess: false,
      };
    case CONFIRM_RECEPTION_SUCCESS:
      return {
        ...state,
        isLoadingConfirmReception: false,
        confirmReceptionSuccess: true,
      };
    case CONFIRM_RECEPTION_ERROR:
      return {
        ...state,
        confirmReceptionSuccess: false,
        confirmReceptionError: action.error,
      };
    case CANCEL_RECEPTION_REQUEST:
      return {
        ...state,
        isLoadingCancelReception: true,
        cancelReceptionSuccess: false,
      };
    case CANCEL_RECEPTION_SUCCESS:
      return {
        ...state,
        isLoadingCancelReception: false,
        cancelReceptionSuccess: true,
      };
    case CANCEL_RECEPTION_ERROR:
      return {
        ...state,
        cancelReceptionSuccess: false,
        cancelReceptionError: action.error,
      };
    case CHANGE_RECEPTION_PAGINATION:
      const data = { ...action.changes };
      return { ...state, ...data };
    case RESET_RECEPTION_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetReceptionEpic(action$: ActionsObservable<ReceptionAction>, state$: StateObservable<RootState>) {
  return action$.ofType(GET_RECEPTION_REQUEST).pipe(
    mergeMap(
      ({ filter }) => getReceptionApi(filter).pipe(
        map((response) => executeGetReceptionSuccess(response)),
        catchError((e: HTTPError) => of(executeGetReceptionError(e))))
    )
  )
    ;
}

export function executeConfirmReceptionEpic(action$: ActionsObservable<ReceptionAction>) {
  return action$.ofType(CONFIRM_RECEPTION_REQUEST).pipe(
    mergeMap(
      ({ request }) => confirmReceptionApi(request).pipe(
        map(() => executeConfirmReceptionSuccess()),
        catchError((e: HTTPError) => of(executeConfirmReceptionError(e))))
    )
  );
}

export function executeCancelReceptionEpic(action$: ActionsObservable<ReceptionAction>) {
  return action$.ofType(CANCEL_RECEPTION_REQUEST).pipe(
    mergeMap(
      ({ request }) => cancelReceptionApi(request).pipe(
        map(() => executeCancelReceptionSuccess()),
        catchError((e: HTTPError) => of(executeCancelReceptionError(e))))
    )
  );
}

export function changeReceptionPaginationEpic(action$: ActionsObservable<ReceptionAction>) {
  return action$.ofType(CHANGE_RECEPTION_PAGINATION).pipe(
    map(() => executeGetReception()
    )
  );
}

export function executeGetReceptionAfterPostEpic(action$: ActionsObservable<ReceptionAction>) {
  return action$.ofType(CONFIRM_RECEPTION_SUCCESS, CANCEL_RECEPTION_SUCCESS).pipe(
    map(() => executeGetReception()
    )
  );
}
