export const apiOrders = [
  {
    type: 'order',
    beforeDate: '2019-05-06T23:34:52+00:00',
    couponCode: 'ANSON',
    createdAt: 1557186222,
    expiresAt: 1544575237,
    extendedWarranty: true,
    id: 'TRPE20184576',
    deliveryCountry: {
      code: 'PE',
      countryId: 2,
      name: 'Peru',
    },
    invoice: {
      type: 'invoice',
    },
    flight: {
      arrivalAdmin1: {
        id: 6,
        name: "Lima"
      },
      arrivalAdmin2: {
        id: 17,
        name: "Lima"
      },
      arrivalCountry: {
        code: "PE",
        countryId: 2,
        name: "Peru"
      },
      departureAdmin1: {
        id: 76,
        name: "Florida"
      },
      departureAdmin2: {
        id: 11412,
        name: "Miami"
      },
      departureCountry: {
        code: "US",
        countryId: 1,
        name: "United States"
      },
      flightDate: "2018-12-21",
      taxRate: "6.800%"
    },
    items: [
      {
        cancellation: {
          commissionDeduction: "0.00",
          message: "concha la wea",
          qempoDeduction: "0.00",
          transactionItemCancellationMotiveId: "OTHER"
        },
        externalInvoice: {
          image: {
            resourceGroup: "EXTERNAL_INVOICE",
            resourceId: "fxbqtxzfnednjjror4zc",
            type: "CLOUDINARY",
            url: "https://res.cloudinary.com/qempo/image/upload/external-invoice/fxbqtxzfnednjjror4zc.png"
          }
        },
        orderItem: {
          amount: 1,
          detail: "test",
          fromBrickAndMortarStore: true,
          name: "Prueba 1",
          orderItemId: 7,
          originalPackaging: true,
          price: "15.23",
          storeAddress: {
            address1: "test address 1",
            address2: "test asdasdass 2",
            admin1: {
              id: 6,
              name: "Lima"
            },
            admin2: {
              id: 17,
              name: "Lima"
            },
            admin3: null,
            country: {
              code: "PE",
              countryId: 2,
              name: "Peru"
            },
            storeName: "Test Store",
            zipCode: "15076"
          },
          url: "https://www.google.com",
          weight: "0.150",
          image: 'https://www.thesprucepets.com/thmb/rD9vUV_ALr9TgRf3jHbBi_yB7xs=/960x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/beagle-RolfKopfle-Photolibrary-Getty-135631212-56a26b1d3df78cf772756667.jpg',
        },
        state: "PENDING",
        tracking: {
          trackingNumber: "12345648",
          trackingUrl: "https://www.google.com"
        },
        transactionItemId: 1,
      },
      {
        cancellation: {
          commissionDeduction: "0.00",
          message: "concha la wea",
          qempoDeduction: "0.00",
          transactionItemCancellationMotiveId: "OTHER"
        },
        externalInvoice: {
          image: {
            resourceGroup: "EXTERNAL_INVOICE",
            resourceId: "fxbqtxzfnednjjror4zc",
            type: "CLOUDINARY",
            url: "https://res.cloudinary.com/qempo/image/upload/external-invoice/fxbqtxzfnednjjror4zc.png"
          }
        },
        orderItem: {
          amount: 1,
          detail: "test",
          fromBrickAndMortarStore: true,
          name: "Prueba 1",
          orderItemId: 7,
          originalPackaging: true,
          price: "15.23",
          storeAddress: {
            address1: "test address 1",
            address2: "test asdasdass 2",
            admin1: {
              id: 6,
              name: "Lima"
            },
            admin2: {
              id: 17,
              name: "Lima"
            },
            admin3: null,
            country: {
              code: "PE",
              countryId: 2,
              name: "Peru"
            },
            storeName: "Test Store",
            zipCode: "15076"
          },
          url: "https://www.google.com",
          weight: "0.150",
          image: 'https://www.thesprucepets.com/thmb/rD9vUV_ALr9TgRf3jHbBi_yB7xs=/960x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/beagle-RolfKopfle-Photolibrary-Getty-135631212-56a26b1d3df78cf772756667.jpg',
        },
        state: "PENDING",
        tracking: {
          trackingNumber: "12345648",
          trackingUrl: "https://www.google.com"
        },
        transactionItemId: 2
      },
      {
        cancellation: {
          commissionDeduction: "0.00",
          message: "concha la wea",
          qempoDeduction: "0.00",
          transactionItemCancellationMotiveId: "OTHER"
        },
        externalInvoice: {
          image: {
            resourceGroup: "EXTERNAL_INVOICE",
            resourceId: "fxbqtxzfnednjjror4zc",
            type: "CLOUDINARY",
            url: "https://res.cloudinary.com/qempo/image/upload/external-invoice/fxbqtxzfnednjjror4zc.png"
          }
        },
        orderItem: {
          amount: 1,
          detail: "test",
          fromBrickAndMortarStore: true,
          name: "Prueba 1",
          orderItemId: 7,
          originalPackaging: true,
          price: "15.23",
          storeAddress: {
            address1: "test address 1",
            address2: "test asdasdass 2",
            admin1: {
              id: 6,
              name: "Lima"
            },
            admin2: {
              id: 17,
              name: "Lima"
            },
            admin3: null,
            country: {
              code: "PE",
              countryId: 2,
              name: "Peru"
            },
            storeName: "Test Store",
            zipCode: "15076"
          },
          url: "https://www.google.com",
          weight: "0.150",
          image: 'https://www.thesprucepets.com/thmb/rD9vUV_ALr9TgRf3jHbBi_yB7xs=/960x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/beagle-RolfKopfle-Photolibrary-Getty-135631212-56a26b1d3df78cf772756667.jpg',
        },
        state: "PENDING",
        tracking: {
          trackingNumber: "12345648",
          trackingUrl: "https://www.google.com"
        },
        transactionItemId: 3
      },
      {
        cancellation: {
          commissionDeduction: "0.00",
          message: "concha la wea",
          qempoDeduction: "0.00",
          transactionItemCancellationMotiveId: "OTHER"
        },
        externalInvoice: {
          image: {
            resourceGroup: "EXTERNAL_INVOICE",
            resourceId: "fxbqtxzfnednjjror4zc",
            type: "CLOUDINARY",
            url: "https://res.cloudinary.com/qempo/image/upload/external-invoice/fxbqtxzfnednjjror4zc.png"
          }
        },
        orderItem: {
          amount: 1,
          detail: "test",
          fromBrickAndMortarStore: true,
          name: "Prueba 1",
          orderItemId: 7,
          originalPackaging: true,
          price: "15.23",
          storeAddress: {
            address1: "test address 1",
            address2: "test asdasdass 2",
            admin1: {
              id: 6,
              name: "Lima"
            },
            admin2: {
              id: 17,
              name: "Lima"
            },
            admin3: null,
            country: {
              code: "PE",
              countryId: 2,
              name: "Peru"
            },
            storeName: "Test Store",
            zipCode: "15076"
          },
          url: "https://www.google.com",
          weight: "0.150",
          image: 'https://www.thesprucepets.com/thmb/rD9vUV_ALr9TgRf3jHbBi_yB7xs=/960x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/beagle-RolfKopfle-Photolibrary-Getty-135631212-56a26b1d3df78cf772756667.jpg',
        },
        state: "PENDING",
        tracking: {
          trackingNumber: "12345648",
          trackingUrl: "https://www.google.com"
        },
        transactionItemId: 4
      },
      {
        cancellation: {
          commissionDeduction: "0.00",
          message: "concha la wea",
          qempoDeduction: "0.00",
          transactionItemCancellationMotiveId: "OTHER"
        },
        externalInvoice: {
          image: {
            resourceGroup: "EXTERNAL_INVOICE",
            resourceId: "fxbqtxzfnednjjror4zc",
            type: "CLOUDINARY",
            url: "https://res.cloudinary.com/qempo/image/upload/external-invoice/fxbqtxzfnednjjror4zc.png"
          }
        },
        orderItem: {
          amount: 1,
          detail: "test",
          fromBrickAndMortarStore: true,
          name: "Prueba 1",
          orderItemId: 7,
          originalPackaging: true,
          price: "15.23",
          storeAddress: {
            address1: "test address 1",
            address2: "test asdasdass 2",
            admin1: {
              id: 6,
              name: "Lima"
            },
            admin2: {
              id: 17,
              name: "Lima"
            },
            admin3: null,
            country: {
              code: "PE",
              countryId: 2,
              name: "Peru"
            },
            storeName: "Test Store",
            zipCode: "15076"
          },
          url: "https://www.google.com",
          weight: "0.150",
          image: 'https://www.thesprucepets.com/thmb/rD9vUV_ALr9TgRf3jHbBi_yB7xs=/960x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/beagle-RolfKopfle-Photolibrary-Getty-135631212-56a26b1d3df78cf772756667.jpg',
        },
        state: "PENDING",
        tracking: {
          trackingNumber: "12345648",
          trackingUrl: "https://www.google.com"
        },
        transactionItemId: 5
      },
    ],
    lastMileTracking: {
      type: 'order-last-mile-tracking',
    },
    purchaseCountry: {
      code: 'PE',
      countryId: 2,
      name: 'Peru',
    },
    reservationExpiresAt:1544595237,
    serviceTier: {
      type: 'serviceTier',
    },
    payment: {
      fundings: [
        {
          apiResult: {
            amount: 2126,
            amountRefunded: 0,
            antifraudDetails: {
              address: "sdfsdf",
              addressCity: "Utcubamba",
              countryCode: "PE",
              firstName: "sdfsdf",
              lastName: "sdfsdf",
              object: "client",
              phone: "981981981"
            },
            authorizationCode: "0mekuQ",
            capture: true,
            creationDate: 1544470632000,
            currencyCode: "USD",
            currentAmount: 2126,
            description: "ORPE08158243",
            dispute: false,
            duplicated: false,
            email: "cupontest@qempo.com",
            feeDetails: {
              fixedFee: {},
              variableFee: {
                commision: 0.0399,
                currencyCode: "USD",
                total: 85
              }
            },
            fraudScore: 14,
            id: "chr_test_vDZOWk0mOGjSkPfT",
            installments: 0,
            installmentsAmount: null,
            metadata: {
              orderId: "7",
              paymentId: "7"
            },
            object: "charge",
            outcome: {
              code: "AUT0000",
              merchantMessage: "La operación de venta ha sido autorizada exitosamente",
              type: "venta_exitosa",
              userMessage: "Su compra ha sido exitosa."
            },
            paid: false,
            referenceCode: "JSz4SopHhD",
            source: {
              active: true,
              cardNumber: "411111******1111",
              client: {
                browser: null,
                deviceFingerprint: "6a681437ece32cbf00ac83a4fbe6881d",
                deviceType: "Escritorio",
                ip: "200.1.183.213",
                ipCountry: "Perú",
                ipCountryCode: "PE"
              },
              creationDate: 1544470623000,
              email: "sdf@asdf.com",
              id: "tkn_test_BBHnJHpc6ILS9Jpq",
              iin: {
                bin: "411111",
                cardBrand: "Visa",
                cardCategory: "Clásica",
                cardType: "credito",
                installmentsAllowed: [
                  2,
                  4,
                  6,
                  8,
                  10,
                  12,
                  3,
                  5,
                  7,
                  9,
                  24,
                  48
                ],
                issuer: {
                  country: "PERU",
                  countryCode: "PE",
                  name: "BBVA",
                  phoneNumber: null,
                  website: null
                },
                object: "iin"
              },
              lastFour: "1111",
              metadata: {
                clientIp: "200.1.183.213",
                installments: "1",
                url: "no-website"
              },
              object: "token",
              type: "card"
            },
            statementDescriptor: "CULQI*",
            totalFee: 100,
            totalFeeTaxes: 15,
            transferAmount: 2026,
            transferId: null
          },
          createdAt: 1544470624,
          fundingId: "4",
          fundingMethodId: "CULQI",
          fundingTypeId: "CARD",
          state: "RECEIVED",
          total: "21.26",
          updatedAt: 1544470625
        },
        {
          apiResult: {
            amount: 2126,
            amountRefunded: 0,
            antifraudDetails: {
              address: "sdfsdf",
              addressCity: "Utcubamba",
              countryCode: "PE",
              firstName: "sdfsdf",
              lastName: "sdfsdf",
              object: "client",
              phone: "981981981"
            },
            authorizationCode: "0mekuQ",
            capture: true,
            creationDate: 1544470632000,
            currencyCode: "USD",
            currentAmount: 2126,
            description: "ORPE08158243",
            dispute: false,
            duplicated: false,
            email: "cupontest@qempo.com",
            feeDetails: {
              fixedFee: {},
              variableFee: {
                commision: 0.0399,
                currencyCode: "USD",
                total: 85
              }
            },
            fraudScore: 14,
            id: "chr_test_vDZOWk0mOGjSkPfT",
            installments: 0,
            installmentsAmount: null,
            metadata: {
              orderId: "7",
              paymentId: "7"
            },
            object: "charge",
            outcome: {
              code: "AUT0000",
              merchantMessage: "La operación de venta ha sido autorizada exitosamente",
              type: "venta_exitosa",
              userMessage: "Su compra ha sido exitosa."
            },
            paid: false,
            referenceCode: "JSz4SopHhD",
            source: {
              active: true,
              cardNumber: "411111******1111",
              client: {
                browser: null,
                deviceFingerprint: "6a681437ece32cbf00ac83a4fbe6881d",
                deviceType: "Escritorio",
                ip: "200.1.183.213",
                ipCountry: "Perú",
                ipCountryCode: "PE"
              },
              creationDate: 1544470623000,
              email: "sdf@asdf.com",
              id: "tkn_test_BBHnJHpc6ILS9Jpq",
              iin: {
                bin: "411111",
                cardBrand: "Visa",
                cardCategory: "Clásica",
                cardType: "credito",
                installmentsAllowed: [
                  2,
                  4,
                  6,
                  8,
                  10,
                  12,
                  3,
                  5,
                  7,
                  9,
                  24,
                  48
                ],
                issuer: {
                  country: "PERU",
                  countryCode: "PE",
                  name: "BBVA",
                  phoneNumber: null,
                  website: null
                },
                object: "iin"
              },
              lastFour: "1111",
              metadata: {
                clientIp: "200.1.183.213",
                installments: "1",
                url: "no-website"
              },
              object: "token",
              type: "card"
            },
            statementDescriptor: "CULQI*",
            totalFee: 100,
            totalFeeTaxes: 15,
            transferAmount: 2026,
            transferId: null
          },
          createdAt: 1544470624,
          fundingId: "4",
          fundingMethodId: "CULQI",
          fundingTypeId: "CARD",
          state: "RECEIVED",
          total: "21.26",
          updatedAt: 1544470625
        }
      ],
      lines: [
        {
          name: "PowerA Joy-Con & Pro Controller Charging Dock - Nintendo Switch",
          paymentLineId: 34,
          price: "18.00",
          type: "ITEM"
        },
        {
          name: "Commission",
          paymentLineId: 35,
          price: "12.26",
          type: "COMMISSION"
        },
        {
          name: "Warranty",
          paymentLineId: 36,
          price: "0.00",
          type: "WARRANTY"
        },
        {
          name: "Delivery",
          paymentLineId: 37,
          price: "0.00",
          type: "DELIVERY"
        },
        {
          name: "Qempo fee",
          paymentLineId: 38,
          price: "6.00",
          type: "QEMPO"
        },
        {
          name: "QEMPOLOVE",
          paymentLineId: 39,
          price: "-15.00",
          type: "COUPON"
        }
      ],
      state: "RECEIVED",
    },
    shippingAddress: {
      address1: "4th Avenue 123",
      address2: "no idea why this has_ but no can do nothing",
      admin1: {
        id: 2,
        name: "California"
      },
      admin2: {
        id: 33,
        name: "San Francisco"
      },
      admin3: null,
      country: {
        code: "US",
        countryId: 1,
        name: "United States"
      },
      firstName: "lele",
      lastName: "test",
      recipientId: "07968437",
      phone: "998-595-060",
      shippingAddressId: 7,
      zipCode: "15076"
    },
    state: "ARRIVED",
    buyingHours: 1557186222,
    commission: 'whateverNumber',
    tax: 'someNumber',
    weightTotal: 'whateverNumber',
    hasReservation: true,
    purchaser: {
      firstName: "cupon",
      lastName: "test",
      image: "whateverImageUrl",
    },
    traveler: {
      firstName: "traveler",
      lastName: "qempo",
      image: "whateverImageUrl",
    },
    hasPaidDisbursement: true,
  },
];

export default apiOrders;
