// @flow
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TrackingNotificationStatus from '../components/TrackingNotificationStatus/TrackingNotificationStatus';
import { actions as CourierTrackingActions } from '../duck';
import { withRouter } from 'react-router-dom';

import type { RootState } from '../../index';

const mapStateToProps = (state: RootState) => {
  return {
    isLoadingTrackingNotificationStatus: state.courierTracking.isLoadingTrackingNotificationStatus,
    isPostingTrackingNotificationStatus: state.courierTracking.isPostingTrackingNotificationStatus,
    trackingNotificationStatusMap: state.courierTracking.trackingNotificationStatusMap,
  };
}

const mapDispatchToProps = (dispatch: Function) => {
  return bindActionCreators({
    ...CourierTrackingActions,
  }, dispatch);
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrackingNotificationStatus));
