// @flow
import { map, mergeMap, catchError, debounceTime, switchMap, takeUntil, delay } from 'rxjs/operators';
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import {
  endpoints,
  uploadInvoiceFileApi,
  getCouriersOrdersApi,
  updateOrderItemInvoiceApi,
  updateOrderItemTrackingApi,
  updateOrderItemStoreOrderIdApi,
  cancelReservationApi,
  receiveOrderItemApi,
  missingTrackingApi,
  getTrackingNotificationStatusApi,
  postTrackingNotificationStatusApi,
  setRealCostApi,
} from './api';
import { ofType } from 'redux-observable';
import { OrderedMap } from 'immutable';
import update from 'immutability-helper';

import type { HTTPError } from '../../common/error';
import type { ShipmentOrder } from '../../entities';

// Actions
const GET_COURIERS_ORDERS_REQUEST = 'GET_COURIERS_ORDERS_REQUEST';
const GET_COURIERS_ORDERS_SUCCESS = 'GET_COURIERS_ORDERS_SUCCESS';
const GET_COURIERS_ORDERS_ERROR = 'GET_COURIERS_ORDERS_ERROR';
const UPDATE_ORDER_ITEM_INVOICE_REQUEST = 'UPDATE_ORDER_ITEM_INVOICE_REQUEST';
const UPDATE_ORDER_ITEM_INVOICE_FILE_SUCCESS = 'UPDATE_ORDER_ITEM_INVOICE_FILE_SUCCESS';
const UPDATE_ORDER_ITEM_INVOICE_SUCCESS = 'UPDATE_ORDER_ITEM_INVOICE_SUCCESS';
const UPDATE_ORDER_ITEM_INVOICE_ERROR = 'UPDATE_ORDER_ITEM_INVOICE_ERROR';
const UPDATE_ORDER_ITEM_TRACKING_REQUEST = 'UPDATE_ORDER_ITEM_TRACKING_REQUEST';
const UPDATE_ORDER_ITEM_TRACKING_SUCCESS = 'UPDATE_ORDER_ITEM_TRACKING_SUCCESS';
const UPDATE_ORDER_ITEM_TRACKING_ERROR = 'UPDATE_ORDER_ITEM_TRACKING_ERROR';
const UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_REQUEST = 'UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_REQUEST';
const UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_SUCCESS = 'UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_SUCCESS';
const UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_ERROR = 'UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_ERROR';
const CANCEL_RESERVATION_REQUEST = 'CANCEL_RESERVATION_REQUEST';
const CANCEL_RESERVATION_SUCCESS = 'CANCEL_RESERVATION_SUCCESS';
const CANCEL_RESERVATION_ERROR = 'CANCEL_RESERVATION_ERROR';
const RECEIVE_ORDER_ITEM_REQUEST = 'RECEIVE_ORDER_ITEM_REQUEST';
const RECEIVE_ORDER_ITEM_SUCCESS = 'RECEIVE_ORDER_ITEM_SUCCESS';
const RECEIVE_ORDER_ITEM_ERROR = 'RECEIVE_ORDER_ITEM_ERROR';
const MISSING_TRACKING_MAIL_REQUEST = 'MISSING_TRACKING_MAIL_REQUEST';
const MISSING_TRACKING_MAIL_SUCCESS = 'MISSING_TRACKING_MAIL_SUCCESS';
const MISSING_TRACKING_MAIL_ERROR = 'MISSING_TRACKING_MAIL_ERROR';
const GET_TRACKING_NOTIFICATION_STATUS_REQUEST = 'GET_TRACKING_NOTIFICATION_STATUS_REQUEST';
const GET_TRACKING_NOTIFICATION_STATUS_SUCCESS = 'GET_TRACKING_NOTIFICATION_STATUS_SUCCESS';
const GET_TRACKING_NOTIFICATION_STATUS_ERROR = 'GET_TRACKING_NOTIFICATION_STATUS_ERROR';
const POST_TRACKING_NOTIFICATION_STATUS_REQUEST = 'POST_TRACKING_NOTIFICATION_STATUS_REQUEST';
const POST_TRACKING_NOTIFICATION_STATUS_SUCCESS = 'POST_TRACKING_NOTIFICATION_STATUS_SUCCESS';
const POST_TRACKING_NOTIFICATION_STATUS_ERROR = 'POST_TRACKING_NOTIFICATION_STATUS_ERROR';
const SET_REAL_COST_REQUEST = 'SET_REAL_COST_REQUEST';
const SET_REAL_COST_SUCCESS = 'SET_REAL_COST_SUCCESS';
const SET_REAL_COST_ERROR = 'SET_REAL_COST_ERROR';

const STATUS_RESET = 'STATUS_RESET';

type CourierTrackingAction = {
  +type: string;
  +queryFilter?: { [key: string]: string };
  +couriersOrders?: ShipmentOrder[];
  +couriersOrdersOM?: OrderedMap<string, ShipmentOrder>;
  +couriersOrder?: ShipmentOrder;
  +courierId?: string;
  +orderItemId?: number;
  +invoiceFormData?: FormData;
  +invoiceId?: string;
  +storeOrderId?: string;
  +shipmentId?: number;
  +orderId?: string;
  +trackingCourier?: string;
  +trackingNumber?: string;
  +trackingUrl?: string;
  +error?: HTTPError;
  +trackingNotificationStatus?: boolean;
  +realCost?: string;
};

export type CourierTrackingState = {
  isLoadingGetCouriersOrders?: boolean;
  couriersOrdersOM?: OrderedMap<string, ShipmentOrder>;
  getCouriersOrdersError?: HTTPError;
  isLoadingUpdateInvoice?: boolean;
  updateInvoiceSuccess?: boolean;
  updateInvoiceError?: HTTPError;
  isLoadingUpdateTracking?: boolean;
  updateTrackingSuccess?: boolean;
  updateTrackingError?: HTTPError;
  isLoadingUpdateStoreOrderId?: boolean;
  updateStoreOrderIdSuccess?: boolean;
  updateStoreOrderIdError?: HTTPError;
  isCancellingReservation?: boolean;
  cancelReservationSuccess?: boolean;
  cancelReservationError?: HTTPError;
  isReceivingOrderItem?: boolean;
  receiveOrderItemSuccess?: boolean;
  receiveOrderItemError?: HTTPError;
  isPostingMissingTrackingMail?: boolean;
  missingTrackingMailSuccess?: boolean;
  missingTrackingMailError?: HTTPError;
  isLoadingTrackingNotificationStatus?: boolean;
  loadingTrackingNotificationError?: HTTPError;
  isPostingTrackingNotificationStatus?: boolean;
  postingTrackingNotificationError?: HTTPError;
  trackingNotificationStatusMap: { [key: string]: boolean };
  isLoadingSetRealCost?: boolean;
  setRealCostSuccess?: boolean;
  setRealCostError?: HTTPError;
  realCostMap: { [key: string]: number };
};

// Actions Creators
export function executeGetCouriersOrders(queryFilter?: { [key: string]: string }): CourierTrackingAction {
  return {
    type: GET_COURIERS_ORDERS_REQUEST,
    queryFilter,
  };
}

export function executeGetCouriersOrdersSuccess(couriersOrders: ShipmentOrder[]): CourierTrackingAction {
  return {
    type: GET_COURIERS_ORDERS_SUCCESS,
    couriersOrders,
  };
}

export function executeGetCouriersOrdersError(error: HTTPError): CourierTrackingAction {
  return {
    type: GET_COURIERS_ORDERS_ERROR,
    error,
  };
}

export function executeUpdateOrderItemInvoice(orderItemId: number, invoiceFormData: FormData): CourierTrackingAction {
  return {
    type: UPDATE_ORDER_ITEM_INVOICE_REQUEST,
    orderItemId,
    invoiceFormData,
  };
}

export function executeUpdateOrderItemInvoiceFileSuccess(orderItemId: number, invoiceId: string): CourierTrackingAction {
  return {
    type: UPDATE_ORDER_ITEM_INVOICE_FILE_SUCCESS,
    orderItemId,
    invoiceId,
  };
}

export function executeUpdateOrderItemInvoiceSuccess(couriersOrder: ShipmentOrder): CourierTrackingAction {
  return {
    type: UPDATE_ORDER_ITEM_INVOICE_SUCCESS,
    couriersOrder,
  };
}

export function executeUpdateOrderItemInvoiceError(error: HTTPError): CourierTrackingAction {
  return {
    type: UPDATE_ORDER_ITEM_INVOICE_ERROR,
    error,
  };
}

export function executeUpdateOrderItemTracking(
  orderItemId: number,
  trackingCourier: string,
  trackingNumber: string,
  trackingUrl: string,
): CourierTrackingAction {
  return {
    type: UPDATE_ORDER_ITEM_TRACKING_REQUEST,
    orderItemId,
    trackingCourier,
    trackingNumber,
    trackingUrl,
  };
}

export function executeUpdateOrderItemTrackingSuccess(): CourierTrackingAction {
  return {
    type: UPDATE_ORDER_ITEM_TRACKING_SUCCESS,
  };
}

export function executeUpdateOrderItemTrackingError(error: HTTPError): CourierTrackingAction {
  return {
    type: UPDATE_ORDER_ITEM_TRACKING_ERROR,
    error,
  };
}

export function executeUpdateOrderItemStoreOrderId(orderItemId: number, storeOrderId: string): CourierTrackingAction {
  return {
    type: UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_REQUEST,
    orderItemId,
    storeOrderId,
  };
}

export function executeUpdateOrderItemStoreOrderIdSuccess(): CourierTrackingAction {
  return {
    type: UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_SUCCESS,
  };
}

export function executeUpdateOrderItemStoreOrderIdError(error: HTTPError): CourierTrackingAction {
  return {
    type: UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_ERROR,
    error,
  };
}

export function executeCancelReservation(shipmentId: number, orderId: string): CourierTrackingAction {
  return {
    type: CANCEL_RESERVATION_REQUEST,
    shipmentId,
    orderId,
  };
}

export function executeCancelReservationSuccess(orderId: string): CourierTrackingAction {
  return {
    type: CANCEL_RESERVATION_SUCCESS,
    orderId,
  };
}

export function executeCancelReservationError(error: HTTPError): CourierTrackingAction {
  return {
    type: CANCEL_RESERVATION_ERROR,
    error,
  };
}

export function executeReceiveOrderItem(orderItemId: number): CourierTrackingAction {
  return {
    type: RECEIVE_ORDER_ITEM_REQUEST,
    orderItemId,
  };
}

export function executeReceiveOrderItemSuccess(couriersOrder: ShipmentOrder): CourierTrackingAction {
  return {
    type: RECEIVE_ORDER_ITEM_SUCCESS,
    couriersOrder,
  };
}

export function executeReceiveOrderItemError(error: HTTPError): CourierTrackingAction {
  return {
    type: RECEIVE_ORDER_ITEM_ERROR,
    error,
  };
}

export function executeMissingTrackingMail(orderId: string): CourierTrackingAction {
  return {
    type: MISSING_TRACKING_MAIL_REQUEST,
    orderId,
  };
}

export function executeMissingTrackingMailSuccess(): CourierTrackingAction {
  return {
    type: MISSING_TRACKING_MAIL_SUCCESS,
  };
}

export function executeMissingTrackingMailError(error: HTTPError): CourierTrackingAction {
  return {
    type: MISSING_TRACKING_MAIL_ERROR,
    error,
  };
}

export function getTrackingNotificationStatus(orderId: string): CourierTrackingAction {
  return {
    type: GET_TRACKING_NOTIFICATION_STATUS_REQUEST,
    orderId,
  };
}

export function getTrackingNotificationStatusSuccess(orderId: string, trackingNotificationStatus: boolean): CourierTrackingAction {
  return {
    type: GET_TRACKING_NOTIFICATION_STATUS_SUCCESS,
    orderId,
    trackingNotificationStatus,
  };
}

export function getTrackingNotificationStatusError(error: HTTPError): CourierTrackingAction {
  return {
    type: GET_TRACKING_NOTIFICATION_STATUS_ERROR,
    error,
  };
}

export function postTrackingNotificationStatus(orderId: string, trackingNotificationStatus: boolean): CourierTrackingAction {
  return {
    type: POST_TRACKING_NOTIFICATION_STATUS_REQUEST,
    orderId,
    trackingNotificationStatus,
  };
}

export function postTrackingNotificationStatusSuccess(orderId: string): CourierTrackingAction {
  return {
    type: POST_TRACKING_NOTIFICATION_STATUS_SUCCESS,
    orderId,
  };
}

export function postTrackingNotificationStatusError(error: HTTPError): CourierTrackingAction {
  return {
    type: POST_TRACKING_NOTIFICATION_STATUS_ERROR,
    error,
  };
}

export function executeStatusReset(): CourierTrackingAction {
  return {
    type: STATUS_RESET,
  };
}

export function executeSetRealCost(realCost: string, invoiceId: string, orderId: string): CourierTrackingAction {
  return {
    type: SET_REAL_COST_REQUEST,
    realCost,
    invoiceId,
    orderId,
  };
}

export function executeSetRealCostSuccess(orderId: string): CourierTrackingAction {
  return {
    type: SET_REAL_COST_SUCCESS,
    orderId,
  };
}

export function executeSetRealCostError(error: HTTPError): CourierTrackingAction {
  return {
    type: SET_REAL_COST_ERROR,
    error,
  };
}

export const actions = {
  GET_COURIERS_ORDERS_REQUEST,
  GET_COURIERS_ORDERS_SUCCESS,
  GET_COURIERS_ORDERS_ERROR,
  UPDATE_ORDER_ITEM_INVOICE_REQUEST,
  UPDATE_ORDER_ITEM_INVOICE_FILE_SUCCESS,
  UPDATE_ORDER_ITEM_INVOICE_SUCCESS,
  UPDATE_ORDER_ITEM_INVOICE_ERROR,
  UPDATE_ORDER_ITEM_TRACKING_REQUEST,
  UPDATE_ORDER_ITEM_TRACKING_SUCCESS,
  UPDATE_ORDER_ITEM_TRACKING_ERROR,
  UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_REQUEST,
  UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_SUCCESS,
  UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_ERROR,
  CANCEL_RESERVATION_REQUEST,
  CANCEL_RESERVATION_SUCCESS,
  CANCEL_RESERVATION_ERROR,
  RECEIVE_ORDER_ITEM_REQUEST,
  RECEIVE_ORDER_ITEM_SUCCESS,
  RECEIVE_ORDER_ITEM_ERROR,
  MISSING_TRACKING_MAIL_REQUEST,
  MISSING_TRACKING_MAIL_SUCCESS,
  MISSING_TRACKING_MAIL_ERROR,
  GET_TRACKING_NOTIFICATION_STATUS_REQUEST,
  GET_TRACKING_NOTIFICATION_STATUS_SUCCESS,
  GET_TRACKING_NOTIFICATION_STATUS_ERROR,
  POST_TRACKING_NOTIFICATION_STATUS_REQUEST,
  POST_TRACKING_NOTIFICATION_STATUS_SUCCESS,
  POST_TRACKING_NOTIFICATION_STATUS_ERROR,
  STATUS_RESET,
  executeGetCouriersOrders,
  executeGetCouriersOrdersSuccess,
  executeGetCouriersOrdersError,
  executeUpdateOrderItemInvoice,
  executeUpdateOrderItemInvoiceFileSuccess,
  executeUpdateOrderItemInvoiceSuccess,
  executeUpdateOrderItemInvoiceError,
  executeUpdateOrderItemTracking,
  executeUpdateOrderItemTrackingSuccess,
  executeUpdateOrderItemTrackingError,
  executeUpdateOrderItemStoreOrderId,
  executeUpdateOrderItemStoreOrderIdSuccess,
  executeUpdateOrderItemStoreOrderIdError,
  executeCancelReservation,
  executeCancelReservationSuccess,
  executeCancelReservationError,
  executeReceiveOrderItem,
  executeReceiveOrderItemSuccess,
  executeReceiveOrderItemError,
  executeMissingTrackingMail,
  executeMissingTrackingMailSuccess,
  executeMissingTrackingMailError,
  executeStatusReset,
  getTrackingNotificationStatus,
  getTrackingNotificationStatusSuccess,
  getTrackingNotificationStatusError,
  postTrackingNotificationStatus,
  postTrackingNotificationStatusSuccess,
  postTrackingNotificationStatusError,
  executeSetRealCost,
  executeSetRealCostSuccess,
  executeSetRealCostError,
};

export const initialState: CourierTrackingState = {
  trackingNotificationStatusMap: {},
  realCostMap: {},
};

// Reducer
export default function reducer(state: CourierTrackingState = initialState, action: CourierTrackingAction): CourierTrackingState {
  switch (action.type) {
    case GET_COURIERS_ORDERS_REQUEST:
      return {
        ...state,
        isLoadingGetCouriersOrders: true,
        couriersOrdersOM: undefined,
      };
    case GET_COURIERS_ORDERS_SUCCESS:
      if (!action.couriersOrders) return state;
      const couriersOrdersOM = OrderedMap(action.couriersOrders.map((shipmentOrder: ShipmentOrder) => [shipmentOrder.id, shipmentOrder]));
      return {
        ...state,
        isLoadingGetCouriersOrders: false,
        couriersOrdersOM: couriersOrdersOM,
      };
    case GET_COURIERS_ORDERS_ERROR:
      return {
        ...state,
        isLoadingGetCouriersOrders: false,
        getCouriersOrdersError: action.error,
      };
    case UPDATE_ORDER_ITEM_INVOICE_REQUEST:
      return {
        ...state,
        isLoadingUpdateInvoice: true,
        updateInvoiceError: undefined,
      };
    case UPDATE_ORDER_ITEM_INVOICE_SUCCESS:
      if (!action.couriersOrder || !state.couriersOrdersOM) return state;
      return {
        ...state,
        couriersOrdersOM: state.couriersOrdersOM.set(action.couriersOrder.id, action.couriersOrder),
        isLoadingUpdateInvoice: false,
        updateInvoiceSuccess: true,
      };
    case UPDATE_ORDER_ITEM_INVOICE_ERROR:
      return {
        ...state,
        isLoadingUpdateInvoice: false,
        updateInvoiceError: action.error,
      };
    case UPDATE_ORDER_ITEM_TRACKING_REQUEST:
      return {
        ...state,
        isLoadingUpdateTracking: true,
      };
    case UPDATE_ORDER_ITEM_TRACKING_SUCCESS:
      return {
        ...state,
        isLoadingUpdateTracking: false,
        updateTrackingSuccess: true,
      };
    case UPDATE_ORDER_ITEM_TRACKING_ERROR:
      return {
        ...state,
        isLoadingUpdateTracking: false,
        updateTrackingError: action.error,
      };
    case UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_REQUEST:
      return {
        ...state,
        isLoadingUpdateStoreOrderId: true,
      };
    case UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_SUCCESS:
      return {
        ...state,
        isLoadingUpdateStoreOrderId: false,
        updateStoreOrderIdSuccess: true,
      };
    case UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_ERROR:
      return {
        ...state,
        isLoadingUpdateStoreOrderId: false,
        updateStoreOrderIdError: action.error,
      };
    case CANCEL_RESERVATION_REQUEST:
      return {
        ...state,
        isCancellingReservation: true,
      };
    case CANCEL_RESERVATION_SUCCESS:
      if (!action.orderId || !state.couriersOrdersOM) return state;
      return {
        ...state,
        couriersOrdersOM: state.couriersOrdersOM.delete(action.orderId),
        isCancellingReservation: false,
        cancelReservationSuccess: true,
      };
    case CANCEL_RESERVATION_ERROR:
      return {
        ...state,
        isCancellingReservation: false,
        cancelReservationError: action.error,
      };
    case RECEIVE_ORDER_ITEM_REQUEST:
      return {
        ...state,
        isReceivingOrderItem: true,
      };
    case RECEIVE_ORDER_ITEM_SUCCESS:
      if (!action.couriersOrder || !state.couriersOrdersOM) return state;
      if (action.couriersOrder.state === 'RECEIVED') {
        return {
          ...state,
          isReceivingOrderItem: false,
          receiveOrderItemSuccess: true,
          couriersOrdersOM: state.couriersOrdersOM.delete(action.couriersOrder.id),
        };
      } else {
        return {
          ...state,
          isReceivingOrderItem: false,
          receiveOrderItemSuccess: true,
          couriersOrdersOM: state.couriersOrdersOM.set(action.couriersOrder.id, action.couriersOrder),
        };
      }
    case RECEIVE_ORDER_ITEM_ERROR:
      return {
        ...state,
        isReceivingOrderItem: false,
        receiveOrderItemError: action.error,
      };
    case MISSING_TRACKING_MAIL_REQUEST:
      return {
        ...state,
        isPostingMissingTrackingMail: true,
        missingTrackingMailSuccess: false,
        missingTrackingMailError: undefined,
      };
    case MISSING_TRACKING_MAIL_SUCCESS:
      return {
        ...state,
        isPostingMissingTrackingMail: false,
        missingTrackingMailSuccess: true,
        missingTrackingMailError: undefined,
      };
    case MISSING_TRACKING_MAIL_ERROR:
      return {
        ...state,
        isPostingMissingTrackingMail: false,
        missingTrackingMailSuccess: false,
        missingTrackingMailError: action.error,
      };
    case STATUS_RESET:
      return update(state, {
        updateInvoiceSuccess: { $set: false },
        updateTrackingSuccess: { $set: false },
        updateStoreOrderIdSuccess: { $set: false },
        cancelReservationSuccess: { $set: false },
        receiveOrderItemSuccess: { $set: false },
        missingTrackingMailSuccess: { $set: false },
        setRealCostSuccess: { $set: false },
        $unset: [
          'getCouriersOrdersError',
          'updateInvoiceError',
          'updateTrackingError',
          'updateStoreOrderIdError',
          'cancelReservationError',
          'receiveOrderItemError',
          'missingTrackingMailError',
          'setRealCostError',
        ],
      });
    case GET_TRACKING_NOTIFICATION_STATUS_REQUEST:
      return {
        ...state,
        isLoadingTrackingNotificationStatus: true,
        trackingNotificationStatus: undefined,
        loadingTrackingNotificationError: undefined,
      };
    case GET_TRACKING_NOTIFICATION_STATUS_SUCCESS:
      if (!action.orderId) return state;
      return {
        ...state,
        isLoadingTrackingNotificationStatus: false,
        trackingNotificationStatusMap: {
          ...state.trackingNotificationStatusMap,
          [action.orderId]: action.trackingNotificationStatus,
        },
      };
    case GET_TRACKING_NOTIFICATION_STATUS_ERROR:
      return {
        ...state,
        isLoadingTrackingNotificationStatus: false,
        loadingTrackingNotificationError: action.error,
      }
    case POST_TRACKING_NOTIFICATION_STATUS_REQUEST:
      return {
        ...state,
        isPostingTrackingNotificationStatus: true,
        postingTrackingNotificationError: undefined,
      };
    case POST_TRACKING_NOTIFICATION_STATUS_SUCCESS:
      if (!action.orderId) return state;
      return {
        ...state,
        isPostingTrackingNotificationStatus: false,
        trackingNotificationStatusMap: {
          ...state.trackingNotificationStatusMap,
          [action.orderId]: !state.trackingNotificationStatusMap[action.orderId],
        },
      };
    case POST_TRACKING_NOTIFICATION_STATUS_ERROR:
      return {
        ...state,
        isPostingTrackingNotificationStatus: false,
        postingTrackingNotificationError: action.error,
      }
    case SET_REAL_COST_REQUEST:
      return {
        ...state,
        isLoadingSetRealCost: true,
      };
    case SET_REAL_COST_SUCCESS:
      if (!action.orderId) return state;
      return {
        ...state,
        isLoadingSetRealCost: false,
        setRealCostSuccess: true,
        realCostMap: {
          ...state.realCostMap,
          [action.orderId]: action.realCost,
        },
      };
    case SET_REAL_COST_ERROR:
      return {
        ...state,
        isLoadingSetRealCost: false,
        setRealCostError: action.error,
      };
    default:
      return state;
  }
}

// Epics
export function executeGetCouriersOrdersEpic(action$: ActionsObservable<CourierTrackingAction>) {
  return action$.pipe(
    ofType(GET_COURIERS_ORDERS_REQUEST),
    debounceTime(600),
    switchMap(({ queryFilter }) =>
      getCouriersOrdersApi({ ...queryFilter }).pipe(
        takeUntil(action$.pipe(ofType(GET_COURIERS_ORDERS_REQUEST))),
        map((couriersOrders: ShipmentOrder[]) => executeGetCouriersOrdersSuccess(couriersOrders)),
        catchError((e: HTTPError) => of(executeGetCouriersOrdersError(e))),
      ),
    ),
  );
}

export function executeUpdateOrderItemInvoiceFileEpic(action$: ActionsObservable<CourierTrackingAction>) {
  return action$.ofType(UPDATE_ORDER_ITEM_INVOICE_REQUEST).pipe(
    mergeMap(({ orderItemId, invoiceFormData }) =>
      uploadInvoiceFileApi(invoiceFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).pipe(
        map((invoiceResponse: { storedFileId: string }) =>
          executeUpdateOrderItemInvoiceFileSuccess(orderItemId, invoiceResponse.storedFileId),
        ),
        catchError((e: HTTPError) => {
          let error: HTTPError = e;
          if (e.code === 413) {
            error = { ...e, data: { orderItemId } };
          }
          return of(executeUpdateOrderItemInvoiceError(error));
        }),
      ),
    ),
  );
}

export function executeUpdateOrderItemInvoiceEpic(action$: ActionsObservable<CourierTrackingAction>) {
  return action$.ofType(UPDATE_ORDER_ITEM_INVOICE_FILE_SUCCESS).pipe(
    mergeMap(({ orderItemId, invoiceId }) =>
      updateOrderItemInvoiceApi(
        { storedFileId: invoiceId },
        {
          route: endpoints.UPDATE_ORDER_ITEM_INVOICE.replace(':orderItemId', orderItemId),
        },
      ).pipe(
        map((couriersOrder: ShipmentOrder) => executeUpdateOrderItemInvoiceSuccess(couriersOrder)),
        catchError((e: HTTPError) => of(executeUpdateOrderItemInvoiceError(e))),
      ),
    ),
  );
}

export function executeUpdateOrderItemTrackingEpic(action$: ActionsObservable<CourierTrackingAction>) {
  return action$.ofType(UPDATE_ORDER_ITEM_TRACKING_REQUEST).pipe(
    mergeMap(({ orderItemId, trackingCourier, trackingNumber, trackingUrl }) =>
      updateOrderItemTrackingApi(
        { trackingCourier, trackingNumber, trackingUrl },
        {
          route: endpoints.UPDATE_ORDER_ITEM_TRACKING.replace(':orderItemId', orderItemId),
        },
      ).pipe(
        map(executeUpdateOrderItemTrackingSuccess),
        catchError((e: HTTPError) => of(executeUpdateOrderItemTrackingError(e))),
      ),
    ),
  );
}

export function executeUpdateOrderItemStoreOrderIdEpic(action$: ActionsObservable<CourierTrackingAction>) {
  return action$.ofType(UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_REQUEST).pipe(
    mergeMap(({ orderItemId, storeOrderId }) =>
      updateOrderItemStoreOrderIdApi(
        { storeOrderId: storeOrderId },
        {
          route: endpoints.UPDATE_ORDER_ITEM_TRACKING_ORDER_ID.replace(':orderItemId', orderItemId),
        },
      ).pipe(
        map(executeUpdateOrderItemStoreOrderIdSuccess),
        catchError((e: HTTPError) => of(executeUpdateOrderItemStoreOrderIdError(e))),
      ),
    ),
  );
}

export function executeCancelReservationEpic(action$: ActionsObservable<CourierTrackingAction>) {
  return action$.ofType(CANCEL_RESERVATION_REQUEST).pipe(
    mergeMap(({ shipmentId, orderId }) =>
      cancelReservationApi(undefined, {
        route: endpoints.CANCEL_RESERVATION.replace(':shipmentId', shipmentId),
      }).pipe(
        map(() => executeCancelReservationSuccess(orderId)),
        catchError((e: HTTPError) => of(executeCancelReservationError(e))),
      ),
    ),
  );
}

export function executeReceiveOrderItemEpic(action$: ActionsObservable<CourierTrackingAction>) {
  return action$.ofType(RECEIVE_ORDER_ITEM_REQUEST).pipe(
    mergeMap(({ orderItemId }) =>
      receiveOrderItemApi(undefined, {
        route: endpoints.RECEIVE_ORDER_ITEM.replace(':orderItemId', orderItemId),
      }).pipe(
        map((couriersOrder: ShipmentOrder) => executeReceiveOrderItemSuccess(couriersOrder)),
        catchError((e: HTTPError) => of(executeReceiveOrderItemError(e))),
      ),
    ),
  );
}

export function executeMissingTrackingEmailEpic(action$: ActionsObservable<CourierTrackingAction>) {
  return action$.ofType(MISSING_TRACKING_MAIL_REQUEST).pipe(
    mergeMap(({ orderId }) =>
      missingTrackingApi(undefined, {
        route: endpoints.MISSING_TRACKING.replace(':orderId', orderId),
      }).pipe(
        map(executeMissingTrackingMailSuccess),
        catchError((e: HTTPError) => of(executeMissingTrackingMailError(e))),
      ),
    ),
  );
}

export function getTrackingNotificationStatusEpic(action$: ActionsObservable<CourierTrackingAction>) {
  return action$.ofType(GET_TRACKING_NOTIFICATION_STATUS_REQUEST).pipe(
    mergeMap(({ orderId }) => getTrackingNotificationStatusApi(undefined, {
      route: endpoints.GET_TRACKING_NOTIFICATION_STATUS.replace(':orderId', orderId),
    }).pipe(
      map(({ enabled }) => getTrackingNotificationStatusSuccess(orderId, enabled)),
      catchError((e: HTTPError) => of(getTrackingNotificationStatusError(e))),
    )),
  );
}

export function postTrackingNotificationStatusEpic(action$: ActionsObservable<CourierTrackingAction>) {
  return action$.ofType(POST_TRACKING_NOTIFICATION_STATUS_REQUEST).pipe(
    mergeMap(({ orderId, trackingNotificationStatus }) => postTrackingNotificationStatusApi(
      { enabled: trackingNotificationStatus },
      { route: endpoints.POST_TRACKING_NOTIFICATION_STATUS.replace(':orderId', orderId) },
    ).pipe(
      map(() => postTrackingNotificationStatusSuccess(orderId)),
      catchError((e: HTTPError) => of(postTrackingNotificationStatusError(e))),
    )),
  );
}

export function executeSetRealCostEpic(action$: ActionsObservable<CourierTrackingAction>) {
  return action$.ofType(SET_REAL_COST_REQUEST).pipe(
    mergeMap(({ orderId, realCost, invoiceId }) => setRealCostApi({ total: realCost }, {
      route: endpoints.SET_REAL_COST.replace(':invoiceId', invoiceId),
    }).pipe(
      map(() => executeSetRealCostSuccess(orderId)),
      catchError((e: HTTPError) => of(executeSetRealCostError(e))),
    )),
  );
}

export function executeCourierTrackingStatusResetEpic(action$: ActionsObservable<CourierTrackingAction>) {
  return action$
    .ofType(
      GET_COURIERS_ORDERS_SUCCESS,
      UPDATE_ORDER_ITEM_INVOICE_FILE_SUCCESS,
      UPDATE_ORDER_ITEM_INVOICE_SUCCESS,
      UPDATE_ORDER_ITEM_TRACKING_SUCCESS,
      UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_SUCCESS,
      CANCEL_RESERVATION_SUCCESS,
      RECEIVE_ORDER_ITEM_SUCCESS,
      POST_TRACKING_NOTIFICATION_STATUS_SUCCESS,
      SET_REAL_COST_SUCCESS,
      GET_COURIERS_ORDERS_ERROR,
      UPDATE_ORDER_ITEM_INVOICE_ERROR,
      UPDATE_ORDER_ITEM_TRACKING_ERROR,
      UPDATE_ORDER_ITEM_TRACKING_ORDER_ID_ERROR,
      CANCEL_RESERVATION_ERROR,
      RECEIVE_ORDER_ITEM_ERROR,
      MISSING_TRACKING_MAIL_ERROR,
      POST_TRACKING_NOTIFICATION_STATUS_ERROR,
      SET_REAL_COST_ERROR,
    )
    .pipe(
      delay(3000),
      map(() => executeStatusReset()),
    );
}
