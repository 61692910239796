// @flow
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import AvailableOrderItem from '../components/AvailableOrderItem/AvailableOrderItem';
import update from 'immutability-helper';
import { FormValidator } from '../../../common/form';
import TablePaginationActions from '../../../elements/TablePaginationActions/TablePaginationActions';

import type { HTTPError } from '../../../common/error';
import type { AvailableOrderType } from "../duck";

type Props = {
  +classes: { [key: string]: mixed };
  +executeGetAvailableOrders: (queryFilter?: { [key: string]: string }) => void;
  +isLoadingGetAvailableOrders?: boolean;
  +availableOrders?: AvailableOrderType[];
  +getAvailableOrdersError?: HTTPError;
  +executeGetAvailableOrder: (orderId: string) => void;
  +isLoadingGetAvailableOrder?: boolean;
  +availableOrder?: AvailableOrderType;
  +getAvailableOrderError?: HTTPError;
}

type State = {
  search: {
    value: string,
    errors?: string[];
  };
  page: number,
  rowsPerPage: number,
}

const initialState: State = {
  search: {
    value: '',
  },
  page: 0,
  rowsPerPage: 100,
}

class AvailableOrdersList extends Component<Props, State> {
  searchFormValidator: FormValidator;

  constructor(props: Props) {
    super(props);
    this.searchFormValidator = new FormValidator({
      search: {
        orderIdFormat: 'orderIdFormat',
      },
    });
    this.state = initialState;
  }

  componentDidMount(): * {
    const { availableOrders, executeGetAvailableOrders, isLoadingGetAvailableOrders } = this.props;
    if (!availableOrders || !isLoadingGetAvailableOrders) {
      executeGetAvailableOrders();
    }
  }

  handleClick = (orderId: string) => {
    const { executeGetAvailableOrder } = this.props;
    executeGetAvailableOrder(orderId);
  };

  buildAvailableOrders = (availableOrders: AvailableOrderType[]) => {
    return availableOrders.map((availableOrder: AvailableOrderType, index: number) =>
      <AvailableOrderItem key={index}
                          availableOrder={availableOrder}
                          handleClick={this.handleClick}/>);
  };

  handleChange = (e: Event) => {
    const { name, value } = (e.target: { [key: string]: any });
    this.setState(prevState => update(prevState, {
      [name]: {
        value: { $set: value },
        $unset: ['errors'],
      },
    }));
  };

  handleChangePage = (e: Event, page: number) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({ page: 0, rowsPerPage: parseInt(value) });
  };

  handleOnKeyPress = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { charCode, target } = e;
    const { executeGetAvailableOrders } = this.props;
    if (charCode === 13) {
      if (target instanceof HTMLInputElement) {
        const { value } = target;
        if (value && value.length) {
          const errors = this.searchFormValidator.validateForm({ search: value });
          if (errors.length) {
            this.setState(prevState => update(prevState, {
              search: {
                errors: { $set: errors },
              },
            }));
          } else {
            const filter = { orderId: value };
            executeGetAvailableOrders(filter);
          }
        } else {
          executeGetAvailableOrders();
        }
      }
    }
  };

  handleReset = () => {
    const { executeGetAvailableOrders } = this.props;
    this.setState(initialState, () => executeGetAvailableOrders());
  };

  render() {
    const { classes, isLoadingGetAvailableOrders, availableOrders } = this.props;
    const { search, page, rowsPerPage } = this.state;
    const helperText = !!search.errors ? "Incorrect order id" : "Press enter to search";
    return (
      <div className={classes.listWrap}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Paper className={classes.toolBar}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <div className={classes.searchWrap}>
                    <div className={classes.searchIcon}>
                      <SearchIcon/>
                    </div>
                    <TextField placeholder="Search…"
                               value={search.value}
                               error={!!search.errors && !!search.errors}
                               helperText={helperText}
                               name="search"
                               onChange={this.handleChange}
                               onKeyPress={this.handleOnKeyPress}
                               classes={{
                                 root: classes.inputRoot,
                               }}/>
                  </div>
                </Grid>
                <Grid item>
                  <Button className={classes.buttonReset}
                          color="primary"
                          disabled={isLoadingGetAvailableOrders}
                          variant="contained"
                          size="large"
                          onClick={this.handleReset}>Reset</Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {(isLoadingGetAvailableOrders || !availableOrders) ?
            <CircularProgress className={classes.progress} size={80}/> :
            <Fragment>
              {this.buildAvailableOrders(
                availableOrders.slice(
                  page * rowsPerPage, page * rowsPerPage + rowsPerPage,
                ))}
              <Grid item xs={12}>
                <Paper>
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[100, 200, 300, 500]}
                    count={availableOrders.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </Paper>
              </Grid>
              {availableOrders.length === 0 &&
              <p className={classes.progress}>No orders available to ship</p>}
            </Fragment>}
        </Grid>
      </div>
    );
  }
}

AvailableOrdersList.propTypes = {
  classes: PropTypes.object.isRequired,
  executeGetAvailableOrders: PropTypes.func.isRequired,
  isLoadingGetAvailableOrders: PropTypes.bool,
  availableOrders: PropTypes.array,
  getAvailableOrdersError: PropTypes.object,
  executeGetAvailableOrder: PropTypes.func.isRequired,
  isLoadingGetAvailableOrder: PropTypes.bool,
  availableOrder: PropTypes.object,
  getAvailableOrderError: PropTypes.object,
};

export default withStyles(styles)(AvailableOrdersList);
