// @flow
import { Observable } from 'rxjs';
// import { getCreator, postCreator, putCreator, deleteCreator } from '@qempo/qempo-web-common/http';
import environment from '../../environment';
import type { Transaction } from '../../entities/webapp';
import apiOrders from './orders';

export const endpoints = Object.freeze({
  GET_ORDERS: `${environment.apiUrl}/administrative/order/list`,
});

export function searchTransaction(transactionId: string): Transaction {
  for (let i = 0; i < apiOrders.length; i++) {
    if (apiOrders[i].transactionId === transactionId) {
      return apiOrders[i];
    }
  }
}

// export const getTransactionsApi = getCreator(fetch, endpoints.GET_USERS);
export const getOrdersApi = (queryParams: any) => Observable.create(
  (observer) => {
    // console.log('getUsersApi:', `page: ${page},\tsize: ${size},\tord: ${ord},\tsort: ${sort}`);
    // let transactions;
    // const { page, size, ord, sort, filterId } = queryParams;
    //
    // transactions = stableSort(apiOrders, getSorting(ord, sort))
    //   .slice(page * size, page * size + size);
    const totalOrders = apiOrders.length;
    //
    // if (filterId !== '') {
    //   let preTransactions = [searchTransaction(filterId)];
    //   if (preTransactions[0] !== undefined) {
    //     transactions = preTransactions
    //   } else {
    //     transactions = [];
    //   }
    // }

    observer.next({ orders: apiOrders, totalOrders });
  });
