// @flow
import { map, mergeMap, catchError, delay } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { endpoints, getShipOrderApi, sendProductsApi } from './api';

import type { RootState } from '../index';
import type { HTTPError } from '../../common/error';

// Actions
const GET_SHIP_ORDER_REQUEST = 'GET_SHIP_ORDER_REQUEST';
const GET_SHIP_ORDER_SUCCESS = 'GET_SHIP_ORDER_SUCCESS';
const GET_SHIP_ORDER_ERROR = 'GET_SHIP_ORDER_ERROR';
const SEND_PRODUCTS_REQUEST = 'SEND_PRODUCTS_REQUEST';
const SEND_PRODUCTS_SUCCESS = 'SEND_PRODUCTS_SUCCESS';
const SEND_PRODUCTS_ERROR = 'SEND_PRODUCTS_ERROR';
const RESET_SHIP_PRODUCTS_STATE = 'RESET_SHIP_PRODUCTS_STATE';

type ShipProductsAction = {
  +type: string;
  +shipOrder?: { [key: string]: any };
  +shipProducts?: { [key: string]: any };
  +error?: HTTPError;
};

export type ShipProductsState = {
  isLoadingGetShipOrder?: boolean;
  getShipOrderSuccess?: boolean;
  getShipOrderError?: HTTPError;
  isLoadingSendProducts?: boolean;
  sendProductsSuccess?: boolean;
  sendProductsError?: HTTPError;
  shipOrder?: { [key: string]: any };
};

// Action Creators
export function executeGetShipOrder(id: string): ShipProductsAction {
  return {
    type: GET_SHIP_ORDER_REQUEST,
    id,
  };
}

export function executeGetShipOrderSuccess(shipOrder: { [key: string]: any }): ShipProductsAction {
  return {
    type: GET_SHIP_ORDER_SUCCESS,
    shipOrder,
  };
}

export function executeGetShipOrderError(error: HTTPError): ShipProductsAction {
  return {
    type: GET_SHIP_ORDER_ERROR,
    error,
  };
}

export function executeSendProducts(id: string, data: { [key: string]: any }): ShipProductsAction {
  return {
    type: SEND_PRODUCTS_REQUEST,
    id,
    data,
  };
}

export function executeSendProductsSuccess(shipOrder: { [key: string]: any }): ShipProductsAction {
  return {
    type: SEND_PRODUCTS_SUCCESS,
    shipOrder,
  };
}

export function executeSendProductsError(error: HTTPError): ShipProductsAction {
  return {
    type: SEND_PRODUCTS_ERROR,
    error,
  };
}

export function executeResetShipProductsState(): ShipProductsAction {
  return {
    type: RESET_SHIP_PRODUCTS_STATE,
  };
}

export const actions = {
  GET_SHIP_ORDER_REQUEST,
  GET_SHIP_ORDER_SUCCESS,
  GET_SHIP_ORDER_ERROR,
  SEND_PRODUCTS_REQUEST,
  SEND_PRODUCTS_SUCCESS,
  SEND_PRODUCTS_ERROR,
  RESET_SHIP_PRODUCTS_STATE,
  executeGetShipOrder,
  executeGetShipOrderSuccess,
  executeGetShipOrderError,
  executeSendProducts,
  executeSendProductsSuccess,
  executeSendProductsError,
  executeResetShipProductsState,
};

export const initialState: ShipProductsState = {};

// Reducer
export default function reducer(state: ShipProductsState = initialState,
                                action: ShipProductsAction): ShipProductsState {
  switch (action.type) {
    case GET_SHIP_ORDER_REQUEST:
      return {
        ...state,
        isLoadingGetShipOrder: true,
        getShipOrderSuccess: false,
      };
    case GET_SHIP_ORDER_SUCCESS:
      return {
        ...state,
        isLoadingGetShipOrder: false,
        getShipOrderSuccess: true,
        shipOrder: action.shipOrder,
      };
    case GET_SHIP_ORDER_ERROR:
      return {
        ...state,
        getShipOrderSuccess: false,
        getShipOrderError: action.error,
      };
    case SEND_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoadingSendProducts: true,
        sendProductsSuccess: false,
      };
    case SEND_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoadingSendProducts: false,
        sendProductsSuccess: true,
        shipOrder: action.shipOrder,
      };
    case SEND_PRODUCTS_ERROR:
      return {
        ...state,
        sendProductsSuccess: false,
        sendProductsError: action.error,
      };
    case RESET_SHIP_PRODUCTS_STATE:
      return initialState;
    default:
      return state;
  }
}

// Epics
export function executeGetShipOrderEpic(action$: ActionsObservable<ShipProductsAction>, state$: StateObservable<RootState>) {
  return action$.ofType(GET_SHIP_ORDER_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ id }) => getShipOrderApi(undefined, {
        route: endpoints.GET_SHIP_ORDERS.replace(':id', id),
      }).pipe(
        map((shipOrder: { [key: string]: any }) => executeGetShipOrderSuccess(shipOrder)),
        catchError((e: HTTPError) => of(executeGetShipOrderError(e))))
    )
  );
}

export function executeSendProductsEpic(action$: ActionsObservable<ShipProductsAction>) {
  return action$.ofType(SEND_PRODUCTS_REQUEST).pipe(
    delay(1000),
    mergeMap(
      ({ id, data }) => sendProductsApi(data, {
        route: endpoints.SEND_SHIP_PRODUCTS.replace(':id', id),
      }).pipe(
        map((data) => executeSendProductsSuccess(data)),
        catchError((e: HTTPError) => of(executeSendProductsError(e))))
    )
  );
}
