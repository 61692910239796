// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

type Props = {
  // +classes: { [key: string]: mixed };
  +onChangePage: (e: Event, page: number) => void;
  +count: number;
  +page: number;
  +rowsPerPage: number;
};

export default class TablePaginationActions extends Component<Props> {
  handleFirstPageButtonClick = (e: Event) => {
    this.props.onChangePage(e, 0);
  };

  handleBackButtonClick = (e: Event) => {
    this.props.onChangePage(e, this.props.page - 1);
  };

  handleNextButtonClick = (e: Event) => {
    this.props.onChangePage(e, this.props.page + 1);
  };

  handleLastPageButtonClick = (e: Event) => {
    this.props.onChangePage(
      e,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { count, page, rowsPerPage } = this.props;
    return (
      <div style={{flexShrink: 0}}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          <FirstPageIcon/>
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          <KeyboardArrowLeft/>
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          <KeyboardArrowRight/>
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          <LastPageIcon/>
        </IconButton>
      </div>
    )
  }
}

TablePaginationActions.propTypes = {
  // classes: PropTypes.object.isRequired,
  onChangePage: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}
