// @flow
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import update from 'immutability-helper';
import TextField from '@material-ui/core/TextField';
import red from '@material-ui/core/colors/red';
import { FormValidator } from '../../../common/form';

import styles from './style';

type Props = {
  +classes: { [key: string]: any };
  +handleCancelDeliveryConfirm: (message: string) => void;
  +closeModal: () => void;
  +open: boolean;
};

type State = {
  message: {
    value: string,
    errors?: string[],
  };
};

const innerTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    secondary: {
      main: red[500],
    },
  },
});

class DeliveryConfirmTableDialog extends PureComponent<Props, State> {
  cancellationFormValidator: FormValidator;
  state = {
    message: {
      value: '',
    },
  };

  constructor(props: Props) {
    super(props);
    this.cancellationFormValidator = new FormValidator({
      message: {
        required: 'required',
      },
    });
  }

  handleChange = (e: Event): void => {
    if (e.target instanceof HTMLInputElement) {
      const { value } = e.target;
      this.setState(prevState => update(prevState, {
        message: {
          value: { $set: value },
          $unset: ['errors'],
        },
      }));
    }
  };

  handleConfirmCancellation = (): void => {
    const { handleCancelDeliveryConfirm } = this.props;
    const { message } = this.state;
    const errors = this.cancellationFormValidator.validateForm({ message: message.value });
    if (errors.length) {
      this.setState(prevState => update(prevState, {
        message: {
          errors: { $set: errors },
        },
      }));
    } else {
      handleCancelDeliveryConfirm(message.value);
    }
  };

  handleClose = () => {
    const { closeModal } = this.props;
    closeModal();
    this.setState({
      message: {
        value: '',
      },
    });
  };


  render() {
    const { open } = this.props;
    const { message } = this.state;
    console.log('hello');
    return (
      <Dialog open={open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
          Why are you cancelling this shipment?
        </DialogTitle>
        <DialogContent>
          <TextField value={message.value}
                     error={message.errors && !!message.errors}
                     style={{
                       margin: 'auto',
                       marginTop: 1,
                     }}
                     inputProps={{
                       style: {
                         paddingTop: 8,
                         paddingBottom: 8,
                         fontSize: '0.8em',
                       },
                     }}
                     onChange={this.handleChange}
                     fullWidth
                     variant="outlined"
                     name="message"/>
        </DialogContent>
        <MuiThemeProvider theme={innerTheme}>
          <DialogActions>
            <Button onClick={this.handleClose} style={{ margin: 'auto' }}>
              CANCEL
            </Button>
            <Button color="secondary" onClick={this.handleConfirmCancellation} style={{ margin: 'auto' }}>
              CONFIRM
            </Button>
          </DialogActions>
        </MuiThemeProvider>
      </Dialog>);
  }
}

DeliveryConfirmTableDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCancelDeliveryConfirm: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DeliveryConfirmTableDialog);
