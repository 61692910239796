// @flow
import { Observable } from 'rxjs';
// import { getCreator, postCreator, putCreator, deleteCreator } from '@qempo/qempo-web-common/http';
import environment from '../../environment';
import apiProgressOrders from './progressOrders';

export const endpoints = Object.freeze({
  GET_PROGRESS_ORDERS: `${environment.apiUrl}/administrative/progress-orders/list`,
});

function desc(a, b, sort) {
  if (b[sort] < a[sort]) {
    return -1;
  }
  if (b[sort] > a[sort]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order: string, sort: string) {
  return order === 'desc' ? (a, b) => desc(a, b, sort) : (a, b) => -desc(a, b, sort);
}

export const getProgressOrdersApi = (queryParams: any) => Observable.create(
  (observer) => {
    let progressOrders;
    const { page, limit, ord, sort, filterId } = queryParams;

    progressOrders = stableSort(apiProgressOrders, getSorting(ord, sort))
      .slice(page * limit, page * limit + limit);
    const totalProgressOrders = apiProgressOrders.length;

    if (filterId !== '') {
      progressOrders = [apiProgressOrders.find(e => e.id === filterId)];
      if (progressOrders[0] === undefined) {
        progressOrders = [];
      }
    }

    observer.next({ progressOrders, totalProgressOrders });
  });

export const confirmReceptionApi = (data: any) => Observable.create(
  (observer) => {
    const { id } = data;
    const orderIndex = apiProgressOrders.findIndex(e => e.id === id);
    apiProgressOrders.splice(orderIndex, 1);
    observer.next(apiProgressOrders);
  });

export const shipOrdersApi = (data: any) => Observable.create(
  (observer) => {
    data.forEach(order => {
      const { id } = order;
      const orderIndex = apiProgressOrders.findIndex(e => e.id === id);
      apiProgressOrders.splice(orderIndex, 1);
    });
    observer.next(apiProgressOrders);
  });
