// @flow
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames/bind';
import styles from './styles';

import type { OrderItem } from "../../entities/webapp";
import type { Node } from 'React';

type Props = {
  +classes: { [key: string]: string };
  +items: OrderItem[];
};

function MultiImage(props: Props): Node {
  const { classes, items } = props;
  const itemsContent = items && [0, 1, 2, 3].map((n: number) => {
    if (items[n]) {
      return (
        <Fragment key={n}>
          <img className={classNames(classes.image, classes[`image-${n}`], {
            [classes.imageMulti]: items.length > 1,
          })}
               src={items[n].image}
               alt={items[n].name}/>
          {(n === 3 && items.length > 4) && <div className={classes.hasMore}>{`+${items.length - 4}`}</div>}
        </Fragment>
      )
    } else if (items.length > 1) {
      return <div key={n}
                  className={classNames({
                    [classes.default1]: n === 2,
                    [classes.default2]: n === 3,
                  })}/>
    }
    return false;
  });
  return (
    <div className={classNames(classes.multiImageWrap, {
      [classes.sizeAlone]: items.length === 1,
      [classes.sizeMulti]: items.length > 1,
    })}>
      {itemsContent}
    </div>
  )
}

MultiImage.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default withStyles(styles)(MultiImage);
